import PrivateLayout from 'components/Layouts/private'
import { CommonStyle } from 'styles/pages/Common'
import ProfileView from 'views/ProfileView'

const myProfile = () => {
  return (
    <PrivateLayout>
      {/* <BodyWrapper> */}
      <CommonStyle>
        <ProfileView />
      </CommonStyle>
      {/* </BodyWrapper> */}
    </PrivateLayout>
  )
}

export default myProfile

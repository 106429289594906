import styled from 'styled-components'

export const SplitStoresHyperlocalWrapper = styled.div`
  padding: 56px 71px;
  .ant-tabs-nav-wrap {
    width: 100%;
    div {
      width: 178px !important;

      div {
        width: 100%;
        text-align: center;
      }
    }
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary_label_active_text_color);
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: var(--primary_label_active_text_color);
  }
  .ant-tabs .ant-tabs-tab:hover {
    color: unset;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100% !important;
  }
  @media (max-width: 768px) {
    padding: 20px 0px;
    .ant-tabs-nav-wrap {
      div {
        justify-content: center;
        width: 50% !important;
      }
    }
  }
`
export const StoreMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  .mobile-items,
  .mobile-heading {
    display: none;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    gap: 0px;
    .mobile-items,
    .mobile-heading {
      display: block;
    }
    .web-heading {
      display: none;
    }
    .loadBtn {
      padding: 0px 20px;
    }
  }
`
export const MainBodyFilterContainer = styled.div`
  display: flex;
  gap: 32px;
`
export const MainBodyWrapper = styled.div`
  width: 100%;
  /* padding-top: 24.5px; */
  /* .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary_label_active_text_color);
  } */
  .load-more {
    margin-top: 20px;

    display: none;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  button {
    width: max-content;
  }
  .ant-pagination {
    background-color: white;
  }

  .ant-pagination-item {
    border: 1px solid black;
    color: black;
  }

  .ant-pagination-item-active {
    border-color: black;
    background-color: black;
  }

  .ant-pagination-item-active a {
    color: white;
  }
  .category-name {
    text-transform: capitalize;
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-pagination-item:hover,
  .ant-pagination-item:focus {
    border-color: black;
    background: white;
  }

  .ant-pagination-item:hover a,
  .ant-pagination-item:focus a {
    color: black;
  }
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link {
    border-color: black;
  }

  .content-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 40px 30px;
  }
  .content-item {
    /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 40px 30px; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .item-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    margin-top: auto;
  }
  .quantity-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .defaultImage {
    min-height: 242px;
  }
  .customizable-wrap {
    transform: translate(0px, -22px);
  }
  .custom-heading {
    margin-top: 6px;
  }
  .quantity {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 6px;
    width: 72px;
    height: 30px;
    border-radius: 8px;
    border: 1px solid var(--primary_button_stroke_color, #4f4e4d);
    background: var(--primary_box_fill_color);
    color: var(--secondary_label_active_text_color);
  }
  .discount-details {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .discount-percentage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 16px;
    border-radius: 8px;
    background: var(--primary_label_active_fill_color);
    color: #fff;
    padding: 0 0px;
  }
  .after-discount {
    color: #000;
  }
  .before-discount {
    text-decoration: line-through;
  }

  @media (max-width: 768px) {
    padding-top: 8px;
    padding-bottom: 80px;
    .ant-tabs {
      display: none;
    }
    .content-item {
      align-items: flex-start;
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 20px;
      border-bottom: 1px solid var(--primary_line_color_dark);
      padding: 16px 0 14px;
    }
    .load-more {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .loadBtn {
      padding: 0px 20px;
    }
    .pagination {
      display: none;
    }
    .content-list {
      grid-template-columns: 1fr;
    }
    .category-name {
      width: 200px;
    }
    .defaultImage {
      min-height: 100px;
    }
  }

  /* display: flex; */
  /* gap: 32px; */
`

export const RecomendedContainer = styled.div`
  margin-bottom: 24px;
  .mobile-item {
    display: none;
  }
  .heading {
    color: var(--primary_text_color_dark);
  }
  @media (max-width: 768px) {
    .mobile-item {
      display: block;
    }
    .web-item {
      display: none;
    }
  }
`
export const MenuTypeWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 32px 0;
  border-bottom: 1px solid var(--primary_button_stroke_color_light);
  @media (max-width: 768px) {
    border-bottom: unset;
    padding: 10px 0;
  }
`
export const ImageWrapper = styled.div`
  border-radius: 18px;
  overflow: hidden;
  .storeImge {
    max-height: 179px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  img {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    min-width: 96px;

    img {
      width: 96px;
      object-fit: cover;
      border-radius: 8px;
    }
  }
`
export const StoreContentContainer = styled.div`
  /* display: flex;
  gap: 80px; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 40px 30px;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
    padding-top: 20px;
  }
`
export const StoreContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  max-width: 200px;
  @media (max-width: 768px) {
    flex-direction: row;
    gap: 20px;
    /* justify-content: space-between; */
    max-width: 100%;
    cursor: pointer;
  }
`
export const StoreItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: var(--primary_text_color_dark);
  margin-top: auto;

  .store-distance-details {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
  }
  .offers,
  .rating {
    display: flex;
    align-items: center;
    gap: 3px;
  }
  .offer-heading {
    color: var(--primary_text_link_color);
  }
  .category-name {
    text-transform: capitalize;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 768px) {
  }
`
export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  transform: translate(0, -20px);

  @media (max-width: 768px) {
    button {
      color: var(--secondary_text_link_color);
      border: 1px solid var(--secondary_text_link_color) !important;
      background: #fff;
      width: 74px;
      height: 30px;
      border-radius: 8px;
    }
  }
`

export const StoreFilterWrapper = styled.div`
  width: 100%;
  max-width: 286px;
  border-right: 1px solid var(--primary_button_stroke_color_light);

  @media (max-width: 768px) {
    display: none;
  }
`
export const StoreFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 768px) {
  }
`
export const StoreNameHeading = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px 0 10px 30px;
  cursor: pointer;
  background: ${({ active }) =>
    active ? 'linear-gradient(90deg, rgba(255, 62, 107, 0) 0%, rgba(255, 62, 107, 0.25) 100%)' : 'transparent'};
  color: ${({ active }) => (active ? 'var(--primary_label_active_text_color)' : '')};

  /* color: var(--primary_label_active_text_color); */
  /* background: linear-gradient(90deg, rgba(255, 62, 107, 0) 0%, rgba(255, 62, 107, 0.25) 100%); */
  /* border-right: 1px solid var(--primary_label_active_text_color); */
  border-right: 1px solid
    ${({ active }) => (active ? 'var(--primary_label_active_text_color)' : 'var(--primary_button_stroke_color_light)')};
  @media (max-width: 768px) {
  }
`

import { Button, Select } from 'antd'
import FileUploader from 'components/FileUploader'
import SelectField from 'components/SelectField'
import APIS from 'constants/api'
import usePost from 'hooks/usePost'
import { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CommonPopupModalBodySection, Divider } from 'styles/components/PopupModal'
import { CommonStyle, FileUpload, LabelForm, Note } from 'styles/pages/Common'
import { ErrorMessage, TextLabel, TextWrapper } from 'styles/pages/Login'
import { AddressFormWrapper, FormFieldsWrapper } from 'styles/views/SaveAddress'
import Modal from 'components/Modal'
import { SnackBarContext } from 'context/SnackBarContext'
import TagManager from 'react-gtm-module'
import { pageData } from 'utils/datalayer/pageData'
import ChevronDown from 'assets/svg/DownArrow'
import { useTheme } from 'context/themeContext'

const PopupModal = ({
  open,
  onOk,
  onCancel,
  title,
  BtnName,
  getAllTickets,
}: {
  open: boolean
  onOk: any
  onCancel: any
  title: string
  BtnName: string
  getAllTickets: any
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      orderId: 'none',
      description: '',
      queryType: '',
      fileUpload: [],
    },
  })
  const { Option } = Select
  const [subjects, setSubjects] = useState<any>([])
  const { mutateAsync } = usePost()
  const { setShowSnackBar } = useContext(SnackBarContext)
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const customerId = localStorage.getItem('customerId')
  const [data, setData] = useState<any>([])
  const [orderOption, setOrderOptions] = useState<any>([])
  const [base64List, setBase64List] = useState<string[]>([])
  const [defaultValue, setDefaultValue] = useState<any[]>([])
  const { storesData } = useTheme()

  const getSubjectList = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.SUBJECT_LIST,
        payload: {
          customerId: customerId,
        },
      })
      if (res) {
        setSubjects(res.d?.ticketSubjects)
      }
    } catch (err: any) {
      throw new Error(err?.message)
    }
  }
  const getOrderIdList = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.ORDER_LIST,
        payload: {
          customerId: customerId,
        },
      })
      if (res) {
        setData(res.d?.orders)
      }
    } catch (err: any) {
      throw new Error(err?.message)
    }
  }
  const handleBase64ListChange = (newBase64List: string[]) => {
    setBase64List(newBase64List)
  }
  // const handleChange = (value: any) => {
  //   console.log(`${value}`)
  // }

  const submitRaiseTicket = async (data: any) => {
    try {
      const orderKeys = data?.orderId?.split('/')
      const selectedQuery = subjects.find((item: any) => item.id === data.query_type)
      const queryTypeName = selectedQuery ? selectedQuery.name : 'NA'
      const payload = {
        accountId: 0,
        content: data?.description,
        createdBy: 0,
        customerId: customerId,
        fileUpload: base64List?.[0] || '',
        orderId: orderKeys[0] ? orderKeys[0] : 0,
        orderItemId: orderKeys[2] ? orderKeys[2] : 0,
        shipmentId: orderKeys[1] ? orderKeys[1] : 0,
        status: 0,
        subjectId: data?.query_type,
        alternateOrderId: '',
        channelType: 'website',
      }
      const res = await mutateAsync({
        url: APIS.CREATE_TICKET,
        payload: payload,
      })

      if (res) {
        const initTicketDataToPush = {
          event: 'raise_ticket_submit',
          page_type: GDL_PAGE_TYPE,
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          cta_text: 'raise ticket',
          order_ticket_id: orderKeys[0] ? orderKeys[0] : 'NA',
          select_type: queryTypeName?.toLowerCase(),
        }
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: initTicketDataToPush })

        // setShowSnackBar({ open: true, message: `${res.m}`, severity: '' })
        setShowSnackBar({
          open: true,
          message: `We have received your complaint and we will update you within 24 hrs. Please find your complaint number ${res.d.ticketId}`,
          severity: '',
        })

        onCancel()
        reset()
        getAllTickets()
        setBase64List([])
      }
    } catch (err: any) {
      throw new Error(err?.message)
    }
  }

  useEffect(() => {
    getSubjectList()
    getOrderIdList()
  }, [customerId])

  useEffect(() => {
    const options_data: any = {
      order_id: [],
      ondc_order_id: [],
    }
    if (data) {
      data.forEach((order: any) => {
        order?.shipments?.forEach((shipments: any) => {
          shipments?.products.forEach((product: any) => {
            const option = {
              key: order.orderId + '/' + shipments.id + '/' + product.orderItemId,
              value: 'Order ' + order.orderId + ' / ' + product.orderItemId,
            }
            const optionOndc = {
              key: order.orderId + '/' + shipments.id + '/' + product.orderItemId,
              value: product.ondcOrderId,
            }
            options_data['order_id'].push(option)
            options_data['ondc_order_id'].push(optionOndc)
          })
        })
      })
      setOrderOptions(options_data)
    }
  }, [data])
  const handleDefaultValue = (fileData: any) => {
    setDefaultValue(fileData)
  }
  const handleCancle = () => {
    onCancel()
    reset()
    setDefaultValue([])
  }

  return (
    <Modal
      centered
      className="raiseTicketModal h1b"
      open={open}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button key="back" onClick={handleCancle}>
          Close
        </Button>,
        <Divider key="" />,
        <Button className="customButton" key="submit" type="primary" onClick={handleSubmit(submitRaiseTicket)}>
          <span className="h1b">{BtnName}</span>
        </Button>,
      ]}
    >
      <CommonPopupModalBodySection>
        <AddressFormWrapper onSubmit={handleSubmit(submitRaiseTicket)}>
          <FormFieldsWrapper className="paddingData">
            <CommonStyle>
              <TextWrapper className="textWrapper h2b">
                <SelectField
                  defaultValue={''}
                  className="selectInputBox"
                  control={control}
                  name="query_type"
                  suffixIcon={<ChevronDown />}
                >
                  {/* <SelectField defaultValue={''} onChange={handleChange} control={control} name="query_type"> */}
                  <Option key="" value="" attachment_required="">
                    Select Query
                  </Option>
                  {subjects?.map((item: any) => {
                    return (
                      <Option key={item.id} value={item.id} attachment_required={item.attachment_required}>
                        {item.name}
                      </Option>
                    )
                  })}
                </SelectField>
                <TextLabel className="h3b formLabel">Please Select Your Query</TextLabel>
                <Note>
                  (We request you to select the appropriate Query for Faster Resolution. If your Selection Category is
                  Others. make sure to specify the details of your request in the Subject Line.)
                </Note>
                <ErrorMessage>{errors?.message}</ErrorMessage>
              </TextWrapper>
              <TextWrapper className="textWrapper h2b">
                <SelectField defaultValue="none" control={control} name="orderId" suffixIcon={<ChevronDown />}>
                  {/* <SelectField defaultValue="none" onChange={handleChange} control={control} name="orderId"> */}
                  {orderOption?.order_id &&
                    orderOption?.order_id.map((item: any) => {
                      return (
                        <Option name="orderId" key={item.key} value={item.key}>
                          {item.value}
                        </Option>
                      )
                    })}
                  <Option key="none" value="none">
                    None
                  </Option>
                </SelectField>
                <TextLabel className="h3b formLabel">Order Id</TextLabel>
                <ErrorMessage></ErrorMessage>
              </TextWrapper>
              <TextWrapper className="textWrapper h2b">
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <textarea {...field} rows={5} placeholder="Description...." className="custom-textarea" />
                  )}
                />
                <TextLabel className="h3b formLabel">Description</TextLabel>
                <ErrorMessage>{errors?.message}</ErrorMessage>
              </TextWrapper>
              <TextWrapper className="textWrapper h2b">
                <LabelForm className="h3b formLabel">Attachment</LabelForm>
                <FileUpload>
                  <FileUploader
                    className="customFileUploader"
                    name="fileUpload"
                    control={control}
                    defaultValue={defaultValue}
                    handleDefault={handleDefaultValue}
                    onBase64ListChange={handleBase64ListChange}
                    length={1}
                  />
                </FileUpload>
                <ErrorMessage>{errors?.message}</ErrorMessage>
              </TextWrapper>
            </CommonStyle>
          </FormFieldsWrapper>
        </AddressFormWrapper>
      </CommonPopupModalBodySection>
    </Modal>
  )
}

export default PopupModal

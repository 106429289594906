// import { useTheme } from 'context/themeContext'
// import { FacebookOutlined, InstagramOutlined, TwitterOutlined, WhatsAppOutlined } from '@ant-design/icons'
// import { SocialIconWrappers } from 'styles/views/PdpDetailView'

// const SocialShareModal = () => {
//   const { themedata } = useTheme()

//   const socialIcons = themedata?.d?.objectDetails?.socialShare

//   return (
//     <SocialIconWrappers>
//       {socialIcons?.showFacebookIcon === '1' && <FacebookOutlined />}
//       {socialIcons?.showWhatsappIcon === '1' && <WhatsAppOutlined />}
//       {socialIcons?.showInstagramIcon === '1' && <InstagramOutlined />}
//       {socialIcons?.showTwitterIcon === '1' && <TwitterOutlined />}
//     </SocialIconWrappers>
//   )
// }

// export default SocialShareModal

import React, { useState, useEffect } from 'react'
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons'
import { useTheme } from 'context/themeContext'
import { SocialIconWrappers } from 'styles/views/PdpDetailView'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'

type SocialNetwork = {
  name: string
  url: string
  icon?: React.ReactNode
}

type SocialNetworks = {
  [key: string]: SocialNetwork | { [key: string]: SocialNetwork }
}

const socialNetworks: SocialNetworks = {
  twitter: {
    name: 'Twitter',
    icon: <TwitterOutlined className="share_twitter" />,
    url: `http://twitter.com/home?status={title}%20{url}`,
  },
  instagram: {
    name: 'Instagram',
    icon: <InstagramOutlined />,
    url: 'http://www.instagram.com/home?status={title}%20{url}',
  },
  facebook: {
    name: 'Facebook',
    icon: <FacebookOutlined />,
    url: 'http://www.facebook.com/sharer.php?u={url}&amp;t={title}',
  },
  email: {
    name: 'Email',
    icon: <TwitterOutlined />,
    url: 'mailto:?to=&amp;subject={title}&body={url}%0A{description}',
  },
  linkedin: {
    name: 'LinkedIn',
    icon: <LinkedinOutlined />,
    url: 'http://www.linkedin.com/shareArticle?mini=true&amp;url={url}&amp;title={title}&amp;ro=false&amp;summary={description}&amp;source=',
  },
  whatsapp: {
    name: 'Whatsapp',
    icon: <WhatsAppOutlined />,
    url: 'http://wa.me/?text={title}%20{url}',
  },
}

interface SocialShareProps {
  productDetails: {
    title: string
    description: string
    url: string
    image: string
    productId: string
    categoryName: any
    productName: string
  }
  networks?: string[] // Specify which networks to display
}

const SocialShareModal: React.FC<any> = ({ productDetails, networks = [] }) => {
  const [menuItems, setMenuItems] = useState<JSX.Element[]>([])
  const { themedata, storesData } = useTheme()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  // Get the social icon visibility configuration from the theme context
  const socialIcons = themedata?.d?.objectDetails?.socialShare || {}

  useEffect(() => {
    generateMenu()
  }, [])

  const generateMenu = () => {
    const items: JSX.Element[] = []
    const networkKeys = networks.length > 0 ? networks : Object.keys(socialNetworks)

    networkKeys.forEach((brand: any) => {
      const network = socialNetworks[brand]

      // Check if the icon should be displayed based on the configuration
      if (shouldDisplayIcon(brand, socialIcons)) {
        if (network && 'name' in network) {
          items.push(createMenuItem(brand, network as SocialNetwork))
        } else if (network) {
          // Handle sub-brands (e.g., Google with Gmail and Google Bookmarks)
          Object.keys(network).forEach((subBrand) => {
            items.push(createMenuItem(`${brand}-${subBrand}`, network[subBrand]))
          })
        }
      }
    })
    setMenuItems(items)
  }

  // Utility function to check if a particular social icon should be displayed
  const shouldDisplayIcon = (brand: string, socialIcons: any) => {
    switch (brand) {
      case 'twitter':
        return socialIcons?.showTwitterIcon !== '1'
      case 'facebook':
        return socialIcons?.showFacebookIcon !== '1'
      case 'instagram':
        return socialIcons?.showInstagramIcon === '1'
      case 'linkedin':
        return socialIcons?.showLinkedinIcon === '1'
      case 'whatsapp':
        return socialIcons?.showWhatsappIcon === '1'
      default:
        return false
    }
  }

  const handleSocialShare = (brand: string) => {
    const dataLayer = {
      event: 'product_share',
      page_type: `${GDL_PAGE_TYPE}`,
      User_Id: `${loggedIn}`,
      User_Status: `${loggedInStatus}`,
      cta_text: brand.toLowerCase(),
      product_name: productDetails?.productName?.toLowerCase(),
      product_id: productDetails?.productId,
      category: `${productDetails?.categoryName ? productDetails?.categoryName[0]?.toLowerCase() : 'na'}`,
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataLayer })
  }

  const createMenuItem = (brand: string, network: SocialNetwork) => {
    const shareUrl = network.url
      .replace(/\{url\}/, encodeURIComponent(window.location.href))
      .replace(/\{title\}/, encodeURIComponent(productDetails.productName))
      .replace(/\{description\}/, encodeURIComponent(productDetails.description))
      .replace(/\{image\}/, encodeURIComponent(productDetails.imgUrl))

    return (
      <a
        key={brand}
        className={`dropdown-item share-${network.name.toLowerCase()}`}
        href={shareUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleSocialShare(brand)}
      >
        <SocialIconWrappers>{network.icon}</SocialIconWrappers>
      </a>
    )
  }

  return (
    <div className="social-share">
      <SocialIconWrappers className="dropdown-menu">
        {menuItems.length > 0 ? menuItems : <p>No social networks available</p>}
      </SocialIconWrappers>
    </div>
  )
}

export default SocialShareModal

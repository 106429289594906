import styled from 'styled-components'

export const CommonPopupModalBodySection = styled.div`
  .custom-select {
    height: 40px !important;
    border-radius: 4px !important;
    border: 1px solid #edecec !important;
    color: var(--primary_text_color_dark) !important;
  }
  .custom-textarea {
    border-radius: 4px !important;
    border: 1px solid #edecec !important;
    color: var(--primary_text_color_dark) !important;
    padding: 10px;
    width: -webkit-fill-available;
  }

  .formLabel {
    color: var(--primary_text_color_darkgrey);
  }
  .textWrapper {
    margin-bottom: 16px;
    padding-top: unset !important;
  }
  .ant-select-selector {
    padding-top: unset !important;
    border-radius: 4px;
    border-color: #edecec !important;
  }
  .ant-select-arrow {
    transform: translateY(5px);
  }
  .ant-select-selection-item {
    font-weight: unset;
    font-size: 12px;
  }
  .ant-select:hover .ant-select-clear {
    opacity: 0;
  }
  .ant-select:hover .ant-select-arrow:not(:last-child) {
    opacity: 1;
  }
  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: 100%;
    border-radius: var(--small, 8px);
    border: 1px solid var(--primary_stroke_color_light);
    background: var(--primary_box_fill_highlight_color, #fff6f6);
  }
  .ant-upload-select .anticon {
    color: var(--primary_brand_color);
  }
  .ant-upload-select .anticon svg {
    width: 22px;
    height: 22px;
  }
  .ant-upload-select .ant-upload button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .upload-type {
    color: var(--primary_success_color);
  }
  .returnData {
    gap: 20px;
  }

  @media screen and (max-width: 768px) {
    .paddingData {
      width: -webkit-fill-available !important;
      padding: 0 21px;
      height: 84vh;
    }
    .returnData {
      width: calc(100vw - 42px);
      padding: 0 21px;
      height: 70vh;
      gap: 30px;
    }
  }
`
export const Divider = styled.div`
  width: 1px !important;
  height: 30px;
  background: #c7c6c6;
  margin: 0 10px;
`

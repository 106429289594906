import LoginDetailView from 'views/LoginDetailView'
import PrivateLayout from 'components/Layouts/private'

const LoginPage = () => {
  return (
    <PrivateLayout>
      <LoginDetailView />
    </PrivateLayout>
  )
}

export default LoginPage

import { useState } from 'react'
import DownArrow from '../../assets/svg/ChevronForward'
import {
  CommentUserSection,
  CommentWrapper,
  ReadMoreSection,
  TicketDeatiledSection,
  TicketDetails,
} from 'styles/components/Ticket'

function stripHtmlTags(html: any) {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html
  return tempDiv.innerText || tempDiv.textContent || ''
}

const CommentComponent = ({ data }: { data: any }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div>
      <CommentWrapper>
        <CommentUserSection>
          <TicketDetails>
            <div className="h1b commentBy">{data?.commentUser}</div>
            <div className="h3r commentedAt">{data?.createdAt}</div>
            <div className="row-divider"></div>
          </TicketDetails>
          <TicketDeatiledSection>
            {data.fileUploadUrl[0] ? <img src={data.fileUploadUrl[0]} /> : ''}
            <div className="ticket-details">
              <div className="h3r">{data.emailSubject}</div>
              <div className="h3r ">{data.shortDescription}</div>
              <div className={`h3r commentDescription ${isExpanded ? 'readMoreOpen' : ''}`}>
                {stripHtmlTags(data.commentDescription)}
              </div>
            </div>
          </TicketDeatiledSection>
          {data.commentDescription?.length > 214 && (
            <ReadMoreSection>
              <DownArrow className={`readMore ${isExpanded ? 'readLess' : ''}`} onClick={toggleExpand} />
            </ReadMoreSection>
          )}
        </CommentUserSection>
      </CommentWrapper>
    </div>
  )
}

export default CommentComponent

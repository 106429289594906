import styled from 'styled-components'

interface IStyledButton {
  variant?: 'contained' | 'text' | 'outline' | 'disabled' | 'standard'
}

const getButtonStyles = ({ variant }: IStyledButton) => {
  switch (variant) {
    case 'contained':
      return `
      background: var(--primary_button_active_fill_color);
      color: var(--primary_text_color_light);
      border: none;
      cursor: pointer;
      
      `
    case 'disabled':
      return `
      background: var(--primary_button_inactive_fill_color);
      color: var(--primary_text_color_light);
      border: none;
      cursor: not-allowed      
      `

    case 'outline':
      return `
      background:transparent;
      color: var(--primary_button_active_fill_color);
      border: 1px solid var(--primary_button_active_fill_color);
      cursor: pointer;
      `
    case 'text':
      return `
      background:transparent;
      color: var(--secondary_text_link_color);
      border: none;
      cursor: pointer;
      `
    case 'standard':
      return `
      background: var(--secondary_button_active_fill_color) !important;
      color: var(--primary_text_color_dark) !important;
      border: 1px solid var(--primary_stroke_color_light) !important;
      cursor: pointer;
      border-radius: 4px;
      `
    default:
      return `
      background: var(--primary_button_active_fill_color);
      color: var(--primary_text_color_light);
      border: none;
      cursor: pointer;
  `
  }
}

export const StyledButton = styled.button<IStyledButton>`
  ${({ variant }) => getButtonStyles({ variant })};
  width: 100%;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
`
export const CurrentLocationWrapper = styled.div`
  width: 208px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 16px;
  cursor: pointer;
  border: 1px solid var(--primary_label_active_stroke_color);
  background: var(--primary_box_fill_color);
  color: var(--primary_label_active_text_color);
`
export const LocationLabel = styled.div`
  /* //styleName: H1-B; */
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
  line-height: 17.57px;
`
export const ButtonWrapper = styled.div`
  width: 336px;
  padding: 8px 11px;
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  margin-top: auto;
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 24px);
  }
  @media (max-width: 425px) {
    width: calc(100vw - 24px);
  }
`
export const ReturnButtonWrapper = styled.div`
  width: 336px;
  padding: 8px 11px;
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  margin-top: auto;
  position: fixed;
  bottom: 120px;
  @media (max-width: 768px) {
    position: unset;
    width: calc(100vw - 24px);
  }
  @media (max-width: 425px) {
    width: calc(100vw - 24px);
  }
`
export const OssButtonWrapper = styled.div`
  width: 336px;
  padding: 8px 11px;
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  margin-top: auto;
  position: fixed;
  bottom: 33px;

  @media (max-width: 768px) {
    position: unset;
    width: calc(100vw - 24px);
  }
  @media (max-width: 425px) {
    width: calc(100vw - 24px);
  }
`
export const OssSubButtonWrapper = styled.div`
  width: 336px;
  padding: 8px 11px;
  background: var(--primary_box_fill_color);
  margin-top: auto;
  position: fixed;
  bottom: 80px;
  Button.subAction {
    border: 0;
  }

  @media (max-width: 768px) {
    /* position: unset; */
    width: calc(100vw - 24px);
  }
  @media (max-width: 425px) {
    width: calc(100vw - 24px);
    bottom: 48px;
  }
`
export const ButtonWrappers = styled.div`
  width: 336px;
  padding: 8px 11px;
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 24px);
  }
`

import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import PrivateLayout from 'components/Layouts/private'
import { HomeRoute } from 'constants/routes'
import BackArrow from 'assets/svg/BackArrow'
import { NotBackWrapper } from 'styles/pages/Login'
import {
  NotFoundPageWrapper,
  MainContainer,
  LogoWrapper,
  MainWrapper,
  Heading,
  SubHeading,
  Details,
} from 'styles/pages/NotFound'
import { useTheme } from 'context/themeContext'

const NotFoundPage = () => {
  const { themedata } = useTheme()

  const themeLogo = themedata?.d?.objectDetails?.header?.siteLogo?.logoImage

  const router = useHistory()
  const handleBack = () => {
    // router.goBack()
    history.go(-2)
  }
  const handleClick = () => {
    router.push(HomeRoute.path)
  }

  return (
    <PrivateLayout>
      <NotFoundPageWrapper>
        <NotBackWrapper onClick={handleBack}>
          <BackArrow />
          PAGE NOT FOUND
        </NotBackWrapper>
        <MainContainer>
          <LogoWrapper>
            <img src={themeLogo} alt="WebsiteLogo" />
          </LogoWrapper>
          <MainWrapper>
            <Heading>404</Heading>
            <SubHeading>Page Not Found</SubHeading>
            <Details>Page you are looking for is either moved or does not exist</Details>
          </MainWrapper>
          <Button label="Go To Home" variant="contained" type="submit" className="small" onClick={handleClick} />
        </MainContainer>
      </NotFoundPageWrapper>
    </PrivateLayout>
  )
}

export default NotFoundPage

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      // stroke={theme.primaryIconStaticColor}
      stroke="var(--primary_icon_static_color)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M6.88 16.887a.63.63 0 1 0 0-1.262.63.63 0 0 0 0 1.262ZM15.63 16.887a.63.63 0 1 0 0-1.262.63.63 0 0 0 0 1.262ZM1.875 3.125h2.5L6.25 13.75h10"
    />
    <path
      stroke="var(--primary_icon_static_color)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M6.25 11.25h9.745A.312.312 0 0 0 16.3 11l1.125-5.626a.312.312 0 0 0-.306-.373H5"
    />
  </svg>
)
export default SvgComponent

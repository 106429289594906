import CheckmarkCircle from 'assets/svg/CheckmarkCircle'
import HelpCircle from 'assets/svg/HelpCircle'
import RightArrow from 'assets/svg/RightArrow'

import {
  OrderHistoryWrapper,
  // MainHeading,
  OrderConfirmationWrapper,
  StatusWrapper,
  OrderStateWrapper,
} from 'styles/views/OrderHistory'
// import { orders } from './data'
import { useHistory } from 'react-router-dom'
import APIS from 'constants/api'
import { Tabs, Typography } from 'antd'
import {
  CancelOrderRoute,
  FailedViewOrderDetailsRoute,
  ReturnOrderRoute,
  ViewOrderDetailsRoute,
} from 'constants/routes'
import { useEffect, useState } from 'react'
import usePost from 'hooks/usePost'
import { IFailedOrder, IOrderData, IOrderItem, IProduct } from 'interfaces/pages/order'
import Button from 'components/Button'
import { useTheme } from 'context/themeContext'
import PendingIcon from 'assets/svg/PendingIcon'
import CancelledIcon from 'assets/svg/CancelledIcon'
import PopupModal from 'components/TicketModal'
import useGet from 'hooks/useGet'
import ReturnOrderModal from 'views/ReturnModal'
import { CommonStyle } from 'styles/pages/Common'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'

const OrderHistory = () => {
  const [open, setOpen] = useState(false)
  const [openReturnModal, setOpenReturnModal] = useState(false)
  const [orderData, setOrderData] = useState<IOrderData[]>([])
  const [failedOrderData, setFailedOrderData] = useState<IFailedOrder[]>([])
  const [orderEligibilityData, setOrderEligibilityData] = useState<any>([])
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  const [filter] = useState({
    page: 1,
    perPage: 48,
  })
  const customerId = localStorage.getItem('customerId')
  const navigate = useHistory()
  const { storesData } = useTheme()
  const { mutateAsync } = usePost()
  const { refetch: refetchTicket } = useGet('ticket-list', `${APIS.TICKET_LIST}?customerId=${customerId}`)
  const changeModal = () => {
    setOpen(true)
  }

  const handleOk = () => {
    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }
  const handleReturnOk = () => {
    setTimeout(() => {
      setOpenReturnModal(false)
    }, 3000)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const fetchOrderListData = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.ORDER_LIST,
        payload: {
          customerId: customerId,
          pageId: filter.page,
          perPage: filter.perPage,
        },
      })
      if (res) {
        setOrderData(res?.d?.orders)
        setOrderEligibilityData(res?.d)
      }
    } catch (error: any) {
      return error
    }
  }

  const fetchFailedOrderListData = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.FAILED_ORDER_LIST,
        payload: {
          customerId: customerId,
          pageId: filter.page,
          perPage: filter.perPage,
        },
      })
      if (res) {
        setFailedOrderData(res?.d?.orders)
      }
    } catch (error: any) {
      return error
    }
  }

  const handleTabChange = (key: string) => {
    const tabName = key === '1' ? 'Confirmed' : 'Cancelled'
    const tabDataToPush = {
      event: 'order_tabs_interaction',
      page_type: GDL_PAGE_TYPE,
      User_Status: loggedInStatus,
      User_Id: loggedIn,
      cta_text: tabName.toLowerCase(),
    }
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: tabDataToPush })
  }

  useEffect(() => {
    fetchOrderListData()
  }, [filter.page, window.location])

  useEffect(() => {
    fetchFailedOrderListData()
  }, [filter.page])

  const confirmedOrdersTab = {
    label: 'Confirmed',
    key: '1',
    onchange: handleTabChange,
    children: (
      <>
        {orderData && orderData?.length > 0 ? (
          <>
            {orderData?.map((order: IOrderData, index: number) => (
              <OrderConfirmationWrapper
                key={index}
                onClick={() => {
                  const tabDataToPush = {
                    event: 'view_order_detail',
                    page_type: GDL_PAGE_TYPE,
                    User_Status: loggedInStatus,
                    User_Id: loggedIn,
                    cta_text: 'view order detail',
                    order_ticket_id: order.orderId,
                    select_type: 'expand',
                  }
                  storesData?.d?.googleDatalayer &&
                    storesData?.d?.googleDatalayer == 1 &&
                    TagManager.dataLayer({ dataLayer: tabDataToPush })
                  navigate.replace(`${ViewOrderDetailsRoute.path}?id=${order.orderId.toString()}`)
                  localStorage.setItem('orderId', order.orderId.toString())
                }}
              >
                <div className="confirmed-wrap">
                  <div className="confirm-order">
                    {order?.shipments[0]?.products[0].statusLabel == 'Pending' ? <PendingIcon /> : <CheckmarkCircle />}

                    {/* <PendingIcon />
                    <CheckmarkCircle /> */}
                    <div className="orders-details">
                      <StatusWrapper className="h1b" status={order?.shipments[0]?.products[0]?.statusLabel}>
                        {order?.shipments[0]?.products[0].statusLabel}
                      </StatusWrapper>

                      <label className="h3b">
                        Order: {order.orderId} ({order?.shipments[0]?.products?.length} Item
                        {order?.shipments[0]?.products?.length > 1 ? '(s)' : ''})
                      </label>
                      <label className="h3r">Placed on {order.orderDate}</label>
                    </div>
                  </div>
                  <RightArrow />
                </div>
                {order?.shipments[0]?.products?.map((item: IProduct, shipmentIndex: number) => {
                  let imgUrl =
                    item?.image?.includes('http://') || item?.image?.includes('https://')
                      ? item?.image
                      : `https://cdnaz.plotch.io/image/upload/w_118,h_118${item?.image}`
                  if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
                    imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
                    imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
                  }

                  const isStatusCodeValid =
                    item?.statusCode === 1 ||
                    (storesData?.d?.ondcEnabled === '1' && (item?.statusCode === 4 || item?.statusCode === 1))

                  const statusListEligibleNumbers = orderEligibilityData?.statusListEligible
                  const isReturnable = item?.isReturnable === 'true'
                  const deliveredOn = order?.shipments[0]?.deliveredOn // Extract the deliveredOn date
                  const returnTimeInDays = 7 // Define the return time limit

                  // Calculate the return eligibility
                  const deliveryDate = new Date(deliveredOn)
                  const currentDate = new Date()

                  const diffInMs = currentDate.getTime() - deliveryDate.getTime() // Get difference in milliseconds
                  const diffInDays = diffInMs / (1000 * 60 * 60 * 24) // Convert milliseconds to days

                  const canReturn = diffInDays <= returnTimeInDays

                  // Determine if return is still valid and set dl value
                  // if (isReturnable && canReturn) {
                  //   let dl = 1
                  // } else if (item.statusCode == 6) {
                  //   let dl = 0
                  // }

                  const productName = item?.name
                    .replace(/[^a-zA-Z ]/g, '')
                    .split(' ')
                    .join('-')

                  return (
                    <div className="confirmed-wrap order-state-type" key={shipmentIndex}>
                      <div className="confirm-order">
                        <img src={imgUrl} />
                        <div className="orders-details">
                          <div className="order-name-detail">
                            <label className="h3r item-name">
                              <Typography.Text ellipsis={{ tooltip: item.name }} className="h3r item-name">
                                {item.name.toLowerCase()}
                              </Typography.Text>
                              ({item?.qty} Qty)
                            </label>
                            <HelpCircle
                              onClick={(e: any) => {
                                const initTicketDataToPush = {
                                  event: 'raise_ticket_initiate',
                                  page_type: GDL_PAGE_TYPE,
                                  User_Id: loggedIn,
                                  User_Status: loggedInStatus,
                                  cta_text: 'get help',
                                  order_ticket_id: order.orderId ? order.orderId : 'NA',
                                }
                                e.stopPropagation()
                                storesData?.d?.googleDatalayer &&
                                  storesData?.d?.googleDatalayer == 1 &&
                                  TagManager.dataLayer({ dataLayer: initTicketDataToPush })

                                changeModal()
                              }}
                              className="help-icon"
                            />
                          </div>
                          <div className="cancellable-wrap">
                            <div className="status-wrap">
                              {item.statusCode != 8 && (
                                <label className="h3r">
                                  {[7, 11, 12, 14, 15, 16, 30]?.includes(item?.statusCode)
                                    ? 'Delivered on ' + order?.shipments[0]?.deliveredOn
                                    : 'Delivered By ' + order?.shipments[0]?.expectedDelivery}
                                </label>
                              )}
                              <OrderStateWrapper className="h4b" state={item.statusLabel}>
                                {item.statusLabel}
                              </OrderStateWrapper>
                            </div>
                            {isStatusCodeValid
                              ? item?.isCancellable === 1 && (
                                  <Button
                                    label="Cancel"
                                    variant="outline"
                                    type="submit"
                                    className="small h2b"
                                    onClick={(e: any) => {
                                      e.stopPropagation()
                                      navigate.push(CancelOrderRoute.path, { state: order?.shipments[0] })
                                    }}
                                  />
                                )
                              : null}

                            {isReturnable && item?.statusCode == statusListEligibleNumbers && canReturn && (
                              <Button
                                label="Return"
                                variant="outline"
                                type="submit"
                                className="small h2b"
                                onClick={(e: any) => {
                                  e.stopPropagation()
                                  // Determine the dl value
                                  // const dl = canReturn ? 1 : 0

                                  // Create the query parameters
                                  const queryParams = new URLSearchParams({
                                    id: item?.orderItemId.toString(),
                                    // id: order.orderId.toString(),
                                    // dl: dl.toString(),
                                    // orderItemId: o,
                                  })
                                  navigate.replace(
                                    // `${ReturnOrderRoute.path}?id=${order.orderId.toString()}?dl=${canReturn ? 1 : 0}`,
                                    `${ReturnOrderRoute.path}?${queryParams.toString()}`,
                                  )
                                  localStorage.setItem('orderItemId', item?.orderItemId.toString())
                                  localStorage.setItem('orderId', order.orderId.toString())
                                  localStorage.setItem('productId', order.orderId.toString())
                                  localStorage.setItem('productName', productName)
                                }}
                              />
                            )}
                          </div>
                          {!isReturnable && <label className="h4b non-returnable">Item is non-returnable</label>}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </OrderConfirmationWrapper>
            ))}
          </>
        ) : (
          <div className="no-order">No Orders!</div>
        )}
      </>
    ),
  }

  const cancelledOrdersTab = {
    label: 'Cancelled',
    key: '2',
    onchange: handleTabChange,

    children: (
      <>
        {failedOrderData && failedOrderData?.length > 0 ? (
          <>
            {failedOrderData?.map((failedOrder: IFailedOrder, index: number) => {
              const id = failedOrder.orderId.toString()
              const orderId = failedOrder.items[0].orderItemId.toString()

              return (
                <OrderConfirmationWrapper
                  key={index}
                  onClick={() => {
                    const tabDataToPush = {
                      event: 'view_order_detail',
                      page_type: GDL_PAGE_TYPE,
                      User_Status: loggedInStatus,
                      User_Id: loggedIn,
                      cta_text: 'view order detail',
                      order_ticket_id: failedOrder.orderId,
                      select_type: 'na',
                    }
                    storesData?.d?.googleDatalayer &&
                      storesData?.d?.googleDatalayer == 1 &&
                      TagManager.dataLayer({ dataLayer: tabDataToPush })
                    navigate.replace(`${FailedViewOrderDetailsRoute.path}?id=${id}&orderId=${orderId}`)

                    localStorage.setItem('orderId', failedOrder.orderId.toString())
                    localStorage.setItem('orderItemId', failedOrder.items[0].orderItemId.toString())
                  }}
                >
                  <div className="confirmed-wrap">
                    <div className="confirm-order">
                      {/* <div style={{ width: '20px' }} /> */}
                      <CancelledIcon />
                      <div className="orders-details">
                        <StatusWrapper className="h1b" status="Cancelled">
                          Cancelled
                        </StatusWrapper>
                        <label className="h3b">
                          Order: {failedOrder.orderId} ({failedOrder?.items?.length} Item
                          {failedOrder?.items?.length > 1 ? '(s)' : ''})
                        </label>
                        <label className="h3r">Placed on {failedOrder.items[0]?.createdAt}</label>
                      </div>
                    </div>
                    <RightArrow />
                  </div>
                  {failedOrder?.items?.map((item: IOrderItem, shipmentIndex: number) => {
                    let imgUrl =
                      item?.image?.includes('http://') || item?.image?.includes('https://')
                        ? item?.image
                        : `https://cdnaz.plotch.io/image/upload/w_118,h_118${item?.image}`
                    if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
                      imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
                      imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
                    }

                    return (
                      <div className="confirmed-wrap order-state-type" key={shipmentIndex}>
                        <div className="confirm-order">
                          <img src={imgUrl} />
                          <div className="orders-details">
                            <div className="order-name-detail">
                              <label className="h3r item-name">
                                {item.name.length > 22
                                  ? `${item.name.toLowerCase().slice(0, 22)} ...`
                                  : item.name.toLowerCase()}
                                ({item?.qty} Qty)
                              </label>
                              <HelpCircle
                                // onClick={changeModal}
                                onClick={(e: any) => {
                                  const initTicketDataToPush = {
                                    event: 'raise_ticket_initiate',
                                    page_type: GDL_PAGE_TYPE,
                                    User_Id: loggedIn,
                                    User_Status: loggedInStatus,
                                    cta_text: 'get help',
                                    order_ticket_id: failedOrder.orderId ? failedOrder.orderId : 'NA',
                                  }

                                  e.stopPropagation()
                                  storesData?.d?.googleDatalayer &&
                                    storesData?.d?.googleDatalayer == 1 &&
                                    TagManager.dataLayer({ dataLayer: initTicketDataToPush })

                                  changeModal()
                                }}
                                className="help-icon"
                              />
                            </div>

                            <label className="h3r">{item?.createdAt}</label>
                            <OrderStateWrapper className="h4b" state={item.statusLabel}>
                              {item.statusLabel}
                            </OrderStateWrapper>
                          </div>
                        </div>
                        {/* <HelpCircle /> */}
                      </div>
                    )
                  })}
                </OrderConfirmationWrapper>
              )
            })}
          </>
        ) : (
          <div className="no-order">No Orders!</div>
        )}
      </>
    ),
  }

  const tabItems = [confirmedOrdersTab, cancelledOrdersTab]

  return (
    <OrderHistoryWrapper>
      {/* {orderData && orderData.length > 0 ? ( */}
      <Tabs defaultActiveKey="1" items={tabItems} onChange={handleTabChange} />

      {/* ) : (
        <div className="no-order">No Orders!</div>
      )} */}
      <PopupModal
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Raise Ticket"
        BtnName="Raise Ticket"
        getAllTickets={refetchTicket}
      />
      <CommonStyle>
        <ReturnOrderModal
          open={openReturnModal}
          onOk={handleReturnOk}
          onCancel={() => {
            setOpenReturnModal(false)
          }}
          title="Return Item"
          BtnName="Return Item"
          getAllTickets={refetchTicket}
        />
      </CommonStyle>
    </OrderHistoryWrapper>
  )
}

export default OrderHistory

import { useTheme } from 'context/themeContext'
import DOMPurify from 'dompurify'
import Marquee from 'react-fast-marquee'
import { AnnouncementWrapper } from 'styles/components/Announcements'

const AnnouncementsSection = ({ viewType }: { viewType: string }) => {
  const { storesData } = useTheme()
  const announcementData = storesData?.d?.announcements
  const filteredData = announcementData?.filter((item: any) => item.viewType === viewType)

  return (
    <div>
      <div>
        {filteredData?.map((announcementData: any, index: number) => {
          const commonStyle = {
            backgroundColor: announcementData.backgroundColor,
            borderColor: announcementData.borderColor,
            fontFamily: announcementData.fontFamily,
            borderStyle: 'solid',
            color: announcementData.descriptionColor,
            // padding: '10px',
            overflow: 'hidden',
          }

          return (
            <AnnouncementWrapper key={index}>
              {announcementData?.style === 'static' ? (
                <>
                  {announcementData?.targetUrl ? (
                    <a href={announcementData?.targetUrl} target="_self" className="plotch_a_link">
                      <div
                        style={commonStyle}
                        className="desktop-announcement"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(announcementData?.description) }}
                      />
                    </a>
                  ) : (
                    <div
                      style={commonStyle}
                      className="desktop-announcement"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(announcementData?.description) }}
                    />
                  )}

                  {announcementData?.targetUrl ? (
                    <a href={announcementData?.targetUrl} target="_self" className="plotch_a_link">
                      <div
                        style={commonStyle}
                        className="mobile-announcement"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(announcementData?.mobileDescription) }}
                      />
                    </a>
                  ) : (
                    <div
                      style={commonStyle}
                      className="mobile-announcement"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(announcementData?.mobileDescription) }}
                    />
                  )}
                </>
              ) : (
                <Marquee style={commonStyle} gradient={false}>
                  {announcementData?.targetUrl ? (
                    <a href={announcementData?.targetUrl} target="_self" className="plotch_a_link">
                      <div
                        style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(announcementData.description) }}
                      />
                    </a>
                  ) : (
                    <div
                      style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(announcementData.description) }}
                    />
                  )}
                  {announcementData?.targetUrl ? (
                    <a href={announcementData?.targetUrl} target="_self" className="plotch_a_link">
                      <div
                        style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                        className="mobile-announcement"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(announcementData?.mobileDescription) }}
                      />
                    </a>
                  ) : (
                    <div
                      style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
                      className="mobile-announcement"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(announcementData?.mobileDescription) }}
                    />
                  )}
                </Marquee>
              )}
            </AnnouncementWrapper>
          )
        })}
      </div>
    </div>
  )
}

export default AnnouncementsSection

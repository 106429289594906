import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      // stroke={theme.primaryBrandColor}
      stroke="#FF3E6B"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M17.5 10c0-4.14-3.36-7.5-7.5-7.5-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5Z"
    />
    <path
      stroke="#FF3E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M9.776 6.486 10 11.25l.224-4.764a.223.223 0 0 0-.226-.235v0a.224.224 0 0 0-.222.235v0Z"
    />
    <path stroke="#FF3E6B" d="M10.004 14.38a.786.786 0 1 1 0-1.572.786.786 0 0 1 0 1.571Z" />
  </svg>
)
export default SvgComponent

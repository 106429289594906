import { type FC, useState, useEffect, useContext } from 'react'
import { Input, Button, Skeleton } from 'antd'
import APIS from 'constants/api'
import { SnackBarContext } from 'context/SnackBarContext'
import usePost from 'hooks/usePost'
import useGet from 'hooks/useGet'
import CheckmarkCircle from 'assets/svg/CheckmarkCircle'
import CutIcon from 'assets/svg/CutIcon'
import CircleIcon from 'assets/svg/CircleIcon'
import {
  CouponDetailsWrapper,
  BestCouponWrapper,
  CouponNameWrapper,
  BestCouponContainer,
  RemoveWrapper,
} from 'styles/views/CouponDetails'
import { ErrorMessage, ValidMessage } from 'styles/pages/Login'
import { DividerLine } from 'styles/views/PaymentOrderDetails'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
import { useTheme } from 'context/themeContext'

interface ICouponDetails {
  setCoupon: (val: string) => void
  coupon: string
}

const CouponDetails: FC<ICouponDetails> = ({ setCoupon, coupon }) => {
  const [error, setError] = useState('')
  const [isValid, setIsValid] = useState('')
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number | null>(null)
  const [promoCoupons, setPromoCoupons] = useState<any>(null)
  const { setShowSnackBar } = useContext(SnackBarContext)
  const { mutateAsync } = usePost()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const { storesData } = useTheme()
  const { refetch: refetchPaymentOption, data } = useGet('quote-details', `${APIS.CHECKOUT_QUOTE_DETAILS}`)

  const handleCouponSelect = (index: number) => {
    const couponCode = promoCoupons?.[index]?.coupon_code
    setSelectedAddressIndex(index)
    setCoupon(couponCode)
    setIsValid('')
    setError('')
  }

  const checkCoupanClick = async () => {
    if (!coupon) {
      // If the input is blank, reset both error and valid states
      setIsValid('')
      setError('')
      return
    }

    try {
      const res = await mutateAsync({
        url: APIS.CHECK_COUPAN,
        payload: {
          customerId: localStorage.getItem('customerId'),
          couponCode: coupon,
        },
      })
      if (res) {
        const applyCoupanDataToPush = {
          event: 'apply_coupon',
          page_type: GDL_PAGE_TYPE,
          User_Status: loggedInStatus,
          User_Id: loggedIn,
          cta_text: 'check coupon validity',
          select_type: coupon,
        }
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: applyCoupanDataToPush })
        setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
        setError('')
        setIsValid('Coupon is valid')
      }
    } catch (error) {
      setIsValid('')
      setError('Sorry this coupon is not valid')
    }
  }

  const removeCoupanClick = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.REMOVE_COUPAN,
        payload: {
          customerId: localStorage.getItem('customerId'),
        },
      })
      if (res) {
        setSelectedAddressIndex(null)
        setCoupon('')
        setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
      }
    } catch (error) {
      setError('Failed to remove coupon')
    }
  }

  useEffect(() => {
    // Fetch the coupon data when component mounts
    refetchPaymentOption()
  }, [refetchPaymentOption])

  useEffect(() => {
    if (data) {
      setPromoCoupons(data?.d?.promoCoupons || [])
      const savedCouponCode = data?.d?.couponCode
      if (savedCouponCode) {
        setCoupon(savedCouponCode)
        const selectedIndex = data?.d?.promoCoupons.findIndex((coupon: any) => coupon.coupon_code === savedCouponCode)
        if (selectedIndex !== -1) {
          setSelectedAddressIndex(selectedIndex)
        }
      }
    }
  }, [data, setCoupon])

  useEffect(() => {
    const matchingIndex = promoCoupons?.findIndex((item: any) => item.coupon_code === coupon)
    if (matchingIndex === -1) {
      setSelectedAddressIndex(null)
    }
  }, [coupon, promoCoupons])

  return (
    <>
      <CouponDetailsWrapper>
        <div className="coupan-wrapper">
          <Input
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
            placeholder="Enter coupon code"
            addonAfter={
              <Button type="link" style={{ color: 'red' }} onClick={checkCoupanClick}>
                CHECK
              </Button>
            }
          />
          {isValid && <ValidMessage className="h3b">{isValid}</ValidMessage>}
          {error && <ErrorMessage className="h3b">{error}</ErrorMessage>}
        </div>
        <DividerLine />
        {promoCoupons?.length > 0 ? (
          <BestCouponWrapper>
            <label className="h1b">Best Coupons For You</label>
            <BestCouponContainer>
              {promoCoupons ? (
                promoCoupons?.map(
                  (items: { coupon_code: string; discount_type: string; rule_name: string }, index: number) => (
                    <div key={index} className="available-coupon-wrapper" onClick={() => handleCouponSelect(index)}>
                      {selectedAddressIndex === index ? <CheckmarkCircle /> : <CircleIcon />}
                      <div className="available-coupon-container">
                        <RemoveWrapper>
                          <CouponNameWrapper className="coupon-name-wrapper" isActive={selectedAddressIndex === index}>
                            <div className="coupan-name h1b">{items?.coupon_code}</div>
                            <CutIcon />
                          </CouponNameWrapper>
                          {selectedAddressIndex === index && (
                            <div className="remove-coupan" onClick={removeCoupanClick}>
                              Remove
                            </div>
                          )}
                        </RemoveWrapper>
                        <label className="h1b color-style">{items?.rule_name}</label>
                        <label className="h1b color-style">
                          Expires on: <span>{items.discount_type}</span>
                        </label>
                      </div>
                    </div>
                  ),
                )
              ) : (
                <div>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </div>
              )}
            </BestCouponContainer>
          </BestCouponWrapper>
        ) : (
          <BestCouponWrapper>
            <BestCouponContainer className="no-couponsAvailable" />
          </BestCouponWrapper>
        )}
      </CouponDetailsWrapper>
    </>
  )
}

export default CouponDetails

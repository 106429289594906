import CloseIcon from 'assets/svg/CloseIcon'
import VegImage from 'assets/images/veg-img.png'
import CheckedImg from 'assets/images/checkmark.circle.png'
import UncheckedCircle from 'assets/images/circle.png'
import React, { useEffect, useState } from 'react'
import {
  CustomizedMainWrapper,
  MainHeadingWrappee,
  ContentMainWrapper,
  TopHeadingWrapper,
  SizeMainWrapper,
  SizeTypeWrapper,
  SizeSubTypeWrapper,
  HeadingWrapper,
  ToppingType,
  MobileCheckoutButtonwrapper,
  MainWrapperAddOn,
} from 'styles/views/CustomizableItems'
import Line from 'assets/svg/Line'
import Button from 'components/Button'

interface ProductConfig {
  min: string
  max: string
  seq: string
  input: string
}
interface Addon {
  child: number[]
  ondc_child?: string[]
  price: string
  product_name: string
  id: string
}
interface ProductGroup {
  name: string
  parent: string[]
  custom_group_id: number
  ondc_group_id: string
  addons: Addon[]
  config: ProductConfig
}
interface GroupData {
  [key: string]: ProductGroup
}
interface ProductListProps {
  groupData: GroupData
  initialGroupId?: number
  onPriceChange?: any
  setAddons?: any
}
interface SelectProductobj {
  productParentId: string
  addonId: string
  isMultiple: boolean
  price: number
  parents: string[]
  product_id: string
  product_name: string
  ondc_group_id: string
}
let price = 0
const ProductList: React.FC<ProductListProps> = ({ groupData, initialGroupId, onPriceChange, setAddons }) => {
  const [selectedProducts, setSelectedProducts] = useState<SelectProductobj[]>([
    {
      productParentId: '-1',
      addonId: initialGroupId?.toString() as string,
      isMultiple: false,
      price: 0,
      parents: [],
      product_id: '0',
      product_name: '',
      ondc_group_id: '',
    },
  ])
  // const removeAddedAddon = (productParentId: string) => {

  // }
  // const handleSelectionChange = (
  //   productParentId: string,
  //   addonId: string,
  //   isMultiple: boolean,
  //   price: number,
  //   parents: string[],
  //   product_id: string,
  //   product_name: string,
  //   ondc_group_id: string,
  // ) => {
  //   setSelectedProducts((prev) => {
  //     const currentParentSelections = prev.filter((product) => product.productParentId === productParentId)
  //     const groupConfig = groupData[productParentId].config
  //     // const addon = groupData[productParentId].addons.find((addon) => addon.id === addonId)
  //     // const addonPrice = parseInt(addon?.price || '0')
  //     const isAlreadySelected = currentParentSelections.some((product) => product.addonId === addonId)
  //     if (isAlreadySelected) {
  //       // Deselect the addon if it's already selected
  //       const newSelections = prev.filter(
  //         (product) =>
  //           !(
  //             (product.productParentId === productParentId && product.addonId === addonId) ||
  //             product.productParentId === addonId
  //           ),
  //       )

  //       // onPriceChange(-price) // Subtract the deselected addon's price from the total
  //       return newSelections
  //     } else {
  //       let newSelections = []
  //       if (!isMultiple) {
  //         // Replace the selection if only one selection is allowed
  //         const previousSelection = prev.find((product) => product.productParentId === productParentId)
  //         const removeChildAddon: any = []
  //         if (previousSelection) {
  //           const prevParentChild = prev.filter((product) => product.productParentId == previousSelection?.addonId)
  //           prevParentChild.map((item) => {
  //             removeChildAddon.push(item.addonId)
  //           })
  //         }

  //         newSelections = prev.filter(
  //           (product) =>
  //             product.productParentId !== productParentId &&
  //             product.productParentId != addonId &&
  //             product.productParentId !== previousSelection?.addonId,
  //         )
  //         newSelections = newSelections.filter((product) => !removeChildAddon.includes(product.productParentId))
  //         newSelections.push({
  //           productParentId,
  //           addonId,
  //           isMultiple,
  //           price,
  //           parents,
  //           product_id,
  //           product_name,
  //           ondc_group_id,
  //         })
  //       } else {
  //         // Add the new selection if multiple selections are allowed
  //         newSelections = [
  //           ...prev,
  //           { productParentId, addonId, isMultiple, price, parents, product_id, product_name, ondc_group_id },
  //         ]
  //       }

  //       // Ensure the selections do not exceed the max allowed
  //       if (
  //         newSelections.filter((product) => product.productParentId === productParentId).length >
  //         parseInt(groupConfig.max)
  //       ) {
  //         return prev
  //       }

  //       // onPriceChange(price) // Add the selected addon's price to the total
  //       return newSelections
  //     }
  //   })
  //   // onPriceChange(price)
  // }

  const handleSelectionChange = (
    productParentId: string,
    addonId: string,
    isMultiple: boolean,
    price: number,
    parents: string[],
    product_id: string,
    product_name: string,
    ondc_group_id: string,
    childAddons: string[] = [], // New parameter to handle child addons
  ) => {
    setSelectedProducts((prev) => {
      const groupConfig = groupData[productParentId].config
      const currentParentSelections = prev.filter((product) => product.productParentId === productParentId)

      const isAlreadySelected = currentParentSelections.some((product) => product.addonId === addonId)
      let newSelections = []

      if (isAlreadySelected) {
        // Deselect the addon and any child addons
        newSelections = prev.filter((product) => {
          return (
            !(product.productParentId === productParentId && product.addonId === addonId) &&
            !product.parents.includes(addonId) && // Remove child addons related to this addon
            !childAddons.includes(product.addonId) // Remove any selected child addons
          )
        })
      } else {
        if (!isMultiple) {
          // Replace the selection if only one selection is allowed
          const previousSelection = prev.find((product) => product.productParentId === productParentId)
          const removeChildAddon: any = []

          if (previousSelection) {
            const prevParentChild = prev.filter((product) => product.parents.includes(previousSelection.addonId))
            prevParentChild.forEach((item) => {
              removeChildAddon.push(item.addonId)
            })
          }

          newSelections = prev.filter(
            (product) =>
              product.productParentId !== productParentId &&
              product.productParentId !== addonId &&
              !removeChildAddon.includes(product.addonId),
          )

          newSelections.push({
            productParentId,
            addonId,
            isMultiple,
            price,
            parents,
            product_id,
            product_name,
            ondc_group_id,
          })

          // If the addon has children, add them as well
          childAddons.forEach((childId) => {
            newSelections.push({
              productParentId: addonId,
              addonId: childId,
              isMultiple,
              price: 0, // Assuming child addons do not have an additional price, you can adjust this if needed
              parents: [...parents, addonId],
              product_id: childId,
              product_name: `Child of ${product_name}`, // Adjust as necessary
              ondc_group_id,
            })
          })
        } else {
          // Add the new selection if multiple selections are allowed
          newSelections = [
            ...prev,
            { productParentId, addonId, isMultiple, price, parents, product_id, product_name, ondc_group_id },
          ]

          // If the addon has children, add them as well
          childAddons.forEach((childId) => {
            newSelections.push({
              productParentId: addonId,
              addonId: childId,
              isMultiple,
              price: 0, // Assuming child addons do not have an additional price, you can adjust this if needed
              parents: [...parents, addonId],
              product_id: childId,
              product_name: `Child of ${product_name}`, // Adjust as necessary
              ondc_group_id,
            })
          })
        }

        // Ensure the selections do not exceed the max allowed
        if (
          newSelections.filter((product) => product.productParentId === productParentId).length >
          parseInt(groupConfig.max)
        ) {
          return prev
        }
      }

      return newSelections
    })
  }

  const renderProduct = (groupId: string) => {
    const group = groupData[groupId]
    const max = group?.config?.max
    const isMultipleSelectionAllowed = parseInt(max) > 1
    return (
      group?.addons?.length > 0 && (
        <SizeMainWrapper key={groupId}>
          <HeadingWrapper>
            <div className="h1b main-heading">Choose {group?.name}</div>
            <div className="h4r sub-heading">Select up to {group?.config?.max}</div>
          </HeadingWrapper>
          <SizeSubTypeWrapper>
            {group?.addons?.map((addon) => {
              // const addonId = addon.child[0]?.toString() ?? addon.id
              const parents = group?.parent
              const product_id = addon?.id
              const ondc_group_id = group?.ondc_group_id
              const product_name = addon?.product_name
              // const isSelected = selectedProducts.some(
              //   (product) => product.addonId === addonId && product.productParentId === groupId,
              // )
              // const isSelected = selectedProducts.some(
              //   (product) =>
              //     (product.addonId === addonId && product.productParentId === groupId) ||
              //     (product.parents?.includes(addonId) && product.productParentId === groupId),
              // )
              const addonIds = [addon.id, ...addon.child.map((child) => child.toString())]

              const isSelected = selectedProducts.some(
                (product) =>
                  (addonIds.includes(product.addonId) || addonIds.some((id) => product.parents.includes(id))) &&
                  product.productParentId === groupId,
              )

              return (
                <div key={addon.id}>
                  <SizeTypeWrapper
                    onClick={() =>
                      handleSelectionChange(
                        // groupId,
                        // addon.child[0]?.toString() ?? addon.id,
                        // isMultipleSelectionAllowed,
                        // parseInt(addon.price),
                        // parents,
                        // product_id,
                        // product_name,
                        // ondc_group_id,
                        groupId,
                        addon.id, // Pass the parent addon ID
                        isMultipleSelectionAllowed,
                        parseInt(addon.price),
                        parents,
                        product_id,
                        product_name,
                        ondc_group_id,
                        addon.child.map((child) => child.toString()),
                      )
                    }
                  >
                    <ToppingType>
                      <div className="h2b size-type heading">{addon.product_name}</div>
                    </ToppingType>
                    <div className="size-wrap">
                      {isSelected ? <input type="hidden" className="addonsPrice" value={addon.price} /> : ''}
                      <label className="h1r main-heading">₹{addon.price}</label>
                      <img
                        src={isSelected ? CheckedImg : UncheckedCircle}
                        className={`circle ${isSelected ? 'checked' : ''}`}
                      />
                    </div>
                  </SizeTypeWrapper>
                </div>
              )
            })}
          </SizeSubTypeWrapper>
        </SizeMainWrapper>
      )
    )
  }

  useEffect(() => {
    const totalPrice = selectedProducts.reduce((total, product) => total + product.price, price)
    onPriceChange(totalPrice)
    if (selectedProducts.length > 0) {
      setAddons(selectedProducts)
    }
  }, [selectedProducts])

  return (
    <MainWrapperAddOn>
      {selectedProducts.map((selectedProduct) => {
        return !selectedProduct.isMultiple && renderProduct(selectedProduct.addonId)
      })}
    </MainWrapperAddOn>
  )
}

// Example usage in an application component
const CustomizableItems = ({ products, onClose, handleAddonProducts, addToCart, flag }: any) => {
  const [totalPrice, setTotalPrice] = useState(parseInt(products?.regularPrice))
  const [addons, setAddons] = useState<any>([])
  const [flagAddon, setFlagAddon] = useState<any>([])
  price = parseInt(products?.regularPrice)
  const handlePriceChange = (priceChange: number) => {
    setTotalPrice(priceChange)
  }

  const handleAddon = () => {
    const addAddon: any = []
    if (addons.length > 0) {
      addons.map((item: any) => {
        if (item.productParentId != '-1') {
          const data = {
            product_id: item.product_id,
            price: item.price,
            product_name: item.product_name,
            group_name: item.ondc_group_id,
          }
          addAddon.push(data)
        }
      })
      handleAddonProducts(addAddon)
      setFlagAddon(addAddon)
    }
  }

  useEffect(() => {
    if (flagAddon.length > 0) {
      handleAddToCart()
    }
  }, [flagAddon])

  const handleAddToCart = () => {
    addToCart(products?.productId, flag)
    onClose()
    setFlagAddon([])
  }

  return (
    <CustomizedMainWrapper>
      <MainHeadingWrappee>
        <label className="h0b">Customize</label>
        <div onClick={onClose} className="cross-icon">
          <CloseIcon />
        </div>
      </MainHeadingWrappee>
      <ContentMainWrapper>
        <TopHeadingWrapper>
          <img src={VegImage} alt="Veg" />
          <label className="h0b item-name">{products?.productName}</label>
        </TopHeadingWrapper>
        <ProductList
          groupData={products?.attachGroups?.group_data}
          initialGroupId={parseInt(products?.attachGroups?.attached_groups[0])}
          onPriceChange={handlePriceChange}
          setAddons={setAddons}
        />
      </ContentMainWrapper>
      <MobileCheckoutButtonwrapper className="sticky-bottom">
        <div className="checkout-item-wrap">
          <div className="price-wrap h0b">
            <span> &#8377; </span>
            {totalPrice}
          </div>
        </div>
        <Line />
        <Button label="Checkout" variant="contained" type="button" className="small" onClick={handleAddon} />
      </MobileCheckoutButtonwrapper>
    </CustomizedMainWrapper>
  )
}

export default CustomizableItems

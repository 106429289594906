import styled from 'styled-components'
interface IProps {
  status?: string | boolean
  state?: string | boolean
  confirmStatus?: number
}

export const ViewOrderDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  @media (max-width: 768px) {
    padding: 0px 22px;
  }
`
export const ConfirmationOrderDetailWrapper = styled(ViewOrderDetailWrapper)`
  .confirm-wrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
`

export const OrderConfirmationWrapper = styled.div`
  margin-top: 10px;
  padding: 13px 21px 23px 17px;
  border-radius: 8px;
  background-color: var(--primary_box_fill_highlight_color);
  .confirmed-wrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 27px;
  }
  .help-icon {
    cursor: pointer;
  }
  .item-name {
    width: 210px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    text-transform: capitalize;
  }
  .confirmed-wraps {
    width: 100%;
    display: flex;
    gap: 19px;
    padding-bottom: 27px;
  }

  .order-name {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }

  .order-state-wrapper {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: center;
  }
  .confirm-order {
    width: 100%;
    display: flex;
    gap: 19px;
  }
  .orders-status-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .orders-details {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
  }
  .confirm-order-details {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
  }
  .order-type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 20px;
    border-radius: 4px;
    color: var(--primary_success_color);
    border: 1px solid var(--primary_success_color);
    background: var(--primary_box_fill_highlight_color);
  }
  .order-error-type {
    color: var(--primary_caution_color);
  }
  .order-state-type {
    padding: 7px 0;
    border-bottom: 1px solid var(--primary_line_color_light);
    padding-bottom: 13px;
  }
  .track-color {
    color: var(--primary_brand_color);
  }
  .payment-failed-wrapper {
    color: var(--primary_brand_color);
  }
  .order-pending-wrapper {
    color: var(--primary_caution_color);
  }
  img {
    width: 60px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    .item-name {
      width: 140px;
    }
  }
`
export const OrderPolicyWrapper = styled(OrderConfirmationWrapper)`
  margin-top: 0;
  padding: 8px 23px;
  .policy-orders-details {
  }
  .orders-details {
    gap: 12px;
  }
  .product-name {
    text-transform: capitalize;
  }
  .colourBox {
    display: inline-block;
    width: 12px;
    height: 12px;
    vertical-align: middle;
  }
`
export const OssWrapper = styled(OrderPolicyWrapper)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--primary_line_color_light);
  padding: 8px 13px 8px 23px;

  .oss-message {
    color: var(--primary_caution_color);
  }
  img {
    max-width: 50px;
    height: auto;
  }
  .remove-wrapper {
    display: flex;
    gap: 4px;
  }
  button {
    width: max-content;
    align-items: flex-start;
  }
  span.itemName {
    display: inline-block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .profile-change-btn {
    color: var(--primary_text_link_color) !important;
  }
  .itemSection {
    display: flex;
  }
`

export const StatusWrapper = styled.div<IProps>`
  color: var(--primary_success_color);
  color: ${(props) =>
    props.status === 'Confirmed'
      ? 'var(--primary_success_color)'
      : props.status === 'Cancelled'
      ? 'var(--primary_brand_color)'
      : props.state === 'Delivered'
      ? 'var(--primary_success_color)'
      : props.status === 'Failed'
      ? 'var(--primary_brand_color)'
      : props.status === 'Pending'
      ? 'var(--primary_caution_color)'
      : 'red'};
`
export const ConfirmStatusWrapper = styled.div<IProps>`
  color: var(--primary_success_color);
  color: ${(props) =>
    props.confirmStatus === 1
      ? 'var(--primary_success_color)'
      : props.confirmStatus === 9
      ? 'var(--primary_brand_color)'
      : props.confirmStatus === 7
      ? 'var(--primary_success_color)'
      : props.confirmStatus === 0
      ? 'var(--primary_caution_color)'
      : 'var(--primary_success_color)'};
`
export const OrderStateWrapper = styled.div<IProps>`
  color: var(--primary_text_color_light);
  background-color: ${(props) =>
    props.state === 'Processing'
      ? 'var(--primary_caution_color)'
      : props.state === 'Cancelled'
      ? 'var(--primary_brand_color)'
      : props.state === 'Delivered'
      ? 'var(--primary_success_color)'
      : props.state === 'Shipped'
      ? 'var(--primary_caution_color)'
      : props.state === 'Pending'
      ? 'var(--primary_caution_color)'
      : props.state === 'ASSIGNED'
      ? 'blue'
      : 'var(--primary_caution_color)'};
  border-radius: 8px;
  width: max-content;
  text-align: center;
  padding: 2px 8px;
`

export const PriceDetailWrapper = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid var(--primary_line_color_light);
  border-top: 1px solid var(--primary_line_color_light);

  .price-details-wrapper {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .discount {
    color: var(--secondary_text_link_color);
  }
  .order-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary_box_fill_color);
  }
  .total {
    margin-top: 14px;
  }
`
export const FailureDetailWrapper = styled(PriceDetailWrapper)`
  border-bottom: 0px solid var(--primary_line_color_light);
`
export const PaymentMethodWrapper = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary_line_color_light);

  .delivery-method {
    display: flex;
    gap: 6px;
    margin-top: 14px;
    text-transform: capitalize;
  }
  .paid-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .paid {
    color: var(--primary_brand_color);
    text-transform: uppercase;
  }
`
export const DeliveryAddressWrapper = styled.div`
  padding-bottom: 40px;
`
export const AddressContainer = styled.div`
  display: flex;
  gap: 19px;
  /* width: 100%;s */
  margin-top: 10px;
  border-radius: 8px;
  padding: 9px 10px 11px;
  /* display: flex;
  gap: 24px; */
  background-color: var(--primary_box_fill_highlight_color);
  @media (max-width: 768px) {
  }
`
export const AddressWrapper = styled.div``
export const PincodeLocation = styled.div``
export const BasicAddress = styled.div`
  width: 100%;
`
export const CityDetail = styled.div`
  text-transform: capitalize;
`
export const PersonName = styled.div`
  padding-top: 7px;
`

import styled from 'styled-components'

export const CancelOrderDetailsWrapper = styled.div`
  /* padding: 0px 22px; */
  display: flex;
  flex-direction: column;
  gap: 25px;

  .cancel-color {
    color: var(--primary_brand_color);
  }
  .cancel-wrap {
    padding-bottom: 20px;
  }
  .order-name-Wrap {
    display: flex;
    justify-content: space-between;
  }

  .orders-details,
  .confirm-order {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 0px 22px;
  }
`
export const ReturnDetailsWrapper = styled(CancelOrderDetailsWrapper)`
  @media (max-width: 768px) {
    max-height: 82vh;
    overflow-y: auto;
  }
`
export const ReturnFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const BankDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 24px;
`
export const BankDetailsWrappers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const BeneficiaryDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const CancellationReasonwrapper = styled.div`
  p {
    margin: 0 !important;
  }
`
export const CancelReasonHeading = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  /* padding-top: 25px; */
`
export const ReasonWrapper = styled.div`
  .reason-wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`
export const ButtonWrapper = styled.div`
  /* position: absolute; */
  bottom: 20px;
  display: flex;
  justify-content: center;
  /* width: 336px; */
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  padding: 8px 11px;
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100vw - 24px);
    /* position: fixed;
    bottom: 0;
    display: block; */
  }
`

import PrivateLayout from 'components/Layouts/private'
import TermsAndCondition from 'views/TermsAndCondition'

const CookiePolicyPage = () => {
  return (
    <PrivateLayout>
      <TermsAndCondition />
    </PrivateLayout>
  )
}

export default CookiePolicyPage

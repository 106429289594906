import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import OrderFailureDetail from 'views/OrderFailureDetail'
import BackArrow from 'assets/svg/BackArrow'
import CloseCircle from 'assets/svg/CloseCircle'
import { CommonStyle } from 'styles/pages/Common'
import { ManageAddressWrapper, BackWrapper, ButtonWrapper } from 'styles/pages/MyAccount'
import { ConfirmationMessageWrapper, FailureMessage } from 'styles/pages/OrderSuccessPage'

const OrderFailurePage = () => {
  const navigate = useHistory()

  const handleBack = () => {
    navigate.goBack()
  }

  return (
    <ManageAddressWrapper>
      <CommonStyle>
        <BackWrapper className="padding_full_page" onClick={handleBack}>
          <BackArrow />
          <div className="h1b">ORDER FAILURE</div>
        </BackWrapper>
      </CommonStyle>
      <CommonStyle>
        <ConfirmationMessageWrapper>
          <CloseCircle />
          <FailureMessage className="h0b">Your order has failed!</FailureMessage>
        </ConfirmationMessageWrapper>
      </CommonStyle>
      <CommonStyle>
        <OrderFailureDetail />
      </CommonStyle>
      <ButtonWrapper>
        <Button label="Try Again" variant="contained" type="submit" className="small" />
      </ButtonWrapper>
    </ManageAddressWrapper>
  )
}

export default OrderFailurePage

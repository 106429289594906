import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'antd'
import Button from 'components/Button'
import { SellerRoute, VendorRoute } from 'constants/routes'

import {
  NearStoreSectionWrapper,
  NearMeStoreWrapper,
  ProductDetailsWrapper,
  StoreImageWrapperList,
  StoreListWrapper,
} from 'styles/components/ListProducts'
import { WishlistLabel, WishListMainWrapper, SubHeadingStore } from 'styles/pages/SearchPage'
import SpeedometerIcon from 'assets/svg/SpeedometerIcon'

interface IProductDetails {
  productId: string
  store_name: string
  image: string
  categories: [string]
  distance: number
  city: string
  is_marketplace: number
  is_ondc: number
  seller_id: string
  vendor_id: string
}

const NearStoreList = ({ products, setFilter, filter }: any) => {
  const navigate = useHistory()

  const handlePageChange = () => {
    // window.scrollTo({ top: 0, behavior: 'smooth' })

    setFilter({
      ...filter,
      page: filter.page + 1,
      perPage: filter.perPage + 48,
    })
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100)
  }

  const [error, setError] = useState(false)

  const handleError = () => {
    setError(true)
  }

  return (
    <NearStoreSectionWrapper>
      <WishListMainWrapper>
        <WishlistLabel>Stores Near Me</WishlistLabel>
        <SubHeadingStore>Explore amazing items near you</SubHeadingStore>
        {/* <label className="h1r">{products?.length} Items</label> */}
      </WishListMainWrapper>
      <StoreListWrapper>
        {products?.map((product: IProductDetails, index: number) => {
          const imageRatio = 'w_100,h_100'

          let imgUrl =
            product?.image?.startsWith('http://') || product?.image?.startsWith('https://')
              ? product?.image
              : `https://cdnaz.plotch.io/image/upload/${imageRatio}${product?.image}`
          if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes(imageRatio)) {
            imgUrl = imgUrl?.split('/upload/').join(`/upload/${imageRatio}/`)
          }

          const distance = product?.distance?.toFixed(2)

          const handleClick = () => {
            if (product.is_ondc === 1) {
              navigate.push(SellerRoute.path.replace(':id', product.seller_id))
            } else if (product.is_marketplace === 1) {
              navigate.push(VendorRoute.path.replace(':id', product.vendor_id))
            }

            localStorage.setItem('sellerId', product.seller_id)
            localStorage.setItem('vendorId', product.vendor_id)
          }

          return (
            <NearMeStoreWrapper
              key={index}
              className="product-wrapper"
              // onClick={() => {
              //   navigate.push(
              //     SellerRoute.path.replace(':id', product?.productId),
              //   )
              //   localStorage.setItem('productId', product?.productId)
              // }}
              onClick={handleClick}
            >
              <StoreImageWrapperList>
                <img
                  // src={imgUrl}
                  src={error ? imgUrl : imgUrl}
                  onError={handleError}
                  style={error ? { minHeight: '80%', height: '275px' } : {}}
                />
              </StoreImageWrapperList>
              <ProductDetailsWrapper>
                <div className="heading-wrapper">
                  <div className="heading-details">
                    <Tooltip title={product.store_name}>
                      <label className={`store-name ${window.matchMedia('(max-width:768px)') ? `h2b` : `h1b`}`}>
                        {product.store_name && product.store_name.toLowerCase()}
                        {/* {product.store_name ? `${product.store_name}` : product.store_name.toLowerCase()} */}
                      </label>
                    </Tooltip>
                  </div>
                  <label className="h3r category-name">{product?.categories[0]}</label>
                </div>
                <div className="discount-details">
                  {distance && <SpeedometerIcon />}
                  {distance && (
                    <div className={`after-discount ${window.matchMedia('(max-width:768px)') ? `h2r` : `h1r`}`}>
                      {distance} KM
                    </div>
                  )}

                  <Tooltip title={product.city}>
                    <div className={`city storeCity ${window.matchMedia('(max-width:768px)') ? `h2r` : `h1b`}`}>
                      {product.city && product.city.toLowerCase()}
                      {/* {product.city ? `${product.city}` : product.city.toLowerCase()} */}
                    </div>
                  </Tooltip>
                </div>
              </ProductDetailsWrapper>
            </NearMeStoreWrapper>
          )
        })}
      </StoreListWrapper>

      <div className="load-more">
        <Button label="Load More..." variant="contained" className=" h2b loadBtn" onClick={handlePageChange} />
      </div>
    </NearStoreSectionWrapper>
  )
}

export default NearStoreList

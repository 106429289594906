import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={14} viewBox="0 0 16 14" fill="none" {...props}>
    <path
      stroke="#FCC90E"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M15.003 5.501H9.627L8.002.5 6.376 5.501H1l4.376 3-1.688 5.002 4.314-3.126 4.313 3.126-1.688-5.001 4.376-3Z"
    />
  </svg>
)
export default SvgComponent

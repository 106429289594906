import PrivateLayout from 'components/Layouts/private'
import NodessoLoginView from 'views/NodessoLogin'

const NodessoLogin = () => {
  return (
    <PrivateLayout>
      <NodessoLoginView />
    </PrivateLayout>
  )
}

export default NodessoLogin

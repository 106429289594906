import React, { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { Checkbox, Flex, Radio } from 'antd'
import Button from 'components/Button'
import SearchBar from 'components/SearchBar'
import { useFilter } from 'context/FliterContext'
import { pageData } from 'utils/datalayer/pageData'
import SearchIcon from 'assets/svg/SearchIcon'
import CloseIcon from 'assets/svg/CloseIcon'
import { SimilarSvgWrapper } from 'styles/views/LocationNewUser'

import {
  MobileFilterWrapper,
  FiterTopHeadingWrapper,
  FilterListContainer,
  FilterListWrapper,
  ShowMore,
  CheckBoxMainWrapper,
  LabelNameWrapper,
  SmallSearchWrapper,
  CheckBoxMainWrappers,
  ItemWrapper,
  ColorWrapper,
} from 'styles/views/FilterView'
import { useTheme } from 'context/themeContext'

const MobileFilterView = ({ filtersData, onClose }: any) => {
  const [expandedFilters, setExpandedFilters] = useState<{ [key: number]: boolean }>({})
  const [selectedFilters, setSelectedFilters] = useState<any>({})
  const { filterProduct, setFilterProduct } = useFilter() // Use the context here
  const [searchVisibility, setSearchVisibility] = useState<boolean[]>(filtersData?.map(() => false))
  const [searchValues, setSearchValues] = useState<string[]>(filtersData?.map(() => ''))
  const [filteredItems, setFilteredItems] = useState<any[]>(filtersData?.map(() => []))
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const { storesData } = useTheme()
  const handleFilterSelection = (esFilterName: string, filterItem: string) => {
    const existingFilter = filterProduct?.find((filter: any) => esFilterName in filter)

    let newFilters: any

    if (existingFilter) {
      const updatedItems = existingFilter[esFilterName].includes(filterItem)
        ? existingFilter[esFilterName].filter((item: string) => item !== filterItem)
        : [...existingFilter[esFilterName], filterItem]

      newFilters =
        esFilterName === 'discount_percentage'
          ? { [esFilterName]: [filterItem] }
          : { ...existingFilter, [esFilterName]: updatedItems }
    } else {
      newFilters = { [esFilterName]: [filterItem] }
    }

    const updatedFilters = filterProduct.filter((filter: any) => !(esFilterName in filter))
    setFilterProduct([...updatedFilters, newFilters])
    setSelectedFilters((prev: any) => ({
      ...prev,
      [esFilterName]: newFilters[esFilterName],
    }))
  }

  useEffect(() => {
    const queryString = window.location.search.split('?')[1]
    const searchParams = new URLSearchParams(queryString)

    const filtersObject: { [key: string]: string[] } = {}

    searchParams.forEach((value, key) => {
      filtersObject[key] = value.split(',')
    })

    setFilterProduct(Object.keys(filtersObject).map((key) => ({ [key]: filtersObject[key] })))
    setSelectedFilters(filtersObject)
  }, [])

  const clearAllFilters = () => {
    if (filterProduct.length != 0) {
      setFilterProduct([])
      setExpandedFilters({})
      setSelectedFilters({})
    }

    const queryString = window.location.search.split('?')[1]
    const searchParams = new URLSearchParams(queryString)

    const searchby = searchParams.get('searchby')
    const q = searchParams.get('q')

    const newSearchParams = new URLSearchParams()
    if (searchby) newSearchParams.set('searchby', searchby)
    if (q) newSearchParams.set('q', q)

    const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`
    window.history.pushState({}, '', newUrl) // Update the browser URL
    onClose()
    const dataToPush = {
      event: 'filter_selection',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      category: 'na',
      cta_text: 'clear_all',
      section_name: 'na',
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })
  }

  const toggleShowMore = (index: number) => {
    setExpandedFilters((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  const handleSearchIconClick = (index: number) => () => {
    setSearchVisibility((prevState) => prevState.map((visible, i) => (i === index ? true : visible)))
  }

  const handleClearSearch = (index: number) => () => {
    const newSearchValues = [...searchValues]
    newSearchValues[index] = ''
    setSearchValues(newSearchValues)
    setSearchVisibility((prevState) => prevState.map((visible, i) => (i === index ? false : visible)))
    setFilteredItems((prevFilteredItems) => {
      const newFilteredItems = [...prevFilteredItems]
      newFilteredItems[index] = []
      return newFilteredItems
    })
  }

  const handleSearchInputChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase()
    const currentGroup = filtersData[index]
    const filtered = currentGroup?.filtersData.filter((item: any) => item.filterItem.toLowerCase().includes(query))

    setFilteredItems((prevFilteredItems) => {
      const newFilteredItems = [...prevFilteredItems]
      newFilteredItems[index] = filtered
      return newFilteredItems
    })

    setSearchValues((prevSearchValues) => {
      const newSearchValues = [...prevSearchValues]
      newSearchValues[index] = e.target.value
      return newSearchValues
    })
  }

  // const handleSubmit = () => {
  //   const urlSearchParams = new URLSearchParams(window.location.search)

  //   Object.keys(selectedFilters).forEach((key) => {
  //     if (selectedFilters[key].length > 0) {
  //       selectedFilters[key].forEach((filterItem: string) => {
  //         handleFilterSelection(key, filterItem)
  //       })
  //       urlSearchParams.set(key, selectedFilters[key].join(','))
  //     }
  //   })

  //   const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`
  //   window.history.pushState({}, '', newUrl)
  //   onClose()
  // }

  const handleSubmit = () => {
    // try {
    const urlSearchParams = new URLSearchParams(window.location.search)

    const sectionNames: string[] = []
    const ctaTexts: string[] = []

    Object.keys(selectedFilters).forEach((key) => {
      if (selectedFilters[key].length > 0) {
        selectedFilters[key].forEach((filterItem: string) => {
          handleFilterSelection(key, filterItem)
        })
        urlSearchParams.set(key, selectedFilters[key].join(','))

        if (key !== 'searchby' && key !== 'q' && key !== 'sort_by') {
          sectionNames.push(key)
          ctaTexts.push(selectedFilters[key].join(' | '))
        }
      }
    })

    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`
    window.history.pushState({}, '', newUrl)

    const dataToPush = {
      event: 'filter_selection',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      category: 'na',
      cta_text: ctaTexts.join(' | ')?.toLowerCase(),
      section_name: sectionNames.join(' | ')?.toLowerCase(),
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })

    onClose()
    // } catch (error) {
    //   console.error('Error in handleSubmit:', error)
    // }
  }

  useEffect(() => {
    const queryString = window.location.search.split('?')[1]
    const searchParams = new URLSearchParams(queryString)

    const filtersObject: { [key: string]: string[] } = {}

    searchParams.forEach((value, key) => {
      filtersObject[key] = value.split(',')
    })

    setFilterProduct(Object.keys(filtersObject).map((key) => ({ [key]: filtersObject[key] })))
    setSelectedFilters(filtersObject)
  }, [])

  return (
    <MobileFilterWrapper>
      <FiterTopHeadingWrapper>
        <label className="filter-main-heading">Filters</label>

        <SimilarSvgWrapper>
          <CloseIcon onClick={onClose} />
        </SimilarSvgWrapper>
      </FiterTopHeadingWrapper>
      <FilterListContainer>
        <FilterListWrapper>
          {filtersData?.map((filterGroup: any, index: number) => {
            return (
              <div key={index}>
                <CheckBoxMainWrapper>
                  <LabelNameWrapper>
                    {searchVisibility[index] ? (
                      <SearchBar
                        placeholder={`Search for ${filterGroup?.name}`}
                        name="search"
                        className="searchItem"
                        suffixIcon={<CloseIcon onClick={handleClearSearch(index)} />}
                        value={searchValues[index]}
                        onChange={handleSearchInputChange(index)}
                      />
                    ) : (
                      <>
                        <label className="h0b">{filterGroup.name}</label>
                        {['Color', 'Category', 'Brand', 'Material']?.includes(filterGroup?.name) && (
                          <SmallSearchWrapper className="SmallSearchWrapper">
                            <SearchIcon onClick={handleSearchIconClick(index)} />
                          </SmallSearchWrapper>
                        )}
                      </>
                    )}
                  </LabelNameWrapper>

                  <CheckBoxMainWrappers className={expandedFilters[index] ? 'scrollable-div' : ''}>
                    {filterGroup.esFilterName !== 'discount_percentage' &&
                      (filteredItems[index]?.length > 0 ? filteredItems[index] : filterGroup.filtersData)
                        .sort((a: any, b: any) => {
                          if (filterGroup.esFilterName !== 'discount_price') {
                            const filterItemA = a.filterItem?.toString().toLowerCase() || ''
                            const filterItemB = b.filterItem?.toString().toLowerCase() || ''
                            return filterItemA.localeCompare(filterItemB)
                          }

                          return 0
                        })
                        .slice(0, expandedFilters[index] ? filterGroup.items : 8)
                        .map((filterItem: any, itemIndex: number) => (
                          <Checkbox
                            key={`${filterGroup.esFilterName}-${itemIndex}`}
                            className="h1r filter-checkbox"
                            checked={
                              selectedFilters[filterGroup.esFilterName]?.includes(filterItem.filterItem) || false
                            }
                            onChange={() => handleFilterSelection(filterGroup.esFilterName, filterItem.filterItem)}
                          >
                            <ItemWrapper>
                              {filterGroup?.esFilterName === 'color' && (
                                <ColorWrapper
                                  className="color-wraaper"
                                  style={{ backgroundColor: `${filterItem?.filterItem}` }}
                                />
                              )}
                              <span className="filterItems">{filterItem.filterItem}</span>{' '}
                              <span className="h3r">({filterItem?.productCount}) </span>
                            </ItemWrapper>
                          </Checkbox>
                        ))}
                    {filterGroup.esFilterName === 'discount_percentage' && (
                      <div className="radio-buttons-wrapper">
                        <Radio.Group value={selectedFilters[filterGroup.esFilterName]?.[0] || ''}>
                          {(filteredItems[index]?.length > 0 ? filteredItems[index] : filterGroup?.filtersData)
                            .sort((a: any, b: any) => {
                              const filterItemA = a.filterItem?.toString().toLowerCase() || ''
                              const filterItemB = b.filterItem?.toString().toLowerCase() || ''
                              return filterItemA.localeCompare(filterItemB)
                            })
                            .slice(0, expandedFilters[index] ? filterGroup.items : 8)
                            .map((filterItem: any, itemIndex: number) => (
                              <Radio
                                key={`${filterGroup.esFilterName}-${itemIndex}`}
                                value={filterItem.filterItem}
                                className="filter-checkbox"
                                onChange={() => handleFilterSelection(filterGroup.esFilterName, filterItem.filterItem)}
                              >
                                <label className="h1r">
                                  <span className="filterItems">{filterItem.filterItem}</span>{' '}
                                  <span className="h3r">({filterItem?.productCount}) </span>
                                </label>
                              </Radio>
                            ))}
                        </Radio.Group>
                      </div>
                    )}
                  </CheckBoxMainWrappers>
                  {filterGroup?.filtersData.length > 8 && (
                    <ShowMore onClick={() => toggleShowMore(index)} className="h1r ShowMore">
                      {expandedFilters[index] ? '-' : '+'} {filterGroup.items - 8} more
                    </ShowMore>
                  )}
                </CheckBoxMainWrapper>
              </div>
            )
          })}
        </FilterListWrapper>
      </FilterListContainer>
      <Flex gap={10} justify="center" className="filter-mobile">
        <Button variant="text" label={'Clear all'} onClick={clearAllFilters} className="h1b clears-all" />
        <Button variant="contained" label={'Apply'} onClick={handleSubmit} className="h1b" />
      </Flex>
    </MobileFilterWrapper>
  )
}

export default MobileFilterView

import React from 'react'
import { MainWrapper, Heading, SubHeading, Details, ProductNotFoundWrapper } from 'styles/pages/NotFound'

const NotFound = () => {
  return (
    <ProductNotFoundWrapper>
      <MainWrapper className="productError">
        <Heading>404</Heading>
        <SubHeading>Product Not Found</SubHeading>
        <Details>Product you are looking for does not exist</Details>
      </MainWrapper>
    </ProductNotFoundWrapper>
  )
}

export default NotFound

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill="#121212"
      d="m4.593 8.909 6.553 7.646a1.123 1.123 0 0 0 1.708 0l6.552-7.646c.625-.73.107-1.857-.854-1.857H5.445c-.96 0-1.479 1.127-.852 1.857Z"
    />
  </svg>
)
export default SvgComponent

import { useHistory } from 'react-router-dom'
import { MyOrdersRoute } from 'constants/routes'
// import { MyOrdersRoute, OrderTrackRoute } from 'constants/routes'
import APIS from 'constants/api'
// import { LoaderContext } from 'context/loader'
import usePost from 'hooks/usePost'
import CheckmarkCircle from 'assets/svg/CheckmarkCircle'
import HelpCircle from 'assets/svg/HelpCircle'
import CloseCircle from 'assets/svg/CloseCircle'
import HomeIcon from 'assets/svg/HomeIcon'

import {
  ViewOrderDetailWrapper,
  OrderConfirmationWrapper,
  StatusWrapper,
  OrderStateWrapper,
  PriceDetailWrapper,
  PaymentMethodWrapper,
  DeliveryAddressWrapper,
  AddressContainer,
  AddressWrapper,
  PincodeLocation,
  BasicAddress,
  // CityDetail,
  PersonName,
} from 'styles/views/ViewOrderDetail'
import { CheckoutWrappers, BackWrapper } from 'styles/pages/MyAccount'
import { useEffect, useState } from 'react'
import { IFailedOrder, IProduct } from 'interfaces/views/failedViewOrderDetail'
import BackArrow from 'assets/svg/BackArrow'
import { Skeleton, Typography } from 'antd'
import useGet from 'hooks/useGet'
import PopupModal from 'components/TicketModal'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
import { useTheme } from 'context/themeContext'

const FailedViewOrderDetail = () => {
  const orderId = localStorage.getItem('orderId')
  const customerId = localStorage.getItem('customerId')
  const orderItemId = localStorage.getItem('orderItemId')
  const [failedOrderData, setFailedOrderData] = useState<IFailedOrder>()
  const [open, setOpen] = useState(false)
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const { storesData } = useTheme()
  const { refetch: refetchTicket } = useGet('ticket-list', `${APIS.TICKET_LIST}?customerId=${customerId}`)

  const { mutateAsync } = usePost()

  const navigate = useHistory()
  const changeModal = () => {
    setOpen(true)
  }
  const handleOk = () => {
    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }
  const handleCancel = () => {
    setOpen(false)
  }
  const handleBack = () => {
    navigate.push(MyOrdersRoute.path)
  }

  // const { setLoader } = useContext(LoaderContext)

  const fetchCancelledData = async () => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: `${APIS.FAILED_ORDER_DETAILS}`,
        payload: {
          id: orderItemId,
          orderId: orderId,
        },
      })
      if (res) {
        setFailedOrderData(res?.d?.orderDetails)
        //setLoader(false)
      }
    } catch (error: any) {
      //setLoader(false)
      return error
    }
  }

  // const formatDate = (dateTimeStr: string) => {
  //   const dateObj = new Date(dateTimeStr)
  //   return dateObj.toLocaleDateString() // Formats the date to the local format
  // }

  const formatDate = (dateTimeStr: string) => {
    const dateObj = new Date(dateTimeStr)
    return dateObj.toLocaleDateString('en-GB', {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
  }

  useEffect(() => {
    fetchCancelledData()
  }, [orderItemId])

  return (
    <>
      {failedOrderData ? (
        <CheckoutWrappers>
          <BackWrapper className="padding_full_page" onClick={handleBack}>
            <BackArrow />
            <div className="h1b">ORDER DETAILS</div>
          </BackWrapper>
          <ViewOrderDetailWrapper>
            <OrderConfirmationWrapper>
              <div className="confirmed-wraps">
                {failedOrderData?.status == 0 ? <CheckmarkCircle /> : <CloseCircle />}
                <div className="confirm-order-details">
                  <div className="orders-status-wrap">
                    <StatusWrapper className="h1b" status={'Cancelled'}>
                      {failedOrderData?.status == 8 ? 'Cancelled' : ''}
                    </StatusWrapper>
                    {/* <label className="h2b track-color" onClick={() => navigate.push(OrderTrackRoute.path)}>
                      TRACK
                    </label> */}
                  </div>
                  <label className="h3b">
                    Order: {failedOrderData?.orderId} ({failedOrderData?.products[0]?.qty} Item)
                    {/* {failedOrderData?.products[0]?.qty > 1 ? '(s)' : ''}) */}
                  </label>
                  <label className="h3r">
                    Placed on {failedOrderData?.createdAt && formatDate(failedOrderData?.createdAt)}
                  </label>
                </div>
              </div>
              {failedOrderData?.products?.map((item: IProduct, idx: number) => {
                let imgUrl =
                  item?.image?.includes('http://') || item?.image?.includes('https://')
                    ? item?.image
                    : `https://cdnaz.plotch.io/image/upload/w_118,h_118${item?.image}`
                if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
                  imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
                  imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
                }

                return (
                  <div className="confirmed-wrap order-state-type" key={idx}>
                    <div className="confirm-order">
                      <img src={imgUrl} />
                      <div className="orders-details">
                        <div className="order-name">
                          <Typography.Text ellipsis={{ tooltip: item.name }} className="h3r item-name">
                            {item.name.toLowerCase()}
                          </Typography.Text>
                          <HelpCircle
                            onClick={(e: any) => {
                              const initTicketDataToPush = {
                                event: 'raise_ticket_initiate',
                                page_type: GDL_PAGE_TYPE,
                                User_Id: loggedIn,
                                User_Status: loggedInStatus,
                                cta_text: 'get help',
                                order_ticket_id: orderId ? orderId : 'NA',
                              }
                              e.stopPropagation()
                              storesData?.d?.googleDatalayer &&
                                storesData?.d?.googleDatalayer == 1 &&
                                TagManager.dataLayer({ dataLayer: initTicketDataToPush })

                              changeModal()
                            }}
                            className="help-icon"
                          />
                        </div>

                        <div className="order-state-wrapper">
                          <div className="orders-details">
                            <label className="h3r">
                              {failedOrderData?.createdAt && formatDate(failedOrderData?.createdAt)}
                            </label>
                            <OrderStateWrapper className="h4b" state={item.itemStatusLabel}>
                              {item.itemStatusLabel}
                            </OrderStateWrapper>
                          </div>
                          {/* {item.type ? <div className="order-type h2b">{item.type}</div> : ''} */}
                        </div>
                        {/* {item.error ? <div className="order-error-type h4b">{item.error}</div> : ''} */}
                      </div>
                    </div>
                  </div>
                )
              })}
            </OrderConfirmationWrapper>
            {/* ))} */}
            <PriceDetailWrapper>
              <div className="price-details-container">
                <div className="price-details-wrapper">
                  <label className="h1r">Total MRP</label>
                  <label className="h1r">₹0</label>
                  {/* <label className="h1r">₹{failedOrderData?.orderSummary?.mrpTotal}</label> */}
                </div>
                <div className="price-details-wrapper">
                  <label className="h1r">Discount on MRP</label>
                  <label className="h1r discount">
                    ₹0
                    {/* {failedOrderData?.orderSummary?.discountMrp ? '-' : ''}₹{failedOrderData?.orderSummary?.discountMrp} */}
                  </label>
                </div>
                <div className="price-details-wrapper">
                  <label className="h1r">Delivery Charge</label>
                  <label className="h1r">₹{failedOrderData?.orderSummary?.shippingCost}</label>
                </div>
                <div className="price-details-wrapper">
                  <label className="h1r">Packaging Charge</label>
                  <label className="h1r">₹0</label>
                  {/* <label className="h1r">₹{failedOrderData?.orderSummary?.totalPackingCharge}</label> */}
                </div>
                <div className="price-details-wrapper">
                  <label className="h1r">Taxes</label>
                  <label className="h1r">₹{failedOrderData?.orderSummary?.tax}</label>
                </div>
                {/* <div className="price-details-wrapper">
            <label className="h1r">Convenience Fee</label>
            <label className="h1r">₹{failedOrderData?.orderSummary?.convenientFee}</label>
          </div> */}
                <div className="price-details-wrapper">
                  <label className="h1r">Coupon Discount</label>
                  <label className="h1r discount">-₹{failedOrderData?.orderSummary?.couponDiscount}</label>
                </div>
                <div className="order-wrapper total">
                  <label className="h1b">Order Value</label>
                  <label className="h1b">₹{failedOrderData?.orderSummary?.totalPayable}</label>
                </div>
              </div>
            </PriceDetailWrapper>
            <PaymentMethodWrapper>
              <div className="paid-wrapper">
                <label className="h1b">Payment Method</label>
                {/* {isPaid ? <label className="h2b paid">paid</label> : null} */}
              </div>

              <div className="delivery-method">
                <CheckmarkCircle />
                <label className="h1b">{failedOrderData?.paymentMethod}</label>
              </div>
            </PaymentMethodWrapper>
            <DeliveryAddressWrapper>
              <label className="h1b">Delivery Address</label>

              <AddressContainer>
                <HomeIcon />
                {/* {getAddressIcon(failedOrderData?.customerInfo?.addressType)} */}

                <AddressWrapper>
                  <PincodeLocation className="h1b">
                    {failedOrderData?.customerInfo?.pincode} -{'Home '}
                    {/* {failedOrderData?.customerInfo?.addressType === null
                ? 'Home'
                : failedOrderData?.customerInfo?.addressType} */}
                  </PincodeLocation>
                  <BasicAddress className="h2r">
                    {failedOrderData?.customerInfo?.building}
                    {failedOrderData?.customerInfo?.building == null ? '' : ','} {failedOrderData?.customerInfo?.street}
                    {failedOrderData?.customerInfo?.city}, {failedOrderData?.customerInfo?.state.toLowerCase()},{' '}
                    {failedOrderData?.customerInfo?.countryId}
                  </BasicAddress>
                  {/* <CityDetail className="h2r"></CityDetail> */}
                  <PersonName className="h2r">
                    {failedOrderData?.customerInfo?.name} ({failedOrderData?.customerInfo?.telephone})
                  </PersonName>
                </AddressWrapper>
              </AddressContainer>
            </DeliveryAddressWrapper>
          </ViewOrderDetailWrapper>
        </CheckoutWrappers>
      ) : (
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )}
      <PopupModal
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Raise Ticket"
        BtnName="Raise Ticket"
        getAllTickets={refetchTicket}
      />
    </>
  )
}

export default FailedViewOrderDetail

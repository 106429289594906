export const tabsFields = [
  {
    name: 'Home',
  },
  {
    name: 'Work',
  },
  {
    name: 'Hotel',
  },
  {
    name: 'Other',
  },
]

import axios from 'axios'
import { Base64 } from 'js-base64'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const NodessoLoginView = () => {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    // Get required parameters from searchParams

    const plotchDomain: any =
      process.env.NODE_ENV == 'production' && process.env.REACT_APP_ENV == ''
        ? window.location.hostname
        : process.env.REACT_APP_PLOTCH_DOMAIN
    const token = searchParams.get('token') || ''
    const messageId = searchParams.get('message_id') || ''
    const nodSsoId = searchParams.get('id') || ''
    const sessionId = localStorage.getItem('sessionId') || ''
    const auth = localStorage.getItem('Authorization')
    if (!token || !nodSsoId) {
      window.location.href = !auth ? '/signin' : '/'
    }

    let url = searchParams.get('redirect_uri') || ''

    // List of keys to check for appending
    const keysToAppend = ['kcode', 'ddt', 'checksum', 'sessionid', 'page_id', 'product_limit', 'category']

    // Iterate over search parameters and append to redirectUrl
    searchParams.forEach((val, key) => {
      if (keysToAppend.includes(key.toLowerCase())) {
        if (key.toLowerCase() === 'category') {
          val = encodeURIComponent(val) // URL encode 'category'
        }

        url += `&${key}=${val}`
      }
    })

    fetchNodeLogin(nodSsoId, url, messageId, token, sessionId, plotchDomain)
  }, [location.search])
  const fetchNodeLogin = async (
    nodSsoId: string,
    redirectUrl: string,
    messageId: string,
    token: string,
    sessionId: string,
    plotchDomain: string,
  ) => {
    const url = `https://nodeappapi1.plotch.io/api/v1/client/nodeLogin?nodesso_id=${nodSsoId}&redirect_path=${encodeURIComponent(
      redirectUrl,
    )}`

    const headers = {
      'Message-Id': messageId,
      Token: token,
      'X-Session': sessionId,
      'Plotch-Account-Domain': plotchDomain,
      'Content-Type': 'application/json',
    }

    try {
      const response = await axios.get(url, { headers })
      if (response.data) {
        const data = response.data
        if (data.s == 1) {
          if (data?.d?.login_details?.Authorization) {
            localStorage.setItem('Authorization', data?.d?.login_details?.Authorization)
            localStorage.setItem('customerId', data?.d?.login_details?.customerId)
            localStorage.setItem('mobile', data?.d?.login_details?.mobile)
            const d = new Date()
            const time = d.getTime()
            localStorage.setItem('accessToken', data?.d?.login_details?.Authorization)
            const customerId = data?.d?.login_details?.customerId
            localStorage.setItem('sessionId', '')
            if (data?.d?.login_details?.Authorization && customerId) {
              document.cookie = 'Authorization=' + data?.d?.login_details?.Authorization
              const session_expiry = new Date(time - 3600)
              document.cookie = 'x-plotch-session= ;' + session_expiry
              document.cookie = 'x-plotch-token=' + data?.d?.login_details?.Authorization + ';'
              const customer_id_base64 = encodeURIComponent(Base64.encode(customerId))
              const mobile_id = data?.d?.login_details?.mobile
                ? encodeURIComponent(Base64.encode(data?.d?.login_details?.mobile))
                : ''
              document.cookie = 'x-plotch-customer-id=c_' + customer_id_base64 + ';'
              mobile_id ? (document.cookie = 'x-plotch-customer-mobile=m_' + mobile_id + '') : ''
            }

            window.location.assign(redirectUrl)
          }
        }
      }
    } catch (error) {
      window.location.href = window.location.origin + '/401'
    }
  }

  return <div></div>
}

export default NodessoLoginView

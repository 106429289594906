import { useEffect, useState, ChangeEvent, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'
import SearchBar from 'components/SearchBar'
// import useDebounce from 'hooks/useDebounce'
import CustomAPIConnector from 'views/AutoSuggestions/customConnectors'

import {
  HomeRoute,
  LoginRoute,
  MyAccountRoute,
  SearchRoute,
  StoreRoute,
  WistlistRoute,
  PdpRoute,
  SellerRoute,
  CheckoutRoute,
  EmptyCartRoute,
} from 'constants/routes'
import LocationNewUser from 'views/LocationNewUser'
import SavedAddresses from 'views/SavedAddresses'
import LocationIcon from 'assets/svg/LocationIcon'
import DownArrow from 'assets/svg/DownArrow'
import CartIcon from 'assets/svg/CartIcon'
import ChevronForward from 'assets/svg/ChevronForward'
// import ListIcon from 'assets/svg/ListIcon'
import StorefrontIcon from 'assets/svg/StorefrontIcon'
import HeartIcon from 'assets/svg/HeartIcon'
import PersonIcon from 'assets/svg/PersonIcon'
import {
  NavbarContainer,
  LeftContainer,
  RightContainer,
  LocationWrapper,
  NavbarOptions,
  Blank,
  // EnterIconWrapper,
} from 'styles/components/Navbar'
import {
  SearchWrapper,
  SuggestinDataContainer,
  ItemNameWrapper,
  TopHeadings,
  SuggestinDataWrapper,
  MainWrapper,
  ImageWrappers,
  PriceWrapper,
  PriceRate,
  SearchInfoWrapper,
} from 'styles/views/LocationNewUser'
import SearchIcon from 'assets/svg/SearchIcon'
import { useTheme } from 'context/themeContext'
import { Badge } from 'antd'
import TagManager from 'react-gtm-module'
import { pageData } from 'utils/datalayer/pageData'
import { DataLayerObject } from './dataLayer'

const Navbar = ({ cartData }: any) => {
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  // const debouncedSearchValue = useDebounce(searchValue, 5000)
  const [suggestionResult, setsuggestionResult] = useState<any>({})
  const [showSuggestions, setShowSuggestions] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [openAddress, setOpenAddress] = useState(false)
  const address: any = localStorage.getItem('location')
  const postcode: any = localStorage.getItem('postcode')
  const currentAddress = JSON.parse(address)
  const customerId = localStorage.getItem('customerId')
  const router = useHistory()
  const { themedata, storesData } = useTheme()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const {
    dataSignInToPush,
    dataMyAccountToPush,
    dataNavLocationToPush,
    dataNavCartToPush,
    dataNavWishlistToPush,
    dataLogoToPush,
    dataToPushStore,
  } = DataLayerObject()
  const [focusedPageType, setFocusedPageType] = useState<string | null>(null)
  const headerLogo = themedata?.d?.objectDetails?.header?.siteLogo?.logoImage
  const showBydefaultLocation = themedata?.d?.objectDetails?.general?.showBydefaultLocation?.showBydefaultLocation
    ? themedata?.d?.objectDetails?.general?.showBydefaultLocation?.showBydefaultLocation
    : ''
  const ishyperlocalEnable = storesData?.d?.isHyperlocalEnabled ? storesData?.d?.isHyperlocalEnabled : 0
  const showStore =
    themedata?.d?.objectDetails?.header?.overview?.storeDisabled == undefined ||
    themedata?.d?.objectDetails?.header?.overview?.storeDisabled == ''
      ? 1
      : 0
  const searchProductData = async () => {
    router.push(`${SearchRoute.path}/searchresults?searchby=product&q=${searchValue}`)
  }
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const toggleAddressDrawer = (newAddress: boolean) => () => {
    setOpenAddress(newAddress)
  }

  const navigate = useHistory()

  const handleLocationClick = () => {
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataNavLocationToPush })

    if (localStorage.getItem('customerId') && localStorage.getItem('sessionId')) {
      toggleAddressDrawer(true)()
    } else {
      toggleDrawer(true)()
    }
  }
  // const dataSignInToPush = {
  //   event: 'navigation_interaction',
  //   page_type: GDL_PAGE_TYPE,
  //   User_Id: loggedIn,
  //   User_Status: loggedInStatus,
  //   cta_text: 'sign_in',
  //   select_type: 'header',
  //   section_name: 'sign_in',
  // }
  const handleLogin = () => {
    if (!localStorage.getItem('customerId')) {
      navigate.push(LoginRoute.path + '?redirect=my-account')

      storesData?.d?.googleDatalayer &&
        storesData?.d?.googleDatalayer == 1 &&
        TagManager.dataLayer({ dataLayer: dataSignInToPush })
    } else {
      navigate.push(MyAccountRoute.path)

      storesData?.d?.googleDatalayer &&
        storesData?.d?.googleDatalayer == 1 &&
        TagManager.dataLayer({ dataLayer: dataMyAccountToPush })
    }
  }

  const handleCartLogin = () => {
    if (!localStorage.getItem('customerId')) {
      navigate.push(LoginRoute.path + '?redirect=checkout')
      storesData?.d?.googleDatalayer &&
        storesData?.d?.googleDatalayer == 1 &&
        TagManager.dataLayer({ dataLayer: dataSignInToPush })
    } else {
      if (!cartData) {
        navigate.push(EmptyCartRoute.path)

        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: dataSignInToPush })
      } else {
        navigate.push(CheckoutRoute.path)

        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: dataNavCartToPush })
      }
    }
  }
  const handleWishlistLogin = () => {
    if (!localStorage.getItem('customerId')) {
      navigate.push(LoginRoute.path + '?redirect=wishlist')
      storesData?.d?.googleDatalayer &&
        storesData?.d?.googleDatalayer == 1 &&
        TagManager.dataLayer({ dataLayer: dataSignInToPush })
    } else {
      navigate.push(WistlistRoute.path)

      storesData?.d?.googleDatalayer &&
        storesData?.d?.googleDatalayer == 1 &&
        TagManager.dataLayer({ dataLayer: dataNavWishlistToPush })
    }
  }
  const handleInputFocus = () => {
    setShowSuggestions(true)

    const dataToPush = {
      event: 'search_interaction',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      cta_text: 'open',
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })
  }
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    setShowSuggestions(true)
    setFocusedPageType(GDL_PAGE_TYPE)
  }

  useEffect(() => {
    if (!customerId && !address) {
      if (showBydefaultLocation == 1 && ishyperlocalEnable == 1) setOpen(true)
    }
  }, [address, customerId, showBydefaultLocation])

  const customConnector = new CustomAPIConnector()

  const searchAddress = async (value: string) => {
    try {
      const result = await customConnector.onAutocomplete(value)
      setsuggestionResult(result)
    } catch (error) {
      return error
    }
  }

  const handleClick = () => {
    storesData?.d?.googleDatalayer && TagManager.dataLayer({ dataLayer: dataLogoToPush })

    router.push(HomeRoute.path)
  }
  const handleStoreClick = () => {
    navigate.push(StoreRoute.path)

    storesData?.d?.googleDatalayer && TagManager.dataLayer({ dataLayer: dataToPushStore })
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchValue.trim() !== '') {
      const dataToPush = {
        event: 'search_term_entered',
        page_type: focusedPageType, // Use the page type captured during focus
        User_Id: loggedIn,
        User_Status: loggedInStatus,
        search_text: searchValue,
        option_selected: 'search_text',
      }

      storesData?.d?.googleDatalayer &&
        storesData?.d?.googleDatalayer == 1 &&
        TagManager.dataLayer({ dataLayer: dataToPush })

      searchProductData()
      setSearchValue('') // Clear the search value after pushing the data
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchValue !== '' && themedata?.d?.objectDetails?.general?.isAutoSearchSuggestionEnabled == 1)
        searchAddress(searchValue)
    }, 100)
    return () => clearTimeout(timeoutId)
  }, [searchValue])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowSuggestions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <NavbarContainer>
      <LeftContainer>
        {headerLogo ? (
          <img
            src={headerLogo}
            // onClick={() => router.push(HomeRoute.path)}
            onClick={handleClick}
            loading="lazy"
            className="main-logo"
          />
        ) : (
          <Blank />
        )}

        <SearchWrapper ref={wrapperRef}>
          <SearchBar
            placeholder={`Search your item here`}
            name="search"
            className="searchMobile"
            prefix={<SearchIcon />}
            onChange={handleSearchChange}
            onFocus={handleInputFocus}
            value={searchValue}
            onKeyPress={handleKeyPress}
          />
          {showSuggestions && searchValue.length > 0 && (
            <>
              {suggestionResult &&
              (suggestionResult?.VendorResults?.length > 0 || suggestionResult?.ProductResults?.length > 0) ? (
                <SuggestinDataContainer>
                  {suggestionResult?.VendorResults?.length > 0 && (
                    <SuggestinDataWrapper>
                      <TopHeadings>Stores</TopHeadings>
                      {suggestionResult?.VendorResults?.map((item: any, index: number) => {
                        let imgUrl =
                          item?.image?.includes('http://') || item?.image?.includes('https://')
                            ? item?.image
                            : `https://cdnaz.plotch.io/image/upload/w_118,h_118/${item?.image}`
                        if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
                          imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
                          imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
                        }

                        return (
                          <div key={index}>
                            <MainWrapper
                              onClick={() => {
                                navigate.push(SellerRoute.path.replace(':id', item?.sellerId))
                                setSearchValue('')
                                localStorage.setItem('sellerId', item?.sellerId)
                                const dataToPush = {
                                  event: 'search_term_entered',
                                  page_type: GDL_PAGE_TYPE,
                                  User_Id: loggedIn,
                                  User_Status: loggedInStatus,
                                  search_text: searchValue,
                                  option_selected: 'enter_click',
                                }

                                storesData?.d?.googleDatalayer &&
                                  storesData?.d?.googleDatalayer == 1 &&
                                  TagManager.dataLayer({ dataLayer: dataToPush })
                              }}
                            >
                              <SearchInfoWrapper>
                                <ImageWrappers>
                                  <img src={imgUrl} />
                                </ImageWrappers>
                                <PriceWrapper>
                                  <ItemNameWrapper>
                                    {item?.name.length > 26
                                      ? `${item.name.toLowerCase().slice(0, 26)} ...`
                                      : item.name.toLowerCase()}
                                  </ItemNameWrapper>
                                </PriceWrapper>
                              </SearchInfoWrapper>

                              <ChevronForward />
                            </MainWrapper>
                          </div>
                        )
                      })}
                    </SuggestinDataWrapper>
                  )}
                  {suggestionResult?.ProductResults?.length > 0 && (
                    <SuggestinDataWrapper>
                      <TopHeadings>Products</TopHeadings>
                      {suggestionResult?.ProductResults?.map((item: any, index: number) => {
                        let imgUrl =
                          item?.image?.includes('http://') || item?.image?.includes('https://')
                            ? item?.image
                            : `https://cdnaz.plotch.io/image/upload/w_118,h_118/${item?.image}`
                        if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
                          imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
                          imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
                        }

                        const productName = item?.name
                          .replace(/[^a-zA-Z ]/g, '')
                          .split(' ')
                          .join('-')
                        return (
                          <div key={index}>
                            <MainWrapper
                              onClick={() => {
                                navigate.push(
                                  PdpRoute.path
                                    .replace(':name', productName ? productName : 'product')
                                    .replace(':id', item?.id),
                                )
                                setSearchValue('')

                                localStorage.setItem('productId', item?.id)
                                const dataToPush = {
                                  event: 'search_term_entered',
                                  page_type: GDL_PAGE_TYPE,
                                  User_Id: loggedIn,
                                  User_Status: loggedInStatus,
                                  search_text: searchValue,
                                  option_selected: 'enter_click',
                                }

                                storesData?.d?.googleDatalayer &&
                                  storesData?.d?.googleDatalayer == 1 &&
                                  TagManager.dataLayer({ dataLayer: dataToPush })
                              }}
                            >
                              <SearchInfoWrapper>
                                <ImageWrappers>
                                  <img src={imgUrl} />
                                </ImageWrappers>
                                <PriceWrapper>
                                  <ItemNameWrapper>
                                    {item?.name.length > 28
                                      ? `${item.name.toLowerCase().slice(0, 28)} ...`
                                      : item.name.toLowerCase()}
                                  </ItemNameWrapper>
                                  <PriceRate> &#8377;{item?.price}</PriceRate>
                                </PriceWrapper>
                              </SearchInfoWrapper>

                              <ChevronForward />
                            </MainWrapper>
                          </div>
                        )
                      })}
                    </SuggestinDataWrapper>
                  )}
                </SuggestinDataContainer>
              ) : null}
            </>
          )}
        </SearchWrapper>
      </LeftContainer>
      <RightContainer>
        {themedata?.d?.objectDetails?.general?.showOndcLocation?.showOndcLocation && ishyperlocalEnable == 1 && (
          <LocationWrapper onClick={handleLocationClick}>
            <LocationIcon />
            {currentAddress?.address?.postcode || postcode ? (
              <>{currentAddress?.address?.postcode || postcode}</>
            ) : (
              <span className="text-nonbreak">Select Location</span>
            )}
            <DownArrow />
          </LocationWrapper>
        )}
        <NavbarOptions>
          {/* <ListIcon /> */}
          {showStore == 1 && <StorefrontIcon onClick={handleStoreClick} />}
          <HeartIcon onClick={handleWishlistLogin} />

          <PersonIcon onClick={handleLogin} />
          <Badge count={cartData} color="var(--primary_icon_highlight_color)">
            <CartIcon onClick={handleCartLogin} />
          </Badge>
        </NavbarOptions>
      </RightContainer>
      <Drawer
        open={open}
        anchor="right"
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '100% ', xl: 'auto' },
          },
        }}
      >
        {<LocationNewUser onClose={toggleDrawer(false)} />}
      </Drawer>
      <Drawer open={openAddress} onClose={toggleAddressDrawer(false)} anchor="right">
        {<SavedAddresses onClose={toggleAddressDrawer(false)} />}
      </Drawer>
    </NavbarContainer>
  )
}

export default Navbar

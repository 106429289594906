import { Button } from 'antd'
import FileUploader from 'components/FileUploader'
import APIS from 'constants/api'
import { SnackBarContext } from 'context/SnackBarContext'
// import { LoaderContext } from 'context/loader'
import usePost from 'hooks/usePost'
import { useState, useCallback, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CommonPopupModalBodySection, Divider } from 'styles/components/PopupModal'
import { CommonStyle, FileUpload, LabelForm } from 'styles/pages/Common'
import { ErrorMessage, TextLabel, TextWrapper } from 'styles/pages/Login'
import { AddressFormWrapper, FormFieldsWrapper } from 'styles/views/SaveAddress'
import Modal from 'components/Modal'

interface FormValues {
  comment: string
  fileUpload: any[]
}

const PopupModal = ({
  open,
  onOk,
  onCancel,
  title,
  BtnName,
  ticketDetails,
  ticketId,
}: {
  open: boolean
  onOk: any
  onCancel: any
  title: string
  BtnName: string
  ticketDetails?: any
  ticketId?: any
}) => {
  const [base64List, setBase64List] = useState<string[]>([])
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      comment: '',
      fileUpload: [],
    },
  })

  const { mutateAsync } = usePost()
  // const { setLoader } = useContext(LoaderContext)
  const { setShowSnackBar } = useContext(SnackBarContext)
  const customerId = localStorage.getItem('customerId')
  const [defaultValue, setDefaultValue] = useState<any[]>([])

  const handleDefaultValue = (fileData: any) => {
    setDefaultValue(fileData)
  }

  const submitRaiseTicket = useCallback(
    async (data: FormValues) => {
      //setLoader(true)
      try {
        const payload = {
          ticketId,
          customerId,
          fileUploadUrl: base64List?.[0] || '',
          comment: data?.comment,
        }

        const res = await mutateAsync({
          url: APIS.TICKET_COMMENT,
          payload,
        })

        if (res) {
          //setLoader(false)
          setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
          onCancel()
          ticketDetails(ticketId)
          reset()
        }
      } catch (error: any) {
        //setLoader(false)
        setShowSnackBar({ open: true, message: `${error?.res?.m}`, severity: 'error' })
      }
    },
    [base64List],
  )

  const handleBase64ListChange = useCallback((newBase64List: string[]) => {
    setBase64List(newBase64List)
  }, [])

  const handleCancel = useCallback(() => {
    onCancel()
    reset()
  }, [onCancel, reset])

  return (
    <Modal
      className="raiseTicketModal commentModal h1b"
      open={open}
      title={title}
      onOk={onOk}
      onCancel={handleCancel}
      destroyOnClose
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Divider key="" />,
        <Button
          className="customButtom h1b"
          name={BtnName}
          key="submit"
          type="primary"
          onClick={handleSubmit(submitRaiseTicket)}
        >
          Comment
        </Button>,
      ]}
    >
      <CommonPopupModalBodySection>
        <AddressFormWrapper onSubmit={handleSubmit(submitRaiseTicket)}>
          <FormFieldsWrapper className="paddingData">
            <CommonStyle>
              <TextWrapper className="textWrapper h2b">
                <LabelForm className="h3b formLabel">Attachment</LabelForm>
                <FileUpload>
                  <FileUploader
                    handleDefault={handleDefaultValue}
                    defaultValue={defaultValue}
                    name="fileUpload"
                    control={control}
                    onBase64ListChange={handleBase64ListChange}
                    length={1}
                  />
                </FileUpload>
                <ErrorMessage>{errors.fileUpload?.message}</ErrorMessage>
              </TextWrapper>
              <TextWrapper className="textWrapper h2b">
                <TextLabel className="h3b formLabel">Comment</TextLabel>
                <Controller
                  control={control}
                  name="comment"
                  render={({ field }) => (
                    <textarea {...field} rows={5} placeholder="Comment" className="custom-textarea" />
                  )}
                />
                <ErrorMessage>{errors.comment?.message}</ErrorMessage>
              </TextWrapper>
            </CommonStyle>
          </FormFieldsWrapper>
        </AddressFormWrapper>
      </CommonPopupModalBodySection>
    </Modal>
  )
}

export default PopupModal

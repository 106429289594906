import styled from 'styled-components'

export const StoreHyperMainWrapper = styled.div`
  padding: 20px 80px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`
export const StoreHyperHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24.5px;
  border-bottom: 1px solid var(--primary_button_stroke_color_light);
  @media (max-width: 768px) {
    border-bottom: unset;
    padding: 0;
  }
`
export const MenuTypeWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`
export const MenuContainer = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: 1px solid var(--primary_button_stroke_color_light);
`
export const StoreHyperHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: var(--primary_text_color_dark);
  .distance-details {
    display: flex;
    align-items: center;
    gap: 3px;
  }
  .offers {
    display: flex;
    align-items: center;
    gap: 3px;
  }
  .offer-heading {
    color: var(--primary_text_link_color);
  }
`
export const MainBodyWrapper = styled.div`
  padding-top: 24.5px;
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary_label_active_text_color);
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background: linear-gradient(90deg, rgba(255, 62, 107, 0) 0%, rgba(255, 62, 107, 0.25) 100%);
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: var(--primary_label_active_text_color);
  }
  .ant-tabs .ant-tabs-tab:hover {
    color: unset;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }
  .content-list {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .content-item {
    display: flex;
    gap: 80px;
    align-items: center;
  }
  .item-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .quantity-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .quantity {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 6px;
    width: 72px;
    height: 30px;
    border-radius: 8px;
    border: 1px solid var(--primary_button_stroke_color, #4f4e4d);
    background: var(--primary_box_fill_color);
    color: var(--secondary_label_active_text_color);
  }
  .discount-details {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .discount-percentage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 16px;
    border-radius: 8px;
    background: var(--primary_label_active_fill_color);
    color: #fff;

    padding: 0 0px;
  }
  .after-discount {
    color: #000;
  }
  .before-discount {
    text-decoration: line-through;
  }

  @media (max-width: 768px) {
    padding-top: 18px;
    padding-bottom: 80px;
    .ant-tabs {
      display: none;
    }
    .content-item {
      justify-content: space-between;
      gap: 20px;
      border-bottom: 1px solid var(--primary_line_color_dark);
      padding: 16px 0 14px;
    }
  }

  /* display: flex; */
  /* gap: 32px; */
`
export const DetailsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--secondary_text_link_color);
  cursor: pointer;
  svg {
    transform: rotate(-90deg);
    path {
      stroke: var(--secondary_text_link_color);
    }
  }
`
export const RecomendedContainer = styled.div`
  .heading {
    color: var(--primary_text_color_dark);
  }
`
export const ImageWrapper = styled.div`
  border-radius: 18px;
  overflow: hidden;
  @media (max-width: 768px) {
    position: relative;
  }
`
export const ImageWrap = styled.div``
export const ButtonWrap = styled.div`
  transform: translate(0, -20px);

  button {
    color: var(--secondary_text_link_color);
    border: 1px solid var(--secondary_text_link_color) !important;
    background: #fff;
    width: 74px !important;
    height: 30px;
    border-radius: 8px;
    /* position: absolute;
      bottom: 20px; */
  }
  @media (max-width: 768px) {
  }
`
export const CheckoutButtonwrapper = styled.div`
  border-radius: 12px;
  border: 1px solid #edecec;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 120px;
  padding: 15px;
  margin-top: 20px;
  color: var(--primary_text_color_dark);
  .checkout-item-wrap {
    display: flex;
    gap: 4px;
  }
  .item-line {
    height: 20px;
    width: 3px;
    path {
      stroke: var(--primary_text_color_dark);
    }
  }
  button {
    width: 160px;
    height: 40px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
export const MobileCheckoutButtonwrapper = styled(CheckoutButtonwrapper)`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    gap: 40px;
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: unset;
    width: calc(100vw - 18px);
    padding: 8px;
  }
  @media (max-width: 360px) {
    gap: 13px;
  }
`
export const MobileMainWrapperList = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

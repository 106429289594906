import styled from 'styled-components'

export const BodyWrapper = styled.div`
  display: block;
  /* background-color: var(--primary_background_color); */
  height: calc(100vh - 65px);
  @media (max-width: 768px) {
    background-color: unset;
    height: 100vh;
  }
`
export const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  /* gap: 17px; */
  cursor: pointer;
`
export const ManageAddressWrapper = styled.div`
  .padding_full_page {
    padding: 17px 13px;
  }
  svg {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
`
export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 336px;
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  padding: 8px 11px;
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 24px);
    /* position: fixed;
    bottom: 0;
    display: block; */
  }
`

export const CheckoutButtonWrapper = styled.div`
  /* position: absolute; */
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 336px;
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  padding: 8px 11px;
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 24px);
    /* position: fixed;
    bottom: 0;
    display: block; */
  }
`
export const BottomButtonWrapper = styled.div`
  margin-bottom: 16px;
  margin-top: 8px;
  border: 1px solid #edecec;
  background-color: #fff;
  padding: 12px;
  position: absolute;
  bottom: 0;
  width: 336px;
  @media screen and (max-width: 768px) {
    margin-bottom: unset !important;
    margin-top: auto;
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100vw - 26px);
  }
`
export const ReturnButtonWrapper = styled.div`
  margin-bottom: 16px;
  margin-top: 8px;
  border: 1px solid #edecec;
  background-color: #fff;
  padding: 12px;
  /* position: absolute; */
  /* bottom: 0; */
  width: 336px;
  @media screen and (max-width: 768px) {
    margin-bottom: unset !important;
    margin-top: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100vw - 26px);
  }
`
// export const ReturnButtonWrapper = styled(BottomButtonWrapper)`
//   position: unset !imp;
//   @media (max-width: 768px) {
//     left: 0;
//   }
// `
export const CheckoutPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 47px 20px 23px;
  @media screen and (max-width: 768px) {
    display: block;
    padding: unset;
    padding-bottom: 60px;
  }
`
export const TicketPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 47px 20px 23px;
  @media screen and (max-width: 768px) {
    display: block;
    padding: unset;
  }
`
export const AccountWrapper = styled.div`
  background-color: var(--primary_box_fill_color);
  height: 100vh;
  .profile-section-div {
    background-color: var(--primary_box_fill_highlight_color);
    padding: 8px 23px 17px;
    display: flex;
    gap: 4px;
    align-items: self-start;
    border: 1px solid var(--primary_stroke_color_light);
  }
  .profile-name,
  .profile-number {
    color: var(--primary_text_color_dark);
  }
  .profile-section-icon {
    padding: 2px;
  }
  .profile-number {
    margin-top: 6px;
  }
  .padding_full_page {
    padding: 17px;
  }
  .profile-edit-btn,
  .actionArrow {
    margin-left: auto;
  }
  .profile-edit-btn a {
    text-decoration: none;
    color: var(--primary_text_link_color) !important;
  }
  .account-menu-list {
    margin-top: 6px;
  }
  .bottomButton {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .logoutBtn {
    width: 160px;
  }
`

export const CheckoutWrappers = styled.div`
  background-color: var(--primary_box_fill_color);
  border: 1px solid var(--primary_stroke_color_light);

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  display: flex;
  flex-flow: column;
  width: 360px;
  min-height: 70%;
  padding: 8px 20px;
  position: relative;
  .account-main-div {
    padding: 8px 0px 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    align-items: self-start;
  }
  .profile-section-div {
    background-color: var(--primary_box_fill_highlight_color);
    padding: 8px 24px 17px;
    display: flex;
    gap: 4px;
    align-items: center;
    border: 1px solid var(--primary_stroke_color_light);
  }
  .profile-name,
  .profile-number {
    color: var(--primary_text_color_dark);
  }
  .profile-section-icon {
    display: flex;
    gap: 7px;
    /* padding: 2px; */
  }
  .profile-number {
    margin-top: 6px;
  }
  .padding_full_page {
    padding: 17px 0px;
  }
  .profile-edit-btn,
  .actionArrow {
    margin-left: auto;
  }
  .profile-edit-btn a {
    text-decoration: none;
    color: var(--primary_text_link_color) !important;
  }
  .account-menu-list {
    margin-top: 24px;
    padding: 0px 22px;
  }
  .bottomButton {
    margin-top: auto;
    right: 0;
    left: 0;
    bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .logoutBtn {
    width: 160px;
  }
  @media (max-width: 768px) {
    width: 100%;
    justify-content: unset;
    align-items: unset;
    padding: 0;
    border: none;
    .padding_full_page {
      padding: 17px 13px;
    }
    .bottomButton {
      position: fixed;
      bottom: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
    }
    /* padding: 20px; */
  }
  @media (max-width: 600px) {
    min-height: 85%;
  }
`
export const CoupansWrappers = styled(CheckoutWrappers)`
  gap: 10px;
  padding: 8px 20px 25px;

  @media screen and (max-width: 768px) {
    padding: unset;
  }
`
export const AccountWrappers = styled.div`
  background-color: var(--primary_box_fill_color);
  border: 1px solid var(--primary_stroke_color_light);
  margin: 20px auto;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  display: flex;
  flex-flow: column;
  width: 360px;
  min-height: 70%;
  padding: 8px 20px 42px;
  position: relative;
  .account-main-div {
    padding: 8px 0px 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    align-items: self-start;
  }
  .profile-section-div {
    background-color: var(--primary_box_fill_highlight_color);
    padding: 8px 24px 17px;
    display: flex;
    gap: 4px;
    align-items: center;
    border: 1px solid var(--primary_stroke_color_light);
  }
  .profile-name,
  .profile-number {
    text-transform: capitalize;
    color: var(--primary_text_color_dark);
  }
  .profile-section-icon {
    display: flex;
    gap: 7px;
    /* padding: 2px; */
  }
  .profile-number {
    margin-top: 6px;
  }
  .padding_full_page,
  .padding_full_pages {
    padding: 17px 0px;
  }
  .profile-edit-btn,
  .actionArrow {
    margin-left: auto;
  }
  .profile-edit-btn a {
    text-decoration: none;
    color: var(--primary_text_link_color) !important;
  }
  .account-menu-list {
    margin-top: 24px;
    padding: 0px 22px;
  }
  .bottomButton {
    margin-top: auto;
    right: 0;
    left: 0;
    bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .logoutBtn {
    width: 160px;
  }
  @media (max-width: 768px) {
    justify-content: unset;
    align-items: unset;
    margin: unset;
    /* margin: unset auto; */
    padding: 0;
    border: none;
    width: 100%;

    .padding_full_page {
      padding: 17px 24px;
    }
    .padding_full_pages {
      padding: 17px 13px;
    }
    .bottomButton {
      position: fixed;
      bottom: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
    }
    /* padding: 20px; */
  }
  @media (max-height: 600px) {
    min-height: 85%;
  }
`
export const AccountManageWrappers = styled(AccountWrappers)`
  padding: 16px 13px;
  /* border: 1px solid var(--primary_stroke_color_light); */
  @media (max-height: 600px) {
    /* border: 0px solid var(--primary_stroke_color_light); */

    border: none;
  }
`

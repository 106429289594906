import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useTheme } from 'context/themeContext'
import { pageData } from 'utils/datalayer/pageData'
// import { usePageData } from 'utusePageData'

interface GoogleTagManagerProps {
  event?: string
  additionalData?: Record<string, any>
}

const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({ event = 'pageView' }) => {
  const { storesData } = useTheme()
  const googleDatalayer = storesData?.d?.googleDatalayer

  const gtmCode = storesData?.d?.gtmId
  const { loggedIn, loggedInStatus, address, GDL_PAGE_TYPE, sellerNameToUse } = pageData()

  useEffect(() => {
    const data = {
      event: event,
      category: 'na',
      sub_category: 'na',
      tags: 'na',
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      brand: 'na',
      product_id: 'na',
      section_name: 'na',
      selected_location: address,
      items_added_in_cart: 'na',
      items_added_to_wishlist: 'na',
      seller_name: sellerNameToUse,
      page_type: GDL_PAGE_TYPE,
    }

    // if (gtmCode) {
    //   if (!document.getElementById('gtm-script')) {
    //     const tagManagerArgs = {
    //       gtmId: gtmCode,
    //     }
    //     TagManager.initialize(tagManagerArgs)
    //   }

    //   TagManager.dataLayer({
    //     dataLayer: {
    //       ...data,
    //     },
    //   })
    // }
    // Initialize GTM if the gtmCode is present
    if (gtmCode && !document.getElementById('gtm-script')) {
      TagManager.initialize({ gtmId: gtmCode })
    }

    // Push data to the GTM data layer only if googleDatalayer === '1'
    if (googleDatalayer === '1') {
      TagManager.dataLayer({ dataLayer: data })
    }
  }, [gtmCode, loggedIn, loggedInStatus, address, GDL_PAGE_TYPE, sellerNameToUse, event])

  return null
}

export default GoogleTagManager

import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { InputWrapper, InputContainer } from 'styles/components/OtpInput'

const OtpInput = ({ length = 4, getFullOtp, name }: any) => {
  //count of inputs by using the length
  const [otp, setOtp] = useState(new Array(length)?.fill(''))
  //using Ref for focusing of the input
  const inputRefs: any = useRef([])

  //focus on the fist input when landed
  useEffect(() => {
    if (inputRefs?.current[0]) {
      inputRefs?.current[0].focus()
    }
  }, [])

  //handling the change of inputs
  const handleChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    const value: any = e.target.value

    if (isNaN(value)) return

    const newOtp = [...otp]

    // allow only one input
    newOtp[index] = value?.substring(value.length - 1)
    setOtp(newOtp)

    // send back the full otp to main component using getFullOtp function
    const combinedOtp = newOtp?.join('')
    if (combinedOtp?.length === length) getFullOtp(combinedOtp)

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs?.current[index + 1]) {
      inputRefs?.current[index + 1].focus()
    }
  }

  //handling to focus on the first empty input when clicked on any other
  const handleClick = (index: number) => {
    inputRefs?.current[index]?.setSelectionRange(1, 1)

    if (index > 0 && !otp[index - 1]) {
      inputRefs?.current[otp.indexOf('')].focus()
    }
  }

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Backspace' && !otp[index] && index > 0 && inputRefs?.current[index - 1]) {
      // Move focus to the previous input field on backspace
      inputRefs?.current[index - 1].focus()
    }
  }

  return (
    <InputWrapper>
      {otp?.map((value, index) => {
        return (
          <InputContainer
            placeholder=""
            key={index}
            name={name}
            type="tel"
            ref={(input) => (inputRefs.current[index] = input)}
            value={value}
            onChange={(e) => handleChange(index, e)}
            onClick={() => handleClick(index)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className="field"
            autoComplete="off"
          />
        )
      })}
    </InputWrapper>
  )
}

export default OtpInput

import * as yup from 'yup'

export const RETURN_VALIDATION_SCHEMA = yup.object().shape({
  reason: yup.string().required('Reason for return is required'),
  refundMode: yup.string().required('Refund mode is required'),
  holderName: yup
    .string()
    .required('Please enter account holder name')
    .matches(/^(?! )[a-zA-Z ]+$/, 'A valid account holder name is required'),
  accountNumber: yup
    .string()
    .required('Please enter account number')
    .matches(/^\d{9,18}$/, 'A valid account number is required'),
  ifscCode: yup
    .string()
    .required('Please enter IFSC Code')
    .matches(/^[a-zA-Z]{4}[A-Z0-9]+$/, 'A valid IFSC Code is required'),
  description: yup.string().nullable(),
})

export const RETURN_VALIDATION_SCHEMA1 = yup.object().shape({
  reason: yup.string().required('Please select a reason for return'),
  refundMode: yup.string().required('Please select a refund mode'),

  description: yup.string(),
})

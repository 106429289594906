import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import Drawer from '@mui/material/Drawer'
import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import APIS from 'constants/api'
import usePost from 'hooks/usePost'
import { AddAddressRoute } from 'constants/routes'
import { IAddress } from 'interfaces/views'
import { pageData } from 'utils/datalayer/pageData'
import DeleteAddress from 'views/DeleteAddress'
import HomeIcon from 'assets/svg/HomeIcon'
import CheckedImg from 'assets/images/checkmark.circle.png'
import UncheckedCircle from 'assets/images/circle.png'
import NoAddressIcon from 'assets/svg/NoAddressIcon'
import Business from 'assets/svg/Business'
import OfficeIcon from 'assets/svg/OfficeIcon'
import OtherIcon from 'assets/svg/OtherIcon'
import {
  AddressesListContainer,
  AddressMainWrapper,
  AddressLabel,
  AddressesLists,
  AddressContainer,
  AddressesContainer,
  AddressWrapper,
  PincodeLocation,
  BasicAddress,
  CityDetail,
  PersonName,
  AddressesButtonWrapper,
  Divider,
  NoAddressWrapper,
} from 'styles/views/LocationNewUser'
import { AddressInteractionData } from 'utils/datalayer/addressInteractionData'
import { useTheme } from 'context/themeContext'

const AddressList = ({ onClose, refetchOndcQuoteDetails }: any) => {
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null)
  const [openDeleteAddress, setOpenDeleteAddress] = useState(false)
  const [currentAddressId, setCurrentAddressId] = useState<string | null>(null)
  const [checkAddressDeleted, setCheckAddressDeleted] = useState(false)
  const [address, setAddress] = useState([])
  const [anchorChoice, setAnchorChoice] = useState<'bottom' | 'left' | 'top' | 'right'>('bottom')
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const { editAddressDataToPush, useAddressDataToPush } = AddressInteractionData()
  const { storesData } = useTheme()
  const navigate = useHistory()

  const { mutateAsync } = usePost()

  const getAddresses = async (data: string) => {
    try {
      const res = await mutateAsync({
        url: APIS.ADDRESS_LIST,
        payload: {
          customerId: data,
          networkType: 1,
          networkSpeed: 0,
        },
      })
      if (res) {
        setAddress(res?.d?.addressList)
      }
    } catch (error) {
      return error
    }
  }

  const handleCartAddress = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.CART_ATTACH_ADDRESS,
        payload: {
          customerId: localStorage.getItem('customerId'),
          addressId: localStorage.getItem('addressId'),
        },
      })
      if (res) {
        refetchOndcQuoteDetails()
      }
    } catch (error) {
      return error
    }
  }

  const handleAddressSelect = (item: any) => {
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: useAddressDataToPush })

    setSelectedAddressId(item?.addressId)
    localStorage.setItem('postcode', item?.postcode)
    localStorage.setItem('addressId', item?.addressId)
    localStorage.setItem(
      'location',
      JSON.stringify({
        address: item.address === null ? item.city : item.address,
        placeName: item.locality,
        latitude: item.latitude,
        longitude: item.longitude,
      }),
    )
    handleCartAddress()
    const locationDataString: any = localStorage.getItem('location')

    const locationData = JSON.parse(locationDataString).address

    const dataToPush = {
      event: 'select_location_interaction',
      cta_text: locationData.toLowerCase(),
      select_type: 'select location',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
    }

    // Push data to GTM and wait for completion
    const pushToDataLayer = () => {
      return new Promise<void>((resolve) => {
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: dataToPush })
        resolve()
      })
    }

    pushToDataLayer()
    // window.location.reload()
    onClose()
    // onClose()
  }

  const checkDeleted = (value: boolean) => {
    setCheckAddressDeleted(value)
  }

  useEffect(() => {
    const customerId = localStorage.getItem('customerId')
    if (customerId) {
      getAddresses(customerId)
    }
  }, [checkAddressDeleted])

  const toggleDeleteAddressDrawer = (newAddress: boolean) => {
    setOpenDeleteAddress(newAddress)
  }

  const handleEdit = (item: IAddress) => {
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: editAddressDataToPush })

    navigate.push(AddAddressRoute.path, {
      addressId: item?.addressId,
    })
  }

  const handleDelete = (item: IAddress) => {
    setCurrentAddressId(item?.addressId)
    toggleDeleteAddressDrawer(true)
  }

  useEffect(() => {
    const addressId = localStorage.getItem('addressId')
    setSelectedAddressId(addressId)
  }, [])

  const getAddressIcon = (label: string | null) => {
    switch (label) {
      case 'Work':
        return <OfficeIcon />
      case 'Home':
      case null:
        return <HomeIcon />
      case 'Hotel':
        return <Business />
      case 'Other':
        return <OtherIcon />
      default:
        return <OtherIcon />
    }
  }

  useEffect(() => {
    const updateAnchor = () => {
      if (window.innerWidth > 768) {
        setAnchorChoice('right')
      } else {
        setAnchorChoice('bottom')
      }
    }

    updateAnchor()

    window.addEventListener('resize', updateAnchor)

    return () => {
      window.removeEventListener('resize', updateAnchor)
    }
  }, [])

  return (
    <AddressesListContainer>
      {address?.length ? (
        <AddressMainWrapper>
          <AddressLabel>Saved Addresses</AddressLabel>
          <AddressesLists>
            {address?.map((item: IAddress, index: number) => {
              return (
                <AddressesContainer
                  key={index}
                  onClick={() => handleAddressSelect(item)}
                  isActive={selectedAddressId !== null && selectedAddressId === item?.addressId}
                >
                  <AddressContainer>
                    {getAddressIcon(item?.label)}
                    <AddressWrapper>
                      <PincodeLocation>
                        {item?.postcode} - {item?.label === null ? 'Home' : item?.label}
                      </PincodeLocation>
                      <BasicAddress>
                        {item?.building}
                        {item?.building ? ',' : ''}&nbsp;
                        {item?.locality}
                        {item?.locality ? ',' : ''}&nbsp;
                        {item?.landmark}
                        {item?.landmark ? ',' : ''}&nbsp;
                      </BasicAddress>
                      <CityDetail>
                        {item?.city}
                        {item?.city ? ',' : ''}&nbsp;
                        {item?.region}
                        {item?.region ? ',' : ''}&nbsp;
                        {item?.country}
                      </CityDetail>
                      <PersonName>
                        {item?.firstName} {item?.lastName} &nbsp;({item?.telephone})
                      </PersonName>
                      <AddressesButtonWrapper>
                        <Button
                          label="Edit"
                          variant="text"
                          type="button"
                          className="small"
                          onClick={() => handleEdit(item)}
                        />
                        <Divider />
                        <Button
                          label="Delete"
                          variant="text"
                          type="button"
                          className="small"
                          onClick={(e: any) => {
                            e.stopPropagation()
                            handleDelete(item)
                          }}
                        />
                      </AddressesButtonWrapper>
                    </AddressWrapper>
                  </AddressContainer>
                  {selectedAddressId !== null && selectedAddressId === item?.addressId ? (
                    <img src={CheckedImg} alt="checked_img" />
                  ) : (
                    <img src={UncheckedCircle} alt="unchecked_img" className="circle" />
                  )}
                </AddressesContainer>
              )
            })}
          </AddressesLists>
        </AddressMainWrapper>
      ) : (
        <NoAddressWrapper>
          <NoAddressIcon />
          <div>No saved addresses</div>
        </NoAddressWrapper>
      )}
      <Drawer
        open={openDeleteAddress}
        anchor={anchorChoice}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: { xs: '20px 20px 0px 0px', sm: '0', xl: '0' },
          },
        }}
      >
        {
          <DeleteAddress
            onClose={() => toggleDeleteAddressDrawer(false)}
            addressId={currentAddressId}
            checkDeleted={checkDeleted}
          />
        }
      </Drawer>
    </AddressesListContainer>
  )
}

export default AddressList

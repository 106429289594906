import { useHistory } from 'react-router-dom'
import PublicLayout from 'components/Layouts/public'
import { CommonStyle } from 'styles/pages/Common'
import { TrackDetailPageWrapper, TrackingInformation, TrackDetailsWrapper } from 'styles/views/TrackOrderDetail'
import BackArrow from 'assets/svg/BackArrow'

import {
  CheckoutWrappers,
  // ManageAddressWrapper,
  BackWrapper,
  CheckoutPageWrapper,
  CheckoutButtonWrapper,
} from 'styles/pages/MyAccount'
import useGet from 'hooks/useGet'
import APIS from 'constants/api'
import { useEffect } from 'react'
import { HomeRoute } from 'constants/routes'
import Button from 'components/Button'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'

const TrackDetailPage = () => {
  const navigate = useHistory()
  const orderItemId = localStorage.getItem('orderItemId')
  const customerId = localStorage.getItem('customerId')
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  const { refetch, data: trackData } = useGet(
    'track-details',
    `${APIS.TRACK_SHIPMENT}?orderItemId=${orderItemId}&customerId=${customerId}`,
  )
  const trackDetailData = trackData?.d

  const continueDataToPush = {
    event: 'continue_shopping',
    page_type: GDL_PAGE_TYPE,
    User_Id: loggedIn,
    User_Status: loggedInStatus,
    cta_text: 'continue shopping',
  }

  const handleBack = () => {
    navigate.goBack()
  }
  const handleClick = () => {
    TagManager.dataLayer({ dataLayer: continueDataToPush })
    navigate.push(HomeRoute.path)
  }

  useEffect(() => {
    refetch()
  }, [trackData])

  return (
    <PublicLayout>
      <CheckoutPageWrapper>
        <CheckoutWrappers>
          <CommonStyle>
            <BackWrapper className="padding_full_page" onClick={handleBack}>
              <BackArrow />
              <div className="h2b">TRACK DETAILS</div>
            </BackWrapper>
          </CommonStyle>
          <CommonStyle>
            <TrackDetailPageWrapper>
              <TrackingInformation>
                <label className="h1r">
                  Shipment Id: {trackDetailData?.shipmentId ? trackDetailData?.shipmentId : 'Not available'}
                </label>
                <TrackDetailsWrapper>
                  <label className="h2r track-detail">
                    For more deatil:
                    <a href={trackDetailData?.trackingUrl} target="_self" className="plotch_a_link">
                      Click Here
                    </a>
                  </label>
                  {/* <div className=" h3r detail" onClick={handleTrack}>
                    Track details <RightArrow />
                  </div> */}
                </TrackDetailsWrapper>
              </TrackingInformation>
            </TrackDetailPageWrapper>
          </CommonStyle>
          <CheckoutButtonWrapper>
            <Button
              label="Continue Shopping"
              variant="contained"
              type="submit"
              className="small"
              onClick={handleClick}
            />
          </CheckoutButtonWrapper>
        </CheckoutWrappers>
      </CheckoutPageWrapper>
    </PublicLayout>
  )
}

export default TrackDetailPage

import { useContext, useEffect, useState } from 'react'
import { Carousel } from 'antd'
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import Drawer from '@mui/material/Drawer'
import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import Modal from 'components/Modal'
import MobileSimilarProducts from 'views/SimilarProducts/mobileSimilarProducts'
import APIS from 'constants/api'
// import { LoaderContext } from 'context/loader'
import { SnackBarContext } from 'context/SnackBarContext'
import { SellerRoute, VendorRoute, CheckoutRoute, LoginRoute } from 'constants/routes'
import { useTheme } from 'context/themeContext'
import usePost from 'hooks/usePost'
import Line from 'assets/svg/Line'
import HeartIcon from 'assets/svg/HeartIcon'
import DownArrow from 'assets/svg/DownArrow'
import UpArrow from 'assets/svg/UpArrow'
import ShareIcon from 'assets/svg/ShareIcon'
import CloseIcon from 'assets/svg/CloseIcon'
import SimilarProductIcon from 'assets/svg/SimilarProductIcon'
import WishlistedIcon from 'assets/svg/WishlistedIcon'

import {
  PdpDetailViewContainer,
  PdpDetailViewWrapper,
  ProductContainer,
  ProductBasicInfo,
  ProductDetails,
  HeadingWrapper,
  PriceWrapper,
  SizeDetails,
  ColorDetails,
  ProductDesc,
  ReadWrapper,
  ProductDetailsWrapper,
  ProductCompleteDetails,
  DesktopView,
  ImageList,
  CrousalWrapper,
  MobileIconsWrapper,
  SimilarIconWrapper,
  ZoomImage,
  ColorContainer,
  Box1,
  SizeChartImageWrapper,
  ZoomContainer,
  ZoomCarousel,
} from 'styles/views/PdpDetailView'
import { DeleteButtonWrapper, DesktopDeleteButtonWrapper } from 'styles/views/SaveAddress'
import { CommonStyle } from 'styles/pages/Common'
// import ReactImageMagnify from 'react-image-magnify'
import { MagnifierContainer, MagnifierPreview, MagnifierZoom } from 'react-image-magnifiers'
import { useWishListContext } from 'context/WishlistDetailContext'
import { useHomeContext } from 'context/HomeContext'
import CustomizableItems from 'views/CustomizableItems'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
import SocialShareModal from 'views/SocialShareModal'

const PdpDetailView = ({ productDetails, similarProductDetails }: any) => {
  const { themedata, storesData, isServiceableProduct } = useTheme()
  const { setShowSnackBar } = useContext(SnackBarContext)
  // const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  const [flag, setFlag] = useState(0)
  const [expandedAttributes, setExpandedAttributes] = useState<{ [key: number]: boolean }>({})
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE, address } = pageData()

  const [buttonType, setButtonType] = useState(0)
  const [serviceableFlag, setServiceableFlag] = useState(true)
  const socialIcons = themedata?.d?.objectDetails?.socialShare
  const customerId = localStorage.getItem('customerId')
  const showQty = themedata?.d?.objectDetails?.productView?.showQty
  const [anchorChoice, setAnchorChoice] = useState<'bottom' | 'left' | 'top' | 'right'>('bottom')
  const [addonProducts, setAddonProducts] = useState([])
  const { setCartCount, cartCount } = useHomeContext()

  const sizeChartUrl = productDetails?.productAttributes?.find((item: any) => item?.key?.trim() === 'Size Chart')
    ?.value[0]
  let modifiedSizeChartUrl = sizeChartUrl
  if (sizeChartUrl && sizeChartUrl.includes('/cdnaz.plotch.io/image/upload')) {
    modifiedSizeChartUrl = sizeChartUrl.replace(
      '/cdnaz.plotch.io/image/upload',
      '/cdn-img.plotch.io/plotchimagebucket-container',
    )
  }

  const [openSimilarProduct, setOpenSimilarProduct] = useState(false)
  const [count, setCount] = useState(1)
  const [isReadMore, setIsReadMore] = useState(true)
  const [isSeeMore, setIsSeeMore] = useState(true)
  const [variant, setVariant] = useState<any>([])
  const [productVariants, setProductVariants] = useState<any>([])
  const [variantKeys, setVariantKeys] = useState([{}])
  const imageList = productDetails?.galleryImages
  const navigate = useHistory()
  const { wishlistIdData, handleAddWishlist, handleRemoveWishlist, cartIdData } = useWishListContext()
  const [openCustom, setOpenCustom] = useState(false)

  const [selectedImage, setSelectedImage] = useState(
    imageList && imageList.length > 0 ? imageList[0]?.url : productDetails?.imgUrl,
  )
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [scaleCount, setScaleCount] = useState(1)
  const toggleSimilarDrawer = (newOpen: boolean) => () => {
    setOpenSimilarProduct(newOpen)
  }
  const toggleCustomDrawer = (newCustom: boolean) => () => setOpenCustom(newCustom)

  const increment = () => {
    setCount((prevCount) => prevCount + 1)
  }
  const variantList = productDetails?.variants_list || []
  const variantType = themedata?.d?.objectDetails?.productView?.variantType || 0
  const baseImgUrl = 'https://cdnaz.plotch.io/image/upload'
  const productId = productDetails?.productId
  const [touchMove, setTouchMove] = useState(true)
  const checkProduct = cartIdData.find((item: any) => {
    return item == productId
  })

  const variantProducts = productDetails?.variantProducts || []

  const decrement = () => {
    setCount((prevCount) => (prevCount > 1 ? prevCount - 1 : 1))
  }

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  const toggleSeeMore = () => {
    setIsSeeMore(!isSeeMore)
  }

  useEffect(() => {
    if (
      cartIdData.find((item: any) => {
        return item == productId
      })
    )
      setFlag(1)
    else setFlag(0)

    const vendorGps = productDetails?.vendorDetails?.vendorGps
    if (vendorGps) {
      const [vendorLat, vendorLon] = vendorGps?.split(',')
      const circleRadius = productDetails?.circleRadius?.value
      isServiceableProduct(vendorLat, vendorLon, circleRadius).then((flag) => {
        setServiceableFlag(flag)
      })
    }
  }, [productId])

  const toggleAttributeView = (index: number) => {
    setExpandedAttributes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  const convertFormat = (text: string) => {
    const textWithSpaces = text.replace(/_/g, ' ')
    const formattedText = textWithSpaces.replace(/\b\w/g, (char) => char.toUpperCase())
    return formattedText
  }
  const getKeyValuePairs = (payload: any) => {
    return payload?.map((attribute: any) => ({
      key: convertFormat(attribute?.key),
      value: attribute?.value.join(', '),
    }))
  }

  const arr = getKeyValuePairs(productDetails?.productAttributes)
  const initialDisplayCount = 4
  const displayArray = !isSeeMore ? arr : arr?.slice(0, initialDisplayCount)
  useEffect(() => {
    if (imageList && imageList.length > 0) {
      setSelectedImage(imageList[0]?.url)
    }
  }, [imageList])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalSizeChart, setIsModalSizeChart] = useState(false)
  const [zoomModal, setZoomModal] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const customOnCancel = () => {
    setIsModalVisible(false)
  }
  const showSizeModal = () => {
    setIsModalSizeChart(true)
  }

  const handleSizeOk = () => {
    setIsModalSizeChart(false)
  }

  const customSizeOnCancel = () => {
    setIsModalSizeChart(false)
  }

  const customOnCancelModal = () => {
    setZoomModal(false)
  }

  const handleWishlistClick = async (productId: string, productDetails?: any) => {
    const googleDatalayer = storesData?.d?.googleDatalayer
    handleAddWishlist(productId, productDetails, googleDatalayer)
  }

  const handleRemoveWishlistClick = async (productId: string) => {
    handleRemoveWishlist(productId)
  }

  useEffect(() => {
    if (variantProducts && variantProducts.length > 0) {
      const variantArray: any = []
      const keys: any = []
      Object.keys(variantList).map((item: any) => {
        keys.push(item)
        return (variantArray[item] = '')
      })
      if (variantArray) {
        setVariantKeys(keys)
      }

      const variantProductArray: any = []
      Object.values(variantProducts).map((item: any) => {
        variantProductArray[item.productId] = item.variants
      })
      setProductVariants(variantProductArray)

      if (productDetails?.variants) {
        Object.entries(productDetails?.variants).forEach(([key, value]) => {
          Array.from(document.getElementsByClassName(key)).forEach((item) => {
            item.classList.remove('active')
          })
          document.getElementById(key + '_' + value)?.classList.add('active')
        })
      }
    }
  }, [variantProducts])

  const showMultivariant = async (key: string, value: string) => {
    const selectedVariant = Array.from(document.getElementsByClassName('active'))
    const otherVaraintWithSameKey = Array.from(document.getElementsByClassName(key))

    otherVaraintWithSameKey.forEach((element) => {
      element.classList.remove('active')
    })
    const currentVariant = document.getElementById(`${key}_${value}`)
    currentVariant?.classList.add('active')
    const totalVariantCount = Object.keys(variantList).length
    const variantArray: any = variant
    selectedVariant?.forEach((element) => {
      const data = element.id.split('_')
      variantArray[data[0]] = data[1]
    })
    variantKeys.map((item) => {
      if (item === key) {
        variantArray[key] = value
      }
    })
    setVariant(variantArray)
    let data_min: any = []
    if (totalVariantCount === 1) {
      Object.keys(productVariants).map(async (data) => {
        if (!['urlPath', 'imageUrl', 'productId', 'variants', 'discountedPrice'].includes(data)) {
          const productId: number = Number(data)
          productVariants[productId][key][0] === value ? data_min.push(productId) : ''
        }
      })
    } else {
      data_min = checkProductVariant()
    }

    if (data_min.length === 1 && Object.keys(variant).length == totalVariantCount) {
      const newProductId = data_min[0]
      setVariant([])
      const newUrl = navigate.location.pathname.replace(`/${productDetails?.productId}`, `/${newProductId}`)
      navigate.push(newUrl)
    } else {
      const disabledProducts: any = []
      const activeProducts: any = []
      Object.keys(productVariants)?.forEach((x: string) => {
        if (!data_min.includes(String(x))) disabledProducts.push(x)
        else activeProducts.push(x)
      })

      disabledProducts?.every((pId: any) => {
        Object.entries(productVariants[pId])?.every(([variantKey, variantValue]) => {
          if (!Object.keys(variant).includes(variantKey)) {
            const element = document.getElementById(`${variantKey}_${variantValue}`)
            if (element) {
              element.style.display = 'none'
            }
          } else {
            const element = document.getElementById(`${variantKey}_${variantValue}`)
            if (element) {
              element.style.display = 'flex'
            }
          }
        })
      })

      activeProducts.every((pId: any) => {
        Object.entries(productVariants[pId]).every(([variantKey, variantValue]) => {
          const element = document.getElementById(`${variantKey}_${variantValue}`)
          if (element) {
            element.style.display = 'flex'
          }
        })
      })
    }
  }

  const checkProductVariant = () => {
    const finalObject = Object.keys(productVariants).filter((key) => {
      const productVariant = productVariants[key]
      const data = Object.keys(variant).every((k) => {
        return productVariant[k]?.[0] == variant[k]
      })
      return data
    })
    return finalObject
  }
  const handleAddToCart = async (productId: string, buyFlag: number = 0) => {
    // setOpenCustom(true)
    setButtonType(buyFlag)
    const hasAttachedGroupId = productDetails?.attachGroups && productDetails.attachGroups?.attached_groups?.length > 0
    if (hasAttachedGroupId) {
      setOpenCustom(true)
    } else {
      await addToCart(productId, buyFlag)
    }
  }
  const addToCart = async (productId: string, buyFlag: number = 0) => {
    //setLoader(true)

    try {
      const res = await mutateAsync({
        url: APIS.ADD_TO_CART,
        payload: {
          customerId: customerId ? customerId : false,
          products: [
            {
              productId: productId,
              qty: count,
              addons: addonProducts,
            },
          ],
          flag: 1,
        },
      }).then()
      if (res) {
        setCartCount(cartCount + 1)
        setShowSnackBar({ open: true, message: `Added To Cart`, severity: '' })
        setFlag(1)
        if (buyFlag) {
          if (!localStorage.getItem('customerId')) {
            navigate.push(LoginRoute.path + '?redirect=checkout')
          } else {
            navigate.push(CheckoutRoute.path)
          }
        }

        const discount =
          productDetails?.regularPrice && productDetails?.discountedPrice
            ? parseFloat((productDetails.regularPrice - productDetails.discountedPrice).toFixed(2))
            : 0
        const itemCategory = Array.isArray(productDetails?.categoryName)
          ? productDetails?.categoryName[0]?.toLowerCase()
          : productDetails?.categoryName?.toLowerCase()
        const variantStringArray: string[] = []

        if (productDetails?.variants_list) {
          Object.entries(productDetails.variants_list).forEach(([key, values]) => {
            if (Array.isArray(values) && values.length > 0) {
              variantStringArray.push(`${key}-${values.join(', ')}`)
            } else {
              variantStringArray.push(`${key}-`)
            }
          })
        }

        const variantString = variantStringArray.join('|')
        const elObject = {
          event: 'add_to_cart',
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          selected_location: address,
          page_type: GDL_PAGE_TYPE,
          select_type: 'pdp_add_to_cart',
          ecommerce: {
            value: productDetails.discountedPrice,
            currency: 'INR',
            coupon: 'na',
            items: [
              {
                item_name: productDetails?.productName?.toLowerCase() || 'na',
                item_id: productDetails?.productId,
                item_brand: productDetails?.brand?.toLowerCase() || 'na',
                discount: discount,
                item_variant: variantString || 'na',
                item_category: itemCategory,
                price: productDetails?.regularPrice,
                item_list_id: 'na',
                item_list_name: 'na',
                item_category2: 'na',
                item_category3: 'na',
                item_category4: 'na',
                coupon: 'na',
                item_category5: 'na',
                Is_returnable: productDetails?.returnable,
                quantity: 1,
                index: 1,
              },
            ],
          },
        }
        const googleDatalayer = storesData?.d?.googleDatalayer
        if (productDetails?.productId && googleDatalayer === '1') {
          TagManager.dataLayer({ dataLayer: elObject })
        }
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data || 'Something Went Wrong'
      setShowSnackBar({ open: true, message: `${errorMessage?.m}`, severity: '' })
    }
  }

  const handleViewClick = () => {
    const viewSellerDataToPush = {
      event: 'store_seller_link',
      page_type: GDL_PAGE_TYPE,
      User_Status: loggedInStatus,
      User_Id: loggedIn,
      cta_text: `${productDetails?.vendorName?.toLowerCase()}`,
      redirection_url: window.location.href,
      product_name: `${productDetails.productId}`,
      product_id: `${productDetails.productId}`,
      category: `${productDetails?.categoryName ? productDetails?.categoryName[0]?.toLowerCase() : 'na'}`,
    }

    if (storesData?.d?.ondcEnabled === '1') {
      navigate.push(SellerRoute.path.replace(':id', productDetails?.sellerId))
      storesData?.d?.googleDatalayer == 1 && TagManager.dataLayer({ dataLayer: viewSellerDataToPush })
    } else if (productDetails?.isMarketplace === 1) {
      navigate.push(VendorRoute.path.replace(':id', productDetails?.vendorDetails?.vendorId))
    }
  }

  const [error, setError] = useState(false)

  const handleError = () => {
    setError(true)
  }

  const handleZoomIn = () => {
    if (scaleCount < 3) {
      setScaleCount(scaleCount + 1)
      setTouchMove(false)
    }
  }

  const handleZoomOut = () => {
    const count = scaleCount - 1
    if (scaleCount > 1) {
      setScaleCount(count)
    }

    if (count == 1) {
      setTouchMove(true)
    }
  }

  const handleBuyNow = () => {
    if (!localStorage.getItem('customerId')) {
      navigate.push(LoginRoute.path + '?redirect=checkout')
    } else {
      navigate.push(CheckoutRoute.path)
    }
  }

  useEffect(() => {
    const updateAnchor = () => {
      setAnchorChoice(window.innerWidth > 768 ? 'right' : 'bottom')
    }

    updateAnchor()
    window.addEventListener('resize', updateAnchor)
    return () => window.removeEventListener('resize', updateAnchor)
  }, [])

  return (
    <>
      <PdpDetailViewContainer>
        <PdpDetailViewWrapper>
          <DesktopView>
            <ImageList>
              {imageList?.map((image: any, index: number) => {
                let imageUrl =
                  image?.url?.includes('https://') || image?.url?.includes('http://')
                    ? image?.url
                    : `https://cdnaz.plotch.io/image/upload/w_166,h_166${image?.url}`
                let imageUrlSelected =
                  image?.url?.includes('https://') || image?.url?.includes('http://')
                    ? image?.url
                    : `https://cdnaz.plotch.io/image/upload/w_801${image?.url}`
                if (imageUrl.includes('cdnaz.plotch.io')) {
                  imageUrl = imageUrl.includes('w_166,h_166')
                    ? imageUrl
                    : imageUrl.split('upload/').join('upload/w_166,h_166/')
                  imageUrlSelected = imageUrlSelected.includes('w_801')
                    ? imageUrlSelected
                    : imageUrlSelected.split('upload/').join('upload/w_801/')
                }

                return (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedImage(imageUrlSelected)
                      setSelectedImageIndex(index)
                    }}
                    className={`slider image-list ${index === selectedImageIndex ? 'selected-image' : 'image-list'}`}
                  >
                    <img src={imageUrl} />
                  </div>
                )
              })}
            </ImageList>
            <ZoomImage onError={handleError} onLoad={() => setError(false)} style={error ? { minHeight: '80%' } : {}}>
              {/* <img
                src={
                  selectedImage?.includes('https://') || selectedImage?.includes('http://')
                    ? selectedImage
                    : `https://cdnaz.plotch.io/image/upload/w_801${selectedImage}`
                }
                alt="saari"
              /> */}

              <MagnifierContainer autoInPlace={true}>
                <div className="example-class">
                  <MagnifierPreview
                    cursorStyle={'zoom-in'}
                    overlayOpacity={0}
                    onImageLoad={() => setError(false)}
                    imageSrc={
                      selectedImage?.includes('http://') || selectedImage?.includes('https://')
                        ? selectedImage
                        : `https://cdnaz.plotch.io/image/upload/w_801${selectedImage}`
                    }
                  />
                </div>
                <MagnifierZoom
                  className="zoomImageDiv"
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '-500px',
                    border: '2px solid #ccc',
                  }}
                  imageSrc={
                    selectedImage?.includes('http://') || selectedImage?.includes('https://')
                      ? selectedImage
                      : `https://cdnaz.plotch.io/image/upload/w_801${selectedImage}`
                  }
                />
              </MagnifierContainer>
              {/* <ReactImageMagnify
                {...{
                smallImage: {
                    alt: 'Wristwatch by Ted Baker London',
                    isFluidWidth: true,
                    src: selectedImage
                },
                largeImage: {
                    src: selectedImage,
                    width: 1500,
                  height: 1500
                },
                enlargedImageContainerDimensions: {
                  width: '60%', height: '50%'
                },
                enlargedImageClassName:"zoomImageDiv1"
            }} /> */}
              {serviceableFlag == true &&
                (wishlistIdData[productDetails?.productId] ? (
                  <WishlistedIcon
                    className="wishlisted-icon"
                    onClick={(e: any) => {
                      e.stopPropagation()
                      if (customerId) {
                        handleRemoveWishlistClick(productDetails?.productId)
                      }
                    }}
                  />
                ) : (
                  <HeartIcon
                    className="wishlist-icon"
                    onClick={(e: any) => {
                      e.stopPropagation()
                      const path = window.location.pathname.slice(1)

                      if (customerId) {
                        handleWishlistClick(productDetails?.productId, productDetails)
                      } else {
                        navigate.push(LoginRoute.path + `?redirect=${path}`)
                      }
                    }}
                  />
                ))}
            </ZoomImage>
          </DesktopView>

          <CrousalWrapper>
            <Carousel className="carousel-display">
              {imageList?.map((image: any, index: number) => (
                <div key={index} className="slider">
                  <img
                    src={
                      image.url.includes('https://') || image.url.includes('http://')
                        ? image.url
                        : `https://cdnaz.plotch.io/image/upload${image.url}`
                    }
                    alt={image.alt}
                    onClick={() => setZoomModal(true)}
                  />
                </div>
              ))}
            </Carousel>
            <MobileIconsWrapper>
              {similarProductDetails?.length > 0 ? (
                <SimilarIconWrapper onClick={toggleSimilarDrawer(true)}>
                  <SimilarProductIcon />
                  <label className="h2r">View Similar</label>
                </SimilarIconWrapper>
              ) : (
                ''
              )}
              {(socialIcons?.showFacebookIcon === '1' ||
                socialIcons?.showWhatsappIcon === '1' ||
                socialIcons?.showInstagramIcon === '1' ||
                socialIcons?.showTwitterIcon === '1') && <ShareIcon className="share-icon" onClick={showModal} />}
            </MobileIconsWrapper>
          </CrousalWrapper>

          <ProductContainer>
            <ProductBasicInfo>
              <HeadingWrapper>
                <div className="heading-details">
                  <div className="detail-product-name sub-title  h1b">{productDetails?.vendorName?.toLowerCase()}</div>
                  <div className="detail-product-name productNameTitle h3r">
                    {productDetails?.productName?.toLowerCase()}
                  </div>
                </div>
                {wishlistIdData[productDetails?.productId] ? (
                  <WishlistedIcon
                    className="mobile-wishlist-icon"
                    onClick={(e: any) => {
                      e.stopPropagation()
                      if (customerId) {
                        handleRemoveWishlistClick(productDetails?.productId)
                      }
                    }}
                  />
                ) : (
                  <HeartIcon
                    className="mobile-wishlist-icon"
                    onClick={(e: any) => {
                      e.stopPropagation()
                      const path = window.location.pathname.slice(1) // e.g., /product/Rare-Blue-Rose--Muslin-print-saree-with-blouse/1131489065

                      if (customerId) {
                        handleWishlistClick(productDetails?.productId)
                      } else {
                        navigate.push(LoginRoute.path + `?redirect=${path}`)
                      }
                    }}
                  />
                )}
                {(socialIcons?.showFacebookIcon === '1' ||
                  socialIcons?.showWhatsappIcon === '1' ||
                  socialIcons?.showInstagramIcon === '1' ||
                  socialIcons?.showTwitterIcon === '1') && <ShareIcon className="share-icon" onClick={showModal} />}

                {/* <ShareIcon className="share-icon" onClick={() => setSuccessModal(true)} /> */}
              </HeadingWrapper>
              <PriceWrapper>
                <div className="price-details">
                  <div className="discount-details">
                    {productDetails?.regularPrice === productDetails?.discountedPrice ? (
                      <div className="after-discount h1b">
                        <span> &#8377; </span>
                        {productDetails?.regularPrice}
                      </div>
                    ) : (
                      <>
                        <div className="after-discount h1b">
                          <span> &#8377; </span>
                          {productDetails?.discountedPrice}
                        </div>
                        <div className="before-discount h3r">
                          <span> &#8377; </span>
                          {productDetails.regularPrice}
                        </div>
                      </>
                    )}
                    {productDetails?.discountPercentage > 0 ? (
                      <div className="discount-percentage h4b">
                        {productDetails.discountPercentage ? `${productDetails.discountPercentage}% OFF` : null}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="ship-detail h3r ">inclusive of all taxes</div>
                </div>
                {showQty !== '' && productDetails?.isInStock === 1 ? (
                  <div className="quantity h1b">
                    <div onClick={decrement}>-</div>
                    <div>{count}</div>
                    <div onClick={increment}>+</div>
                  </div>
                ) : (
                  ''
                )}
              </PriceWrapper>
              {Object.keys(variantList).map((key) => {
                if (key.includes('size') && variantList[key][0] != 'NA') {
                  return (
                    <SizeDetails key={key}>
                      <div className="size-details">
                        <div className="size-heading h4b">Select {key.split('_').join(' ')}</div>
                        <div className="size-name-wrapper h4r" id={key}>
                          {variantList[key]?.map((item: string, index: number) => {
                            const nonCircle = item.length > 4 ? 'notCircle' : 'circleVariant'
                            const nonCircleParent = item.length > 4 ? 'mainItemDiv' : ''
                            return (
                              <div
                                className={`size-name ${key} ${nonCircleParent}`}
                                key={index}
                                onClick={() => showMultivariant(key, item)}
                                id={`${key}_${item}`}
                              >
                                <p className={`${nonCircle}`}>{item}</p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {key.includes('size') && (
                        <div className="size-chart withSize h3b" onClick={showSizeModal}>
                          SIZE CHART
                        </div>
                      )}
                    </SizeDetails>
                  )
                } else if (key == 'color') {
                  return (
                    <ColorDetails key={key}>
                      <div className="size-heading h4b">Select {key}</div>
                      <ColorContainer id={key}>
                        {variantList[key]?.map((colours: any) => {
                          return Object.keys(colours)?.map((colorKey: any, index: any) => {
                            const color = variantList[key][0][colorKey]
                            const image_url = color?.imageUrl
                            if (variantType == 0) {
                              return (
                                <Box1
                                  key={index}
                                  textColor={color?.colorCode}
                                  className={`${key}`}
                                  style={{ backgroundColor: `${color?.colorCode}` }}
                                  onClick={() => showMultivariant(key, colorKey)}
                                  id={`${key}_${colorKey}`}
                                />
                              )
                            } else {
                              return (
                                <Box1
                                  key={color}
                                  className={`${key}`}
                                  textColor={color}
                                  onClick={() => showMultivariant(key, color)}
                                  id={`${key}_${color}`}
                                >
                                  <img src={baseImgUrl + image_url} width={32} height={32} />
                                </Box1>
                              )
                            }
                          })
                        })}
                      </ColorContainer>
                    </ColorDetails>
                  )
                } else if (key === 'colour') {
                  return (
                    <ColorDetails key={key}>
                      <div className="size-heading h4b">Select {key}</div>
                      <ColorContainer id={key}>
                        {variantList[key]?.map((colours: any, index: string) => {
                          return (
                            <Box1
                              key={index}
                              textColor={colours}
                              className={`${key}`}
                              style={{ backgroundColor: `${colours}` }}
                              onClick={() => showMultivariant(key, colours)}
                              id={`${key}_${colours}`}
                            />
                          )
                        })}
                      </ColorContainer>
                    </ColorDetails>
                  )
                } else if (variantList[key][0] != 'NA') {
                  return (
                    <SizeDetails key={key}>
                      <div className="size-details">
                        <div className="size-heading h4b">Select {key.split('_').join(' ')}</div>
                        <div className="size-name-wrapper h4r" id={key}>
                          {variantList[key]?.map((item: string, index: number) => {
                            const nonCircle = item.length > 4 ? 'notCircle' : 'circleVariant'
                            const nonCircleParent = item.length > 4 ? 'mainItemDiv' : ''
                            return (
                              <div
                                className={`size-name ${key} ${nonCircleParent}`}
                                key={index}
                                onClick={() => showMultivariant(key, item)}
                                id={`${key}_${item}`}
                              >
                                <p className={`${nonCircle}`}>{item}</p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </SizeDetails>
                  )
                }
              })}
            </ProductBasicInfo>
            {productDetails?.isInStock === 0 ? (
              <DesktopDeleteButtonWrapper className="desktop-buy-now pdpBtns">
                <Button label="Out Of Stock" variant="contained" className="small" />
              </DesktopDeleteButtonWrapper>
            ) : serviceableFlag === true ? (
              (flag == 1 && checkProduct) || flag == 1 ? (
                // {checkProduct ? (
                <DesktopDeleteButtonWrapper className="desktop-buy-now pdpBtns">
                  <Button label="Go To Cart " variant="outline" type="submit" onClick={handleBuyNow} className="skip" />

                  <Line />
                  <Button label="Buy Now" variant="contained" onClick={handleBuyNow} type="submit" className="small" />
                </DesktopDeleteButtonWrapper>
              ) : (
                <DesktopDeleteButtonWrapper className="desktop-buy-now pdpBtns">
                  <Button
                    label="Add To Cart"
                    variant="outline"
                    type="submit"
                    // onClick={() => {
                    //   addToCart(productId)
                    // }}
                    onClick={() => {
                      // addToCart(productId)
                      handleAddToCart(productId)
                    }}
                    className="skip"
                  />
                  <Line />
                  <Button
                    label="Buy Now"
                    variant="contained"
                    onClick={() => {
                      handleAddToCart(productId, 1)
                    }}
                    type="submit"
                    className="small"
                  />
                </DesktopDeleteButtonWrapper>
              )
            ) : (
              <DesktopDeleteButtonWrapper className="desktop-buy-now pdpBtns">
                <Button label="Non Deliverable" variant="contained" type="button" className="small" />
              </DesktopDeleteButtonWrapper>
            )}
            <ProductDetails>
              <ProductDesc>
                <div className="prod-desc-heading h2b">Product Description</div>
                <div className="product-description h3r">
                  {isReadMore ? `${productDetails?.description?.slice(0, 187)}` : productDetails?.description}
                </div>
              </ProductDesc>
              {productDetails?.description?.length > 120 && (
                <ReadWrapper className="h4b" onClick={toggleReadMore}>
                  {isReadMore ? (
                    <>
                      <DownArrow /> Read More
                    </>
                  ) : (
                    <>
                      <UpArrow />
                      Read Less
                    </>
                  )}
                </ReadWrapper>
              )}
              <ProductDetailsWrapper>
                <div className="prod-desc-heading h2b">Product Details</div>
                <ProductCompleteDetails>
                  {displayArray?.map((attribute: any, index: any) => {
                    const isExpanded = expandedAttributes[index] || false
                    const attributeValue = attribute?.value?.toLowerCase()
                    const shortValue = attributeValue?.slice(0, 100)

                    if (
                      attribute?.key.includes('Size Chart') &&
                      document.getElementsByClassName('withSize').length == 0
                    ) {
                      return (
                        <div key={index} className="prod-detail-wrap">
                          <div className="heading h4r">{attribute?.key}</div>
                          {attribute?.key.includes('Size Chart') ? (
                            <div className="desc-detail h3r">
                              <div className="size-chart" onClick={showSizeModal}>
                                Click here
                              </div>
                            </div>
                          ) : (
                            <div className="desc-detail attributeValue h3r">
                              {isExpanded ? attributeValue : shortValue + '...'}
                              {attributeValue.length > 100 && (
                                <span className="toggle-view view-store h4b" onClick={() => toggleAttributeView(index)}>
                                  {isExpanded ? '\nView Less' : ' View More'}
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      )
                    } else if (!attribute?.key.includes('Size Chart')) {
                      return (
                        <div key={index} className="prod-detail-wrap">
                          <div className="heading h4r">{attribute?.key}</div>
                          <div className="desc-detail attributeValue h3r">
                            {isExpanded ? attributeValue : shortValue}
                            {attributeValue.length > 100 && !isExpanded ? '... ' : ' '}
                            {attributeValue.length > 100 && (
                              <span className="toggle-view view-store h4b" onClick={() => toggleAttributeView(index)}>
                                {isExpanded ? (
                                  <>
                                    <br />
                                    View Less
                                  </>
                                ) : (
                                  ' View More'
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                      )
                    }
                  })}
                </ProductCompleteDetails>
              </ProductDetailsWrapper>

              {arr?.length > initialDisplayCount && (
                <ReadWrapper className="h4b" onClick={toggleSeeMore}>
                  {isSeeMore ? (
                    <>
                      <DownArrow /> See More
                    </>
                  ) : (
                    <>
                      <UpArrow />
                      See Less
                    </>
                  )}
                </ReadWrapper>
              )}

              <ProductDetailsWrapper>
                <div className="prod-desc-heading h1b">Sold By</div>
                <ProductCompleteDetails onClick={handleViewClick}>
                  {storesData?.d?.ondcEnabled === 1 ? (
                    <div className="desc-detail seller_name h3r">{productDetails?.storeName?.toLowerCase()}</div>
                  ) : (
                    <div className="desc-detail seller_name h3r">{productDetails?.vendorName?.toLowerCase()}</div>
                  )}
                  <ReadWrapper className="view-store h4b">
                    View Store
                    <DownArrow />
                  </ReadWrapper>
                </ProductCompleteDetails>
              </ProductDetailsWrapper>
            </ProductDetails>
          </ProductContainer>
        </PdpDetailViewWrapper>
        {productDetails?.isInStock === 0 ? (
          <DeleteButtonWrapper className="buy-now">
            <Button label="Out Of Stock" variant="contained" className="large" />
          </DeleteButtonWrapper>
        ) : serviceableFlag == true ? (
          (flag == 1 && checkProduct) || flag == 1 ? (
            // {checkProduct ? (
            <DeleteButtonWrapper className="buy-now">
              <Button label="Go To Cart " variant="outline" type="submit" onClick={handleBuyNow} className="skip" />

              <Line />
              <Button label="Buy Now" variant="contained" onClick={handleBuyNow} type="submit" className="small" />
            </DeleteButtonWrapper>
          ) : (
            <DeleteButtonWrapper className="buy-now">
              <Button
                label="Add To Cart "
                variant="outline"
                type="submit"
                onClick={() => {
                  // addToCart(productId)
                  handleAddToCart(productId)
                }}
                className="skip"
              />
              <Line />
              <Button
                label="Buy Now"
                variant="contained"
                onClick={() => {
                  // addToCart(productId, 1)
                  handleAddToCart(productId, 1)
                }}
                type="submit"
                className="small"
              />
            </DeleteButtonWrapper>
          )
        ) : (
          <DeleteButtonWrapper className="buy-now">
            <Button label="Non Deliverable" variant="contained" type="button" className="large" />
          </DeleteButtonWrapper>
        )}
      </PdpDetailViewContainer>
      <Modal
        centered
        title="Share"
        open={isModalVisible}
        onOk={handleOk}
        closeIcon={<CloseIcon onClick={customOnCancel} />}
      >
        <SocialShareModal
          productDetails={productDetails}
          // title={productDetails?.description}
          // description={productDetails?.description}
          // image={productDetails?.description}
        />
      </Modal>
      <Modal
        centered
        title="Size Chart"
        open={isModalSizeChart}
        onOk={handleSizeOk}
        closeIcon={<CloseIcon onClick={customSizeOnCancel} />}
      >
        {modifiedSizeChartUrl && (
          <SizeChartImageWrapper>
            <img
              src={
                modifiedSizeChartUrl?.includes('https://') || modifiedSizeChartUrl?.includes('http://')
                  ? modifiedSizeChartUrl
                  : modifiedSizeChartUrl
              }
            />
          </SizeChartImageWrapper>
        )}
      </Modal>
      <ZoomContainer className="zoomModal">
        <Modal centered title="" open={zoomModal} closeIcon={<CloseIcon onClick={customOnCancelModal} />}>
          <ZoomCarousel>
            {/* <Image.PreviewGroup> */}
            <Carousel dots afterChange={() => setScaleCount(1)} infinite={false} touchMove={touchMove}>
              {imageList?.map((image: any, index: number) => (
                <div key={index} className="slider">
                  <img
                    className={`scale${scaleCount}`}
                    src={
                      image.url.includes('https://') || image.url.includes('http://')
                        ? image.url
                        : `https://cdnaz.plotch.io/image/upload${image.url}`
                    }
                    alt={image.alt}
                  />
                </div>
              ))}
            </Carousel>
            <div className="zoomBtns">
              <ZoomInOutlined onClick={handleZoomIn} style={scaleCount != 3 ? { opacity: '1' } : { opacity: '.5' }} />
              <ZoomOutOutlined onClick={handleZoomOut} style={scaleCount != 1 ? { opacity: '1' } : { opacity: '.5' }} />
            </div>

            {/* </Image.PreviewGroup> */}
          </ZoomCarousel>
        </Modal>
      </ZoomContainer>
      <Drawer
        open={openSimilarProduct}
        anchor="bottom"
        sx={{
          '& .MuiDrawer-paper': {
            height: 'auto', // Set height to auto
            borderRadius: '20px 20px 0px 0px',
          },
        }}
      >
        {similarProductDetails?.length > 0 && (
          <CommonStyle>
            <MobileSimilarProducts products={similarProductDetails} onClose={toggleSimilarDrawer(false)} />
          </CommonStyle>
        )}
      </Drawer>
      <Drawer
        open={openCustom}
        onClose={toggleCustomDrawer(true)}
        anchor={anchorChoice}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: { xs: '20px 20px 0px 0px', sm: '0', xl: '0' },
          },
        }}
      >
        <CommonStyle>
          <CustomizableItems
            onClose={toggleCustomDrawer(false)}
            products={productDetails}
            handleAddonProducts={setAddonProducts}
            addToCart={addToCart}
            flag={buttonType}
          />
        </CommonStyle>
      </Drawer>
      {/* <Drawer
        open={openCustom}
        onClose={toggleCustomDrawer(true)}
        anchor={anchorChoice}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: { xs: '20px 20px 0px 0px', sm: '0', xl: '0' },
          },
        }}
      >
        <CommonStyle>
          <CustomizableItems
            onClose={toggleCustomDrawer(false)}
            // onClose={
            //   isEmpty(quoteData?.shippingAddress) ? toggleServicableDrawer(false) : toggleServicableDrawer(true)
            // }
            products={productDetails}
          />
        </CommonStyle>
      </Drawer> */}
    </>
  )
}

export default PdpDetailView

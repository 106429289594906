import { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import './App.css'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import ThemeProvider from './context/themeContext'
import Routes from './routes'
import { LoaderContext } from 'context/loader'
import SpinnerDialog from 'components/SpinnerDialog'
import { SnackBarContext } from 'context/SnackBarContext'
import CustomSnackbar from 'components/SnackBar'
import FilterProvider from 'context/FliterContext'
import HomeProvider from 'context/HomeContext'
import SessionProvider from 'context/SessionContext'
import WishListProvider from 'context/WishlistDetailContext'
import { activeRequests } from 'services/axiosInstance'
import MenuProvider from 'context/MenuContext'
// import GoogleTagManager from 'components/GTM'
const queryClient = new QueryClient()

const App = () => {
  const [loader, setLoader] = useState(false)
  const [themeContext, setThemeContext] = useState<any>()
  const [siteTitle, setSiteTitle] = useState<any>()
  const [robots, setRobots] = useState<any>()
  const [metaKeywords, setMetaKeywords] = useState<any>()
  const [pageDescription, setPageDescription] = useState<any>()
  const [propertyUrl, setPropertyUrl] = useState<any>()
  const [propertyImage, setPropertyImage] = useState<any>()
  const [dynamicTags, setDynamicTags] = useState<JSX.Element[]>([])
  const site_favicon = themeContext?.d?.objectDetails?.general?.favicon?.icon
  // const { showLoader, hideLoader, incrementRequests, decrementRequests, isAnyRequestPending } = useLoader()

  const [showSnackBar, setShowSnackBar] = useState({
    open: false,
    message: '',
    severity: '',
  })
  const [activeRequestCount, setActiveRequestCount] = useState(0)
  // console.log('first', activeRequests)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveRequestCount(activeRequests.length)
    }, 100) // Check every 100ms
    return () => clearInterval(intervalId) // Cleanup interval on unmount
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <SessionProvider>
          <ThemeProvider>
            <HomeProvider>
              <SpinnerDialog
                loader={loader || activeRequestCount}
                setThemeContext={setThemeContext}
                setSiteTitle={setSiteTitle}
                setRobot={setRobots}
                setPageDescription={setPageDescription}
                setMetaKeywords={setMetaKeywords}
                setPropertyUrl={setPropertyUrl}
                setPropertyImage={setPropertyImage}
                setDynamicTags={setDynamicTags}
              />
              <LoaderContext.Provider value={{ loader, setLoader }}>
                <SnackBarContext.Provider value={{ showSnackBar, setShowSnackBar }}>
                  <CustomSnackbar />
                  <FilterProvider>
                    <WishListProvider>
                      <MenuProvider>
                        <Routes />
                      </MenuProvider>
                    </WishListProvider>
                  </FilterProvider>
                </SnackBarContext.Provider>
              </LoaderContext.Provider>
            </HomeProvider>
          </ThemeProvider>
        </SessionProvider>

        <Helmet>
          {siteTitle && <title>{siteTitle}</title>}
          <meta name="description" content={pageDescription} />
          {site_favicon && <link rel="icon" href={site_favicon} type="image/x-icon" />}
          <meta name="keywords" content={metaKeywords} />
          <meta name="robots" content={robots} />
          <meta property="og:title" content={siteTitle} />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge"></meta>
          <meta property="og:description" content={pageDescription} />
          {propertyUrl && <meta property="og:url" content={propertyUrl} />}
          {propertyImage && <meta property="og:image" content={propertyImage} />}
          {propertyUrl && <link rel="canonical" href={propertyUrl} />}
          {dynamicTags}
        </Helmet>
      </HelmetProvider>
    </QueryClientProvider>
  )
}

export default App

import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import PendingOrderDetail from 'views/PendingOrderDetail'
import BackArrow from 'assets/svg/BackArrow'
import PendingIcon from 'assets/svg/PendingIcon'
import { CommonStyle } from 'styles/pages/Common'
import { ManageAddressWrapper, BackWrapper, ButtonWrapper } from 'styles/pages/MyAccount'
import { ConfirmationMessageWrapper, PendingMessage } from 'styles/pages/OrderSuccessPage'

const OrderPendingPage = () => {
  const navigate = useHistory()

  const handleBack = () => {
    navigate.goBack()
  }

  return (
    <ManageAddressWrapper>
      <CommonStyle>
        <BackWrapper className="padding_full_page" onClick={handleBack}>
          <BackArrow />
          <div className="h1b">ORDER PENDING</div>
        </BackWrapper>
      </CommonStyle>
      <CommonStyle>
        <ConfirmationMessageWrapper>
          <PendingIcon />
          <PendingMessage className="h0b">Your order is pending for confirmation!</PendingMessage>
        </ConfirmationMessageWrapper>
      </CommonStyle>
      <CommonStyle>
        <PendingOrderDetail />
      </CommonStyle>
      <ButtonWrapper>
        <Button label="See Order Current Status" variant="contained" type="submit" className="small h1b" />
      </ButtonWrapper>
    </ManageAddressWrapper>
  )
}

export default OrderPendingPage

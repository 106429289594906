import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M2.438 4.876a1.313 1.313 0 1 0 0-2.626 1.313 1.313 0 0 0 0 2.626ZM2.438 9.751a1.313 1.313 0 1 0 0-2.626 1.313 1.313 0 0 0 0 2.626Z"
    />
    <path
      stroke="#000"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="m3.68 4.101-.258.354.871.353s.082-.151.165-.236l-.777-.47Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="m3.613 7.838 7.168-4.041c-.059-.157-.656-.281-1.5-.094-.682.152-2.84.681-3.73 1.314-.727.516-.23 1.358-1.051 1.967-.6.446-1.042.535-1.042.535l.155.32ZM8.074 6.521l-1.16.667c.334.158 1.536.78 2.743.851.35.021.703.01.914-.14L8.074 6.52Z"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M6 6.376a.75.75 0 1 0 0-1.501.75.75 0 0 0 0 1.501Z"
    />
  </svg>
)
export default SvgComponent

import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Radio } from 'antd'
import useGet from 'hooks/useGet'
import APIS from 'constants/api'
import { SnackBarContext } from 'context/SnackBarContext'
import { IProduct } from 'interfaces/pages/order'
import HelpCircle from 'assets/svg/HelpCircle'
import InformationCircle from 'assets/svg/InformationCircle'
// import Saari from 'assets/images/short-saari-img.png'
import {
  CancelOrderDetailsWrapper,
  CancellationReasonwrapper,
  CancelReasonHeading,
  ReasonWrapper,
  ButtonWrapper,
} from 'styles/views/CancelOrderDetails'
// import { ButtonWrapper } from 'styles/pages/MyAccount'

import { OrderConfirmationWrapper, OrderStateWrapper } from 'styles/views/ViewOrderDetail'
import Button from 'components/Button'
import { MyOrdersRoute } from 'constants/routes'

interface IOrderChangeReasonData {
  reason: string
  reasonId: string
}

const CancelOrderDetails = () => {
  const [cancelReason, setCancelReason] = useState('')
  const location = useLocation()
  const { setShowSnackBar } = useContext(SnackBarContext)
  const navigate = useHistory()

  const { state: productDetails }: any = location
  const { refetch, data: cancelReasonData } = useGet('cancel-reason-data', `${APIS.CANCEL_REASONS}`)
  // const { refetch: refetchCancel, data: cancelData } = useGet(
  const { refetch: refetchCancel, data: cancelData } = useGet(
    'cancel-data',
    `${APIS.CANCEL_ORDERS}?orderItemId=${productDetails?.state?.products[0]?.orderItemId}&cancelReasonId=${cancelReason}&productId=${productDetails?.state?.products[0]?.id}`,
  )
  const handleCancelClick = () => {
    if (cancelReason.length > 0) {
      refetchCancel()
      // setShowSnackBar({ open: true, message: `${cancelData?.m}`, severity: '' })
      // window.location.href = MyOrdersRoute.path

      // navigate.push(MyOrdersRoute.path)
    }
  }
  // const handleChange = () => {
  //   clo
  // }

  useEffect(() => {
    refetch()
  }, [cancelReasonData])
  useEffect(() => {
    if (cancelData && cancelReason.length > 0) {
      setShowSnackBar({ open: true, message: `${cancelData?.m}`, severity: '' })
      navigate.push(MyOrdersRoute.path)
    }
  }, [cancelData])

  return (
    <CancelOrderDetailsWrapper>
      {/* {orders.map((order, index) => ( */}
      <OrderConfirmationWrapper>
        <div className="cancel-wrap">
          <div className="confirm-order">
            <InformationCircle />
            <label className="h1b cancel-color">1 item will be cancelled</label>
          </div>
        </div>
        {productDetails?.state?.products?.length &&
          productDetails?.state?.products?.map((item: IProduct, index: number) => {
            let imgUrl =
              item?.image?.includes('http://') || item?.image?.includes('https://')
                ? item?.image
                : `https://cdnaz.plotch.io/image/upload/w_118,h_118${item?.image}`
            if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
              imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
              imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
            }

            return (
              <div className="confirmed-wrap order-state-type" key={index}>
                <div className="confirm-order">
                  <img src={imgUrl} />

                  <div className="orders-details">
                    <div className="order-name-Wrap">
                      <label className="h3r">
                        {item.name} ({item?.qty} Qty)
                      </label>
                      <HelpCircle />
                    </div>
                    <div className="order-state-wrapper">
                      <div className="orders-details">
                        <label className="h3r">Delivery by {productDetails?.state?.expectedDelivery}</label>
                        <OrderStateWrapper className="h4b" state={item.statusLabel}>
                          {item.statusLabel}
                        </OrderStateWrapper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </OrderConfirmationWrapper>
      {/* ))} */}
      <CancellationReasonwrapper>
        <CancelReasonHeading>
          <label className="h1b">Reason for Cancellation</label>
          <label className="h2r">This will help us improve your experience</label>
        </CancelReasonHeading>
        <ReasonWrapper>
          <Radio.Group className="reason-wrap">
            {cancelReasonData?.d?.cancellationReasons?.map((reasons: IOrderChangeReasonData) => (
              <Radio
                key={reasons?.reasonId}
                value={reasons?.reason}
                onChange={() => setCancelReason(reasons?.reasonId)}
              >
                {reasons?.reason}
              </Radio>
            ))}
          </Radio.Group>
        </ReasonWrapper>
      </CancellationReasonwrapper>
      <ButtonWrapper>
        <Button
          label="CANCEL ORDER"
          variant={cancelReason.length > 0 ? 'contained' : 'disabled'}
          type="submit"
          className="small"
          onClick={handleCancelClick}
        />
      </ButtonWrapper>
    </CancelOrderDetailsWrapper>
  )
}

export default CancelOrderDetails

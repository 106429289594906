import { useHistory } from 'react-router-dom'
import ReturnOrderDetails from 'views/ReturnOrderDetails'
// import Button from 'components/Button'
import BackArrow from 'assets/svg/BackArrow'
import { CommonStyle } from 'styles/pages/Common'
import PublicLayout from 'components/Layouts/public'
import {
  CheckoutWrappers,
  // ManageAddressWrapper,
  BackWrapper,
  CheckoutPageWrapper,
} from 'styles/pages/MyAccount'
import { MyOrdersRoute } from 'constants/routes'
// import { ManageAddressWrapper, BackWrapper } from 'styles/pages/MyAccount'

const ReturnOrderPage = () => {
  const navigate = useHistory()

  const handleBack = () => {
    // navigate.goBack()
    navigate.push(MyOrdersRoute.path)
  }

  return (
    <PublicLayout>
      <CheckoutPageWrapper>
        <CheckoutWrappers>
          <CommonStyle>
            <BackWrapper className="padding_full_page" onClick={handleBack}>
              <BackArrow />
              <div className="h1b">RETURN ORDER</div>
            </BackWrapper>
          </CommonStyle>
          <CommonStyle>
            <ReturnOrderDetails />
          </CommonStyle>
        </CheckoutWrappers>
      </CheckoutPageWrapper>
    </PublicLayout>
    // <ManageAddressWrapper>
    //   <CommonStyle>
    //     <BackWrapper className="padding_full_page" onClick={handleBack}>
    //       <BackArrow />
    //       <div className="h1b">RETURN ORDER</div>
    //     </BackWrapper>
    //   </CommonStyle>
    //   <CommonStyle>
    //     <ReturnOrderDetails />
    //   </CommonStyle>
    // </ManageAddressWrapper>
  )
}

export default ReturnOrderPage

import Button from 'components/Button'
import Ticket from 'components/Ticket'
import PopupModal from 'components/TicketModal'
import APIS from 'constants/api'
import { useTheme } from 'context/themeContext'
import useGet from 'hooks/useGet'
import { useState } from 'react'
import TagManager from 'react-gtm-module'
import { ButtonWrapper } from 'styles/components/Button'
import { TicketListWrapper } from 'styles/components/Ticket'
import { CommonPageWrapper } from 'styles/pages/Common'
import { pageData } from 'utils/datalayer/pageData'

const ListTickets = () => {
  const customerId = localStorage.getItem('customerId')
  const orderId = localStorage.getItem('orderId')
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const { refetch: refetchTicket, data } = useGet('ticket-list', `${APIS.TICKET_LIST}?customerId=${customerId}`)
  const [open, setOpen] = useState(false)
  const { storesData } = useTheme()

  const changeModal = () => {
    const initTicketDataToPush = {
      event: 'raise_ticket_initiate',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      cta_text: 'raise ticket',
      order_ticket_id: orderId ? orderId : 'NA',
    }
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: initTicketDataToPush })

    setOpen(true)
  }

  const handleOk = () => {
    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <TicketListWrapper>
      <CommonPageWrapper>
        <div className="h1b">All Tickets</div>
        <Ticket refetchTicket={refetchTicket} data={data} />
      </CommonPageWrapper>
      <ButtonWrapper className="buy-now">
        <Button variant="contained" type="button" label="Raise Ticket" className="large" onClick={changeModal} />
        <PopupModal
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          title="Raise Ticket"
          BtnName="Raise Ticket"
          getAllTickets={refetchTicket}
        />
      </ButtonWrapper>
    </TicketListWrapper>
  )
}

export default ListTickets

import WishListProducts from 'components/WishListProduct'
import PublicLayout from 'components/Layouts/public'
import { CommonStyle } from 'styles/pages/Common'

const WistlistPage = () => {
  return (
    <PublicLayout>
      <CommonStyle>
        <WishListProducts />
      </CommonStyle>
    </PublicLayout>
  )
}

export default WistlistPage

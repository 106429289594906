import styled from 'styled-components'

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 24px;
  /* flex-wrap: wrap; */
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    gap: 20px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
export const MobileNavbarContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    gap: 22px;
    align-items: center;
    justify-content: center;
  }
`
export const LeftContainer = styled.div`
  display: flex;
  gap: 24px;
  img {
    width: 160px;
    height: 46.135px;
  }
  .main-logo {
    cursor: pointer;
  }
  input {
    height: 30px;
    ::placeholder {
      color: var(--primary_text_color_dark);
      font-family: 'Mulish';
      font-size: 14px;
      font-weight: 400;
    }
  }
`
export const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  .item-menu {
    border-bottom: 1px solid grey;
  }
  .item-menu:last-child {
    border-bottom: none;
  }
  li.ant-menu-item.ant-menu-item-only-child.item-menu {
    border-bottom: 1px solid red !important;
    border-radius: unset !important;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`
export const MobileRightContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const CartWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-left: auto;
`
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 124px;
    height: 35px;
  }
`
export const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: var(--primary_text_color_darkgrey);
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  /* svg {
    path {
      stroke: var(--primary_icon_static_color);
    }
  } */
  .text-nonbreak {
    white-space: nowrap;
  }
`
export const BottomNavbarContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid var(--primary_stroke_color_light);
    background: var(--primary_box_fill_color);
    padding: 13px 0px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    /* max-width: 360px; */
    margin: 0px auto;
  }
`
export const Blank = styled.div`
  width: 160px;
  height: 46.135px;
`
export const NavItems = styled.div``
export const EnterIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary_button_active_fill_color);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 3px;
  cursor: pointer;
  svg {
    transform: rotate(180deg);
    path {
      stroke: #fff;
    }
  }
`
export const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 18px;
  border: 1px solid #edecec;
  background: var(--primary_box_fill_color);
  padding: 5px 12px;
  .searchMobile {
    border: none;
    padding: 0;
  }

  .ant-input-outlined:hover,
  .ant-input-outlined:focus,
  .ant-input-outlined:hover,
  .ant-input-outlined:focus-within {
    border: 0px solid var(--primary_stroke_color_light) !important;
  }

  .custom-option {
    color: var(--primary_stroke_color_light);
  }

  .custom-select.ant-select:not(.ant-select-customize-input):hover .ant-select-selector,
  .custom-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: var(--primary_button_stroke_color_light) !important;
    box-shadow: none !important; /* Focus shadow */
  }
  .ant-select-single .ant-select-selector {
    border-radius: 10px;
    background: var(--primary_text_color_subtitle);
  }
  .ant-select-outlined .ant-select-selector {
  }
  svg {
    width: 20px;
    height: 20px;
  }
  .searchMobile svg {
    margin-top: 5px;
  }

  .ant-select-outlined .ant-select-focused .ant-select-single .ant-select-show-arrow {
    border-color: var(--primary_button_stroke_color_light) !important;
  }
  .ant-select-focused .ant-select-outlined .ant-select-outlined:hover .ant-select-selector {
    border-color: var(--primary_button_stroke_color_light) !important;
    box-shadow: none !important; /* Focus shadow */
  }
`
export const NavbarOptions = styled.div`
  display: flex;
  gap: 20px;
  svg {
    cursor: pointer;
  }
`
export const DataWrapper = styled.div`
  a {
    text-decoration: none;
    color: var(--primary_text_color_dark);
    font-family: 'Mulish';
    font-size: 12px;
    font-weight: 700;
    :hover {
      color: var(--primary_label_active_text_color);
      svg {
        path {
          stroke: var(--primary_label_active_text_color);
        }
      }
    }
  }
  a.active {
    color: var(--primary_label_active_text_color);
    svg {
      path {
        stroke: var(--primary_label_active_text_color);
      }
    }
  }
  a.inactive {
    color: var(--primary_text_color_dark);
    svg {
      path {
        stroke: var(--primary_text_color_dark);
      }
    }
  }
`
export const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const IconWrapper = styled.div``

// import PrivateLayout from 'components/Layouts/private'
import PublicLayout from 'components/Layouts/public'
import MyAccountView from 'views/MyAccountView'

const MyAccount = () => {
  return (
    <PublicLayout>
      <MyAccountView />
    </PublicLayout>
  )
}

export default MyAccount

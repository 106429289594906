import APIS from 'constants/api'
// import { LoaderContext } from 'context/loader'
import { SnackBarContext } from 'context/SnackBarContext'
import usePost from 'hooks/usePost'
import { useContext, useState, createContext } from 'react'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IWishlist {
  wishlistIdData: any
  cartIdData: any
  setWishlistIdData: (val: any) => void
  setCartIdData: (val: any) => void
  handleAddWishlist: (productId: string, product?: any, googledatalayer?: any) => Promise<void>
  handleRemoveWishlist: (productId: string) => Promise<void>
}

const DefaultValues = {
  wishlistIdData: [],
  cartIdData: [],
  setWishlistIdData: () => null,
  setCartIdData: () => null,
  handleAddWishlist: async () => {
    // Default implementation returning a resolved promise
    return Promise.resolve()
  },
  handleRemoveWishlist: async () => {
    // Default implementation returning a resolved promise
    return Promise.resolve()
  },
}

const WishListContext = createContext<IWishlist>(DefaultValues)

const WishListProvider = ({ children }: { children: React.ReactNode }) => {
  const [wishlistIdData, setWishlistIdData] = useState([])
  const [cartIdData, setCartIdData] = useState([])
  const { setShowSnackBar } = useContext(SnackBarContext)
  // const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  const customerId = localStorage.getItem('customerId') || false
  const { loggedIn, loggedInStatus, address, GDL_PAGE_TYPE } = pageData()
  const handleAddWishlist = async (productId: string, product?: any, googledatalayer?: any): Promise<void> => {
    const discount =
      product?.regularPrice && product?.discountedPrice
        ? parseFloat((product.regularPrice - product.discountedPrice).toFixed(2))
        : 0
    const itemCategory = Array.isArray(product?.categoryName)
      ? product?.categoryName[0]?.toLowerCase()
      : product?.categoryName?.toLowerCase()

    let categoryText = 'na'
    if (product?.returnable) {
      categoryText = `Returnable: ${product?.returnable}`
    }

    if (product?.cancellable) {
      categoryText +=
        categoryText !== 'na' ? ` | Cancellable: ${product?.cancellable}` : `Cancellable: ${product?.cancellable}`
    }

    const GDL_CATEGORY_5_lowercase = categoryText?.toLowerCase()
    const variantStringArray: string[] = []

    if (product?.variants_list) {
      Object.entries(product.variants_list).forEach(([key, values]) => {
        if (Array.isArray(values) && values.length > 0) {
          variantStringArray.push(`${key}-${values.join(', ')}`)
        } else {
          variantStringArray.push(`${key}-`)
        }
      })
    }

    const variantString = variantStringArray.join('|')
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.ADD_WISHLIST,
        payload: {
          customerId: customerId,
          productId: [productId],
        },
      })
      if (res) {
        //setLoader(false)
        setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
        setWishlistIdData((prev) => ({ ...prev, [productId]: true }))
        const elObject = {
          event: 'add_to_wishlist',
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          selected_location: address,
          page_type: GDL_PAGE_TYPE,
          ecommerce: {
            value: product.discountedPrice,
            currency: 'INR',
            items: [
              {
                item_name: product.productName?.toLowerCase() || 'na',
                item_id: productId,
                item_brand: product.brand?.toLowerCase() || 'na',
                discount: discount,
                item_variant: variantString || 'na',
                item_category: itemCategory,
                price: product?.regularPrice,
                item_list_id: 'na',
                item_list_name: 'na',
                item_category2: 'na',
                item_category3: 'na',
                item_category4: 'na',
                item_category5: GDL_CATEGORY_5_lowercase,
                quantity: 1,
                index: 1,
              },
            ],
          },
        }

        if (product?.productId && googledatalayer === '1') {
          TagManager.dataLayer({ dataLayer: elObject })
        }
      }
    } catch (error) {
      //setLoader(false)

      setShowSnackBar({ open: true, message: `${error}`, severity: '' })
    }
  }

  const handleRemoveWishlist = async (productId: string): Promise<void> => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.REMOVE_FROM_WISHLIST,
        payload: {
          customerId: customerId,
          productId: productId,
        },
      })
      if (res) {
        //setLoader(false)
        setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
        setWishlistIdData((prev) => ({ ...prev, [productId]: false }))
      }
    } catch (error) {
      //setLoader(false)
      setShowSnackBar({ open: true, message: `${error}`, severity: '' })
    }
  }

  return (
    <WishListContext.Provider
      value={{ wishlistIdData, setWishlistIdData, cartIdData, setCartIdData, handleAddWishlist, handleRemoveWishlist }}
    >
      {children}
    </WishListContext.Provider>
  )
}

export default WishListProvider

export const useWishListContext = () => {
  return useContext(WishListContext)
}

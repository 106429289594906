import React, { useEffect, useState } from 'react'
import {
  FooterMainWrapper,
  FooterWrapper,
  FooterContainer,
  HeadingWrapper,
  CategoryContainer,
  AddressHeading,
  Categorieswrapper,
  AppWrapper,
  // IconWrapper,
} from 'styles/components/Footer'
import { pageData } from 'utils/datalayer/pageData'
import { useTheme } from 'context/themeContext'
import TagManager from 'react-gtm-module'

const FooterLayout1 = () => {
  const { themedata, storesData } = useTheme()
  const [footerData, setFooterData] = useState<any>({})
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  useEffect(() => {
    if (themedata?.d) {
      setFooterData(themedata?.d?.objectDetails?.footer)
    }
  }, [themedata?.d])

  const handleFooterDataLayerPush = (category: any, sectionName: any) => {
    const itemName = category?.text.toLowerCase()
    const url = `${category.href.includes('https') ? category.href : '/' + category.href}`

    // router.push(`${category.href.includes('https') ? category.href : '/' + category.href}`)
    const dataToPush = {
      event: 'footer_interaction',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      cta_text: itemName ? itemName : 'na',
      section_name: sectionName?.toLowerCase(),
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })

    window.location.href = url
  }

  return (
    <FooterMainWrapper>
      <FooterWrapper>
        {footerData?.appLink?.enableGooglePlayStore == 1 ||
          (footerData?.appLink?.enableApplePlayStore == 1 && (
            <FooterContainer>
              <HeadingWrapper className="footer-heading">Download our app</HeadingWrapper>
              <AppWrapper></AppWrapper>
            </FooterContainer>
          ))}

        {footerData?.footerAboutUs?.enableAboutUs == 1 && (
          <FooterContainer>
            <AddressHeading className="footer-heading">
              {footerData?.footerAboutUs?.footerAboutUsHeader
                ? footerData?.footerAboutUs?.footerAboutUsHeader
                : 'About Us'}
            </AddressHeading>
            {footerData?.footerAboutUs?.footerAboutUs && (
              <Categorieswrapper
                onClick={() =>
                  handleFooterDataLayerPush(
                    footerData?.footerAboutUs?.footerAboutUs,
                    footerData?.footerAboutUs?.footerAboutUsHeader
                      ? footerData?.footerAboutUs?.footerAboutUsHeader
                      : 'About Us',
                  )
                }
              >
                {footerData?.footerAboutUs?.footerAboutUs}
              </Categorieswrapper>
            )}
          </FooterContainer>
        )}
        {/* {footerData?.paymentMethods?.enablePayment == 1 && (
          <FooterContainer>
            <div>
              <AddressHeading className="footer-heading">Payment options</AddressHeading>
              <IconWrapper>1</IconWrapper>
            </div>
          </FooterContainer>
        )} */}
      </FooterWrapper>
      <FooterWrapper>
        {footerData?.footerLinkList1?.enableLinkList1 && (
          <FooterContainer>
            <HeadingWrapper className="footer-heading">
              {footerData?.footerLinkList1?.footerLinkList1Title}
            </HeadingWrapper>
            {footerData?.footerLinkList1?.objectDetails && (
              <CategoryContainer>
                {footerData?.footerLinkList1?.objectDetails?.map((item: any, index: any) => {
                  return (
                    <Categorieswrapper key={index}>
                      <a
                        onClick={() =>
                          handleFooterDataLayerPush(item, footerData?.footerLinkList1?.footerLinkList1Title)
                        }
                        target="_self"
                        className="plotch_a_link"
                      >
                        {item?.text}
                      </a>
                    </Categorieswrapper>
                  )
                })}
              </CategoryContainer>
            )}
          </FooterContainer>
        )}
      </FooterWrapper>
      <FooterWrapper>
        {footerData?.footerLinkList2?.enableLinkList2 && (
          <FooterContainer>
            <HeadingWrapper className="footer-heading">
              {footerData?.footerLinkList2?.footerLinkList2Title}
            </HeadingWrapper>
            {footerData?.footerLinkList2?.objectDetails && (
              <CategoryContainer>
                {footerData?.footerLinkList2?.objectDetails?.map((item: any, index: any) => {
                  return (
                    <Categorieswrapper key={index}>
                      <a
                        target="_self"
                        className="plotch_a_link"
                        onClick={() =>
                          handleFooterDataLayerPush(item, footerData?.footerLinkList2?.footerLinkList2Title)
                        }
                      >
                        {item?.text}
                      </a>
                    </Categorieswrapper>
                  )
                })}
              </CategoryContainer>
            )}
          </FooterContainer>
        )}
      </FooterWrapper>
      {footerData?.footerLinkList3?.enableLinkList3 && (
        <FooterContainer>
          <HeadingWrapper className="footer-heading">
            {footerData?.footerLinkList3?.footerLinkList3Title}
          </HeadingWrapper>
          {footerData?.footerLinkList3?.objectDetails && (
            <CategoryContainer>
              {footerData?.footerLinkList3?.objectDetails?.map((item: any, index: any) => {
                return (
                  <Categorieswrapper key={index}>
                    <a
                      target="_self"
                      className="plotch_a_link"
                      onClick={() => handleFooterDataLayerPush(item, footerData?.footerLinkList3?.footerLinkList3Title)}
                    >
                      {item?.text}
                    </a>
                  </Categorieswrapper>
                )
              })}
            </CategoryContainer>
          )}
        </FooterContainer>
      )}
      {footerData?.footerLinkList4?.enableLinkList4 == 1 && (
        <FooterContainer>
          <HeadingWrapper className="footer-heading">
            {footerData?.footerLinkList4?.footerLinkList4Title}
          </HeadingWrapper>
          {footerData?.footerLinkList4?.objectDetails && (
            <CategoryContainer>
              {footerData?.footerLinkList4?.objectDetails?.map((item: any, index: any) => {
                return (
                  <Categorieswrapper key={index}>
                    <a
                      target="_self"
                      className="plotch_a_link"
                      onClick={() => handleFooterDataLayerPush(item, footerData?.footerLinkList4?.footerLinkList4Title)}
                    >
                      {item?.text}
                    </a>
                  </Categorieswrapper>
                )
              })}
            </CategoryContainer>
          )}
        </FooterContainer>
      )}
      <FooterWrapper>
        {footerData?.footerContactUs?.enableContactUs == '1' && (
          <FooterContainer>
            <HeadingWrapper className="footer-heading">
              {footerData?.footerContactUs?.footerContactUsHeader
                ? footerData?.footerContactUs?.footerContactUsHeader
                : `SUPPORT`}
            </HeadingWrapper>
            <CategoryContainer>
              <Categorieswrapper>{footerData?.footerContactUs?.footerContactUs}</Categorieswrapper>
            </CategoryContainer>
          </FooterContainer>
        )}
      </FooterWrapper>
    </FooterMainWrapper>
  )
}

export default FooterLayout1

import { useState } from 'react'
import Drawer from '@mui/material/Drawer'
import TextInput from 'components/TextField'
import DownArrow from 'assets/svg/DownArrow'
import CloseIcon from 'assets/svg/CloseIcon'
import { Wrapper, CountryCodes } from 'styles/components/TextInput'
import CheckmarkCircle from 'assets/svg/CheckmarkCircle'
import {
  CountryCodeWrapper,
  CountryTopWrapper,
  Label,
  SvgWrapper,
  CodeWrapperContainer,
  CountryCode,
  CodeWrapper,
} from 'styles/pages/Login'

const countryList = [
  {
    countryCode: 'IN',
    countryId: 104,
    countryName: 'India',
    dialingCode: '+91',
  },
]

const MobileInput = ({ control, name, className, disabled }: any) => {
  const [open, setOpen] = useState(false)
  const [selectedCountryCodeIndex, setSelectedCountryCodeIndex] = useState(0)
  const [state, SetState] = useState('+91')

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const handleCountryCodesClick = () => {
    toggleDrawer(true)()
  }

  const handleCountryCodeSelect = (index: any, dialCode: any) => {
    setSelectedCountryCodeIndex(index)
    SetState(dialCode)
    toggleDrawer(false)()
  }

  return (
    <Wrapper className={className}>
      <CountryCodes onClick={handleCountryCodesClick}>
        {state} <DownArrow />
      </CountryCodes>

      <TextInput
        type="number"
        placeholder="Mobile Number *"
        control={control}
        name={name}
        className="field"
        maxLength={10}
        disabled={disabled}
      />
      <Drawer
        open={open}
        anchor="bottom"
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            height: 'auto',
            // width: '360px',
            borderRadius: '20px 20px 0px 0px',
          },
        }}
      >
        <CountryCodeWrapper>
          <CountryTopWrapper>
            <Label>Select a Country Code</Label>
            <SvgWrapper onClick={toggleDrawer(false)}>
              <CloseIcon />
            </SvgWrapper>
          </CountryTopWrapper>
          {countryList.map((item: any, index: number) => (
            <CodeWrapperContainer key={index} onClick={() => handleCountryCodeSelect(index, item.dialingCode)}>
              <CodeWrapper>
                <CountryCode>
                  {item.dialingCode} ({item.countryName})
                </CountryCode>
                {selectedCountryCodeIndex === index && <CheckmarkCircle />}
              </CodeWrapper>
            </CodeWrapperContainer>
          ))}
        </CountryCodeWrapper>
      </Drawer>
    </Wrapper>
  )
}

export default MobileInput

import { useEffect, useState } from 'react'
import { SortByMobileWrapper, TopWrapper, DetailsWrapper, SortOptions } from 'styles/components/ListProducts/SortBy'
import { SimilarSvgWrapper } from 'styles/views/LocationNewUser'
import CloseIcon from 'assets/svg/CloseIcon'
import { IFilterOption } from 'interfaces/components'
import { Flex, Radio } from 'antd'
import Button from 'components/Button'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
import { useTheme } from 'context/themeContext'

interface ISortBy {
  onClose?: any
  filtersSortData: any
  handleOptionChange: (value: string) => void
  setIsSorting: any
}

const SortByMobile = ({ onClose, filtersSortData, handleOptionChange, setIsSorting }: ISortBy) => {
  const [selectedOption, setSelectedOption] = useState<string>('')
  const [selectedLabel, setSelectedLabel] = useState<string>('')
  const { storesData } = useTheme()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  const handleOptionClick = (value: string, label: string) => {
    setSelectedOption(value)
    setSelectedLabel(label)
  }

  const handleSubmit = () => {
    handleOptionChange(selectedOption)
    setIsSorting(true)
    const dataToPush = {
      event: 'sort_interactions',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      category: 'na',
      cta_text: selectedLabel?.toLocaleLowerCase(), // Dynamically set cta_text
      section_name: 'sort_by',
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })
    onClose()
  }

  useEffect(() => {
    const queryString = window.location.search.split('?')[1]
    const searchParams = new URLSearchParams(queryString)
    const sortByData = searchParams.get('sort_by') || ''
    if (sortByData) {
      if (sortByData == 'priceasc') setSelectedOption('discountedPriceOrder:asc')
      else if (sortByData == 'pricedesc') setSelectedOption('discountedPriceOrder:desc')
      else if (sortByData == 'popular') setSelectedOption('relevance:desc')
    }
  }, [])

  const handleReset = () => setSelectedOption('')

  return (
    <SortByMobileWrapper>
      <TopWrapper>
        <div className="h0b mobile-similar-heading">Sort By</div>
        <SimilarSvgWrapper>
          <CloseIcon onClick={onClose} />
        </SimilarSvgWrapper>
      </TopWrapper>

      <DetailsWrapper>
        {filtersSortData?.map((filterItems: IFilterOption) => {
          const itemName = filterItems?.name.split(':')
          const label = itemName.length > 1 ? `${itemName[0]} (${itemName[1].trim()})` : itemName[0]
          const value = `${filterItems.esFilterName}:${filterItems.orderBy}`
          return (
            <SortOptions className={'h1r'} key={value} onClick={() => handleOptionClick(value, label)}>
              <Radio checked={selectedOption === value} className={'h1r radio-name'} />
              {label}
            </SortOptions>
          )
        })}
      </DetailsWrapper>
      <Flex gap={10} justify="center" className="sort-mobile">
        <Button variant="outline" label={'Reset'} onClick={handleReset} className="h1b" />
        <Button variant="contained" label={'Apply'} onClick={handleSubmit} className="h1b" />
      </Flex>
    </SortByMobileWrapper>
  )
}

export default SortByMobile

import { SVGProps } from 'react'
// import { theme } from 'theme'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M14.3763 5.00079H17.5036"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.50092 5.00079H11.8759"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3763 15.0002H17.5036"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.50092 15.0002H11.8759"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.12598 10.0014H17.501"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.50092 10.0014H5.62821"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1255 6.25269C13.8164 6.25269 14.3764 5.69264 14.3764 5.00177C14.3764 4.31091 13.8164 3.75085 13.1255 3.75085C12.4347 3.75085 11.8746 4.31091 11.8746 5.00177C11.8746 5.69264 12.4347 6.25269 13.1255 6.25269Z"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.87702 11.2523C7.56788 11.2523 8.12794 10.6923 8.12794 10.0014C8.12794 9.31054 7.56788 8.75049 6.87702 8.75049C6.18615 8.75049 5.6261 9.31054 5.6261 10.0014C5.6261 10.6923 6.18615 11.2523 6.87702 11.2523Z"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1255 16.2523C13.8164 16.2523 14.3764 15.6923 14.3764 15.0014C14.3764 14.3105 13.8164 13.7505 13.1255 13.7505C12.4347 13.7505 11.8746 14.3105 11.8746 15.0014C11.8746 15.6923 12.4347 16.2523 13.1255 16.2523Z"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent

import React from 'react'

interface SnackBarState {
  open: boolean
  message: string
  severity: any
}

interface ISnackBarContext {
  showSnackBar: SnackBarState
  setShowSnackBar: (newState: SnackBarState) => void
}

const defaultState = {
  showSnackBar: { open: false, message: '', severity: '' },
  setShowSnackBar: () => null,
}

const SnackBarContext = React.createContext<ISnackBarContext>(defaultState)

export { SnackBarContext }

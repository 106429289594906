import Drawer from '@mui/material/Drawer'
import Button from 'components/Button'
import SortIcon from 'assets/svg/SortIcon'
import FilterIcon from 'assets/svg/FilterIcon'
import { BottomButtonDivider, BottomButtonWrapper } from 'styles/components/SortFilter'
import { useState } from 'react'
import SortByMobile from 'components/ListProduct/SortBy'
import { CommonStyle } from 'styles/pages/Common'
import MobileFilterView from 'views/FilterView/MobileFilterView'

const SortFilterComponent = ({ filtersSortData, handleOptionChange, setIsSorting, filtersData }: any) => {
  const [openSortBy, setOpenSortBy] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)

  const toggleSortByDrawer = (newSort: boolean) => () => {
    setOpenSortBy(newSort)
  }
  const toggleFilterDrawer = (newSort: boolean) => () => {
    setOpenFilter(newSort)
  }

  return (
    <>
      <BottomButtonWrapper>
        <Button
          label="Sort"
          variant="standard"
          className=" h1b"
          icon={<SortIcon />}
          onClick={toggleSortByDrawer(true)}
        />
        <BottomButtonDivider />
        <Button
          label="Filter"
          variant="standard"
          className=" h1b"
          icon={<FilterIcon />}
          onClick={toggleFilterDrawer(true)}
        />
      </BottomButtonWrapper>
      <Drawer
        open={openSortBy}
        onClose={toggleSortByDrawer(false)}
        anchor="bottom"
        // sx={{
        //   '& .MuiDrawer-paper': {
        //     height: 'auto',
        //   },
        // }}
        sx={{
          '& .MuiDrawer-paper': {
            height: 'auto',
            borderRadius: '20px 20px 0px 0px',
          },
        }}
      >
        <CommonStyle>
          <SortByMobile
            onClose={toggleSortByDrawer(false)}
            filtersSortData={filtersSortData}
            handleOptionChange={handleOptionChange}
            setIsSorting={setIsSorting}
          />
        </CommonStyle>
      </Drawer>
      <Drawer
        open={openFilter}
        onClose={toggleFilterDrawer(false)}
        anchor="bottom"
        sx={{
          '& .MuiDrawer-paper': {
            height: 'auto',
            borderRadius: '20px 20px 0px 0px',
          },
        }}
      >
        <CommonStyle>
          <MobileFilterView filtersData={filtersData} onClose={toggleFilterDrawer(false)} />
        </CommonStyle>
      </Drawer>
    </>
  )
}

export default SortFilterComponent

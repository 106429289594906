import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import NavBottom from 'components/Navbar/MobileNavbar/navBottom'
import PublicLayout from 'components/Layouts/public'
import { HomeRoute } from 'constants/routes'
import { DesktopWrapper, MobileCategoryWrapper, ButtonWrap } from 'styles/pages/Home'

const CategoryPage = () => {
  const router = useHistory()

  const handleClick = () => {
    router.push(HomeRoute.path)
  }

  return (
    <PublicLayout>
      <DesktopWrapper>
        <div>Category Page</div>

        <ButtonWrap>
          <Button label="Go To Home" variant="contained" type="submit" className="small" onClick={handleClick} />
        </ButtonWrap>
      </DesktopWrapper>
      <MobileCategoryWrapper>CategoryPage</MobileCategoryWrapper>

      <NavBottom />
    </PublicLayout>
  )
}

export default CategoryPage

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import routes from 'constants/routes'
import { IRoute } from 'interfaces'
import { isLogin } from '../utils'
import GoogleTagManager from 'components/GTM'
import LoginPage from 'pages/login'
import OtpPage from 'pages/otp'
import NodessoLogin from 'pages/nodessoLogin'
// import { useTheme } from 'context/themeContext'

const ProtectedRoute = ({ component: Component, restricted, path }: IRoute) => {
  if (isLogin() && (path === '/signin' || path === '/otp' || path === '/login')) {
    return <Redirect to="/" />
  }

  return <>{isLogin() || !restricted ? <Component /> : <Redirect to="/" />}</>
}

const AuthRedirect = ({ component: Component, ...rest }: any) => {
  return <Route {...rest} render={(props) => (isLogin() ? <Redirect to="/" /> : <Component {...props} />)} />
}

const Routing = () => {
  // const { storesData } = useTheme()
  // const googleDatalayer = storesData?.d?.googleDatalayer
  return (
    <BrowserRouter>
      <GoogleTagManager />
      {/* {googleDatalayer === '1' && <GoogleTagManager />} */}
      <Switch>
        <AuthRedirect path="/signin" component={LoginPage} exact={false} />
        <AuthRedirect path="/otp" component={OtpPage} exact={false} />
        <AuthRedirect path="/login" component={NodessoLogin} exact={false} />
        {routes.map((route, index) => {
          const { component: Component, path, restricted, exact } = route
          return (
            <Route
              key={index}
              path={path}
              exact={exact}
              render={() => <ProtectedRoute path={path} restricted={restricted} component={Component} exact={exact} />}
            />
          )
        })}
      </Switch>
    </BrowserRouter>
  )
}

export default Routing

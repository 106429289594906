import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import PrivateLayout from 'components/Layouts/private'
import { HomeRoute } from 'constants/routes'
import BackArrow from 'assets/svg/BackArrow'
import CartIcon from 'assets/svg/CartIcon'
import { BackWrapperDisplay } from 'styles/pages/Login'
import { EmptyCartPageWrapper, MainContainer, MainWrapper, SubHeading } from 'styles/pages/NotFound'

const CartEmptyPage = () => {
  const router = useHistory()
  // const handleBack = () => {
  //   router.goBack()
  // }
  const handleClick = () => {
    router.push(HomeRoute.path)
  }

  return (
    <PrivateLayout>
      <EmptyCartPageWrapper>
        {/* <BackWrapperDisplay onClick={handleBack}> */}
        <BackWrapperDisplay onClick={handleClick}>
          <BackArrow />
          EMPTY CART
        </BackWrapperDisplay>
        <MainContainer>
          <MainWrapper>
            <SubHeading>Your cart is empty</SubHeading>
            <CartIcon />
          </MainWrapper>
          <Button label="Go To Home" variant="contained" type="submit" className="small" onClick={handleClick} />
        </MainContainer>
      </EmptyCartPageWrapper>
    </PrivateLayout>
  )
}

export default CartEmptyPage

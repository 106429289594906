import { useFilter } from 'context/FliterContext'
import { StoreFilterWrapper, StoreFilterContainer, StoreNameHeading } from 'styles/views/SplitStoresHyperlocalView'

const StoreFilter = ({ filtersData }: any) => {
  const { setFilterProduct } = useFilter()
  const storeFilter = filtersData.find((filter: any) => filter.esFilterName === 'store_name')

  const appendQueryParam = (param: string, value: string | null) => {
    const queryString = window.location.search.split('?')[1] // Get the query string part of the URL
    const searchParams = new URLSearchParams(queryString)

    if (param && value) {
      searchParams.set(param, value)
    } else if (param && !value) {
      searchParams.delete(param)
    }

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    window.history.replaceState(null, '', newUrl)
  }

  const handleFilterClick = (filter: any) => {
    setFilterProduct(filter)

    if (filter.filterItem === 'item') {
      // Remove the store_name parameter for "All Stores"
      appendQueryParam(storeFilter?.esFilterName, null)
    } else {
      // Append the store_name parameter for specific stores
      appendQueryParam(storeFilter?.esFilterName, filter.filterItem)
    }
  }

  const searchParams = new URL(window.location.href).searchParams
  const storeName = searchParams.get('store_name')

  return (
    <StoreFilterWrapper>
      <StoreFilterContainer>
        <StoreNameHeading className="h0r" onClick={() => handleFilterClick({ filterItem: 'item' })} active={!storeName}>
          All Stores
        </StoreNameHeading>
        {storeFilter?.filtersData.map((filter: { filterItem: string }, index: number) => (
          <StoreNameHeading
            key={index}
            className="h0r"
            onClick={() => handleFilterClick(filter)}
            active={storeName === filter.filterItem}
          >
            {filter.filterItem}
          </StoreNameHeading>
        ))}
      </StoreFilterContainer>
    </StoreFilterWrapper>
  )
}

export default StoreFilter

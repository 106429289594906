import { useEffect } from 'react'

const PayuPayment = ({ prepaidData }: any) => {
  // console.log('prepaidData', prepaidData)
  useEffect(() => {
    localStorage.setItem('orderId', prepaidData?.d?.orderId)
    // Create a form and submit it when the component mounts
    const form = document.createElement('form')
    form.action = prepaidData?.d?.placeOrderUri
    form.method = 'post'
    form.id = 'prepaidForm'
    form.name = 'prepaidForm'

    // Add hidden inputs for requestParams
    prepaidData?.d?.requestParams &&
      Object.entries(prepaidData?.d?.requestParams).forEach(([key, val]) => {
        const input: any = document.createElement('input')
        input.type = 'hidden'
        input.name = key
        input.value = val
        form.appendChild(input)
      })
    const udf4Url: any = document.createElement('input')
    udf4Url.type = 'hidden'
    udf4Url.name = 'successUrl'
    udf4Url.value = window.location.origin + '/order-success'
    form.appendChild(udf4Url)

    const udf5Url: any = document.createElement('input')
    udf5Url.type = 'hidden'
    udf5Url.name = 'failUrl'
    udf5Url.value = window.location.origin + '/checkout'
    form.appendChild(udf5Url)
    // // Add hidden inputs for success and failure URLs
    const successInput: any = document.createElement('input')
    successInput.type = 'hidden'
    successInput.name = 'surl'
    successInput.value = 'https://nodeappapi1.plotch.io/api/v1/checkout/successCallbackUrlWithRedirect'
    form.appendChild(successInput)

    const failureInput: any = document.createElement('input')
    failureInput.type = 'hidden'
    failureInput.name = 'furl'
    failureInput.value = 'https://nodeappapi1.plotch.io/api/v1/checkout/failureCallbackUrlWithRedirect'
    form.appendChild(failureInput)
    // Append form to the document and submit it
    document.body.appendChild(form)

    form.submit()
  }, [prepaidData])

  return null // This component does not render anything
}

export default PayuPayment

import { useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom'
import usePost from 'hooks/usePost'
import PublicLayout from 'components/Layouts/public'
import ListProducts from 'components/ListProduct'
import APIS from 'constants/api'
// import { NotFoundRoute } from 'constants/routes'
import { useFilter } from 'context/FliterContext'
// import { LoaderContext } from 'context/loader'
import { Product } from 'interfaces/pages'
import { CommonStyle } from 'styles/pages/Common'
import { SellerNearMeStoreWrapper, ProductDetailsWrapper, StoreImageWrapper } from 'styles/components/ListProducts'
import SpeedometerIcon from 'assets/svg/SpeedometerIcon'
import { useParams } from 'react-router-dom'

const VendorPage = () => {
  const [productDetails, setProductDetails] = useState<Product[]>([])
  const [loadMoreData, setLoadMoreData] = useState(false)
  const [filtersData, setFiltersData] = useState<any>([])
  const [filtersSortData, setFiltersSortData] = useState<any>([])
  const [productCount, setProductCount] = useState('')
  const [productInitialCount, setProductInitialCount] = useState('')
  const [feedDetails, setFeedDetails] = useState<any>()
  const [isSorting, setIsSorting] = useState(false)
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768)
  // const { setLoader } = useContext(LoaderContext)
  const { filterProduct, filterSortProduct } = useFilter()
  const { mutateAsync } = usePost()
  // const router = useHistory()
  const param = useParams<any>()
  const vendorId = param?.id

  const [filter, setFilter] = useState({
    page: 1,
    perPage: 48,
  })

  // useEffect(() => {
  //   if (isLoading) //setLoader(true)
  //   else //setLoader(false)
  // }, [isLoading])

  const fetchData = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.VENDOR_PRODUCT,
        payload: {
          pageId: filter.page,
          perPage: filter.perPage,
          filters: filterProduct,
          sortBy: filterSortProduct,
          vendorId: vendorId,
        },
      })
      if (res) {
        setFiltersData(res?.d?.displayFilters)
        setFeedDetails(res?.d?.feedDetails)
        setFiltersSortData(res?.d?.sorts)

        if (!isLargeScreen && loadMoreData) {
          if (isSorting) {
            setProductDetails(res?.d?.products)
            setIsSorting(false)
          } else setProductDetails((prev: Product[]) => [...prev, ...res?.d?.products])
        } else {
          setProductDetails(res?.d?.products)
        }

        setProductInitialCount(res?.d?.productCount)
        setProductCount(res?.d?.totalProducts)
        //setLoader(false)
      }
    } catch (error: any) {
      //setLoader(false)
      // router.push(NotFoundRoute.path)
      return error
    }
  }

  useEffect(() => {
    fetchData()
  }, [filter.page, filterProduct, filterSortProduct])

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <PublicLayout>
      <CommonStyle>
        <SellerNearMeStoreWrapper className="product-wrapper">
          <StoreImageWrapper>
            <img src={productDetails[0]?.storeImage} />
          </StoreImageWrapper>
          <ProductDetailsWrapper>
            <div className="heading-wrapper">
              <div className="heading-details">
                <label className="h0b">{productDetails[0]?.storeName}</label>
              </div>
            </div>
            <div className="discount-details">
              <SpeedometerIcon />
              <div className="after-discount h0r">{productDetails[0]?.distance.toFixed(2)} KM</div>
            </div>
          </ProductDetailsWrapper>
        </SellerNearMeStoreWrapper>
      </CommonStyle>

      <CommonStyle>
        {productDetails.length > 0 && (
          <ListProducts
            products={productDetails}
            setFilter={setFilter}
            productCount={productCount}
            productInitialCount={productInitialCount}
            filter={filter}
            feedDetails={feedDetails}
            filtersData={filtersData}
            filtersSortData={filtersSortData}
            setIsSorting={setIsSorting}
            setLoadMoreData={setLoadMoreData}
          />
        )}
      </CommonStyle>
    </PublicLayout>
  )
}

export default VendorPage

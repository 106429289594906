import { useEffect } from 'react'
import Button from 'components/Button'
import AlertIcon from 'assets/svg/AlertIcon'
import {
  DeleteAddressContainer,
  HeadingWrappers,
  TopHeadingWrapper,
  Heading,
  Description,
  DeleteButtonWrapper,
} from 'styles/views/SaveAddress'
import { SvgWrappers } from 'styles/views/LocationNewUser'
import Line from 'assets/svg/Line'
// import { LoaderContext } from 'context/loader'
import usePost from 'hooks/usePost'
import APIS from 'constants/api'
import CloseIcon from 'assets/svg/CloseIcon'

const DeleteAddress = ({ onClose, addressId, checkDeleted }: any) => {
  // const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  const customerId = localStorage.getItem('customerId')

  const handleDeleteAddress = async () => {
    //setLoader(true)

    try {
      const res = await mutateAsync({
        url: APIS.DELETE_ADDRESS,
        payload: { customerId, addressId },
      })
      if (res) {
        //setLoader(false)
        checkDeleted(true)
        onClose()
      }
    } catch (error) {
      //setLoader(false)
      return error
    }
  }

  useEffect(() => {
    checkDeleted(false)
  }, [])

  return (
    <DeleteAddressContainer>
      <SvgWrappers className="delete-close">
        <CloseIcon onClick={onClose} />
      </SvgWrappers>
      <HeadingWrappers>
        <TopHeadingWrapper>
          <AlertIcon />
          <Heading>Delete Address</Heading>
        </TopHeadingWrapper>
        <Description>Are you sure you want to delete this address?</Description>
      </HeadingWrappers>

      <DeleteButtonWrapper>
        <Button label="Delete" variant="outline" type="submit" className="skip" onClick={handleDeleteAddress} />
        <Line />
        <Button label="Do Not Delete" variant="contained" type="submit" className="small" onClick={onClose} />
      </DeleteButtonWrapper>
    </DeleteAddressContainer>
  )
}

export default DeleteAddress

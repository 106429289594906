import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import { AddAddressPindropRoute, AddAddressRoute } from 'constants/routes'
import CloseIcon from 'assets/svg/CloseIcon'
import { ButtonWrapper } from 'styles/components/Button'
import { DeliveryHeading } from 'styles/views/SaveAddress'
import {
  AddressesListContainer,
  AddressesListWrapper,
  DeliverySvgWrapper,
  CloseWrapper,
} from 'styles/views/LocationNewUser'
import AddressList from './addressList'
import { useTheme } from 'context/themeContext'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
import { AddressInteractionData } from 'utils/datalayer/addressInteractionData'

const SavedAddresses = ({ onClose, refetchOndcQuoteDetails }: any) => {
  const navigate = useHistory()
  const { themedata, storesData } = useTheme()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const { addressInteractionDataToPush } = AddressInteractionData()

  const addAddressByPindrop = themedata?.d?.objectDetails?.general?.addAddressByPindrop
  const handleAddressClick = () => {
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: addressInteractionDataToPush })
    if (addAddressByPindrop === 0) {
      navigate.push(AddAddressRoute.path)
    } else {
      navigate.push(AddAddressPindropRoute.path)
    }
  }

  const handleClose = async () => {
    // const location: any = localStorage.getItem('location')
    // const locationData = JSON.parse(location)?.address

    const dataToPush = {
      event: 'select_location_interaction',
      cta_text: 'close_icon',
      select_type: 'close',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
    }

    const pushToDataLayer = () => {
      return new Promise<void>((resolve) => {
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: dataToPush })
        resolve()
      })
    }

    await pushToDataLayer()

    onClose()
  }

  return (
    <AddressesListContainer>
      <AddressesListWrapper>
        <CloseWrapper>
          <DeliveryHeading>Select a Delivery Address</DeliveryHeading>
          <DeliverySvgWrapper className="crossIcon" onClick={handleClose}>
            <CloseIcon />
          </DeliverySvgWrapper>
        </CloseWrapper>

        <AddressList onClose={onClose} refetchOndcQuoteDetails={refetchOndcQuoteDetails} />
      </AddressesListWrapper>
      <ButtonWrapper onClick={handleAddressClick}>
        <Button label="Add Address" variant="contained" type="button" />
      </ButtonWrapper>
    </AddressesListContainer>
  )
}

export default SavedAddresses

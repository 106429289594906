import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import { Carousel } from 'antd'
import APIS from 'constants/api'
import useGet from 'hooks/useGet'
import {
  MobileCategoryWrapper,
  ItemsCategoryWrapper,
  WidgetsImageWrapper,
  StaticCarousel,
  BlockDiv,
  StaticCarouselContainer,
  WidgetWrap,
} from 'styles/pages/Home'
import { useHomeContext } from 'context/HomeContext'
import { useSession } from 'context/SessionContext'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { ProductWrapper, ProductDetailsWrapper, ImageWrapper } from 'styles/components/ListProducts'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'context/themeContext'
import { PdpRoute } from 'constants/routes'
import { CommonStyle } from 'styles/pages/Common'
import Button from 'components/Button'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'

const Arrow = ({
  type,
  style,
  className,
  onClick,
}: {
  type: string
  style?: any
  className?: string
  onClick?: any
}) => {
  return (
    <div key={type} style={style} className={className} onClick={onClick}>
      {type === 'left' ? <LeftOutlined /> : <RightOutlined />}
    </div>
  )
}

// const carouselSettings = {
//   infinite: false,
//   arrows: true,
//   dots: false,
//   slidesToShow: 4,
//   responsive: [
//     {
//       breakpoint: 768, // screens less than 768px
//       settings: {
//         slidesToShow: 4,
//       },
//     },
//   ],
// }

const MobileHomeDetails = () => {
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  const { homeData, setHomeData } = useHomeContext() // Use the context here
  const { isSession } = useSession()
  const [preview, setPreview] = useState<string>(APIS.HOME_PAGE)
  const params = new URLSearchParams(window.location.search)

  const { refetch, data } = useGet('home-data', `${preview}`)
  const separateDataByMsite = (dataArray: any) => {
    const msite0Data = dataArray?.filter((item: any) => item.msite === 0)
    // const msite1Data = dataArray?.filter((item: any) => item.msite === 1)
    return { msite0Data }
  }

  const { msite0Data } = separateDataByMsite(data?.d?.sections)
  const navigate = useHistory()
  const { storesData } = useTheme()
  // const token = localStorage.getItem('sessionId')

  useEffect(() => {
    if (params.get('preview')) setPreview(`${APIS.HOME_PAGE}?preview=1`)

    if (isSession == true) refetch()
  }, [isSession])

  useEffect(() => {
    if (data) setHomeData(data?.d?.seoDetails)
  }, [data])

  useEffect(() => {
    if (storesData?.d?.pageConfig) {
      const pageConfig = storesData?.d?.pageConfig
      const enableHomepageCache = pageConfig?.home_page_cache
      const homeTTL = pageConfig?.home_ttl
      if (enableHomepageCache == 1 && homeTTL != '') {
        const metaTag = document.createElement('meta')
        metaTag.httpEquiv = 'Cache-Control'
        metaTag.content = `public,max-age=${homeTTL}`
        document.head.appendChild(metaTag)

        return () => {
          document.head.removeChild(metaTag)
        }
      }
    }
  }, [storesData])

  const renderWidget = (widget: any) => {
    switch (widget.type) {
      case '_round_image_carousel': {
        const roundCarouselType = widget?.elements?.[0]?.roundCarouselType == 1 ? true : false
        const roundImageCarouselHideArrow =
          widget?.elements?.[0]?.roundImageCarouselHideArrow != ''
            ? widget?.elements?.[0]?.roundImageCarouselHideArrow
            : 1
        const hideArrows = roundImageCarouselHideArrow == 1 ? true : false
        const roundImageCount =
          widget?.elements?.[0]?.roundImageCount !== '' ? widget?.elements?.[0]?.roundImageCount : 7
        const carouselSetting = {
          infinite: false,
          arrows: hideArrows,
          dots: false,
          slidesToShow: Number(roundImageCount),
          draggable: !hideArrows,
          responsive: [
            {
              breakpoint: 1024, // screens less than 768px
              settings: {
                slidesToShow: Number(roundImageCount),
              },
            },
            {
              breakpoint: 600, // screens less than 768px
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: Number(roundImageCount),
              },
            },
          ],
        }

        const handleClick = (category: any) => {
          // const targetUrl = category?.targetUrl.startsWith('/') ? category.targetUrl : `/${category.targetUrl}`
          const dataToPush = {
            event: 'shop_by_category',
            page_type: `${GDL_PAGE_TYPE} landing page`,
            User_Id: loggedIn,
            User_Status: loggedInStatus,
            cta_text: category.labelText ? category.labelText : 'NA',
            section_name: homeData?.feedName,
          }

          // Push data to dataLayer
          storesData?.d?.googleDatalayer &&
            storesData?.d?.googleDatalayer == 1 &&
            TagManager.dataLayer({ dataLayer: dataToPush })
          // navigate.push(targetUrl)
          // window.location.href = targetUrl
        }

        if (roundCarouselType) {
          return (
            <Carousel className="round_image_carousel" key={widget?.type} {...carouselSetting}>
              {widget?.elements?.map((item: any, index: number) => {
                return (
                  <div key={index} className="slider">
                    {/* <a className="plotch_a_link" onClick={() => handleClick(item)}> */}
                    <a
                      href={item?.targetUrl}
                      target="_self"
                      className="plotch_a_link"
                      onClick={() => handleClick(item)}
                    >
                      {/* <a className="plotch_a_link" onClick={handleClick}> */}
                      <img src={item?.imageUrl} loading="lazy" />
                      {item.labelText && <label className="labelText">{item.labelText}</label>}
                    </a>
                  </div>
                )
              })}
            </Carousel>
          )
        } else {
          return (
            <StaticCarouselContainer>
              {hideArrows && <Arrow type="left" style={{ color: '#000' }} className="slick-prev" />}
              <StaticCarousel className="round_image_carousel" key={widget?.type}>
                {widget?.elements?.map((item: any, index: number) => {
                  return (
                    <div key={index} className="slider roundCarousel">
                      {/* <a className="plotch_a_link static-roundCarousel" onClick={() => handleClick(item)}> */}
                      <a
                        href={item?.targetUrl}
                        target="_self"
                        className="plotch_a_link"
                        onClick={() => handleClick(item)}
                      >
                        <img src={item?.imageUrl} loading="lazy" />
                        {item.labelText && <label className="labelText">{item.labelText}</label>}
                      </a>
                    </div>
                  )
                })}
              </StaticCarousel>
              {hideArrows && <Arrow type="right" style={{ color: '#000' }} className="slick-next" />}
            </StaticCarouselContainer>
          )
        }
      }

      case '_carousel': {
        const carouselHideArrow =
          widget?.elements?.[0]?.carouselHideArrow != '' ? widget?.elements?.[0]?.carouselHideArrow : 1
        const hideArrows = carouselHideArrow == 1 ? true : false
        const imageCount = 1
        const carouselSetting = {
          infinite: true,
          arrows: hideArrows,
          dots: true,
          autoplay: true,
          slidesToShow: Number(imageCount),
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: Number(imageCount),
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: Number(imageCount),
              },
            },
          ],
        }
        return (
          <Carousel className="carousel-display" {...carouselSetting} key={widget?.type}>
            {widget?.elements.map((item: any, index: number) => (
              <div key={index} className="slider">
                <a href={item?.targetUrl} target="_self" className="plotch_a_link">
                  <img className="carouselImage" src={item?.imageUrl} />
                </a>
              </div>
            ))}
          </Carousel>
        )
      }

      case '_image': {
        return (
          <div>
            {widget.elements.map((item: any, index: number) => (
              <WidgetsImageWrapper key={index} style={item.blockSettings}>
                <a id="" href={item.targetUrl} target="_self" className="plotch_a_link">
                  <img src={item.imageUrl} />
                </a>
              </WidgetsImageWrapper>
            ))}
          </div>
        )
      }

      case '_label': {
        const handleSection = (item: any) => {
          const dataLayer = {
            event: 'section_header_click',
            cta_text: item?.name?.toLowerCase(),
            page_type: `${GDL_PAGE_TYPE}`,
            User_Id: loggedIn,
            User_Status: loggedInStatus,
          }

          storesData?.d?.googleDatalayer &&
            storesData?.d?.googleDatalayer == 1 &&
            TagManager.dataLayer({ dataLayer: dataLayer })
        }

        return (
          <WidgetWrap>
            {widget.elements.map((item: any, index: number) => {
              return (
                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <label
                    style={{
                      color: item.labelColour,
                      backgroundColor: item.labelBgColour,
                      textAlign: item.labelAlignment,
                      fontSize: item.labelSize,
                      fontFamily: item.labelFont,
                    }}
                    onClick={() => handleSection(item)}
                  >
                    {item.labelName}
                  </label>
                  <div
                    style={{
                      flexGrow: 1,
                      height: '1px',
                      backgroundColor: '#ccc', // Line color
                    }}
                  />
                </div>
              )
            })}
          </WidgetWrap>
        )
      }

      case '_button': {
        const handleDataClick = (category: any) => {
          const targetUrl = category?.targetUrl.startsWith('https')
            ? category.targetUrl
            : category?.targetUrl.startsWith('/')
            ? category.targetUrl
            : `/${category.targetUrl}`
          const dataToPush = {
            event: 'see_all_click',
            page_type: `${GDL_PAGE_TYPE}`,
            User_Id: loggedIn,
            User_Status: loggedInStatus,
            cta_text: category.data ? category.data?.toLowerCase() : 'NA',
          }

          // Push data to dataLayer
          storesData?.d?.googleDatalayer &&
            storesData?.d?.googleDatalayer == 1 &&
            TagManager.dataLayer({ dataLayer: dataToPush })
          // navigate.push(targetUrl)
          window.location.href = targetUrl
        }

        return (
          <WidgetWrap>
            {widget.elements.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    color: item.labelColour,
                    backgroundColor: item.labelBgColour,
                    textAlign: item.buttonAlignment,
                    fontSize: item.labelSize,
                    fontFamily: item.labelFont,
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  {/* <a
                    href={item.targetUrl}
                    style={{
                      textDecoration: 'none',
                      textAlign: item.buttonAlignment,
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  > */}
                  <Button
                    label={item.data}
                    variant="contained"
                    type="button"
                    className="view-all"
                    onClick={() => {
                      handleDataClick(item)
                    }}
                  />

                  {/* <button>{item.data}</button> */}
                  {/* </a> */}
                </div>
              )
            })}
          </WidgetWrap>
        )
      }

      case '_rectangle_image_carousel': {
        const rectangleCarouselHideArrow =
          widget?.elements?.[0]?.rectangleCarouselHideArrow != ''
            ? widget?.elements?.[0]?.rectangleCarouselHideArrow
            : 1
        const hideArrows = rectangleCarouselHideArrow == 1 ? true : false
        const imageCount = widget?.elements?.[0]?.imageCount != '' ? widget?.elements?.[0]?.imageCount : 7
        const msiteImageCount = widget?.elements?.[0]?.imageCount != '' ? widget?.elements?.[0]?.imageCount : 3
        const rectangleCarouselType = widget?.elements?.[0]?.rectangleCarouselType == 1 ? true : false
        const carouselSetting = {
          infinite: true,
          arrows: hideArrows,
          dots: false,
          draggable: !hideArrows,
          slidesToShow: Number(imageCount),
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: Number(imageCount),
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: Number(msiteImageCount),
              },
            },
          ],
        }
        const handleClick = (category: any) => {
          // const targetUrl = category?.targetUrl.startsWith('/') ? category.targetUrl : `/${category.targetUrl}`
          const dataToPush = {
            event: 'shop_by_category',
            page_type: `${GDL_PAGE_TYPE}`,
            User_Id: loggedIn,
            User_Status: loggedInStatus,
            cta_text: category?.altTag ? category?.altTag?.toLowerCase() : 'NA',
            section_name: homeData?.feedName?.toLowerCase(),
          }

          // Push data to dataLayer
          storesData?.d?.googleDatalayer &&
            storesData?.d?.googleDatalayer == 1 &&
            TagManager.dataLayer({ dataLayer: dataToPush })
          // navigate.push(targetUrl)
          // window.locat
        }

        if (rectangleCarouselType) {
          return (
            <Carousel className="carousel-rect rectangleCarousel" {...carouselSetting} key={widget.type}>
              {widget?.elements?.map((item: any, index: number) => (
                <div key={index} className="slider">
                  <a href={item?.targetUrl} target="_self" className="plotch_a_link" onClick={() => handleClick(item)}>
                    <div className="imageWithLabel">
                      <img src={item?.imageUrl} loading="lazy" />
                      {item.altTag && <label className="labelText">{item.altTag}</label>}
                    </div>
                  </a>
                </div>
              ))}
            </Carousel>
          )
        } else {
          return (
            <StaticCarouselContainer className="rectangleCarousel">
              {hideArrows && <Arrow type="left" style={{ color: '#000' }} className="slick-prev" />}
              {/* <StaticCarousel className="rectabgle_image_carousel" key={widget?.type}>
              {widget?.elements?.map((item: any, index: number) => {
                return (
                  <div key={index} className="slider rectangleCarousel">
                    <a href={item?.targetUrl} target="_self" className="plotch_a_link">
                      <img src={item?.imageUrl} loading="lazy" />
                      {item.labelText && <label className="labelText">{item.labelText}</label>}
                    </a>
                  </div>
                )
              })}
            </StaticCarousel> */}
              <StaticCarousel className="rectangle_image_carousel" key={widget?.type}>
                {widget?.elements?.map((item: any, index: number) => {
                  return (
                    <div key={index} className="slider rectangleCarousel">
                      <a
                        href={item?.targetUrl}
                        target="_self"
                        className="plotch_a_link"
                        onClick={() => handleClick(item)}
                      >
                        <div className="image-container">
                          <img src={item?.imageUrl} loading="lazy" className="carousel-image" />
                          {item.altTag && <label className="labelText">{item.altTag}</label>}
                        </div>
                      </a>
                    </div>
                  )
                })}
              </StaticCarousel>
              {hideArrows && <Arrow type="right" style={{ color: '#000' }} className="slick-next" />}
            </StaticCarouselContainer>
          )
        }
      }

      case '_product_carousel': {
        const productCarouselHideArrow =
          widget?.elements?.[0]?.productCarouselHideArrow != '' ? widget?.elements?.[0]?.productCarouselHideArrow : 1
        const hideArrows = productCarouselHideArrow == 1 ? true : false
        const imageCount =
          widget?.elements?.[0]?.slider_config?.slides_to_show != ''
            ? widget?.elements?.[0]?.slider_config?.slides_to_show
            : 6
        const imageCountMsite =
          widget?.elements?.[0]?.slider_config?.slides_to_show_msite != ''
            ? widget?.elements?.[0]?.slider_config?.slides_to_show_msite
            : 2
        const carouselSetting = {
          infinite: false,
          arrows: hideArrows,
          dots: false,
          draggable: !hideArrows,
          slidesToShow: Number(imageCount),
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: Number(imageCount),
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: Number(imageCountMsite),
              },
            },
          ],
        }

        return (
          <Carousel className="carousel-rect" {...carouselSetting} key={widget?.type}>
            {widget?.elements?.map((item: any) => {
              return item?.products?.map((product: any, index: number) => {
                const imageRatio = storesData?.d?.imageSize == 'Square' ? 'w_300,h_300' : 'w_300,h_450'

                let imgUrl =
                  product?.imgUrl?.startsWith('http://') || product?.imgUrl?.startsWith('https://')
                    ? product?.imgUrl
                    : `https://cdnaz.plotch.io/image/upload/${imageRatio}${product?.imgUrl}`
                if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes(imageRatio)) {
                  imgUrl = imgUrl?.split('/upload/').join(`/upload/${imageRatio}/`)
                }

                imgUrl = imgUrl + '?product_id=' + product?.productId + '&s=1&tf=sq'

                const productName = product?.productName
                  .replace(/[^a-zA-Z ]/g, '')
                  .split(' ')
                  .join('-')
                return (
                  <ProductWrapper
                    key={index}
                    className="product-wrapper"
                    onClick={() => {
                      navigate.push(
                        PdpRoute.path
                          .replace(':name', productName ? productName : 'product')
                          .replace(':id', product?.productId),
                      )
                      localStorage.setItem('productId', product?.productId)
                    }}
                  >
                    <ImageWrapper>
                      <img src={imgUrl} />
                    </ImageWrapper>

                    <ProductDetailsWrapper>
                      <div className="heading-wrapper">
                        <div className="heading-details">
                          <label className="h1b product-name">{product.productName}</label>
                          {/* {wishlistIdData[product.productId] ? (
                            <WishlistedIcon
                              className="wishlist-icon"
                              onClick={(e: any) => {
                                e.stopPropagation()
                                if (customerId) {
                                  handleRemoveWishlistClick(product?.productId)
                                }
                              }}
                            />
                          ) : (
                            <HeartIcon
                              className="wishlist-icon"
                              onClick={(e: any) => {
                                e.stopPropagation()
                                if (customerId) {
                                  handleWishlistClick(product?.productId)
                                } else {
                                  navigate.push(LoginRoute.path)
                                }
                              }}
                            />
                          )} */}
                        </div>
                        <label className="h3r subCategory">{product?.categoryName}</label>
                      </div>
                      <div className="discount-details">
                        {product?.regularPrice === product?.discountedPrice ? (
                          <div className="after-discount h1b">
                            &#8377;
                            {product?.regularPrice}
                          </div>
                        ) : (
                          <>
                            <div className="after-discount h1b">
                              &#8377;
                              {product?.discountedPrice}
                            </div>
                            <div className="before-discount">
                              <span> &#8377; {product.regularPrice} </span>
                            </div>
                          </>
                        )}

                        {product?.discountPercentage > 0 ? (
                          <div className="discount-percentage h4r">
                            {product.discountPercentage ? `${product.discountPercentage}% OFF` : null}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </ProductDetailsWrapper>
                  </ProductWrapper>
                )
              })
            })}
          </Carousel>
        )
      }

      case '_space_card':
        return <div key={widget?.type} style={{ height: widget?.elements[0]?.data }} />
      default:
        return null
    }
  }

  return (
    <MobileCategoryWrapper>
      {msite0Data ? (
        <CommonStyle>
          <ItemsCategoryWrapper>
            {msite0Data?.map((block: any, index: any) => {
              return (
                <BlockDiv
                  className={`myBlock ${block.msite == 1 ? 'hide-on-mobile' : ''}`}
                  key={index}
                  css={block.blockSettings}
                  dimensions={block?.dimension}
                >
                  {Array.from({ length: block?.dimension?.['cols'] || 0 }).map((val, account: any) => {
                    return (
                      <div key={account + val}>
                        {block.widgets?.map((widget: any) => {
                          if (account + 1 === widget?.['position']?.['col']) {
                            return renderWidget(widget)
                          }

                          return null // Explicitly return null if condition is not met
                        })}
                      </div>
                    )
                  })}
                </BlockDiv>
              )
            })}
          </ItemsCategoryWrapper>
        </CommonStyle>
      ) : (
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )}
    </MobileCategoryWrapper>
  )
}

export default MobileHomeDetails

import styled from 'styled-components'

export const AddressesListContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100%;
  .bordered {
    width: unset;
  }
`
export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary_box_fill_color);
  padding: 8px;
  gap: 40px;
`
export const AddressesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--primary_box_fill_color);
  padding: 8px 20px;
  gap: 12px;
  .borderedBtn {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 21px 23px 10px;
  }
`
export const AddressMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const SavedAddressesContainer = styled.div`
  padding: unset;
`
export const NoAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  min-height: 62vh;
`
export const Divider = styled.div`
  width: 1px;
  height: 14px;
  background: var(--primary_line_color_light);
`
export const SvgWrapper = styled.div`
  width: 100%;
  text-align: end;
  svg {
    cursor: pointer;
  }
`
export const DeliverySvgWrapper = styled.div`
  /* width: 100%;
  text-align: end; */
  svg {
    cursor: pointer;
  }
`
export const CloseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const SimilarSvgWrapper = styled.div`
  text-align: end;
  svg {
    cursor: pointer;
  }
`
export const SvgWrappers = styled(SvgWrapper)`
  padding: 8px 0;
  @media screen and (min-height: 786px) {
    display: none;
  }
`
export const ImageWrapper = styled.div`
  img {
    width: 160px;
    height: 46.135px;
  }
`
export const StoreNearWrapper = styled.div`
  display: flex;
  gap: 8px;
`
export const SearchWrapper = styled.div`
  position: relative;
  .suggestion-data {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const TypeDataContainer = styled.div`
  box-shadow: 0px 3px 24px -6px rgba(0, 0, 0, 0.16);
  background-color: var(--secondary_button_active_fill_color);
  position: absolute;
  width: 100%;
  cursor: pointer;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 40vh;
  overflow: auto;
  border-radius: 0 0 6px 6px;
  left: 0;
`
export const SuggestinDataContainer = styled.div`
  box-shadow: 0px 3px 24px -6px rgba(0, 0, 0, 0.16);
  background-color: var(--secondary_button_active_fill_color);
  position: absolute;
  width: 100%;
  cursor: pointer;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 60vh;
  overflow: auto;
  border-radius: 12px;
  left: 0;
  top: 43px;
  /* border-radius: 8px; */
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 786px) {
    height: 85vh;
  }
`
export const TypeData = styled.div`
  padding: 10px 12px 4px 20px;
  border-bottom: 1px solid var(--primary_stroke_color_light);

  :hover {
    background-color: var(--primary_box_fill_highlight_color);
  }
`
export const LocationButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  button {
    width: 160px;
    border-radius: 4px;
  }
  .skip {
    border: 1px solid var(--primary_stroke_color_light);
    background: var(--secondary_button_active_fill_color);
    color: var(--primary_text_color_dark);
  }
`
export const LocationText = styled.div`
  /*  H0-B; */
  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  width: 100%;
  max-width: 251px;
  text-align: center;
`

export const AddressLabel = styled.div`
  /*  H0-B; */
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 4px;
  @media screen and (max-width: 786px) {
    margin-bottom: 12px;
  }
`
export const ManageAddressLabel = styled.div`
  /*  H0-B; */
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
  @media screen and (max-width: 786px) {
    margin-bottom: 6px;
  }
`
export const AddressMainWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 62vh;
  /* max-width: 312px; */
`
export const ManageAddressMainWrapper = styled.div`
  padding-top: 41px;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
`
export const AddressesLists = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 65vh;
  overflow-y: auto;
  padding-bottom: 80px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 786px) {
    /* padding-bottom: 0px; */
    /* max-height: 80vh; */
  }
`
export const ManageAddressesLists = styled(AddressesLists)`
  gap: 8px;
`
export const AddressesContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ isActive }) => (isActive ? 'var(--primary_box_fill_highlight_color)' : '')};
  svg {
    path {
      stroke: ${({ isActive }) => (isActive ? 'var(--primary_icon_highlight_color)' : '')};
    }
  }
  :hover,
  :active {
    background-color: var(--primary_box_fill_highlight_color);
    svg {
      path {
        stroke: var(--primary_icon_highlight_color);
      }
    }
  }
  img {
    width: 20px;
    height: 20px;
  }
  .circle {
    width: 16px;
    height: 16px;
  }
`
export const ManageAddressesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 8px;
  background-color: var(--primary_box_fill_highlight_color);
  svg {
    path {
      stroke: var(--primary_icon_highlight_color);
    }
  }

  img {
    width: 20px;
    height: 20px;
  }
  .circle {
    width: 16px;
    height: 16px;
  }
`
export const AddressContainer = styled.div`
  display: flex;
  gap: 19px;
  width: 100%;
`
export const AddressesButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  button {
    width: max-content;
    font-size: 12px;
  }
`
export const AddressWrapper = styled.div`
  width: 100%;
  max-width: 250px;
`
export const PincodeLocation = styled.div`
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
`
export const BasicAddress = styled.div`
  color: var(--primary_text_color_dark);
  /* H2-R */
  font-family: 'Mulish';
  font-size: 12px;
  font-weight: 400;
`
export const CityDetail = styled(BasicAddress)``
export const PersonName = styled(BasicAddress)`
  margin-top: 9px;
`
export const TopHeadings = styled.div`
  background: rgba(255, 62, 107, 0.05);
  color: var(--primary_label_active_text_color);
  font-size: 14px;
  padding: 10px 10px;
`
export const NameWrapper = styled.div`
  padding: 4px 0;

  /* :hover {
    background-color: var(--primary_box_fill_highlight_color);
  } */
`
export const ItemNameWrapper = styled.div`
  padding: 4px 0;
  text-transform: capitalize;

  /* :hover {
    background-color: var(--primary_box_fill_highlight_color);
  } */
`
export const SuggestinDataWrapper = styled.div`
  /* padding: 10px 12px 4px 20px; */
  /* padding: 10px 10px; */

  border-bottom: 1px solid var(--primary_stroke_color_light);

  /* :hover {
    background-color: var(--primary_box_fill_highlight_color);
  } */
`
export const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  svg {
    width: 12px;
    height: 12px;
  }
`
export const SearchInfoWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  /* padding: 5px 10px; */
`
export const ImageWrappers = styled.div`
  border-radius: 4px;
  overflow: hidden;
  img {
    width: 24px !important;
    height: 24px !important;
  }
`
export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const PriceRate = styled.div`
  font-size: 12px;
  color: var(--primary_label_active_text_color);
`

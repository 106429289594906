import { createContext, useContext, useEffect, useState } from 'react'
import APIS from 'constants/api'
import usePost from 'hooks/usePost'
import { updateDynamicHeaders } from 'services/axiosInstance'
// // import { LoaderContext } from 'context/loader'

interface ISessionContext {
  isSession: boolean
  setIsSession: (data: any) => void
  fetchSession: () => void
}

const defaultValues = {
  isSession: false,
  setIsSession: () => null,
  fetchSession: () => null,
}

const SessionContext = createContext<ISessionContext>(defaultValues)

const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSession, setIsSession] = useState<boolean>(false)
  // const { setLoader } = useContext(LoaderContext)

  const { mutateAsync } = usePost()

  const fetchSession = async () => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.SESSION,
        payload: { deviceId: '4c7111aa99335fcf' },
      })
      if (res) {
        localStorage.setItem('sessionId', res?.d?.sessionId)
        updateDynamicHeaders()
        setIsSession(true)
        //setLoader(false)
      }
    } catch (error: any) {
      //setLoader(false)
      return error
    }
  }

  useEffect(() => {
    fetchSession()
  }, [])

  return <SessionContext.Provider value={{ isSession, setIsSession, fetchSession }}>{children}</SessionContext.Provider>
}

export default SessionProvider
export { SessionContext }

export const useSession = () => {
  return useContext(SessionContext)
}

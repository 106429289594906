import { ReactNode, useEffect, useRef, useState, ChangeEvent } from 'react'
import TagManager from 'react-gtm-module'
import { useHistory } from 'react-router-dom'
import FooterLayout2 from 'components/FooterLayout2'
import Navbar from 'components/Navbar'
import MobileNavbar from 'components/Navbar/MobileNavbar'
import NavBottom from 'components/Navbar/MobileNavbar/navBottom'
import SearchBar from 'components/SearchBar'
import PdpMobileNavbar from 'components/Navbar/MobileNavbar/pdpNavbar'
import APIS from 'constants/api'
// import useDebounce from 'hooks/useDebounce'
import CustomAPIConnector from 'views/AutoSuggestions/customConnectors'
import {
  AboutUsRoute,
  CancellationPolicyRoute,
  CookiePolicyRoute,
  HomeRoute,
  PrivacyPolicyRoute,
  ReturnPolicyRoute,
  SearchRoute,
  TermsAndCondionRoute,
  PdpRoute,
  SellerRoute,
  CheckoutRoute,
  MyOrdersRoute,
  ReturnOrderRoute,
  ViewOrderDetailsRoute,
  FailedViewOrderDetailsRoute,
  OrderSuccessRoute,
  CancelOrderRoute,
  MyAccountRoute,
  CouponRoute,
  OrderTrackRoute,
  TrackDetailRoute,
  MyTicketRoute,
} from 'constants/routes'
import { useWishListContext } from 'context/WishlistDetailContext'
import { useHomeContext } from 'context/HomeContext'
import AnnouncementsSection from 'components/Announcements'
import useGet from 'hooks/useGet'
import usePost from 'hooks/usePost'
import { pageData } from 'utils/datalayer/pageData'
import DownArrow from 'assets/svg/DownArrow'
import SearchIcon from 'assets/svg/SearchIcon'
import ChevronForward from 'assets/svg/ChevronForward'
import BackArrow from 'assets/svg/BackArrow'

import {
  HomeMainWrapper,
  NavbarWrapper,
  ProductListWrapper,
  MobileTopBar,
  ProductCategoryList,
  NavigationContainer,
  NavigationOptionWrapper,
  NavigationHeading,
  BodyContainer,
  ProductListContainer,
} from 'styles/pages/Home'
import {
  SearchWrapper,
  SuggestinDataContainer,
  ItemNameWrapper,
  TopHeadings,
  SuggestinDataWrapper,
  MainWrapper,
  ImageWrappers,
  PriceWrapper,
  PriceRate,
  SearchInfoWrapper,
} from 'styles/views/LocationNewUser'
import { EnterIconWrapper } from 'styles/components/Navbar'
import { CategoryName } from 'styles/views/NavProductList'
import { CommonStyle } from 'styles/pages/Common'
import { useTheme } from 'context/themeContext'
import FooterLayout1 from 'components/FooterLayout1'
// import NewsletterPopup from 'components/Announcements/newsletterPopup'
// import CloseIcon from 'assets/svg/CloseIcon'
// import { Modal } from 'antd'

const PublicLayout = ({ children }: { children: ReactNode }) => {
  const [searchValue, setSearchValue] = useState('')
  const { themedata, storesData } = useTheme()
  const [scrollTriggered, setScrollTriggered] = useState({
    25: false,
    50: false,
    75: false,
    100: false,
  })
  // const debouncedSearchValue = useDebounce(searchValue, 100)
  const [suggestionResult, setsuggestionResult] = useState<any>({})
  const router = useHistory()
  const navigate = useHistory()
  const [focusedPageType, setFocusedPageType] = useState<string | null>(null)
  // const [isModalVisible, setIsModalVisible] = useState(false)
  // const customOnCancel = () => {
  //   setIsModalVisible(false)
  // }
  const [showSuggestions, setShowSuggestions] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { refetch, data } = useGet('navigation-data', `${APIS.HEADER_NAVIGATION}`)
  const navigationDetails = data?.d?.navigationDetails
  const [openCategories, setOpenCategories] = useState(Array(navigationDetails?.length).fill(false))

  const searchProductData = async () => {
    router.push(`${SearchRoute.path}/searchresults?searchby=product&q=${searchValue}`)
  }
  const { cartCount, setCartCount, setVisitorData } = useHomeContext()
  const customerId = localStorage.getItem('customerId')
  const { mutateAsync } = usePost()

  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE, address } = pageData()
  //  const addressData = address && address != 'na' ? JSON.parse(address) : ""

  const timeoutRef = useRef<any>([])
  const { setWishlistIdData, setCartIdData } = useWishListContext()

  const handleCategoryMouseEnter = (index: number) => {
    if (timeoutRef.current[index]) {
      clearTimeout(timeoutRef.current[index])
    }

    setOpenCategories((prevState) => {
      const newState = [...prevState]
      newState[index] = true
      return newState
    })
  }

  const handleInputFocus = () => {
    setShowSuggestions(true)

    const dataToPush = {
      event: 'search_icon_interaction',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      cta_text: 'open',
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })
  }
  // const handleInputFocus = () => setShowSuggestions(true)
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    setShowSuggestions(true)
    setFocusedPageType(GDL_PAGE_TYPE)
  }

  const handleCategoryMouseLeave = (index: number) => {
    timeoutRef.current[index] = setTimeout(() => {
      setOpenCategories((prevState) => {
        const newState = [...prevState]
        newState[index] = false
        return newState
      })
    }, 200) // delay time in milliseconds
  }

  const handleVisitorClick = async () => {
    // //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.VISITOR_DETAILS,
        payload: {
          customerId: customerId,
        },
      })
      if (res) {
        setVisitorData(res?.d)
        setCartCount(res?.d?.cartItemsCount)
        setCartIdData(res?.d?.productIdList)
        const wishlistedProduct: any = []
        res?.d?.wishlist_product_id?.map((item: any) => {
          wishlistedProduct[item] = true
        })
        wishlistedProduct && setWishlistIdData(wishlistedProduct)
        // //setLoader(false)
      }
    } catch (error) {
      // //setLoader(false)

      return error
    }
  }

  const handleScroll = () => {
    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight
    const documentHeight = document.documentElement.scrollHeight

    const scrollPercent = ((scrollTop + windowHeight) / documentHeight) * 100

    if (scrollPercent >= 25 && !scrollTriggered[25]) {
      triggerFunction(25)
      setScrollTriggered((prevState) => ({ ...prevState, 25: true }))
    }

    if (scrollPercent >= 50 && !scrollTriggered[50]) {
      triggerFunction(50)
      setScrollTriggered((prevState) => ({ ...prevState, 50: true }))
    }

    if (scrollPercent >= 75 && !scrollTriggered[75]) {
      triggerFunction(75)
      setScrollTriggered((prevState) => ({ ...prevState, 75: true }))
    }

    if (scrollPercent >= 99.5 && !scrollTriggered[100]) {
      triggerFunction(100)
      setScrollTriggered((prevState) => ({ ...prevState, 100: true }))
    }
  }

  const triggerFunction = (percentage: any) => {
    const scrollDataLayer = {
      event: 'page_scroll',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      scroll_percent: `${percentage}%`,
    }

    // Assuming gtm_event is your function to push events to GTM or dataLayer
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: scrollDataLayer })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollTriggered])

  const handleDataLayerPush = (category: any, item?: any, categoryHead?: string) => {
    const itemName = category?.name.toLowerCase()
    // const categoryNames = category.map((item: any) => item.name)
    const url = `${category.target.url.includes('https') ? category.target.url : '/' + category.target.url}`
    // router.push(`${category.target.url.includes('https') ? category.target.url : '/' + category.target.url}`)
    let sectionName = 'na'
    let categoryName = 'na'

    if (item) {
      sectionName = item?.name?.toLowerCase() || 'na'
    } else {
      categoryName = itemName
    }

    if (categoryHead) {
      categoryName = categoryHead?.toLowerCase()
    }

    const dataToPush = {
      event: 'top_navigation',
      page_type: GDL_PAGE_TYPE,
      User_Status: loggedInStatus,
      cta_text: itemName,
      select_type: 'header',
      section_name: sectionName,
      category: categoryName,
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })

    window.location.href = url
  }

  const customConnector = new CustomAPIConnector()

  const searchAddress = async (value: string) => {
    try {
      const result = await customConnector.onAutocomplete(value)
      setsuggestionResult(result)
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchValue !== '' && themedata?.d?.objectDetails?.general?.isAutoSearchSuggestionEnabled == 1)
        searchAddress(searchValue)
    }, 100)
    return () => clearTimeout(timeoutId)
  }, [searchValue])

  useEffect(() => {
    handleVisitorClick()
  }, [customerId])

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowSuggestions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    const pageLoadData = {
      event: '-',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      selected_location: address,
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: pageLoadData })
  }, [])

  return (
    <HomeMainWrapper>
      <CommonStyle>
        <NavbarWrapper>
          {/* {announcementData?.viewType === 'top_bar' && ( */}
          <CommonStyle className="desktop-display">
            <AnnouncementsSection viewType="top_bar" />
          </CommonStyle>

          <CommonStyle>
            <Navbar cartData={cartCount} />
          </CommonStyle>
          {/* {announcementData?.viewType === 'below_header' && ( */}
          <CommonStyle className="desktop-display">
            <AnnouncementsSection viewType="below_header" />
          </CommonStyle>

          {/* <CommonStyle> */}
          <ProductListWrapper>
            {navigationDetails &&
              navigationDetails?.map((item: any, index: number) => {
                return (
                  <ProductListContainer
                    key={index}
                    className="main"
                    onMouseEnter={() => item?.data && item?.data?.length > 0 && handleCategoryMouseEnter(index)}
                    onMouseLeave={() => item?.data && item?.data?.length > 0 && handleCategoryMouseLeave(index)}
                  >
                    {item?.data && item?.data?.length > 0 ? (
                      <ProductCategoryList>
                        <a
                          // href={`${item.target.url.includes('https') ? item.target.url : '/' + item.target.url}`}
                          className="plotch_a_link"
                          onClick={() => handleDataLayerPush(item)}
                        >
                          <CategoryName className="h1b">{item?.name}</CategoryName>
                        </a>
                        <DownArrow />
                      </ProductCategoryList>
                    ) : (
                      <ProductCategoryList>
                        <a
                          // href={`${item.target.url.includes('https') ? item.target.url : '/' + item.target.url}`}
                          className="plotch_a_link"
                          target="_self"
                          onClick={() => handleDataLayerPush(item)}

                          // onClick={() => handleDataLayerPush(item?.name)}
                        >
                          <CategoryName className="h1b">{item?.name}</CategoryName>
                        </a>
                      </ProductCategoryList>
                    )}
                    {openCategories[index] && (
                      <NavigationContainer className="NavigationContainer">
                        {item?.data?.map((subItem: any, subIndex: number) => (
                          <NavigationOptionWrapper key={subIndex}>
                            <NavigationHeading className="h1b">{subItem?.name}</NavigationHeading>
                            {subItem?.data?.map((category: any, catIdx: number) => {
                              return (
                                <ul key={catIdx}>
                                  <li className="h1r">
                                    <a
                                      // href={`${
                                      //   category.target.url.includes('https')
                                      //     ? category.target.url
                                      //     : '/' + category.target.url
                                      // }`}
                                      className="plotch_a_link"
                                      onClick={() => handleDataLayerPush(category, item, subItem?.name)}

                                      // onClick={() => handleDataLayerPush( category?.name)}
                                    >
                                      {category?.name}
                                    </a>
                                  </li>
                                </ul>
                              )
                            })}
                          </NavigationOptionWrapper>
                        ))}
                      </NavigationContainer>
                    )}
                  </ProductListContainer>
                )
              })}
          </ProductListWrapper>
          {/* </CommonStyle> */}
        </NavbarWrapper>
      </CommonStyle>
      {!(
        location.pathname == `${TermsAndCondionRoute.path}` ||
        location.pathname == `${PrivacyPolicyRoute.path}` ||
        location.pathname == `${AboutUsRoute.path}` ||
        location.pathname == `${CookiePolicyRoute.path}` ||
        location.pathname == `${ReturnPolicyRoute.path}` ||
        location.pathname == `${CheckoutRoute.path}` ||
        location.pathname == `${MyOrdersRoute.path}` ||
        location.pathname == `${ViewOrderDetailsRoute.path}` ||
        location.pathname == `${FailedViewOrderDetailsRoute.path}` ||
        location.pathname == `${OrderSuccessRoute.path}` ||
        location.pathname == `${CancelOrderRoute.path}` ||
        location.pathname == `${MyAccountRoute.path}` ||
        location.pathname == `${CouponRoute.path}` ||
        location.pathname == `${ReturnOrderRoute.path}` ||
        location.pathname == `${OrderTrackRoute.path}` ||
        location.pathname == `${TrackDetailRoute.path}` ||
        location.pathname == `${CancellationPolicyRoute.path}` ||
        location.pathname == `${MyTicketRoute.path}` ||
        location.pathname.includes(`/ticket/`)
      ) ? (
        <>
          {/* {announcementData?.viewType === 'top_bar' && ( */}
          <CommonStyle className="mobile-display-top">
            {/* <a href={announcementData?.targetUrl} target="_self" className="plotch_a_link"> */}
            <AnnouncementsSection viewType="top_bar" />
            {/* </a> */}
          </CommonStyle>

          <MobileTopBar>
            {location.pathname == `${HomeRoute.path}` ? (
              <MobileNavbar cartData={cartCount} />
            ) : (
              <PdpMobileNavbar cartData={cartCount} />
            )}

            <SearchWrapper ref={wrapperRef}>
              <SearchBar
                placeholder={`Search your item here`}
                name="search"
                className="searchMobile"
                prefix={<SearchIcon />}
                suffixIcon={
                  searchValue.length > 0 && (
                    <EnterIconWrapper
                      onClick={() => {
                        searchProductData()
                        setSearchValue('')
                        const dataToPush = {
                          event: 'search_term_entered',
                          page_type: focusedPageType,

                          // page_type: GDL_PAGE_TYPE,
                          User_Id: loggedIn,
                          User_Status: loggedInStatus,
                          search_text: searchValue,
                          option_selected: 'enter_click',
                        }

                        storesData?.d?.googleDatalayer &&
                          storesData?.d?.googleDatalayer == 1 &&
                          TagManager.dataLayer({ dataLayer: dataToPush })
                      }}
                    >
                      <BackArrow />
                    </EnterIconWrapper>
                  )
                }
                onChange={handleSearchChange}
                onFocus={handleInputFocus}
                value={searchValue}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    searchProductData()
                    setSearchValue('')
                  }
                }}
              />
              {showSuggestions && searchValue.length > 0 && (
                <>
                  {suggestionResult &&
                  (suggestionResult?.VendorResults?.length > 0 || suggestionResult?.ProductResults?.length > 0) ? (
                    <SuggestinDataContainer>
                      {suggestionResult?.VendorResults?.length > 0 && (
                        <SuggestinDataWrapper>
                          <TopHeadings>Stores</TopHeadings>
                          {suggestionResult?.VendorResults?.map((item: any, index: number) => {
                            let imgUrl =
                              item?.image?.includes('http://') || item?.image?.includes('https://')
                                ? item?.image
                                : `https://cdnaz.plotch.io/image/upload/w_118,h_118/${item?.image}`
                            if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
                              imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
                              imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
                            }

                            return (
                              <div key={index}>
                                <MainWrapper
                                  onClick={() => {
                                    navigate.push(SellerRoute.path.replace(':id', item?.sellerId))
                                    setSearchValue('')
                                    localStorage.setItem('sellerId', item?.sellerId)
                                    const dataToPush = {
                                      event: 'search_term_entered',
                                      page_type: GDL_PAGE_TYPE,
                                      User_Id: loggedIn,
                                      User_Status: loggedInStatus,
                                      search_text: searchValue,
                                      option_selected: 'enter_click',
                                    }

                                    storesData?.d?.googleDatalayer &&
                                      storesData?.d?.googleDatalayer == 1 &&
                                      TagManager.dataLayer({ dataLayer: dataToPush })
                                  }}
                                >
                                  <SearchInfoWrapper>
                                    <ImageWrappers>
                                      <img src={item?.image} />
                                    </ImageWrappers>
                                    <PriceWrapper>
                                      <ItemNameWrapper>
                                        {item?.name.length > 25
                                          ? `${item.name.toLowerCase().slice(0, 25)} ...`
                                          : item.name.toLowerCase()}
                                      </ItemNameWrapper>
                                    </PriceWrapper>
                                  </SearchInfoWrapper>

                                  <ChevronForward />
                                </MainWrapper>
                              </div>
                            )
                          })}
                        </SuggestinDataWrapper>
                      )}
                      {suggestionResult?.ProductResults?.length > 0 && (
                        <SuggestinDataWrapper>
                          <TopHeadings>Products</TopHeadings>
                          {suggestionResult?.ProductResults?.map((item: any, index: number) => {
                            let imgUrl =
                              item?.image?.includes('http://') || item?.image?.includes('https://')
                                ? item?.image
                                : `https://cdnaz.plotch.io/image/upload/w_118,h_118/${item?.image}`
                            if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
                              imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
                              imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
                            }

                            const productName = item?.name
                              .replace(/[^a-zA-Z ]/g, '')
                              .split(' ')
                              .join('-')
                            return (
                              <div key={index}>
                                <MainWrapper
                                  onClick={() => {
                                    navigate.push(
                                      PdpRoute.path
                                        .replace(':name', productName ? productName : 'product')
                                        .replace(':id', item?.id),
                                    )
                                    localStorage.setItem('productId', item?.id)
                                    localStorage.setItem('productName', item?.name)
                                    setSearchValue('')
                                    const dataToPush = {
                                      event: 'search_term_entered',
                                      page_type: GDL_PAGE_TYPE,
                                      User_Id: loggedIn,
                                      User_Status: loggedInStatus,
                                      search_text: searchValue,
                                      option_selected: 'enter_click',
                                    }

                                    storesData?.d?.googleDatalayer &&
                                      storesData?.d?.googleDatalayer == 1 &&
                                      TagManager.dataLayer({ dataLayer: dataToPush })
                                  }}
                                >
                                  <SearchInfoWrapper>
                                    <ImageWrappers>
                                      <img src={imgUrl} />
                                    </ImageWrappers>
                                    <PriceWrapper>
                                      <ItemNameWrapper>
                                        {item?.name.length > 25
                                          ? `${item.name.toLowerCase().slice(0, 25)} ...`
                                          : item.name.toLowerCase()}
                                      </ItemNameWrapper>
                                      <PriceRate> &#8377;{item?.price}</PriceRate>
                                    </PriceWrapper>
                                  </SearchInfoWrapper>

                                  <ChevronForward />
                                </MainWrapper>
                              </div>
                            )
                          })}
                        </SuggestinDataWrapper>
                      )}
                    </SuggestinDataContainer>
                  ) : null}
                </>
              )}
            </SearchWrapper>
          </MobileTopBar>
          {/* {announcementData?.viewType === 'below_header' && ( */}
          <CommonStyle className="mobile-display">
            {/* <a href={announcementData?.targetUrl} target="_self" className="plotch_a_link"> */}
            <AnnouncementsSection viewType="below_header" />
            {/* </a> */}
          </CommonStyle>
        </>
      ) : (
        ''
      )}
      <BodyContainer>{children}</BodyContainer>
      <CommonStyle className="desktop-display">
        <AnnouncementsSection viewType="bottom_bar" />
      </CommonStyle>
      {location.pathname == `${HomeRoute.path}` && <NavBottom />}
      {themedata?.d?.objectDetails?.footer?.footerLayout?.footerType == 'footer1' ? (
        <FooterLayout1 />
      ) : (
        <FooterLayout2 />
      )}
      {/* <Modal centered open={isModalVisible} footer={null} closeIcon={<CloseIcon onClick={customOnCancel} />}>
        <NewsletterPopup />
      </Modal> */}
    </HomeMainWrapper>
  )
}

export default PublicLayout

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import usePost from 'hooks/usePost'
import PublicLayout from 'components/Layouts/public'
import APIS from 'constants/api'
import { NotFoundRoute } from 'constants/routes'
import { useFilter } from 'context/FliterContext'
// import { LoaderContext } from 'context/loader'
import { Product } from 'interfaces/pages'
import { CommonStyle } from 'styles/pages/Common'
import NearStoreList from 'styles/views/NearStoreList'
import { Skeleton } from 'antd'

const StorePage = () => {
  const [nearStoreDetails, setNearStoreDetails] = useState<Product[]>([])
  // const { setLoader } = useContext(LoaderContext)
  const { filterProduct, filterSortProduct } = useFilter()
  const { mutateAsync } = usePost()
  const router = useHistory()

  const [filter, setFilter] = useState({
    page: 1,
    perPage: 48,
  })

  // useEffect(() => {
  //   if (isLoading) //setLoader(true)
  //   else //setLoader(false)
  // }, [isLoading])

  const fetchData = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.NEAR_STORE,
        payload: {
          pageId: filter.page,
          perPage: filter.perPage,
        },
      })
      if (res) {
        setNearStoreDetails(res?.d?.nearbyStores)
        //setLoader(false)
      }
    } catch (error: any) {
      //setLoader(false)
      router.push(NotFoundRoute.path)
      return error
    }
  }

  useEffect(() => {
    fetchData()
  }, [filter.page, filterProduct, filterSortProduct])

  return (
    <PublicLayout>
      <CommonStyle>
        {nearStoreDetails?.length > 0 ? (
          <NearStoreList products={nearStoreDetails} setFilter={setFilter} filter={filter} />
        ) : (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
      </CommonStyle>
    </PublicLayout>
  )
}

export default StorePage

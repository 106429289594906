import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
    <path
      fill="#121212"
      d="M7.644 5.435 6.53 7.205a.727.727 0 0 1-.164.164.706.706 0 0 1-.82-1.149l1.77-1.113a.24.24 0 0 1 .274 0 .236.236 0 0 1 .054.328Z"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M6 1.5a5.248 5.248 0 0 0-3.93 8.728c.025.028.049.056.075.082a.59.59 0 0 0 .87-.002 4.058 4.058 0 0 1 5.97 0 .59.59 0 0 0 .87.002l.075-.082A5.248 5.248 0 0 0 6 1.5Z"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M5.999 2.999v.757M9.757 6.75H9M3.007 6.75H2.25M3.884 4.633l-.535-.535M8.121 4.633l.535-.535"
    />
  </svg>
)
export default SvgComponent

import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 12 14" fill="none" {...props}>
    <path
      d="M6 7.5C7.10457 7.5 8 6.60457 8 5.5C8 4.39543 7.10457 3.5 6 3.5C4.89543 3.5 4 4.39543 4 5.5C4 6.60457 4.89543 7.5 6 7.5Z"
      stroke="var(--primary_icon_static_color)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={'1px'}
    />
    <path
      d="M11 5.5C11 10 6 13.5 6 13.5C6 13.5 1 10 1 5.5C1 4.17392 1.52678 2.90215 2.46447 1.96447C3.40215 1.02678 4.67392 0.5 6 0.5C7.32608 0.5 8.59785 1.02678 9.53553 1.96447C10.4732 2.90215 11 4.17392 11 5.5Z"
      stroke="var(--primary_icon_static_color)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={'1px'}
    />
  </svg>
)
export default SvgComponent

import { useEffect, useState, ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'
import SearchBar from 'components/SearchBar'
// import Navigation from 'views/AutoSuggestion'
import { useTheme } from 'context/themeContext'
import useDebounce from 'hooks/useDebounce'
import CustomAPIConnector from 'views/AutoSuggestions/customConnectors'

import {
  EmptyCartRoute,
  HomeRoute,
  LoginRoute,
  MyAccountRoute,
  SearchRoute,
  StoreRoute,
  WistlistRoute,
  PdpRoute,
} from 'constants/routes'
import LocationNewUser from 'views/LocationNewUser'
import SavedAddresses from 'views/SavedAddresses'
import LocationIcon from 'assets/svg/LocationIcon'
import DownArrow from 'assets/svg/DownArrow'
import CartIcon from 'assets/svg/CartIcon'
import ListIcon from 'assets/svg/ListIcon'
import StorefrontIcon from 'assets/svg/StorefrontIcon'
import HeartIcon from 'assets/svg/HeartIcon'
import PersonIcon from 'assets/svg/PersonIcon'
import SearchIcon from 'assets/svg/SearchIcon'

import {
  NavbarContainer,
  LeftContainer,
  RightContainer,
  LocationWrapper,
  NavbarOptions,
  Blank,
} from 'styles/components/Navbar'

import {
  SearchWrapper,
  SuggestinDataContainer,
  NameWrapper,
  TopHeadings,
  SuggestinDataWrapper,
  MainWrapper,
  ImageWrappers,
  PriceWrapper,
  PriceRate,
} from 'styles/views/LocationNewUser'

const SuggesstionNav = () => {
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 100)
  const [suggestionResult, setsuggestionResult] = useState<any>({})

  const [openAddress, setOpenAddress] = useState(false)
  const address: any = localStorage.getItem('location')
  const postcode: any = localStorage.getItem('postcode')
  const currentAddress = JSON.parse(address)
  const customerId = localStorage.getItem('customerId')
  const router = useHistory()
  const { themedata, storesData } = useTheme()

  const headerLogo = themedata?.d?.objectDetails?.header?.siteLogo?.logoImage
  const showBydefaultLocation = themedata?.d?.objectDetails?.general?.showBydefaultLocation?.showBydefaultLocation
    ? themedata?.d?.objectDetails?.general?.showBydefaultLocation?.showBydefaultLocation
    : ''
  const ishyperlocalEnable = storesData?.d?.isHyperlocalEnabled ? storesData?.d?.isHyperlocalEnabled : 0
  const searchProductData = async () => {
    router.push(`${SearchRoute.path}/searchresults?searchby=product&q=${searchValue}`)
  }
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const toggleAddressDrawer = (newAddress: boolean) => () => {
    setOpenAddress(newAddress)
  }

  const navigate = useHistory()

  const handleLocationClick = () => {
    if (localStorage.getItem('customerId') && localStorage.getItem('sessionId')) {
      toggleAddressDrawer(true)()
    } else {
      toggleDrawer(true)()
    }
  }
  const handleLogin = () => {
    if (!localStorage.getItem('customerId')) {
      navigate.push(LoginRoute.path)
    } else {
      navigate.push(MyAccountRoute.path)
    }
  }

  useEffect(() => {
    if (!customerId && !address) {
      showBydefaultLocation == 1 && ishyperlocalEnable == 1 && setOpen(true)
    }
  }, [address, customerId, showBydefaultLocation, ishyperlocalEnable])

  useEffect(() => {
    const searchAddress = async (value: string) => {
      try {
        const customConnector = new CustomAPIConnector()
        const result = await customConnector.onAutocomplete(value)
        setsuggestionResult(result)
      } catch (error) {
        return error
      }
    }

    if (debouncedSearchValue) {
      if (themedata?.d?.objectDetails?.general?.isAutoSearchSuggestionEnabled == 1) {
        searchAddress(debouncedSearchValue)
      }
    }
  }, [debouncedSearchValue])

  return (
    <NavbarContainer>
      <LeftContainer>
        {headerLogo ? <img src={headerLogo} onClick={() => router.push(HomeRoute.path)} loading="lazy" /> : <Blank />}
        <SearchWrapper>
          {/* <Navigation /> */}
          <SearchBar
            placeholder={`Search items`}
            name="search"
            className="searchMobile"
            prefix={<SearchIcon />}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            value={searchValue}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                searchProductData()
              }
            }}
          />
          {searchValue.length > 2 && (
            <>
              {suggestionResult ? (
                <SuggestinDataContainer>
                  <SuggestinDataWrapper>
                    <TopHeadings>Vendors</TopHeadings>
                    {suggestionResult?.VendorResults?.map((item: any, index: number) => {
                      return (
                        <div key={index}>
                          <MainWrapper>
                            <ImageWrappers>
                              <img src={item?.image} />
                            </ImageWrappers>
                            <PriceWrapper>
                              <NameWrapper>{item?.name}</NameWrapper>
                            </PriceWrapper>
                          </MainWrapper>
                        </div>
                      )
                    })}
                  </SuggestinDataWrapper>
                  <SuggestinDataWrapper>
                    <TopHeadings>Products</TopHeadings>
                    {suggestionResult?.ProductResults?.map((item: any, index: number) => {
                      const productName = item?.name
                        .replace(/[^a-zA-Z ]/g, '')
                        .split(' ')
                        .join('-')
                      return (
                        <div key={index}>
                          <MainWrapper
                            onClick={() => {
                              navigate.push(
                                PdpRoute.path
                                  .replace(':name', productName ? productName : 'product')
                                  .replace(':id', item?.id),
                              )
                              localStorage.setItem('productId', item?.id)
                            }}
                          >
                            <ImageWrappers>
                              <img src={item?.image} />
                            </ImageWrappers>
                            <PriceWrapper>
                              <NameWrapper>{item?.name ? `${item?.name?.slice(0, 40)} ...` : item?.name}</NameWrapper>
                              <PriceRate> &#8377;{item?.price}</PriceRate>
                            </PriceWrapper>
                          </MainWrapper>
                        </div>
                      )
                    })}
                  </SuggestinDataWrapper>
                </SuggestinDataContainer>
              ) : null}
            </>
          )}
        </SearchWrapper>
      </LeftContainer>
      <RightContainer>
        {(themedata?.d?.objectDetails?.general?.showOndcLocation?.showOndcLocation && ishyperlocalEnable == 1)(
          <LocationWrapper onClick={handleLocationClick}>
            <LocationIcon />
            {currentAddress?.address?.postcode || postcode ? (
              <>
                {currentAddress?.address?.postcode}
                {postcode}
              </>
            ) : (
              <>Select Location</>
            )}
            <DownArrow />
          </LocationWrapper>,
        )}
        <NavbarOptions>
          <ListIcon />
          {!themedata?.d?.objectDetails?.header?.storeDisabled && (
            <StorefrontIcon
              onClick={() => {
                navigate.push(StoreRoute.path)
              }}
            />
          )}
          <HeartIcon
            onClick={() => {
              navigate.push(WistlistRoute.path)
            }}
          />

          <PersonIcon onClick={handleLogin} />
          <CartIcon
            onClick={() => {
              navigate.push(EmptyCartRoute.path)
            }}
          />
        </NavbarOptions>
      </RightContainer>
      <Drawer
        open={open}
        anchor="right"
        sx={{
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '100% ', xl: 'auto' },
          },
        }}
      >
        {<LocationNewUser onClose={toggleDrawer(false)} />}
      </Drawer>
      <Drawer open={openAddress} onClose={toggleAddressDrawer(false)} anchor="right">
        {<SavedAddresses onClose={toggleAddressDrawer(false)} />}
      </Drawer>
    </NavbarContainer>
  )
}

export default SuggesstionNav

// import React, { useState } from 'react'
import VegImage from 'assets/images/veg-img.png'
import PizzaImg from 'assets/images/pizza.png'
// import NonVegImage from 'assets/images/non-veg.png'
import {
  MobileMainWrapperList,
  DetailsWrap,
  RecomendedContainer,
  ImageWrapper,
  ImageWrap,
  ButtonWrap,
} from 'styles/views/StoreHyperlocalView'
import DownArrow from 'assets/svg/DownArrow'
import Button from 'components/Button'

const MobileStoreHyperLocalView = () => {
  const items = [
    {
      name: 'Veggie Delight Pizza',
      price: '129',
      originalPrice: '219',
      discount: '41%',
      image: PizzaImg,
      type: VegImage,
    },
    {
      name: 'Veggie Delight Pizza',
      price: '129',
      originalPrice: '219',
      discount: '41%',
      image: PizzaImg,
      type: VegImage,
    },
  ]

  return (
    <MobileMainWrapperList>
      <RecomendedContainer>
        <label className="ultra-bold mobile-items">Your search results for “pizza”</label>
      </RecomendedContainer>
      {items.map((item: any, index: number) => (
        <div className="content-item" key={index}>
          <div className="item-details">
            <ImageWrap>
              <img src={item.type} />
            </ImageWrap>

            <div className="h1r">{item.name}</div>
            <div className="discount-details">
              {/* {productDetails?.regularPrice === productDetails?.discountedPrice ? ( */}
              {/* <div className="after-discount h1b">
              <span> &#8377; </span>
              {price}
            </div> */}
              {/* ) : ( */}
              <>
                <div className="after-discount h1b">
                  <span> &#8377; </span>
                  {item.price}
                </div>
                <div className="before-discount h3r">
                  <span> &#8377; </span>
                  {item.originalPrice}
                </div>
              </>
              {/* )} */}
              {/* {productDetails?.discountPercentage > 0 ? ( */}
              <div className="discount-percentage h4b">
                {item.discount}% OFF
                {/* {discount} % OFF` : null} */}
              </div>
              {/* ) : (
              ''
            )} */}
            </div>
            {/* <div className="price h1r">
            <span>{price}</span> <span className="original-price">{originalPrice}</span>{' '}
            <span className="discount">{discount} OFF</span>
          </div> */}

            <DetailsWrap className="h2b">
              View Details <DownArrow />
            </DetailsWrap>
          </div>
          <div className="quantity-wrapper">
            <ImageWrapper>
              <img src={item.image} />
            </ImageWrapper>

            <ButtonWrap>
              <Button label="Add" variant="outline" type="submit" className="small" />
            </ButtonWrap>
            <div className="h3r">Customizable</div>
          </div>
        </div>
      ))}
    </MobileMainWrapperList>
  )
}

export default MobileStoreHyperLocalView

import { useContext, useEffect } from 'react'
import { SnackBarContext } from 'context/SnackBarContext'
import { OrderSuccessRoute } from 'constants/routes'
import APIS from 'constants/api'
import usePost from 'hooks/usePost'
// import { LoaderContext } from 'context/loader'
// import { useHistory } from 'react-router-dom'

declare global {
  interface Window {
    Razorpay: any
  }
}

const RazorpayPayment = ({ prepaidData }: any) => {
  const { mutateAsync } = usePost()
  const razorpayData = prepaidData?.d
  const { setShowSnackBar } = useContext(SnackBarContext)
  const d = new Date()
  const time = d.getTime()
  const expirationDate = new Date(time + 60 * 20)
  const expires = 'expires=' + expirationDate.toUTCString()

  // this is replaced
  // function loadScript(src: any) {
  //   return new Promise((resolve) => {
  //     const script = document.createElement('script')
  //     script.src = src
  //     script.onload = () => {
  //       resolve(true)
  //     }

  //     script.onerror = () => {
  //       resolve(false)
  //     }

  //     document.body.appendChild(script)
  //   })
  // }

  //this is new one added
  function loadScript(src: string) {
    return new Promise((resolve, reject) => {
      const existingScript = document.getElementById('razorpay-script')

      if (existingScript) {
        resolve(true)

        return
      }

      const script = document.createElement('script')

      script.id = 'razorpay-script'

      script.src = src

      script.onload = () => resolve(true)

      script.onerror = () => reject(new Error('Failed to load script'))

      document.body.appendChild(script)
    })
  }

  async function displayRazorpay() {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    const options = {
      key: razorpayData?.requestParams?.key,
      amount: razorpayData?.requestParams?.amount,
      currency: 'INR',
      name: razorpayData?.requestParams?.name,
      description: razorpayData?.requestParams?.description,
      image: razorpayData?.requestParams?.image,
      // callback_url: window.location.origin+process.env.REACT_APP_RAZORPAY_REDIRECT_PAGE + '?orderId='+razorpayData?.requestParams?.order_id,
      order_id: razorpayData?.requestParams?.order_id, // You should provide a valid order ID
      // redirect: true,
      prefill: {
        contact: razorpayData?.requestParams?.prefill?.contact, // Prefill the customer's phone number
      },
      config: {
        display: {
          blocks: {
            utib: {
              instruments: [
                {
                  method: 'upi',
                  apps: ['google_pay', 'phonepe'],
                  flows: ['qr'],
                },
              ],
            },
          },
          sequence: ['block.utib', 'block.other'],
          preferences: {
            show_default_blocks: true, // Should Checkout show its default blocks?
          },
        },
      },
      handler: async (res: any) => {
        //setLoader(true)
        if (res?.razorpay_payment_id && res?.razorpay_order_id && res?.razorpay_signature) {
          res['orderId'] = razorpayData?.orderId
          res['udf3'] = razorpayData?.orderId
          res['paymentGateway'] = 'razorpay'

          try {
            const orderSuccessResponse = await mutateAsync({
              url: `${APIS.SUCCESS_CALLBACK}`,
              payload: res,
            })
            if (orderSuccessResponse) {
              //setLoader(false)
              document.cookie = 'success_order_id=' + razorpayData?.orderId + ';' + expires
              setShowSnackBar({ open: true, message: `${prepaidData?.m}`, severity: '' })
              window.location.href = OrderSuccessRoute.path
            }
          } catch (error) {
            // console.error(error)
          }
          // navigator.push(OrderSuccessRoute.path)
        }
      },

      onError: async (error: any) => {
        const error_res: any = {}
        error_res['orderId'] = razorpayData?.orderId
        error_res['udf3'] = razorpayData?.orderId
        error_res['paymentGateway'] = 'rzorpay'

        // console.error('Payment failed', error)
        if (error.error) {
          error_res['error'] = error.error
          try {
            //setLoader(true)
            const orderFailureResponse = await mutateAsync({
              url: `${APIS.FAILURE_CALLBACK}`,
              payload: error_res,
            })
            if (orderFailureResponse) {
              //setLoader(false)
              document.cookie = 'x-plotch-error= Payment Error: ' + error.error.description + ';' + expires
              setShowSnackBar({ open: true, message: `Payment failed: ${error.error.description}`, severity: 'error' })
              window.location.reload()
            }
          } catch (error) {
            // console.error(error)
          }
        } else {
          setShowSnackBar({ open: true, message: `Payment failed: Something Went Wrong`, severity: 'error' })
          window.location.reload()
        }
      },
      modal: {
        escape: false,
        ondismiss: function () {
          // console.log('dismiss')
          window.location.reload()
          // window.location.reload
        },
      },
    }

    // Now that we've confirmed the script is loaded, we can safely use Razorpay
    const paymentObject = new window.Razorpay(options)
    paymentObject.on('payment.failed', function (response: any) {
      const { error } = response
      setShowSnackBar({ open: true, message: `Payment failed: ${error.description}`, severity: 'error' })
      window.location.reload()
    })
    paymentObject.open()
  }

  //this is replaced
  // useEffect(() => {
  //   if (prepaidData) {
  //     displayRazorpay()
  //   }
  // }, [prepaidData])
  // Uncomment and update the useEffect hook as needed

  //this is new one added
  useEffect(() => {
    if (prepaidData) {
      displayRazorpay()
    }

    return () => {
      // Clean up script on component unmount
      const script = document.getElementById('razorpay-script')
      if (script) {
        document.body.removeChild(script)
      }
    }
  }, [prepaidData])

  return null
}

export default RazorpayPayment

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      stroke="#FF3E6B"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M9.688 2.5a7.188 7.188 0 1 0 .002 14.376A7.188 7.188 0 0 0 9.688 2.5Z"
    />
    <path
      stroke="#FF3E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M8.594 8.595h1.25v4.533"
    />
    <path stroke="#FF3E6B" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={1.002} d="M8.125 13.282h3.44" />
    <path fill="#FF3E6B" d="M9.691 5.079a1.019 1.019 0 1 0 0 2.037 1.019 1.019 0 0 0 0-2.037Z" />
  </svg>
)
export default SvgComponent

import { Modal } from 'antd'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  &.ant-modal {
    border: 1.1px solid var(--gray-200, #e5e7eb);
    box-shadow: 0px 1.1px 2.19px 0px #00000014;
    border-radius: 12px;
    padding: 0;
  }
  .ant-modal-title {
    color: #000;
    padding-bottom: 13px;
  }

  .ant-modal-footer {
    display: none;
  }

  &.ant-modal.raiseTicketModal .ant-modal-footer {
    display: flex !important;
    align-items: center;
    button {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 700;
    }
    .ant-btn-default {
      background: var(--secondary_button_active_fill_color);
      color: var(--primary_text_color_dark);
      border: 1px solid var(--primary_stroke_color_light);
    }
    .ant-btn-primary {
      background: var(--primary_button_active_fill_color);
      color: var(--primary_text_color_light);
    }
  }
  .ant-modal-close {
    /* color: #ffffff !important; */
    background: #ffffff !important;
    /* top: -15px; */
    /* border-radius: 50% !important; */
    /* right: -15px; */
  }
  .ant-modal-content {
    /* max-width: max-content !important; */
  }
  @media (max-width: 768px) {
    .ant-modal-content {
      max-width: 100% !important;
      border-radius: 0px;
      height: 99.7vh;
      padding: 0;
    }

    &.ant-modal {
      height: 100%;
      margin: 0;
      max-width: 100vw;
      border-radius: 0;
    }
    .commentModal&.ant-modal {
      transform: translateY(-100px);
    }

    .ant-modal-title {
      color: #000;
      padding: 16px 13px;
    }
  }
`

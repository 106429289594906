import styled from 'styled-components'

export const TicketWrapper = styled.div`
  border-radius: 8px;
  background-color: var(--primary_box_fill_highlight_color);
  padding: 10px 16px;
  margin-top: 12px;
  a.links {
    text-decoration: none;
    color: unset;
  }
`
export const TicketDetailWrapper = styled.div`
  .padding_full_page {
    padding: 17px 13px;
  }
  .replyCloseBtns {
    border: none;
  }

  @media screen and (max-width: 786px) {
    .replyCloseBtns {
      border: 1px solid var(--primary_stroke_color_light);
    }
  }
`
export const TicketSection = styled.div`
  padding: 17px 22px;
  margin-top: 17px;
  height: 80vh;
  overflow-y: auto;
`
export const CommentWrapper = styled.div`
  border-radius: var(--small, 8px);
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  padding: 10px 16px;
  margin-top: 16px;
`
export const TicketStatus = styled.div`
  display: flex;
  .forwordIcon {
    margin-left: auto;
  }
  .open {
    color: var(--primary_success_color);
  }
  .closed {
    color: var(--primary_label_active_text_color);
  }
`

export const TicketStatusIcon = styled.div`
  color: var(--primary_success_color);
  margin-left: 20px;
`

export const TicketDetails = styled.div`
  margin-top: 10px;
  .date-row {
    margin-top: 3px;
  }
  .row-divider {
    height: 1px;
    background: #c7c6c6;
    margin-top: 6px;
  }
`
export const TicketDeatiledSection = styled.div`
  margin-top: 14px;
  display: flex;
  gap: 7px;
  .ticket-details {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  img {
    width: 48px;
    font-size: 10px;
  }
  .commentDescription,
  .ticketDescription {
    width: 100%;
    overflow: hidden;
    height: auto;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    unicode-bidi: normal;
    max-height: 6em;
    white-space: normal;
    word-wrap: break-word;
    line-break: anywhere;
  }
  .readMoreOpen {
    display: unset !important;
    max-height: fit-content;
  }
`
export const CommentUserSection = styled.div`
  .commentBy {
    color: var(--primary_text_color_darkgrey);
  }
`
export const ReadMoreSection = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row-reverse;
  top: 10px;
  padding-bottom: 9px;
  .readMore {
    transform: rotate(90deg);
  }
  .readMoreClose {
    display: unset !important;
  }
  .readLess {
    transform: rotate(-90deg) !important;
  }
`
export const TicketAgentTag = styled.div`
  background-color: #ff6746;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: 16px;
  width: 120px;
`
export const TicketListWrapper = styled.div``
export const TagToRight = styled.div`
  margin-left: auto;
`

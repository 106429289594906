import styled from 'styled-components'

export const LoginPageWrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 1; */
`
export const TopBar = styled.div`
  min-height: 46px;
  padding: 9px 20px;
  display: flex;
  background-color: #fff;
  box-shadow: 0 3px 15px 0 rgb(0, 0, 0, 0.11);

  img {
    width: 160px;
    height: 46.135px;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
export const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--primary_background_color); */
  /* height: 100vh; */
  height: 100vh;
  /* padding: 20px 0 20px; */
  @media (max-width: 768px) {
    background-color: unset;
    height: unset;
  }
`
export const BodyWrappers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--primary_background_color); */
  /* height: 100vh; */
  padding: 20px 0 20px;
  @media (max-width: 768px) {
    background-color: unset;
    height: unset;
    padding: 0px 0 20px;
  }
`
export const TermsBodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary_box_fill_color);

  @media (max-width: 768px) {
    background-color: unset;
    height: unset;
  }
`
export const LoginPageContainer = styled.div`
  background-color: var(--primary_box_fill_color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  min-height: 70%;
  border: 1px solid var(--primary_stroke_color_light);

  @media (max-width: 768px) {
    gap: 160px;
    justify-content: unset;
    align-items: unset;
    padding: 20px;
    border: none;
  }
  @media (max-height: 600px) {
    min-height: 85%;
  }
`
export const OtpPageContainer = styled(LoginPageContainer)`
  @media (max-width: 768px) {
    gap: 70px;
  }
`
export const BackWrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    width: max-content;
    display: flex;
    align-items: center;
    gap: 4px;
    text-transform: uppercase;
    color: var(--primary_text_color_dark);
    font-family: 'Mulish';
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
`
export const BackWrapperDisplay = styled(BackWrapper)`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  @media (max-width: 768px) {
    padding: 10px 0 0 0px;
  }
`
export const BackAddressWrapperDisplay = styled(BackWrapper)`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`
export const NotBackWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  text-transform: capitalize;
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`
export const BackWrappers = styled.div`
  /* display: none; */
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    text-transform: capitalize;
    color: var(--primary_text_color_dark);
    font-family: 'Mulish';
    font-size: 14px;
    font-weight: 700;
  }
`

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`
export const OtpMainContainer = styled(LoginContainer)`
  gap: unset;
`
export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* text-transform: capitalize; */
  button {
    .MuiButtonBase-root-MuiButton-root {
      background-color: var(--primaryButtonActiveFillColor);
    }
  }
`
export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const LoginHeading = styled.div`
  width: 100%;
  color: var(--primary_text_color_dark);
  /* H1-B */
  font-family: 'Mulish';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`
export const OtpHeading = styled(LoginHeading)``
export const OtpInputWrapper = styled.div`
  display: flex;

  gap: 16px;
  input {
    width: 32px;
    border: 3px;
    text-align: center;
  }
`
export const OtpInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  input {
    width: 32px;
    height: 40px;
    border: 3px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid var(--primary_stroke_color);
  }
`

export const OtpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`
export const LoginFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  .small {
    width: 160px;
  }
`
export const TextWrapper = styled.div<{ isError?: boolean }>`
  min-height: 62px;
  position: relative;
  .errorBlock.mobileInput {
    border-color: var(--primary_caution_color);
  }
  .addressFields {
    display: none;
    color: var(--primary_text_color_dark) !important;
  }
  input {
    height: 40px;
    background: #fff;
  }
  .mobileInput {
    width: unset;
  }
  .addressFields {
    display: none;
    color: var(--primary_text_color_dark);
  }
  .errorBlock + .addressFields {
    color: var(--primary_caution_color) !important;
  }
  .addressFields.showLable {
    display: block;
  }
`
export const TextWrappers = styled.div<{ isError?: boolean }>`
  min-height: 60px;
  position: relative;
  input {
    border: 1px solid
      ${({ isError }) => (isError ? 'var(--primary_caution_color)' : 'var(--primary_stroke_color_light);')};
    border-radius: 4px;
    background: var(--primary_box_fill_color);
  }
  .ant-input-outlined:hover,
  .ant-input-outlined:focus {
    border: 1px solid
      ${({ isError }) => (isError ? 'var(--primary_caution_color)' : 'var(--primary_stroke_color_light);')} !important;
    border-radius: 4px;
    background: var(--primary_box_fill_color);
  }

  ::placeholder {
    color: var(--primary_text_color_dark);
    font-family: 'Mulish';
    font-size: 12px;
    font-weight: 700;
  }
  .addressFields,
  .profileFields {
    color: ${({ isError }) =>
      isError ? 'var(--primary_caution_color)' : 'var(--primary_text_color_dark);'} !important;
  }
  .addressFields {
    display: none;
  }
  .addressFields.showLable {
    display: block;
  }
`
export const TextLabel = styled.span`
  position: absolute;
  top: -8px;
  left: 8px;
  color: var(--primary_stroke_color_light);
  background: #fff;
  padding: 0px 3px;
`
export const ErrorMessage = styled.div`
  margin-top: 4px;
  color: var(--primary_caution_color);
  width: 100%;
  display: flex;
  align-items: center;
`
export const ValidMessage = styled.div`
  margin-top: 4px;
  color: var(--primary_success_color);
  width: 100%;
  display: flex;
  align-items: center;
`
export const OtpText = styled.div`
  color: var(--primary_text_color_dark);
  text-align: center;
  /* H1-B */
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
`
export const ResendText = styled.div`
  color: var(--primary_text_link_color);
  text-align: center;
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`
export const Counter = styled.div`
  color: var(--primary_text_color_dark);
  text-align: center;
  /* H3-B */
  font-family: 'Mulish';
  font-size: 10px;
  font-weight: 700;
`
export const MessageSent = styled.div`
  color: var(--primary_success_color);
  /* H3-B */
  font-family: 'Mulish';
  font-size: 10px;
  font-weight: 700;
`
export const ChangeNumber = styled.div`
  color: var(--primary_caution_color);
  /* H3-B */
  font-family: 'Mulish';
  font-size: 10px;
  font-weight: 700;
  cursor: pointer;
`
export const PolicyContainer = styled.div`
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 10px;
  font-weight: 400;

  span {
    color: var(--primary_label_active_text_color);
    margin: 0 4px;
    font-weight: 700;
    cursor: pointer;
  }
`

export const OtpWrapper = styled.div`
  min-height: 60px;
  .ant-otp {
    column-gap: 30px;
  }

  .ant-input-outlined:hover,
  .ant-input-outlined:focus {
    border-color: var(--primary_stroke_color);
    border-radius: 4px;
    background: var(--primary_box_fill_color);
    box-shadow: unset;
  }
`
export const CountryCodeWrapper = styled.div`
  padding: 17px 21px;
`
export const CountryTopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 37px;
`
export const Label = styled.div`
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 700;
`
export const CountryCode = styled(Label)``
export const SvgWrapper = styled.div`
  svg {
    cursor: pointer;
  }
`
export const CodeWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
`
export const CodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--primary_stroke_color_light);
`

import { Steps } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import {
  TrackOrderStepperWrapper,
  StepperWrapper,
  CourierInformation,
  TrackDetailsWrapper,
} from 'styles/views/TrackOrderDetail'
import RightArrow from 'assets/svg/RightArrow'

const { Step } = Steps

type StepStatus = 'wait' | 'process' | 'finish' | 'error'

const TrackOrderStepper = ({ trackData, orderData }: any) => {
  const stepperDetails = orderData?.shipments[0]

  const createdAt = stepperDetails?.createdAt || 'NA'
  const shippedOn = stepperDetails?.shippedOn || 'NA'
  const deliveredOn = stepperDetails?.deliveredOn || 'NA'
  const steps: { title: string; description: string; status: StepStatus }[] = [
    {
      title: 'Order created',
      description: createdAt !== 'NA' ? createdAt : 'Not available',
      status: createdAt !== 'NA' ? 'finish' : 'wait',
    },
    // {
    //   title: 'Order packed',
    //   description: 'This could be dynamic if needed',
    //   status: trackData?.status === 'Packed' ? 'finish' : 'wait',
    // },
    {
      title: 'Order shipped',
      description: shippedOn !== 'NA' ? shippedOn : 'Not yet shipped',
      status: shippedOn !== 'NA' ? 'finish' : 'wait',
    },
    {
      title: 'Order delivered',
      description: deliveredOn !== 'NA' ? deliveredOn : 'Not yet delivered',
      status: deliveredOn !== 'NA' ? 'finish' : 'wait',
    },
  ]
  return (
    <TrackOrderStepperWrapper>
      <CourierInformation>
        <label className="h1r">
          Courier Name: {trackData?.courier_name ? trackData?.courier_name : 'Not available'}
        </label>
        <TrackDetailsWrapper>
          <label className="h2r">Tracking No: {trackData?.trackingId ? trackData?.trackingId : 'Not available'}</label>
          <div className=" h3r detail">
            {trackData?.trackingUrl && (
              <a href={trackData?.trackingUrl} target="_blank" className="plotch_a_link h3r" rel="noreferrer">
                More <RightArrow />
              </a>
            )}
          </div>
        </TrackDetailsWrapper>
      </CourierInformation>
      <StepperWrapper>
        {/* <Steps direction="vertical" current={steps.findIndex(step => step.status === 'wait')}> */}
        <Steps direction="vertical">
          {steps?.map((step, index) => (
            <Step
              key={index}
              title={<div className="h0b stepper-title">{step.title}</div>}
              description={<div className="h3r description-color">{step.description}</div>}
              status={step.status}
              icon={<CheckCircleOutlined />}
            />
          ))}
        </Steps>
      </StepperWrapper>
    </TrackOrderStepperWrapper>
  )
}

export default TrackOrderStepper

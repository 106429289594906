// import { CurrentLocationWrapper, LocationLabel } from 'styles/components/Button'
// import CompassIcon from 'assets/svg/CompassIcon'

// const CurrentLocation = () => {
//   return (
//     <>
//       <CurrentLocationWrapper>
//         <CompassIcon />

//         <LocationLabel>Use Current Location</LocationLabel>
//       </CurrentLocationWrapper>
//     </>
//   )
// }

// export default CurrentLocation

import { CurrentLocationWrapper, LocationLabel } from 'styles/components/Button'
import CompassIcon from 'assets/svg/CompassIcon'

const CurrentLocation = () => {
  return (
    <>
      <CurrentLocationWrapper>
        <CompassIcon />

        <LocationLabel>Use Current Location</LocationLabel>
      </CurrentLocationWrapper>
    </>
  )
}

export default CurrentLocation

import OtpDetailView from 'views/OtpDetailView'
import PrivateLayout from 'components/Layouts/private'

const OtpPage = () => {
  return (
    <PrivateLayout>
      <OtpDetailView />
    </PrivateLayout>
  )
}

export default OtpPage

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={49} height={48} fill="none" {...props}>
    <circle cx={24.5} cy={24} r={24} fill="#FF3E6B" fillOpacity={0.05} />
    <path
      fill="#FF3E6B"
      d="M34.633 30.467 32.7 28.533a8.473 8.473 0 0 0 .85-2.133 9.583 9.583 0 0 0 .283-2.333c0-2.578-.91-4.778-2.733-6.6-1.822-1.822-4.022-2.734-6.6-2.734-.8 0-1.578.095-2.333.284a8.475 8.475 0 0 0-2.134.85L18.1 13.933a14.649 14.649 0 0 1 2.417-1.166 11.406 11.406 0 0 1 2.65-.634V9.467h2.666v2.666c2.778.312 5.161 1.462 7.15 3.45 1.99 1.99 3.14 4.373 3.45 7.15H39.1V25.4h-2.667a11.402 11.402 0 0 1-.633 2.65c-.311.833-.7 1.639-1.167 2.417Zm-11.466 8.2V36c-2.778-.311-5.162-1.461-7.15-3.45-1.99-1.989-3.14-4.372-3.45-7.15H9.9v-2.667h2.667c.11-.933.322-1.816.633-2.65.311-.833.7-1.638 1.167-2.416l-4-4 1.866-1.867 24.534 24.533-1.934 1.867-3.933-4c-.778.467-1.583.856-2.417 1.167a11.4 11.4 0 0 1-2.65.633v2.667h-2.666ZM24.5 33.4c.8 0 1.578-.094 2.333-.283a8.475 8.475 0 0 0 2.134-.85L16.3 19.6a8.476 8.476 0 0 0-.85 2.133 9.584 9.584 0 0 0-.283 2.334c0 2.578.91 4.777 2.733 6.6 1.822 1.822 4.022 2.733 6.6 2.733Z"
    />
  </svg>
)
export default SvgComponent

import styled from 'styled-components'

export const SortByMobileWrapper = styled.div`
  padding: 24px;
  .sort-mobile {
    padding-top: 20px;
  }
  .radio-name {
    color: var(--primary_text_color_darkgrey) !important;
  }
  button {
    height: 48px;
    border-radius: 8px;
  }
`
// export const TopWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px;
//   border-bottom: 1px solid var(--primary_stroke_color_light); ;
// `
// export const DetailsWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 6px;
//   padding: 10px 0;
// `

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  /* border-bottom: 1px solid var(--primary_stroke_color_light); ; */
`
export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px 0;
  border-bottom: 1px solid var(--primary_stroke_color_light); ;
`
export const SortOptions = styled.div`
  padding: 6px 0;
`

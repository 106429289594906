import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import APIS from 'constants/api'
import { useTheme } from 'context/themeContext'
import { PdpRoute } from 'constants/routes'
import useGet from 'hooks/useGet'
import { SnackBarContext } from 'context/SnackBarContext'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
// // import { LoaderContext } from 'context/loader'

import TrashIcon from 'assets/svg/TrashIcon'

import {
  WishlistProductSectionWrapper,
  WishListWrapper,
  ProductWrapper,
  ProductDetailsWrapper,
} from 'styles/components/ListProducts'
import { WishlistLabel, WishListMainWrapper } from 'styles/pages/SearchPage'

import usePost from 'hooks/usePost'
import { useWishListContext } from 'context/WishlistDetailContext'
import { NoDataDiv } from 'styles/pages/Common'
import { useHomeContext } from 'context/HomeContext'
import { Typography } from 'antd'

interface IProductDetails {
  productId: string
  productName: string
  imgUrl: string
  categoryName: string
  regularPrice: number
  discountedPrice: number
  originalPrice: number
  discountPercentage: number
  vendorName: string
}

const WishListProducts = () => {
  const { storesData } = useTheme()

  const { setWishlistIdData } = useWishListContext()

  // const { setLoader } = useContext(LoaderContext)
  const { setShowSnackBar } = useContext(SnackBarContext)
  const { mutateAsync } = usePost()
  const navigate = useHistory()
  const customerId = localStorage.getItem('customerId')
  const { setCartCount, cartCount } = useHomeContext()
  const googledatalayer = storesData?.d?.googleDatalayer
  const [noData, setNoData] = useState(0)
  const { loggedIn, loggedInStatus, address, GDL_PAGE_TYPE } = pageData()
  const { refetch, data } = useGet(
    'wishlist-data',
    `${APIS.GET_WISHLIST_DETAILS}?customerId=${localStorage.getItem('customerId')}`,
  )
  const products = data?.d?.products ? data?.d?.products : []
  // const [productList, setProductList] = useState(products)

  const handleRemoveWishlistClick = async (productId: any, flag: any = 0) => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.REMOVE_FROM_WISHLIST,
        payload: {
          customerId: customerId,
          productId: productId,
        },
      })
      if (res) {
        //setLoader(false)

        !flag && setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
        // fsetProductList(res?.d?.products)
        refetch()
      }
    } catch (error) {
      //setLoader(false)

      return error
    }
  }

  const handleMoveToCart = async (productId: string) => {
    await addToCart(productId)
    handleRemoveWishlistClick(productId, 1)
  }

  const addToCart = async (productId: string) => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.ADD_TO_CART,
        payload: {
          customerId: customerId ? customerId : false,
          products: [
            {
              productId: productId,
              qty: 1,
              addons: [],
            },
          ],
          flag: 1,
        },
      }).then()
      if (res) {
        //setLoader(false)
        setCartCount(cartCount + 1)
        setShowSnackBar({ open: true, message: `Moved To Cart`, severity: '' })
        const productDetails = data?.d?.products?.find((product: IProductDetails) => product.productId === productId)
        if (productDetails) {
          const elObject = {
            event: 'add_to_cart',
            User_Id: loggedIn,
            User_Status: loggedInStatus,
            selected_location: address,
            page_type: GDL_PAGE_TYPE,
            select_type: 'wishlist_move_to_cart',
            ecommerce: {
              value: productDetails.discountedPrice,
              currency: 'INR',
              coupon: 'na',
              items: [
                {
                  item_name: productDetails?.productName?.toLowerCase() || 'na',
                  item_id: productDetails?.productId,
                  item_brand: productDetails?.vendorName?.toLowerCase() || 'na',
                  discount: productDetails.discountPercentage || 0,
                  item_variant: productDetails?.variant || 'na',
                  item_category: productDetails?.categoryName || 'na',
                  price: productDetails?.regularPrice,
                  item_list_id: 'na',
                  item_list_name: 'na',
                  item_category2: 'na',
                  item_category3: 'na',
                  item_category4: 'na',
                  coupon: 'na',
                  item_category5: 'na',
                  Is_returnable: productDetails?.returnable,
                  quantity: 1,
                  index: 1,
                },
              ],
            },
          }
          if (productDetails?.productId && googledatalayer === '1') {
            TagManager.dataLayer({ dataLayer: elObject })
          }
        }
      }
    } catch (error: any) {
      //setLoader(false)
      const errorMessage = error?.response?.data || 'Something Went Wrong'
      setShowSnackBar({ open: true, message: `${errorMessage?.m}`, severity: '' })
    }
  }

  useEffect(() => {
    if (data?.s == 1) {
      if (data?.d?.products) {
        const productIds = data?.d?.products?.map((product: { productId: string }) => product.productId)
        setWishlistIdData(productIds)
        data?.d?.products?.length != 0 ? setNoData(0) : setNoData(1)
      } else if (data?.d && Object.keys(data?.d).length == 0) {
        setNoData(1)
      }
    }
  }, [data, setWishlistIdData])

  useEffect(() => {
    refetch()
  }, [customerId])

  return (
    <WishlistProductSectionWrapper>
      <WishListMainWrapper>
        <WishlistLabel>Wishlist</WishlistLabel>
        <label className="h1r">{products?.length > 0 ? 'Items' : ''}</label>
      </WishListMainWrapper>
      <WishListWrapper>
        {products?.map((product: IProductDetails, index: number) => {
          const imageRatio = storesData?.d?.imageSize == 'Square' ? 'w_300,h_300' : 'w_300,h_450'

          let imgUrl =
            product?.imgUrl?.startsWith('http://') || product?.imgUrl?.startsWith('https://')
              ? product?.imgUrl
              : `https://cdnaz.plotch.io/image/upload/${imageRatio}${product?.imgUrl}`
          if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes(imageRatio)) {
            imgUrl = imgUrl?.split('/upload/').join(`/upload/${imageRatio}/`)
          }

          const productName = product?.productName
            .replace(/[^a-zA-Z ]/g, '')
            .split(' ')
            .join('-')
          const discountedPercentage = Math.ceil(product?.discountPercentage)
          return (
            <ProductWrapper
              key={index}
              className="product-wrapper"
              onClick={() => {
                navigate.push(
                  PdpRoute.path
                    .replace(':name', productName ? productName : 'product')
                    .replace(':id', product?.productId),
                )
                localStorage.setItem('productId', product?.productId)
              }}
            >
              <img src={imgUrl} />
              <ProductDetailsWrapper>
                <div className="heading-wrapper">
                  <div className="heading-details">
                    <Typography.Text ellipsis={{ tooltip: product?.vendorName }} className="h1b subCategory">
                      {product?.vendorName.toLowerCase()}
                    </Typography.Text>
                    {/* <label className="h1b">{product?.vendorName}</label> */}
                    <TrashIcon
                      className="wishlist-icon"
                      onClick={(e: any) => {
                        e.stopPropagation()
                        handleRemoveWishlistClick(product?.productId)
                      }}
                    />
                  </div>
                  <label className="h3r product-name"> {product.productName.toLowerCase()}</label>
                </div>
                <div className="discount-details">
                  {product?.regularPrice === product?.discountedPrice ? (
                    <div className="after-discount h1b">
                      &#8377;
                      {product?.regularPrice}
                    </div>
                  ) : (
                    <>
                      <div className="after-discount h2r">
                        &#8377;
                        {product?.discountedPrice}
                      </div>
                      <div className="before-discount h2r">
                        <span> &#8377; {product?.regularPrice} </span>
                      </div>
                    </>
                  )}

                  {discountedPercentage > 0 ? (
                    <div className="discount-percentage h4r">
                      {discountedPercentage ? `${discountedPercentage}% OFF` : null}
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <Button
                  label="Move To Cart"
                  variant="contained"
                  className="small"
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleMoveToCart(product?.productId)
                  }}
                />
              </ProductDetailsWrapper>
            </ProductWrapper>
          )
        })}
      </WishListWrapper>
      {noData == 1 ? <NoDataDiv>No Data</NoDataDiv> : ''}
    </WishlistProductSectionWrapper>
  )
}

export default WishListProducts

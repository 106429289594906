import { useContext, useState, createContext } from 'react'
/* eslint-disable @typescript-eslint/no-explicit-any */
interface IFilter {
  filterProduct: any
  setFilterProduct: (val: any) => void
  filterSortProduct: any
  setFilterSortProduct: (val: any) => void
}

const DefaultValues = {
  filterProduct: [],
  setFilterProduct: () => null,
  filterSortProduct: {},
  setFilterSortProduct: () => null,
}

const FilterContext = createContext<IFilter>(DefaultValues)

const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [filterProduct, setFilterProduct] = useState([])
  const [filterSortProduct, setFilterSortProduct] = useState({})

  return (
    <FilterContext.Provider value={{ filterProduct, setFilterProduct, filterSortProduct, setFilterSortProduct }}>
      {children}
    </FilterContext.Provider>
  )
}

export default FilterProvider

export const useFilter = () => {
  return useContext(FilterContext)
}

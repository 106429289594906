import { ButtonWrappers } from 'styles/components/Button'
import Button from 'components/Button'

const BorderedButton = ({ label, variant }: any) => {
  return (
    <ButtonWrappers>
      <Button label={label} variant={variant} type="submit" />
    </ButtonWrappers>
  )
}

export default BorderedButton

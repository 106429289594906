import React, { useContext } from 'react'
import { Snackbar, Alert } from '@mui/material'
import { SnackBarContext } from 'context/SnackBarContext'

const CustomSnackbar = () => {
  const { showSnackBar, setShowSnackBar } = useContext(SnackBarContext)

  const handleClose = (reason?: any) => {
    if (reason === 'clickaway') {
      return
    }

    setShowSnackBar({ open: false, message: '', severity: '' })
  }

  return (
    <>
      <Snackbar
        open={showSnackBar.open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          severity={showSnackBar.severity}
          variant="filled"
          sx={{
            width: '100%',
            background: 'var(--primary_fail_color)',
            color: 'var(--primary_text_color_light)',
            marginTop: '60px',
          }}
        >
          {showSnackBar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default CustomSnackbar

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M3.124 8.28v9.22a.625.625 0 0 0 .625.625h3.75v-5.313a.938.938 0 0 1 .938-.937h3.125a.937.937 0 0 1 .937.937v5.313h3.75a.625.625 0 0 0 .625-.625V8.28"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="m18.75 10-8.325-7.97c-.195-.206-.652-.208-.85 0L1.25 10M15.625 6.993V2.5H13.75v2.697"
    />
  </svg>
)
export default SvgComponent

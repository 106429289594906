enum APIS {
  THEME_API = '/v1/merchandising/getThemeDetails',
  HEADER_NAVIGATION = '/v1/merchandising/navigationDetails',
  STORE_DETAILS = '/v1/merchandising/getStorefrontDetailsFromDomain',
  LOG_IN = '/v1/user/login',
  SESSION = '/v1/user/getSession',
  ADD_ADDRESS = '/v1/user/addAddress',
  COUNTRY_LIST = '/v1/user/getCountryList',
  ADDRESS_LIST = '/v1/user/addressList',
  UPDATE_ADDRESS = '/v1/user/updateAddress',
  UPDATE_DEFAULT_ADDRESS = '/v1/user/updateDefaultAddress',
  GET_PROFILE = `/v1/user/profile`,
  LOGOUT = `/v1/user/logout`,
  DELETE_ADDRESS = '/v1/user/deleteAddress',
  DYNAMICURL = '/v1/catalog/dynamicUrlDetails',
  DYNAMICURL_v1 = '/v1/catalog/dynamicUrlDetailsV1',
  SAVE_PROFILE = '/v1/user/updateProfile',
  PRODUCT_DETAIL = '/v1/public/catalog/product',
  PRODUCT_DETAIL_V1 = '/v1/public/catalog/productDetails',
  VENDOR_PRODUCT = '/v1/catalog/vendorProducts',
  GET_WIDGET_PRODUCT = '/v1/merchandising/getWidgetProducts',
  SEARCH_PRODUCT = '/v1/catalog/searchProductsV1',
  // SEARCH_PRODUCT = '/v1/catalog/searchProducts',
  HOME_PAGE = '/v1/merchandising/dynamicHomepage',
  ADD_WISHLIST = '/v1/user/addToWishlist',
  GET_WISHLIST_DETAILS = '/v1/user/myWishlistDetails',
  REMOVE_FROM_WISHLIST = '/v1/user/removeFromWishlist',
  TICKET_LIST = '/v1/user/listRaisedTickets',
  TICKET_DETAILS = '/v1/user/getTicketDetails',
  ADD_TO_CART = '/v1/cart/addToCart',
  NEAR_STORE = '/v1/catalog/getNearbyStores',
  // SEARCH_AUTOCOMPLETE = '/v1/search/autocomplete',
  SEARCH_AUTOCOMPLETE = '/v1/search/autocomplete_v1',
  VISITOR_DETAILS = '/v1/user/getVisitorDetails',
  PAYMENT_METHOD = '/v1/checkout/paymentMethods',
  CHECKOUT_QUOTE_DETAILS = '/v1/checkout/quoteDetails',
  CART_ATTACH_ADDRESS = '/v1/cart/attachAddress',
  REMOVE_ITEM = '/v1/cart/removeItem',
  UPDATE_ITEMS = '/v1/cart/updateItems',
  APPLY_COUPAN = '/v1/checkout/applyCoupon',
  REMOVE_COUPAN = '/v1/checkout/removeCoupon',
  CHECK_COUPAN = '/v1/checkout/checkCoupon',
  GET_ORDER_DETAILS = '/v1/user/orderDetails',
  ORDER_LIST = '/v1/user/orderList',
  FAILED_ORDER_LIST = '/v1/user/failedOrderList',
  FAILED_ORDER_DETAILS = '/v1/user/failedOrderDetailsV1',
  PLACED_ORDER_PREPAID = '/v2/checkout/placeOrderPrepaid',
  PLACED_ORDER_COD = '/v2/checkout/placeOrderCod',
  ORDER_SUCCESS_DETAILS = '/v1/checkout/orderSuccessDetails',
  CANCEL_REASONS = '/v1/checkout/cancellationReasons',
  CANCEL_ORDERS = '/v1/user/cancelOrderItem',
  SUBJECT_LIST = '/v1/user/listTicketSubjects',
  CREATE_TICKET = 'v1/user/addPlotchTicket',
  TICKET_COMMENT = '/v1/user/addTicketComment',
  CUSTOM_MENU = '/v1/catalog/customMenuDetails',
  SUCCESS_CALLBACK = 'v1/checkout/successCallbackUrl',
  FAILURE_CALLBACK = 'v1/checkout/failureCallbackUrl',
  GET_CART_DETAILS = '/v1/cart/getCartDetails',
  AGENT_LOCATION_LIST = '/v1/users/agentLocationList',
  TRACK_SHIPMENT = '/v1/user/trackShipment',
  RETURN_REASONS = '/v1/checkout/returnReasons',
  GET_REFUND_TYPES = '/v1/user/getRefundTypes',
  RETURN_ORDER_ITEM = '/v1/user/returnOrderItem',
  CLOSE_TICKET = '/v1/ticket/update_status',
}
export default APIS

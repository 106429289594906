import styled from 'styled-components'

export const FilterWrapper = styled.div`
  /* width: 20%; */
  width: 100%;
  max-width: 254px;
  /* padding: 0 20px; */
  .filter-checkbox {
    display: flex;
    /* gap: 10px; */
  }
  label .filterItems {
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
  .clear-all {
    font-family: 'Mulish';
    font-size: 12px;
    font-weight: 600;
    color: var(--primary_brand_color);
    text-transform: uppercase;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: none;
  }
`
export const MobileFilterWrapper = styled(FilterWrapper)`
  @media (max-width: 768px) {
    max-width: 100%;
    .clears-all {
      color: var(--primary_button_active_fill_color);
      text-transform: capitalize;
    }
    .filter-mobile {
      padding: 10px 20px;
      width: calc(100vw - 40px);
      background-color: white;
      position: fixed;
      bottom: 0;
    }
    display: block;
    /* max-width: 320px; */
    /* padding: 0 20px; */
  }
`
export const FiterTopHeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  .filter-main-heading {
    color: var(--primary_text_color_dark);
    font-family: 'Mulish';
    font-size: 18px;
    font-weight: 700;
  }
  @media (max-width: 768px) {
    width: unset;
    padding: 10px 20px;
  }
`
export const FilterListContainer = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--primary_line_color_dark);
  border-left: 0 solid var(--primary_line_color_dark);
  border-bottom: 0 solid var(--primary_line_color_dark);
  padding-right: 16px;
  @media (max-width: 768px) {
    width: calc(100vw - 40px);
    padding: 0 20px 80px;
    max-height: 80vh;
    overflow-y: auto;
    border: unset;
    border-left: 0 solid var(--primary_line_color_dark);
    border-bottom: 0 solid var(--primary_line_color_dark);
  }
`
export const FilterListWrapper = styled.div`
  width: 100%;
  .scrollable-div {
    max-height: 30vh;
    overflow-y: scroll;
  }
`
export const CircleWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`
export const Circle = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: red;
`
export const ShowMore = styled.div`
  margin-left: 20px;
  cursor: pointer;
  color: var(--primary_brand_color);
`
export const CheckBoxMainContainer = styled.div``
export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
export const ColorWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid var(--primary_light_stroke_color);
`
export const CheckBoxMainWrappers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .color-wraaper {
  }
`
export const LabelNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  .searchItem {
    width: 100%;
  }
  .ant-input-affix-wrapper {
    padding: 1px 10px;
  }
  .ant-input-outlined {
    background: #f5f5f5;
  }
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  input {
    height: 20px;
    font-size: 12px !important;
  }
`
export const SmallSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #f5f5f5;
  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`
export const CheckBoxMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1px solid var(--primary_line_color_dark);
  padding-bottom: 20px;

  .ant-checkbox-inner {
    border: 1px solid #000000;
  }
  .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
    border-color: #000000 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-checked:hover .ant-checkbox-inner,
  .ant-checkbox-checked:focus .ant-checkbox-inner {
    border-color: var(--primary_brand_color) !important;
    background-color: var(--primary_brand_color) !important;
  }

  .ant-checkbox-checked::after,
  .ant-checkbox-checked:hover::after,
  .ant-checkbox-checked:focus::after {
    border-color: var(--primary_brand_color) !important;
  }

  .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--primary_brand_color) !important;
  }
  .ant-radio-inner {
    border-color: #000000 !important;
  }

  .ant-radio-wrapper:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-input:focus-visible + .ant-radio-inner {
    border-color: #000000 !important;
  }

  .ant-radio-checked .ant-radio-inner,
  .ant-radio-checked:hover .ant-radio-inner,
  .ant-radio-checked:focus .ant-radio-inner {
    border-color: var(--primary_brand_color) !important;
    background-color: var(--primary_brand_color) !important;
  }

  .ant-radio-checked::after,
  .ant-radio-checked:hover::after,
  .ant-radio-checked:focus::after {
    border-color: var(--primary_brand_color) !important;
  }

  .ant-radio-checked .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--primary_brand_color) !important;
  }
`

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      // stroke={theme.primaryIconStaticColor}
      // stroke="#FF3E6B"
      stroke="var(--primary_icon_static_color)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M13.785 3.126c-2.536 0-3.786 2.5-3.786 2.5s-1.25-2.5-3.786-2.5c-2.06 0-3.692 1.724-3.714 3.781-.043 4.271 3.388 7.308 7.149 9.86a.625.625 0 0 0 .703 0c3.76-2.552 7.191-5.589 7.148-9.86-.02-2.057-1.653-3.781-3.714-3.781Z"
    />
  </svg>
)
export default SvgComponent

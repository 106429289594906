import { useHistory } from 'react-router-dom'
import TrackOrderDetail from 'views/TrackOrderDetail'
import BackArrow from 'assets/svg/BackArrow'
import { CommonStyle } from 'styles/pages/Common'
import PublicLayout from 'components/Layouts/public'
import { CheckoutWrappers, BackWrapper, CheckoutPageWrapper } from 'styles/pages/MyAccount'

const OrderTrackPage = () => {
  const navigate = useHistory()

  const handleBack = () => {
    navigate.goBack()
  }

  return (
    <PublicLayout>
      <CheckoutPageWrapper>
        <CheckoutWrappers>
          <CommonStyle>
            <BackWrapper className="padding_full_page" onClick={handleBack}>
              <BackArrow />
              <div className="h1b">ORDER TRACKING</div>
            </BackWrapper>
          </CommonStyle>

          <CommonStyle>
            <TrackOrderDetail />
          </CommonStyle>
        </CheckoutWrappers>
      </CheckoutPageWrapper>
    </PublicLayout>
  )
}

export default OrderTrackPage

import styled from 'styled-components'

export const NavProductContainer = styled.div`
  /* width: 100%; */
  /* max-width: 264px; */
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10px 7px 20px 20px;
  .sub-category {
    padding: 2.5px 0;
  }
`
export const Label = styled.div`
  color: var(--primary_text_color_dark);
  /* H1-R */
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 400;
`
export const PolicyLabel = styled(Label)`
  padding-top: 9px;
  border-top: 1px solid var(--primary_line_color_dark);
`
export const SvgWrapper = styled.div`
  display: flex;
  gap: 80px;
  justify-content: space-between;
  align-items: center;
  svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
`
export const NavListSvgWrapper = styled.div`
  display: flex;
  /* gap: 80px; */
  justify-content: space-between;
  align-items: center;
  svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
`
export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  a {
    text-decoration: none;
    color: var(--primary_text_color_dark);
    cursor: pointer;
    /* font-size: 14px;
    font-weight: 400; */
  }
`
export const SubCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  a {
    text-decoration: none;
    color: var(--primary_text_color_dark);
    cursor: pointer;
    /* font-size: 14px;
    font-weight: 400; */
  }
`
export const SubCategoryWrappers = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
  a {
    text-decoration: none;
    color: var(--primary_text_color_dark);
    cursor: pointer;
    /* font-size: 14px;
    font-weight: 400; */
  }
  .sub-categories {
    /* padding: 6px 0; */
    padding: 6px 10px 6px 0;
  }
`
export const CategoryList = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  svg {
    transform: rotate(-90deg);
  }
`
export const CategoriesList = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-right: 8px;

  .sub-icon {
    height: 16px;
    width: 16px;
  }
`
export const CategoryName = styled(Label)`
  font-weight: 700;
  /* a:nth-child(1) {
    padding-top: 18px;
  } */
`
export const SubCategoryName = styled(Label)`
  font-weight: 700;
`

import { Carousel } from 'antd'
import {
  MobileCategoryWrapper,
  ItemsCategoryWrapper,
  BlockDiv,
  StaticCarousel,
  WidgetWrap,
  EditorWrapper,
  StaticCarouselContainer,
} from 'styles/pages/Home'
import { ProductWrapper, ProductDetailsWrapper, ImageWrapper } from 'styles/components/ListProducts'
import HeartIcon from 'assets/svg/HeartIcon'
import WishlistedIcon from 'assets/svg/WishlistedIcon'
import { useHistory } from 'react-router-dom'
import { LoginRoute, PdpRoute } from 'constants/routes'
import { useTheme } from 'context/themeContext'
import { useWishListContext } from 'context/WishlistDetailContext'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
import { useHomeContext } from 'context/HomeContext'
import Button from 'components/Button'
import DOMPurify from 'dompurify'
import ContactUs from 'views/ContactUs'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const Arrow = ({
  type,
  style,
  className,
  onClick,
}: {
  type: string
  style?: any
  className?: string
  onClick?: any
}) => {
  return (
    <div key={type} style={style} className={className} onClick={onClick}>
      {type === 'left' ? <LeftOutlined /> : <RightOutlined />}
    </div>
  )
}

const renderWidget = (
  widget: any,
  wishlistIdData?: any,
  customerId?: any,
  handleWishlistClick?: any,
  handleRemoveWishlistClick?: any,
) => {
  const navigate = useHistory()
  const { storesData } = useTheme()
  const { homeData } = useHomeContext()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  switch (widget?.type) {
    case '_carousel':
      return (
        <Carousel className="carousel-display" infinite={true} autoplay={true} key={widget?.type}>
          {widget?.elements?.map((item: any, index: number) => (
            <div key={index} className="slider">
              <a href={item?.targetUrl} target="_self" className="plotch_a_link">
                <img src={item?.imageUrl} alt={item?.targetUrl} />
                {item.altTag && <label className="textLabel">{item.altTag}</label>}
              </a>
            </div>
          ))}
        </Carousel>
      )
    case '_image':
      return widget?.elements?.map((item: any, index: number) => (
        <div key={index}>
          <a href={item?.targetUrl} target="_self" className="plotch_a_link">
            <img src={item?.imageUrl} alt={item?.targetUrl} className="imageResponsive" />
          </a>
        </div>
      ))

    case '_label': {
      const handleSection = (item: any) => {
        const dataLayer = {
          event: 'section_header_click',
          cta_text: item?.name?.toLowerCase(),
          page_type: `${GDL_PAGE_TYPE}`,
          User_Id: loggedIn,
          User_Status: loggedInStatus,
        }

        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: dataLayer })
      }

      return (
        <WidgetWrap>
          {widget.elements.map((item: any, index: number) => {
            return (
              <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <label
                  style={{
                    color: item.labelColour,
                    backgroundColor: item.labelBgColour,
                    textAlign: item.labelAlignment,
                    fontSize: item.labelSize,
                    fontFamily: item.labelFont,
                  }}
                  onClick={() => handleSection(item)}
                >
                  {item.labelName}
                </label>
                <div
                  style={{
                    flexGrow: 1,
                    height: '1px',
                    backgroundColor: '#ccc', // Line color
                  }}
                />
              </div>
            )
          })}
        </WidgetWrap>
      )
    }

    case '_button': {
      const handleDataClick = (category: any) => {
        const targetUrl = category?.targetUrl.startsWith('https')
          ? category.targetUrl
          : category?.targetUrl.startsWith('/')
          ? category.targetUrl
          : `/${category.targetUrl}`
        const dataToPush = {
          event: 'see_all_click',
          page_type: `${GDL_PAGE_TYPE}`,
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          cta_text: category.labelName ? category.labelName?.toLowerCase() : 'NA',
        }

        // Push data to dataLayer
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: dataToPush })
        // navigate.push(targetUrl)
        window.location.href = targetUrl
      }

      return (
        <WidgetWrap>
          {widget.elements.map((item: any, index: number) => {
            return (
              <div
                key={index}
                style={{
                  color: item.labelColour,
                  backgroundColor: item.labelBgColour,
                  textAlign: item.buttonAlignment,
                  fontSize: item.labelSize,
                  fontFamily: item.labelFont,
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                {/* <a
                  href={item.targetUrl}
                  style={{
                    textDecoration: 'none',
                    textAlign: item.buttonAlignment,
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                > */}
                <Button
                  label={item.data}
                  variant="contained"
                  type="button"
                  className="view-all"
                  onClick={() => {
                    handleDataClick(item)
                  }}
                  // onClick={() => navigate.push(item.targetUrl)}
                />

                {/* <button>{item.data}</button> */}
                {/* </a> */}
              </div>
            )
          })}
        </WidgetWrap>
      )
    }

    case '_rectangle_image_carousel': {
      // const carouselType = widget?.elements?.[0]?.rectangleCarouselType == 1 ? true : false
      const rectangleCarouselHideArrow =
        widget?.elements?.[0]?.rectangleCarouselHideArrow != '' ? widget?.elements?.[0]?.rectangleCarouselHideArrow : 1
      const hideArrows = rectangleCarouselHideArrow == 1 ? true : false
      const imageCount = widget?.elements?.[0]?.imageCount != '' ? widget?.elements?.[0]?.imageCount : 7
      const msiteImageCount = widget?.elements?.[0]?.imageCount != '' ? widget?.elements?.[0]?.imageCount : 3
      const rectangleCarouselType = widget?.elements?.[0]?.rectangleCarouselType == 1 ? true : false
      const carouselSetting = {
        infinite: true,
        arrows: hideArrows,
        dots: false,
        draggable: !hideArrows,
        slidesToShow: Number(imageCount),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: Number(imageCount),
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: Number(msiteImageCount),
            },
          },
        ],
      }
      const handleClick = (category: any) => {
        // const targetUrl = category?.targetUrl.startsWith('/') ? category.targetUrl : `/${category.targetUrl}`
        const dataToPush = {
          event: 'shop_by_category',
          page_type: `${GDL_PAGE_TYPE}`,
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          cta_text: category?.altTag ? category?.altTag?.toLowerCase() : 'NA',
          section_name: homeData?.feedName?.toLowerCase(),
        }

        // Push data to dataLayer
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: dataToPush })
        // navigate.push(targetUrl)
        // window.locat
      }

      if (rectangleCarouselType) {
        return (
          <Carousel className="carousel-rect rectangleCarousel" {...carouselSetting} key={widget.type}>
            {widget?.elements?.map((item: any, index: number) => (
              <div key={index} className="slider">
                <a href={item?.targetUrl} target="_self" className="plotch_a_link" onClick={() => handleClick(item)}>
                  <div className="imageWithLabel">
                    <img src={item?.imageUrl} loading="lazy" />
                    {item.altTag && <label className="labelText">{item.altTag}</label>}
                  </div>
                </a>
              </div>
            ))}
          </Carousel>
        )
      } else {
        return (
          <StaticCarouselContainer className="rectangleCarousel">
            {hideArrows && <Arrow type="left" style={{ color: '#000' }} className="slick-prev" />}
            {/* <StaticCarousel className="rectabgle_image_carousel" key={widget?.type}>
              {widget?.elements?.map((item: any, index: number) => {
                return (
                  <div key={index} className="slider rectangleCarousel">
                    <a href={item?.targetUrl} target="_self" className="plotch_a_link">
                      <img src={item?.imageUrl} loading="lazy" />
                      {item.labelText && <label className="labelText">{item.labelText}</label>}
                    </a>
                  </div>
                )
              })}
            </StaticCarousel> */}
            <StaticCarousel className="rectangle_image_carousel" key={widget?.type}>
              {widget?.elements?.map((item: any, index: number) => {
                return (
                  <div key={index} className="slider rectangleCarousel">
                    <a
                      href={item?.targetUrl}
                      target="_self"
                      className="plotch_a_link"
                      onClick={() => handleClick(item)}
                    >
                      <div className="image-container">
                        <img src={item?.imageUrl} loading="lazy" className="carousel-image" />
                        {item.altTag && <label className="labelText">{item.altTag}</label>}
                      </div>
                    </a>
                  </div>
                )
              })}
            </StaticCarousel>
            {hideArrows && <Arrow type="right" style={{ color: '#000' }} className="slick-next" />}
          </StaticCarouselContainer>
        )
      }
    }

    case '_product_carousel': {
      const hideArrows = widget?.elements?.[0]?.productCarouselHideArrow == 1 ? true : false
      const imageCount =
        widget?.elements?.[0]?.slider_config?.slides_to_show != ''
          ? widget?.elements?.[0]?.slider_config?.slides_to_show
          : 6
      const imageCountMsite =
        widget?.elements?.[0]?.slider_config?.slides_to_show_msite != ''
          ? widget?.elements?.[0]?.slider_config?.slides_to_show_msite
          : 2
      const carouselSetting = {
        infinite: false,
        arrows: hideArrows,
        dots: false,
        draggable: !hideArrows,
        slidesToShow: Number(imageCount),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: Number(imageCount),
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: Number(imageCountMsite),
            },
          },
        ],
      }

      return (
        <Carousel className="carousel-rect" {...carouselSetting} key={widget?.type}>
          {widget?.elements?.map((item: any) => {
            return item?.products?.map((product: any, index: number) => {
              const imageRatio = storesData?.d?.imageSize == 'Square' ? 'w_300,h_300' : 'w_300,h_450'

              let imgUrl =
                product?.imgUrl?.startsWith('http://') || product?.imgUrl?.startsWith('https://')
                  ? product?.imgUrl
                  : `https://cdnaz.plotch.io/image/upload/${imageRatio}${product?.imgUrl}`
              if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes(imageRatio)) {
                imgUrl = imgUrl?.split('/upload/').join(`/upload/${imageRatio}/`)
              }

              const productName = product?.productName
                .replace(/[^a-zA-Z ]/g, '')
                .split(' ')
                .join('-')
              return (
                <ProductWrapper
                  key={index}
                  className="product-wrapper"
                  onClick={() => {
                    navigate.push(
                      PdpRoute.path
                        .replace(':name', productName ? productName : 'product')
                        .replace(':id', product?.productId),
                    )
                    localStorage.setItem('productId', product?.productId)
                  }}
                >
                  <ImageWrapper>
                    <img src={imgUrl} />
                  </ImageWrapper>

                  <ProductDetailsWrapper>
                    <div className="heading-wrapper">
                      <div className="heading-details">
                        <label className="h1b product-name">{product.productName}</label>
                        {wishlistIdData[product.productId] ? (
                          <WishlistedIcon
                            className="wishlist-icon"
                            onClick={(e: any) => {
                              e.stopPropagation()
                              if (customerId) {
                                handleRemoveWishlistClick(product?.productId)
                              }
                            }}
                          />
                        ) : (
                          <HeartIcon
                            className="wishlist-icon"
                            onClick={(e: any) => {
                              e.stopPropagation()
                              if (customerId) {
                                handleWishlistClick(product?.productId)
                              } else {
                                navigate.push(LoginRoute.path)
                              }
                            }}
                          />
                        )}
                      </div>
                      <label className="h3r subCategory">{product?.categoryName}</label>
                    </div>
                    <div className="discount-details">
                      {product?.regularPrice === product?.discountedPrice ? (
                        <div className="after-discount h1b">
                          &#8377;
                          {product?.regularPrice}
                        </div>
                      ) : (
                        <>
                          <div className="after-discount h1b">
                            &#8377;
                            {product?.discountedPrice}
                          </div>
                          <div className="before-discount">
                            <span> &#8377; {product.regularPrice} </span>
                          </div>
                        </>
                      )}

                      {product?.discountPercentage > 0 ? (
                        <div className="discount-percentage h4r">
                          {product.discountPercentage ? `${product.discountPercentage}% OFF` : null}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </ProductDetailsWrapper>
                </ProductWrapper>
              )
            })
          })}
        </Carousel>
      )
    }

    case '_space_card':
      return (
        <div
          key={widget?.type + '_' + Math.floor(Math.random() * 10) + 1}
          style={{ height: widget?.elements[0]?.data }}
        />
      )

    case '_editor':
      return widget?.elements?.map((data: any, index: number) => (
        <EditorWrapper key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.data) }} />
      ))
    case '_form':
      return <ContactUs termData={widget} />
    case '_round_image_carousel': {
      const roundCarouselType = widget?.elements?.[0]?.roundCarouselType == 1 ? true : false
      const roundImageCarouselHideArrow =
        widget?.elements?.[0]?.roundImageCarouselHideArrow != ''
          ? widget?.elements?.[0]?.roundImageCarouselHideArrow
          : 1
      const hideArrows = roundImageCarouselHideArrow == 1 ? true : false
      const roundImageCount = widget?.elements?.[0]?.roundImageCount !== '' ? widget?.elements?.[0]?.roundImageCount : 7
      const carouselSetting = {
        infinite: roundCarouselType,
        arrows: hideArrows,
        dots: false,
        draggable: !hideArrows,
        slidesToShow: Number(roundImageCount),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: Number(roundImageCount),
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: Number(roundImageCount),
            },
          },
        ],
      }

      const handleClick = (category: any) => {
        // const targetUrl = category?.targetUrl.startsWith('/') ? category.targetUrl : `/${category.targetUrl}`
        const dataToPush = {
          event: 'shop_by_category',
          page_type: `${GDL_PAGE_TYPE}`,
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          cta_text: category.labelText ? category.labelText : 'NA',
          section_name: homeData?.feedName,
        }

        // Push data to dataLayer
        TagManager.dataLayer({ dataLayer: dataToPush })
        // navigate.push(targetUrl)
        // window.location.href = targetUrl
      }

      // const handleClick = (category: any) => {
      //   navigate.push(`${'/' + category.targetUrl}`)

      //   const dataToPush = {
      //     event: 'shop_by_category',
      //     page_type: `${GDL_PAGE_TYPE} landing page`,
      //     User_Id: loggedIn,
      //     User_Status: loggedInStatus,
      //     // cta_text: 'NA',
      //     cta_text: category.labelText ? category.labelText : 'NA',
      //     section_name: homeData?.feedName,
      //   }

      //   TagManager.dataLayer({ dataLayer: dataToPush })

      //   // setTimeout(() => {
      //   //   window.location.href = category?.targetUrl
      //   // }, 200) // 200ms delay (can adjust as necessary)
      //   // window.location.href = category?.targetUrl
      // }

      if (roundCarouselType) {
        return (
          <Carousel className="round_image_carousel" key={widget?.type} {...carouselSetting}>
            {widget?.elements?.map((item: any, index: number) => {
              return (
                <div key={index} className="slider">
                  {/* <a className="plotch_a_link" onClick={() => handleClick(item)}> */}
                  <a href={item?.targetUrl} target="_self" className="plotch_a_link" onClick={() => handleClick(item)}>
                    <img src={item?.imageUrl} loading="lazy" />
                    {item.labelText && <label className="textLabel">{item.labelText}</label>}
                  </a>
                </div>
              )
            })}
          </Carousel>
        )
      } else {
        return (
          <StaticCarousel className="round_image_carousel" key={widget?.type}>
            {widget?.elements?.map((item: any, index: number) => {
              return (
                <div key={index} className="slider roundCarousel">
                  {/* <a className="plotch_a_link static-roundCarousel" onClick={() => handleClick(item)}> */}
                  <a
                    href={item?.targetUrl}
                    target="_self"
                    className="plotch_a_link static-roundCarousel"
                    onClick={() => handleClick(item)}
                  >
                    <img src={item?.imageUrl} loading="lazy" />
                    {item.labelText && <label>{item.labelText}</label>}
                  </a>
                </div>
              )
            })}
          </StaticCarousel>
        )
      }
    }

    default:
      return null
  }
}

const ListingType = ({ sectionType }: any) => {
  const { wishlistIdData, handleAddWishlist, handleRemoveWishlist } = useWishListContext()
  const customerId = localStorage.getItem('customerId')
  const handleWishlistClick = (productId: string) => {
    handleAddWishlist(productId)
  }

  const handleRemoveWishlistClick = (productId: string) => {
    handleRemoveWishlist(productId)
  }

  return (
    <MobileCategoryWrapper>
      <ItemsCategoryWrapper>
        {sectionType?.map((block: any, index: any) => {
          return (
            <BlockDiv
              className={`myBlock ${block.msite == 1 ? 'hide-on-mobile' : ''}`}
              key={index}
              css={block.blockSettings}
              dimensions={block?.dimension}
            >
              {Array.from({ length: block?.dimension?.['cols'] || 0 }).map((val, account: any) => {
                return (
                  <div key={account + val}>
                    {block.widgets?.map((widget: any) => {
                      if (account + 1 === widget?.['position']?.['col']) {
                        return renderWidget(
                          widget,
                          wishlistIdData,
                          customerId,
                          handleWishlistClick,
                          handleRemoveWishlistClick,
                        )
                      }

                      return null // Explicitly return null if condition is not met
                    })}
                  </div>
                )
              })}
            </BlockDiv>
          )
        })}
      </ItemsCategoryWrapper>
    </MobileCategoryWrapper>
  )
}

export default ListingType

import HelpCircle from 'assets/svg/HelpCircle'
import Saari from 'assets/images/short-saari-img.png'
import PendingIcon from 'assets/svg/PendingIcon'

import {
  ConfirmationOrderDetailWrapper,
  OrderConfirmationWrapper,
  FailureDetailWrapper,
} from 'styles/views/ViewOrderDetail'
import { orders } from './data'

const PendingOrderDetail = () => {
  return (
    <ConfirmationOrderDetailWrapper>
      {orders.map((order, index) => (
        <OrderConfirmationWrapper key={index}>
          <div className="confirm-wrap">
            <div className="confirm-order">
              <PendingIcon />
              <div className="h1b order-pending-wrapper">Pending for confirmation</div>
            </div>
          </div>
          {order.items.map((item, idx) => (
            <div className="confirmed-wrap order-state-type" key={idx}>
              <div className="confirm-order">
                <img src={Saari} alt="Saari" />
                <div className="orders-details">
                  <div className="order-name">
                    <label className="h3r">{item.name}</label>
                    <HelpCircle />
                  </div>
                  <label className="h3r">Delivery by {item.deliveryDate}</label>
                </div>
              </div>
            </div>
          ))}
        </OrderConfirmationWrapper>
      ))}
      <FailureDetailWrapper>
        <div className="price-details-container">
          <div className="price-details-wrapper">
            <label className="h1r">Total MRP</label>
            <label className="h1r">₹2199</label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Discount on MRP</label>
            <label className="h1r discount">-₹900</label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Delivery Charge</label>
            <label className="h1r">₹100</label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Packaging Charge</label>
            <label className="h1r">₹10</label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Taxes</label>
            <label className="h1r">₹0</label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Convenience Fee</label>
            <label className="h1r">₹25</label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Coupon Discount</label>
            <label className="h1r discount">-₹100</label>
          </div>
          <div className="order-wrapper total">
            <label className="h1b">Order Value</label>
            <label className="h1b">₹1434</label>
          </div>
        </div>
      </FailureDetailWrapper>
    </ConfirmationOrderDetailWrapper>
  )
}

export default PendingOrderDetail

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="#FF6746"
      d="M10.003 11.021a1.019 1.019 0 1 0 0-2.037 1.019 1.019 0 0 0 0 2.037ZM13.518 11.021a1.019 1.019 0 1 0 0-2.037 1.019 1.019 0 0 0 0 2.037ZM6.487 11.021a1.019 1.019 0 1 0 0-2.037 1.019 1.019 0 0 0 0 2.037Z"
    />
    <path
      stroke="#FF6746"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M17.5 10c0-4.14-3.36-7.5-7.5-7.5-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5Z"
    />
  </svg>
)
export default SvgComponent

import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
// // import { LoaderContext } from 'context/loader'

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
})

const SetViewOnChange = ({ position }: any) => {
  const map = useMap()
  useEffect(() => {
    map.setView(position)
  }, [position, map])
  return null
}

const MapComponent = ({ currentPosition, setAddress }: any) => {
  const [dragLocation, setDragLocation] = useState<any>([])
  // const { setLoader } = useContext(LoaderContext)
  const handleMarkerDragEnd = (event: { target: any }) => {
    const marker = event.target
    const position = marker.getLatLng()
    setDragLocation([position.lat, position.lng])
  }

  const fetchLocation = async () => {
    //setLoader(true)
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${dragLocation[0]}&lon=${dragLocation[1]}`,
      )
      const data = await response.json()
      const location = JSON.stringify({
        pincode: data.address['ISO3166-2-lvl4'],
        county: data.address?.county,
        state: data.address?.state,
        district: data.address?.state_district,
        town: data.address?.town,
        country: data.address?.country,
      })
      localStorage.setItem('myAddress', location)
      setAddress(JSON.parse(location))

      //setLoader(false)
    } catch (error) {
      //setLoader(false)
      return error
    }
  }

  useEffect(() => {
    fetchLocation()
  }, [dragLocation])
  return (
    <MapContainer center={currentPosition} zoom={10} className="map-component">
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker
        position={currentPosition}
        draggable={true}
        eventHandlers={{
          dragend: handleMarkerDragEnd,
        }}
      >
        <Popup className="popup">
          Order will be delivered here <br />
          <span>Place the pin accurately on the map</span>
        </Popup>
      </Marker>
      <SetViewOnChange position={currentPosition} />
    </MapContainer>
  )
}

export default MapComponent

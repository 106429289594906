import styled from 'styled-components'
export const ProfileWraper = styled.div`
  /* padding: 17px; */
  height: 100vh;
  .form-margin {
    margin-top: 14px;
    height: 91vh;
  }
  .bottomButton {
    width: 100%;
    margin-top: auto;
  }
  .padding_full_page {
    padding: 17px;
  }
  .name-container {
    margin-top: 32px;
  }
  .mobileInput input:disabled {
    background-color: unset;
    color: unset;
    border-color: unset;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"%3E%3Cpath d="M17.501 10.0001C17.501 5.8595 14.1416 2.50012 10.001 2.50012C5.86035 2.50012 2.50098 5.8595 2.50098 10.0001C2.50098 14.1407 5.86035 17.5001 10.001 17.5001C14.1416 17.5001 17.501 14.1407 17.501 10.0001Z" stroke="%23629E58" strokeWidth="1.00189" strokeMiterlimit="10"%3E%3C/path%3E%3Cpath d="M13.752 6.87488L8.5013 13.1258L6.25098 10.6254" stroke="%23629E58" strokeWidth="1.00189" strokeLinecap="round" strokeLinejoin="round"%3E%3C/path%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 10px center;
  }
`
export const BottomButtonWrapper = styled.div`
  width: 336px;
  /* position: absolute;
  bottom: 42px; */
  margin-top: 40px;
  padding: 8px 11px;
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: calc(100vw - 24px);
    margin-top: unset;
  }
`

export const ManageAddressWrapper = styled.div`
  margin-top: 10px;
  .actionArrow {
    margin-left: auto;
  }
  .option-divider {
    margin: 17px 10px 23px 23px;
    background-color: #c7c6c6;
  }
`

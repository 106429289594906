import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import PublicLayout from 'components/Layouts/public'
import APIS from 'constants/api'
import { CheckoutRoute } from 'constants/routes'
import { SnackBarContext } from 'context/SnackBarContext'
import usePost from 'hooks/usePost'
import CouponDetails from 'views/CouponDetails'
// import { LoaderContext } from 'context/loader'
import BackArrow from 'assets/svg/BackArrow'
import { CommonStyle } from 'styles/pages/Common'
import { CheckoutPageWrapper, BackWrapper, CoupansWrappers, CheckoutButtonWrapper } from 'styles/pages/MyAccount'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
import { useTheme } from 'context/themeContext'

const CouponPage = () => {
  const [coupon, setCoupon] = useState('')
  const { storesData } = useTheme()
  const customerId = localStorage.getItem('customerId')
  const { setShowSnackBar } = useContext(SnackBarContext)
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  // const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  const navigate = useHistory()

  const handleBack = () => {
    navigate.goBack()
  }
  const handleCoupanClick = async () => {
    //setLoader(true)
    try {
      // if ((!coupon && customerId) || (coupon && !customerId)) return

      const res = await mutateAsync({
        url: APIS.APPLY_COUPAN,
        payload: {
          customerId: customerId,
          couponCode: coupon,
        },
      })
      if (res) {
        //setLoader(false)
        const applyCoupanDataToPush = {
          event: 'apply_coupon',
          page_type: GDL_PAGE_TYPE,
          User_Status: loggedInStatus,
          User_Id: loggedIn,
          cta_text: 'apply coupon',
          select_type: coupon,
        }
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: applyCoupanDataToPush })
        setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
        navigate.push(CheckoutRoute.path, { state: true })
      }
    } catch (error) {
      //setLoader(false)
    }
  }

  return (
    <PublicLayout>
      <CheckoutPageWrapper>
        <CoupansWrappers>
          <CommonStyle>
            <BackWrapper className="padding_full_page" onClick={handleBack}>
              <BackArrow />
              <div className="h1b">COUPONS</div>
            </BackWrapper>
          </CommonStyle>
          {
            <CommonStyle>
              <CouponDetails coupon={coupon} setCoupon={setCoupon} />
            </CommonStyle>
          }
          <CheckoutButtonWrapper>
            <Button label="Apply" variant="contained" type="submit" className="small" onClick={handleCoupanClick} />
          </CheckoutButtonWrapper>
        </CoupansWrappers>
      </CheckoutPageWrapper>
    </PublicLayout>
  )
}

export default CouponPage

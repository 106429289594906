import HomeIcon from 'assets/svg/HomeIcon'
// import ListIcon from 'assets/svg/ListIcon'
import StorefrontIcon from 'assets/svg/StorefrontIcon'
import HeartIcon from 'assets/svg/HeartIcon'
import PersonIcon from 'assets/svg/PersonIcon'
import CheckoutIcon from 'assets/svg/CartIcon'
import { INavData, INavbarData } from 'interfaces/components'

export const navData: INavData[] = [
  {
    id: 1,
    title: 'Home',
    icon: <HomeIcon />,
    link: '/',
  },
  // {
  //   id: 2,
  //   title: 'Categories',
  //   icon: <ListIcon />,
  //   link: '/categories',
  // },
  {
    id: 3,
    title: 'Wishlist',
    icon: <HeartIcon />,
    link: localStorage.getItem('customerId') ? '/wishlist' : '/signin?redirect=wishlist',
  },
  {
    id: 4,
    title: 'Store',
    icon: <StorefrontIcon />,
    link: '/stores-near-me',
  },
  {
    id: 5,
    title: 'Account',
    icon: <PersonIcon />,
    link: localStorage.getItem('customerId') ? '/my-account' : '/signin?redirect=my-account',
  },
]

export const navDataNonOndc: INavData[] = [
  {
    id: 1,
    title: 'Home',
    icon: <HomeIcon />,
    link: '/',
  },
  // {
  //   id: 2,
  //   title: 'Categories',
  //   icon: <ListIcon />,
  //   link: '/categories',
  // },
  {
    id: 3,
    title: 'Wishlist',
    icon: <HeartIcon />,
    link: localStorage.getItem('customerId') ? '/wishlist' : '/signin?redirect=wishlist',
  },
  {
    id: 4,
    title: 'Cart',
    icon: <CheckoutIcon />,
    link: '/checkout',
  },
  {
    id: 5,
    title: 'Account',
    icon: <PersonIcon />,
    link: localStorage.getItem('customerId') ? '/my-account' : '/signin?redirect=my-account',
  },
]
export const navDesktopData: INavbarData[] = [
  {
    id: 1,
    title: 'My profile',
    link: '/my-profile',
  },
  {
    id: 2,
    title: 'My Orders',
    link: '/my-orders',
  },
  {
    id: 3,
    title: 'My Wishlist',
    link: '/wishlist',
  },
  {
    id: 4,
    title: 'My Address',
    link: '/manage-addresses',
  },
  {
    id: 5,
    title: 'Logout',
  },
]

import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import BackArrow from 'assets/svg/BackArrow'
import { NotBackWrapper } from 'styles/pages/Login'
import {
  NotFoundPageWrapper,
  MainContainer,
  LogoWrapper,
  MainWrapper,
  Heading,
  SubHeading,
  Details,
} from 'styles/pages/NotFound'
import PrivateLayout from 'components/Layouts/private'
import { useTheme } from 'context/themeContext'

const ServerErrorPage = () => {
  const { themedata } = useTheme()

  const themeLogo = themedata?.d?.objectDetails?.header?.siteLogo?.logoImage

  const router = useHistory()
  const handleBack = () => {
    router.goBack()
  }

  return (
    <PrivateLayout>
      <NotFoundPageWrapper>
        <NotBackWrapper onClick={handleBack}>
          <BackArrow />
          SERVER ERROR
        </NotBackWrapper>
        <MainContainer>
          <LogoWrapper>
            <img src={themeLogo} alt="WebsiteLogo" />
          </LogoWrapper>
          <MainWrapper>
            <Heading>500</Heading>
            <SubHeading>Oops Server Failure</SubHeading>
            <Details>Looks like our server is misbehaving. You can try again or try after some time</Details>
          </MainWrapper>
          <Button label="Try Again" variant="contained" type="submit" className="small" />
        </MainContainer>
      </NotFoundPageWrapper>
    </PrivateLayout>
  )
}

export default ServerErrorPage

import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      d="M12.4997 10.3113L8.99924 14.6869L7.49902 12.9335"
      stroke="var(--primary_static_icon_color)"
      strokeWidth={1.00189}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.12451 6.87463C2.95875 6.87463 2.79978 6.94048 2.68257 7.05769C2.56536 7.1749 2.49951 7.33387 2.49951 7.49963V15.9371C2.49951 17.1184 3.50576 18.1246 4.68701 18.1246H15.312C16.4933 18.1246 17.4995 17.1672 17.4995 15.986V7.49963C17.4995 7.33387 17.4337 7.1749 17.3165 7.05769C17.1992 6.94048 17.0403 6.87463 16.8745 6.87463H3.12451Z"
      stroke="var(--primary_static_icon_color)"
      strokeWidth={1.00189}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.24902 6.87583V5.62566C6.24902 4.63095 6.64417 3.67699 7.34753 2.97363C8.05089 2.27027 9.00485 1.87512 9.99956 1.87512V1.87512C10.9943 1.87512 11.9482 2.27027 12.6516 2.97363C13.3549 3.67699 13.7501 4.63095 13.7501 5.62566V6.87583"
      stroke="var(--primary_static_icon_color)"
      strokeWidth={1.00189}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent

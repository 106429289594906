import { useContext } from 'react'
import Button from 'components/Button'
// import { LoaderContext } from 'context/loader'
import usePost from 'hooks/usePost'
import APIS from 'constants/api'
import { SnackBarContext } from 'context/SnackBarContext'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'

import AlertIcon from 'assets/svg/AlertIcon'
import Line from 'assets/svg/Line'
import {
  RemoveItemsContainer,
  HeadingWrapper,
  TopHeadingWrapper,
  Heading,
  Description,
  DeleteButtonWrapper,
} from 'styles/views/SaveAddress'
import { SvgWrapper } from 'styles/views/LocationNewUser'
import { CommonStyle } from 'styles/pages/Common'
import CloseIcon from 'assets/svg/CloseIcon'
import { useHomeContext } from 'context/HomeContext'

const RemoveItem = ({ onClose, productId, refetchOndcQuoteDetails, quoteData, datalyer }: any) => {
  // const matchingItems = quoteData.filter((item) => item.productId === productId);
  const matchingProducts = quoteData.filter((item: { productId: number }) => item.productId === productId)
  const wishlistData = matchingProducts[0]
  // const [wishlist, setWishlist] = useState<{ [key: string]: boolean }>({})
  const customerId = localStorage.getItem('customerId')
  const { loggedIn, loggedInStatus, address, GDL_PAGE_TYPE } = pageData()

  // const { setLoader } = useContext(LoaderContext)
  const { setShowSnackBar } = useContext(SnackBarContext)
  const { setCartCount, cartCount } = useHomeContext()
  const { mutateAsync } = usePost()
  const discount =
    wishlistData?.regularPrice && wishlistData?.discountedPrice
      ? parseFloat((wishlistData.regularPrice - wishlistData.discountedPrice).toFixed(2))
      : 0
  const itemCategory = Array.isArray(wishlistData?.categoryName)
    ? wishlistData?.categoryName[0]?.toLowerCase()
    : wishlistData?.categoryName?.toLowerCase()

  let categoryText = 'na'
  if (wishlistData?.returnable) {
    categoryText = `Returnable: ${wishlistData?.returnable}`
  }

  if (wishlistData?.cancellable) {
    categoryText +=
      categoryText !== 'na'
        ? ` | Cancellable: ${wishlistData?.cancellable}`
        : `Cancellable: ${wishlistData?.cancellable}`
  }

  const GDL_CATEGORY_5_lowercase = categoryText?.toLowerCase()
  const variantStringArray: string[] = []

  if (wishlistData?.variants) {
    Object.entries(wishlistData.variants).forEach(([key, values]) => {
      if (Array.isArray(values) && values.length > 0) {
        variantStringArray.push(`${key}-${values.join(', ')}`)
      } else {
        variantStringArray.push(`${key}-`)
      }
    })
  }

  const variantString = variantStringArray.join('|')

  const handleWishlistClick = async (productId: string) => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.ADD_WISHLIST,
        payload: {
          customerId: customerId,
          productId: [productId],
        },
      })
      if (res) {
        //setLoader(false)
        //setLoader(false)
        setCartCount(cartCount - 1)
        setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
        onClose()
        const elObject = {
          event: 'add_to_wishlist',
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          selected_location: address,
          page_type: GDL_PAGE_TYPE,
          ecommerce: {
            value: wishlistData.discountedPrice,
            currency: 'INR',
            items: [
              {
                item_name: wishlistData.productName?.toLowerCase() || 'na',
                item_id: productId,
                item_brand: wishlistData.brand?.toLowerCase() || 'na',
                discount: discount,
                item_variant: variantString || 'na',
                item_category: itemCategory,
                price: wishlistData?.regularPrice,
                item_list_id: 'na',
                item_list_name: 'na',
                item_category2: 'na',
                item_category3: 'na',
                item_category4: 'na',
                item_category5: GDL_CATEGORY_5_lowercase,
                quantity: 1,
                index: 1,
              },
            ],
          },
        }
        if (wishlistData?.productId && datalyer === '1') {
          TagManager.dataLayer({ dataLayer: elObject })
        }
        //setLoader(false)
      }
    } catch (error) {
      //setLoader(false)

      return error
    }
  }

  const handleRemoveProduct = async (showSnackbar = true) => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.REMOVE_ITEM,
        payload: { productIds: [productId] },
      })
      if (res) {
        const elObject = {
          event: 'remove_from_cart',
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          selected_location: address,
          page_type: GDL_PAGE_TYPE,
          select_type: 'checkout_remove_item_cta',
          ecommerce: {
            value: wishlistData.discountedPrice,
            currency: 'INR',
            items: [
              {
                item_name: wishlistData.productName?.toLowerCase() || 'na',
                item_id: productId,
                item_brand: wishlistData.brand?.toLowerCase() || 'na',
                discount: discount,
                item_variant: variantString || 'na',
                item_category: itemCategory,
                price: wishlistData?.regularPrice,
                item_list_id: 'na',
                item_list_name: 'na',
                item_category2: 'na',
                item_category3: 'na',
                item_category4: 'na',
                item_category5: GDL_CATEGORY_5_lowercase,
                quantity: 1,
                index: 1,
              },
            ],
          },
        }
        if (wishlistData?.productId && datalyer === '1') {
          TagManager.dataLayer({ dataLayer: elObject })
        }

        if (showSnackbar) {
          setShowSnackBar({ open: true, message: `Product Removed`, severity: '' })
        }

        refetchOndcQuoteDetails()
        //setLoader(false)
        setCartCount(cartCount - 1)
        onClose()
      }
    } catch (error) {
      //setLoader(false)
      return error
    }
  }
  // const handleRemoveProduct = async () => {
  //   //setLoader(true)

  //   try {
  //     const res = await mutateAsync({
  //       url: APIS.REMOVE_ITEM,
  //       payload: { productIds: [productId] },
  //     })
  //     if (res) {
  //       setShowSnackBar({ open: true, message: `Product Removed`, severity: '' })
  //       refetchOndcQuoteDetails()
  //       //setLoader(false)
  //       onClose()
  //     }
  //   } catch (error) {
  //     //setLoader(false)
  //     return error
  //   }
  // }

  return (
    <RemoveItemsContainer>
      <SvgWrapper onClick={onClose} className="cross-icon">
        <CloseIcon />
      </SvgWrapper>
      <CommonStyle>
        <HeadingWrapper className="remove-item">
          <TopHeadingWrapper>
            <AlertIcon />
            <Heading>Remove 1 item</Heading>
          </TopHeadingWrapper>
          <Description className="h1r">Are you sure you want to remove this item?</Description>
        </HeadingWrapper>
      </CommonStyle>
      <DeleteButtonWrapper>
        <Button label="REMOVE" variant="outline" type="submit" className="skip" onClick={handleRemoveProduct} />
        <Line />
        <Button
          label="Move To Wishlist"
          variant="contained"
          type="submit"
          className="small"
          onClick={() => {
            if (customerId) {
              handleWishlistClick(productId)
              handleRemoveProduct(false)
            }
          }}
        />
      </DeleteButtonWrapper>
    </RemoveItemsContainer>
  )
}

export default RemoveItem

import axios from 'axios'
// import * as AxiosLogger from 'axios-logger'
const getDomainFromUrl = () => {
  const pathname =
    process.env.NODE_ENV == 'production' && process.env.REACT_APP_ENV == ''
      ? window.location.hostname
      : process.env.REACT_APP_PLOTCH_DOMAIN
  if (pathname) return pathname

  const pathSegments: any = pathname?.split('/').filter((segment) => segment.trim() !== '')
  return pathSegments[1]
}

const plotchDomain = getDomainFromUrl()
// Create an axios instance with default headers
const instance: any = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'X-VERSION-CODE': '8',
    'Cache-Control': 'no-cache',
    'PLOTCH-ACCOUNT-DOMAIN': plotchDomain,
    App_Authorization_Token: '11uhgfdfhgn!@fdDFDFv!@#$asfdsvcF',
    requestCurrency: '1',
    'Content-Type': 'application/json',
  },
})

const activeRequests: any = []

// Helper function to get a unique identifier for each request
const getRequestIdentifier = (config: any) =>
  `${config.method}-${config.url}-${JSON.stringify(config.params)}-${JSON.stringify(config.data)}`

instance.interceptors.response.use(
  (response: any) => {
    // Remove the request from the activeRequests array
    activeRequests.pop()
    return response
  },
  (error: any) => {
    activeRequests.pop()
    return Promise.reject(error)
  },
)

// instance.interceptors.response.use(AxiosLogger.requestLogger)
const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent)
}

// Function to update the X-Session, Authorization, and customerId headers
export const updateDynamicHeaders = (customHeader?: any) => {
  const sessionId = localStorage.getItem('sessionId')
  const authToken = localStorage.getItem('Authorization')
  const customerId = Number(localStorage.getItem('customerId'))
  const location: any = localStorage.getItem('location')
  const currentLocation = JSON.parse(location)
  const isMobile = isMobileDevice()
  if (customHeader) {
    if (customHeader?.productId) {
      instance.defaults.headers['productId'] = customHeader?.productId
    }
  }

  if (authToken) {
    instance.defaults.headers['Authorization'] = authToken
  } else if (sessionId) {
    instance.defaults.headers['X-Session'] = sessionId
  }

  if (customerId) {
    instance.defaults.headers['customerId'] = customerId
  }

  if (location && currentLocation?.latitude) {
    instance.defaults.headers['gps'] = `${currentLocation?.latitude}, ${currentLocation?.longitude}`
  }

  if (isMobile) {
    instance.defaults.headers['X-Client'] = 'android'
  }
}

// Call the function initially to set the headers if values exist
updateDynamicHeaders()
const checkIdentifier = (requestIdentifier: any, config: any) => {
  if (window.location.pathname == '/' || window.location.pathname.includes('/product/')) {
    if (
      !config?.url.includes('/v1/merchandising/getThemeDetails') &&
      !config?.url.includes('/v1/merchandising/navigationDetails') &&
      !config?.url.includes('/v1/user/getSession') &&
      !config?.url.includes('/v1/user/getVisitorDetails') &&
      !config?.url.includes('/v1/merchandising/getStorefrontDetailsFromDomain') &&
      !config?.url.includes('/v1/merchandising/dynamicHomepage') &&
      !config?.url.includes('/v1/search/autocomplete') &&
      !config?.url.includes('/v1/public/catalog/product') &&
      !config?.url.includes('/v1/catalog/vendorProducts') &&
      !config?.url.includes('/v1/merchandising/getWidgetProducts')
    ) {
      activeRequests.push(requestIdentifier)
    }
  } else if (!config?.url.includes('/v1/search/autocomplete')) {
    activeRequests.push(requestIdentifier)
  }
}

// Add a request interceptor to ensure dynamic headers are updated before each request
instance.interceptors.request.use(
  (config: any) => {
    // Retrieve updated values from localStorage
    const sessionId = localStorage.getItem('sessionId')
    const authToken = localStorage.getItem('Authorization') // Replace with actual logic to get auth token
    const customerId = Number(localStorage.getItem('customerId')) // Replace with actual logic to get customer ID
    const location: any = localStorage.getItem('location')
    const currentLocation = JSON.parse(location)
    const isMobile = isMobileDevice()
    const requestIdentifier = getRequestIdentifier(config)

    // Add the request to the activeRequests array
    checkIdentifier(requestIdentifier, config)

    // Dynamically update headers
    if (authToken) {
      config.headers['Authorization'] = authToken
    } else if (sessionId) {
      config.headers['X-Session'] = sessionId
    }

    if (customerId) {
      config.headers['customerId'] = customerId
    }

    if (location && currentLocation?.latitude) {
      config.headers['gps'] = `${currentLocation?.latitude}, ${currentLocation?.longitude}`
    }

    if (location) {
      instance.defaults.headers['city'] = currentLocation?.address?.city
    }

    if (isMobile) {
      instance.defaults.headers['X-Client'] = 'android'
    }

    return config
  },
  (error: any) => {
    return Promise.reject(error)
  },
)
export { activeRequests }
export default instance

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      stroke="var(--primary_icon_static_color)"
      // stroke="#121212"
      // stroke={theme.primaryIconStaticColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M13.439 5.625c-.153 2.066-1.72 3.75-3.438 3.75-1.72 0-3.288-1.684-3.438-3.75C6.407 3.476 7.93 1.875 10 1.875c2.07 0 3.594 1.64 3.438 3.75Z"
    />
    <path
      stroke="var(--primary_icon_static_color)"
      // stroke={theme.primaryIconStaticColor}
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M10 11.875c-3.398 0-6.848 1.875-7.486 5.414-.077.427.165.836.611.836h13.75c.447 0 .688-.41.611-.836-.638-3.539-4.088-5.414-7.486-5.414Z"
    />
  </svg>
)
export default SvgComponent

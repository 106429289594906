import styled from 'styled-components'

export const ProductSectionContainer = styled.div`
  display: flex;
  gap: 25px;
  padding: 20px 80px;
  @media (max-width: 768px) {
    margin-top: 0;
    padding: 0;
  }
`
export const ProductSectionWrapper = styled.div`
  /* width: 80%; */
  width: 100%;
  .load-more {
    margin-top: 20px;

    display: none;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  button {
    width: max-content;
  }

  .ant-pagination {
    background-color: white;
  }

  .ant-pagination-item {
    border: 1px solid black;
    color: black;
  }

  .ant-pagination-item-active {
    border-color: black;
    background-color: black;
  }

  .ant-pagination-item-active a {
    color: white;
  }

  .ant-pagination-item:hover,
  .ant-pagination-item:focus {
    border-color: black;
    background: white;
  }

  .ant-pagination-item:hover a,
  .ant-pagination-item:focus a {
    color: black;
  }
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link {
    border-color: black;
  }
  @media (max-width: 768px) {
    padding: 20px 0px 70px;
    width: 100%;

    .load-more {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .loadBtn {
      padding: 0px 20px;
    }
    .pagination {
      display: none;
    }
  }
`
export const WishlistProductSectionWrapper = styled.div`
  padding: 0;
  padding: 40px 74px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 768px) {
    padding: 20px 0 0;
    width: unset;
  }
`
export const NearStoreSectionWrapper = styled.div`
  /* width: 100%; */
  padding: 0;
  padding: 40px 74px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .load-more {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  button {
    width: max-content;
  }
  @media (max-width: 768px) {
    padding: 20px 0 0;
    width: unset;
    .load-more {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .loadBtn {
      padding: 0px 20px;
    }
  }
`
export const SimilarProductSectionWrapper = styled.div`
  width: 100%;
  padding-bottom: 30px;
  .slick-arrow {
    transform: translateY(-50px);
  }
  @media (max-width: 768px) {
    display: none;
  }
`
export const MobileSimilarProductSectionWrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    min-height: 62vh;
  }
`

export const ProductListWrapper = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 41px 27px;

  @media (max-width: 425px) {
    gap: unset;
    width: unset;
    grid-template-columns: 1fr 1fr;

    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    .product-wrapper {
      border: 1px solid #c7c6c6;
    }
  }
  @media (max-width: 768px) {
    gap: unset;
    width: unset;
    grid-template-columns: 1fr 1fr;

    .product-wrapper {
      border: 1px solid #c7c6c6;
    }
  }
`
export const WishListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 40px 55px;
  .square {
    height: 300px;
  }
  .vertical {
    height: 450px;
  }
  img {
    width: 300px;
    max-width: 100%;
    object-fit: contain;
  }
  .product-name {
    width: 175px !important;
  }
  @media (max-width: 768px) {
    gap: unset;
    width: unset;
    /* grid-template-columns: 1fr 1fr; */

    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    .product-wrapper {
      border: 1px solid #c7c6c6;
    }
  }
`
export const SimilarProductHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  .view-all {
    color: var(--secondary_text_link_color);
    cursor: pointer;
    padding-right: 10px;
  }
`
export const MobileSimilarProductHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding: 10px 20px 20px;
  .mobile-similar-heading {
    width: 100%;
  }
`
export const SimilarProductWrapper = styled.div`
  display: grid;
  flex-flow: wrap;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  grid-template-columns: repeat(5, 1fr);
  gap: 22.5px;
  @media screen and (max-width: 995px) {
    grid-template-columns: repeat(4, 1fr);
  }
  .similarImg {
    max-height: 300px;
    max-width: 252px;
  }
  @media screen and (max-width: 768px) {
    gap: unset;
    width: unset;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    .product-wrapper {
      border: 1px solid #c7c6c6;
      width: 100%;
    }
    .similarImg {
      max-height: 100px;
    }
  }
`
export const MobileSimilarProductWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    gap: 20px;
    padding-right: 20px;
    overflow-x: auto;
    /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); */
  }
`
export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .similarProducts {
    min-width: 233px;
  }

  :hover {
    background-color: #fff;
    box-shadow: 0 2px 17px 0 rgba(23, 84, 116, 0.18);
    color: #000 !important;
  }
  @media (max-width: 768px) {
    padding-top: unset;
    padding: unset;
    .similarImg {
      max-height: 250px;
    }
  }
`
export const StoreImageWrapper = styled.div`
  height: 72px;
  width: 72px;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 52px;
    height: 52px;
    border-radius: 500px;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    img {
      height: 42px;
      width: 42px;
    }
    height: 60px;
    width: 60px;
  }
`
export const NearMeStoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;

  :hover {
    background-color: #fff;
    box-shadow: 0 2px 17px 0 rgba(23, 84, 116, 0.18);
    color: #000 !important;
  }
  @media (max-width: 768px) {
    flex-direction: row;
    padding-top: unset;
    padding: unset;
    .similarImg {
      max-height: 250px;
    }
  }
`
export const SellerNearMeStoreWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* cursor: pointer; */
  padding: 10px 80px;
  background: linear-gradient(90deg, rgba(255, 62, 107, 0) 0%, rgba(255, 62, 107, 0.1) 100%);
  .storeTime {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .storeDivider {
    display: flex;
    align-items: center;
    margin: 32px;
    width: 1px;
    height: 32px;
    align-self: stretch;
    background: var(--primary_background_transparency_color);
  }

  @media (max-width: 768px) {
    padding-top: unset;
    padding: 10px 80px 10px 16px;

    padding: unset;
    margin-top: 14px;
    padding: 10px;
    gap: 12px;
    background: linear-gradient(270deg, rgba(255, 62, 107, 0.1) 0%, rgba(255, 62, 107, 0) 100%);

    .similarImg {
      max-height: 250px;
    }
    .storeDivider {
      display: none;
    }
    .storeTime {
      flex-flow: column;
      align-items: start;
    }
    .sellerAddr {
      width: 110px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      top: 2px;
      white-space: nowrap;
    }
    .storeMview {
      padding-top: 0;
    }
  }
`
export const MenuContainer = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  position: fixed;
  bottom: 9%;
  transform: translateX(-50%);
  left: 50%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border: 1px solid var(--primary_button_stroke_color_light);
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: #fff;
    }
  }
  :hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
    svg {
      path {
        stroke: #333;
      }
    }
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  .square {
    height: 300px;
  }
  .vertical {
    height: 450px;
  }
  img {
    /* width: 100%; */
    width: 300px;
    max-width: 100%;
    /* height: auto; */
    /* height: 300px; */
    object-fit: contain;
  }

  @media (max-width: 768px) {
    img {
      width: 100%;
      max-width: 100%;
      /* height: 248px; */
      object-fit: contain;
    }
  }
`
export const ProductDetailsWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: auto;
  .before-discount span {
    text-decoration: line-through;
  }
  .discount-details {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
  }
  .discount-percentage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 16px;
    border-radius: 8px;
    background: var(--primary_label_active_fill_color);
    color: var(--primary_text_color_light);
    padding: 1px 0px;
    /* font-family: 'Mulish';
    font-size: 10px;
    font-weight: 700; */
  }
  .heading-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;
  }
  .heading-details {
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
    gap: 4px;
  }
  button {
    height: 38px;
    margin-top: 5px;
  }
  .store-name {
    text-transform: capitalize;
    width: max-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .storeCity {
    width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
  .wishlist-icon {
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;
  }
  .svgIcon {
    align-items: baseline;
    flex-wrap: nowrap;
  }
  .svgIcon svg {
    height: 20px;
    width: 20px;
    /* height: 12px;
    width: 12px; */
  }
  .product-name,
  .subCategory {
    width: 195px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    text-transform: capitalize;
  }
  @media (max-width: 768px) {
    padding: 10px 8px;
    margin-top: unset;
    .product-name,
    .subCategory {
      width: 120px;
    }
  }
`
export const SellerProductDetailsWrapper = styled(ProductDetailsWrapper)`
  margin-top: unset;
  gap: 4px;
  width: auto;
  .storeAvailable {
    color: var(--primary_success_color);
    display: flex;
    gap: 10px;
    align-items: center;
    /* cursor: default; */
  }

  .storeName {
    white-space: nowrap;
    text-transform: capitalize;
  }
  .menuButton {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 13px;
    border: 1px solid var(--primary_button_stroke_color_light, #edecec);
    background: #fff;
    color: var(--primary_text_color_dark, #121212);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: min-content;
  }
  svg {
    width: unset;
    height: unset;
    /* cursor: default; */
  }
  .svgIcon {
    align-items: center;
    flex-wrap: nowrap;
  }
  @media screen and (max-width: 768px) {
    .storeName {
      /* white-space: unset !important; */
      overflow: hidden;
      width: 200px;
      text-overflow: ellipsis;
    }
  }
`

export const StoreImageWrapperList = styled.div`
  img {
    aspect-ratio: 1;
    border-radius: 18px;
  }
  @media screen and (max-width: 768px) {
    img {
      min-width: 96px;
      width: 96px;
    }
  }
`
export const StoreListWrapper = styled(WishListWrapper)`
  @media screen and (max-width: 768px) {
    grid-template-columns: unset !important;
    padding: 0 18px;
    row-gap: 16px;
    .product-wrapper {
      border: none;
      gap: 17px;
    }
    .heading-wrapper {
      width: 150px;
      overflow: hidden;
    }
    svg {
      width: 12px;
      height: 12px;
    }
  }
`

import { useHistory } from 'react-router-dom'
import BackArrow from 'assets/svg/BackArrow'
import { AccountWrappers, BackWrapper } from 'styles/pages/MyAccount'
import { CommonStyle } from 'styles/pages/Common'
import PersonIcon from 'assets/svg/PersonIcon'
import { menuOptions } from 'views/MyAccountView/accoutMenuOption'
import AccountMenu from 'components/AccountMenu'
import Button from 'components/Button'
import APIS from 'constants/api'
import { useEffect } from 'react'
import useGet from 'hooks/useGet'
import { useLogoutApi } from 'hooks/useLogoutApi'
import { HomeRoute, MyProfileRoute } from 'constants/routes'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'

const MyAccountView: React.FC = () => {
  const logout = useLogoutApi()
  const navigate = useHistory()
  const customerId = localStorage.getItem('customerId')
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  const handleBack = () => {
    navigate.push(HomeRoute.path)
    // navigate.goBack()
  }

  const { refetch: refetchProfile, data } = useGet('profile-data', `${APIS.GET_PROFILE}?customerId=${customerId}`)
  const handleLogout = () => {
    logout()
  }
  const handleEditClick = () => {
    const accountEditData = {
      event: 'navigation_interaction',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      cta_text: 'edit',
    }
    TagManager.dataLayer({ dataLayer: accountEditData })
    navigate.push(MyProfileRoute.path)
  }

  useEffect(() => {
    if (customerId && localStorage.getItem('sessionId')) {
      refetchProfile()
    }
  }, [])

  return (
    <AccountWrappers>
      <CommonStyle>
        <BackWrapper className="padding_full_pages" onClick={handleBack}>
          <BackArrow />
          <div className="h1b">ACCOUNT</div>
        </BackWrapper>
      </CommonStyle>
      <div className="account-main-div">
        <CommonStyle style={{ width: '100%' }}>
          <div className="profile-section-div">
            <div className="profile-section-icon svgIcon">
              <PersonIcon />
              <div className="profile-info">
                <div className="profile-name h0b">
                  {data?.d?.firstName && data?.d?.firstName ? data.d.firstName : 'User'}{' '}
                  {data?.d?.lastName && data?.d?.lastName ? data.d.lastName : 'Name'}
                </div>
                <div className="profile-number h2r">{data?.d?.mobile ? data.d.mobile : ''}</div>
              </div>
            </div>

            <div className="profile-edit-btn">
              <a onClick={handleEditClick} className="h2b">
                EDIT
              </a>
            </div>
          </div>
          <div className="account-menu-list padding_full_page">
            <AccountMenu options={menuOptions} />
          </div>
        </CommonStyle>
        <div className="bottomButton">
          <Button label="LOGOUT" variant="standard" type="button" onClick={handleLogout} className="logoutBtn" />
        </div>
      </div>
    </AccountWrappers>
  )
}

export default MyAccountView

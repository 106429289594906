import TermsAndCondition from 'views/TermsAndCondition'
import PrivateLayout from 'components/Layouts/private'

const TermsAndCondionPage = () => {
  return (
    <PrivateLayout>
      <TermsAndCondition />
    </PrivateLayout>
  )
}

export default TermsAndCondionPage

export const orders = [
  {
    orderId: 10770176,
    status: 'Failed',
    placedOn: 'Fri, 08 Mar 2024, 12:58PM',
    items: [
      {
        name: 'Embroidered Ne....  (1 Qty, Red)',
        quantity: 1,
        color: 'Red',
        deliveryDate: '8th March',
      },
    ],
  },
]

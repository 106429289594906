import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import 'react-phone-number-input/style.css'
import TextInput from 'components/TextField'
import MobileInput from 'components/MobileInput'
import BorderedButton from 'components/BorderedButton'
import PincodeField from 'components/PinCodeField'
// import { HomeRoute } from 'constants/routes'
import APIS from 'constants/api'
import { SnackBarContext } from 'context/SnackBarContext'
import usePost from 'hooks/usePost'
import { tabsFields } from 'views/SaveAddress/inputData'
import { ADDRESS_VALIDATION_SCHEMA } from 'utils/validations/loginValidation'
import BackArrow from 'assets/svg/BackArrow'
import { TextWrapper, ErrorMessage, TextWrappers, TextLabel } from 'styles/pages/Login'
import {
  MainAddressWrapper,
  TopHeading,
  TabsWrapper,
  TabsName,
  AddressFormWrapper,
  DetailWrapper,
  TopWrapper,
  FormFieldsWrapper,
} from 'styles/views/SaveAddress'
import { BackAddressWrapperDisplay } from 'styles/pages/Login'
import TagManager from 'react-gtm-module'
import { pageData } from 'utils/datalayer/pageData'
import { useTheme } from 'context/themeContext'

interface AddressData {
  pincode: string
  county: string
  state: string
  district: string
  town: string
  country: string
  city?: string // Assuming 'city' is also part of the data
}
interface LocationState {
  hasLocation?: boolean
  addressData?: AddressData // Adjust this type based on your actual data
}

const SaveAddressView = ({ addressId }: any) => {
  const [label, setLabel] = useState('Home')
  const [pinCode, setPinCode] = useState<string>('')
  const { setShowSnackBar } = useContext(SnackBarContext)
  const [filteredAddress, setFilteredAddress] = useState<any>(null)
  const [check, setCheck] = useState(false)
  const [checkAddress, setCheckAddress] = useState(false)
  const [address, setAddress] = useState<any>()
  const jsonData: any = localStorage.getItem('myAddress')
  const addressdata = JSON.parse(jsonData)
  const customerId = localStorage.getItem('customerId')
  const mobileNumber = localStorage.getItem('mobile')
  const navigate = useHistory()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const location = useLocation()
  const { hasLocation, addressData } = (location.state as LocationState) || {}
  const { storesData } = useTheme()

  const { mutateAsync } = usePost()
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(ADDRESS_VALIDATION_SCHEMA),
    defaultValues: {
      mobile: mobileNumber,
      first_name: '',
      last_name: '',
      email: '',
      pincode: '',
      country: 'India',
      flat: '',
      area: '',
      landmark: '',
      city: '',
      state: '',
    },
  })
  const checkPincode = watch()
  const checkPincodeLength = checkPincode?.pincode?.length === 6

  const submitAddressForm = async (data: any) => {
    try {
      const res = await mutateAsync({
        url: APIS.ADD_ADDRESS,
        payload: {
          telephone: mobileNumber,
          firstName: data?.first_name,
          lastName: data?.last_name,
          email: data?.email,
          pincode: data?.pincode,
          country: data?.country,
          address: `${data?.flat},${data?.area},${data?.landmark},${data?.city}`,
          city: data?.city,
          state: data?.state,
          landmark: data?.landmark,
          building: data?.flat,
          customerId: customerId,
          countryPhoneCode: '+91',
          countryId: 'IN',
          networkType: 1,
          networkSpeed: 0,
          addressType: label,
        },
      })
      if (res) {
        const newAddressDataToPush = {
          event: 'save_address_interaction',
          page_type: GDL_PAGE_TYPE,
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          cta_text: 'save_address',
          section_name: 'add_new_address',
        }
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: newAddressDataToPush })
        setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
        navigate.goBack()
      }
    } catch (error: any) {
      setShowSnackBar({ open: true, message: `${error?.res?.m}`, severity: 'error' })
      return error
    }

    return data
  }
  const handleBack = () => {
    navigate.goBack()
  }
  const [activeTab, setActiveTab] = useState(0)
  const handleTabClick = (index: number, item: any) => {
    setActiveTab(index)
    setLabel(item.name)
  }
  const getAddressDetails = async () => {
    try {
      const response = await axios.get(`https://api.postalpincode.in/pincode/${checkPincode?.pincode}`)
      if (response.data[0]?.Status === 'Error') {
        setShowSnackBar({ open: true, message: 'Invalid Pin code', severity: '' })
        setValue('area', '')
        setValue('state', '')
        setValue('city', '')
        setValue('country', '')
      } else if (response.data && response.data[0] && response.data[0].PostOffice) {
        const { State, Block, Country, District } = response.data[0].PostOffice[0]
        const postOffices = response.data[0].PostOffice
        const nameList = postOffices.map((postOffice: { Name: string }) => postOffice.Name)
        setValue('area', nameList[0])
        setValue('state', State)
        setValue('city', Block === 'NA' ? District : Block)
        setValue('country', Country)
        clearErrors('area')
        clearErrors('state')
        clearErrors('city')
        clearErrors('country')
      }
    } catch (err: any) {
      setShowSnackBar({ open: true, message: `${err.response.data.error}`, severity: '' })
      err.response.data.error
    }
  }
  const updateAddressForm = async (data: any) => {
    try {
      const res = await mutateAsync({
        url: APIS.UPDATE_ADDRESS,
        payload: {
          customerId: customerId,
          email: data?.email,
          pincode: data?.pincode,
          city: data?.city,
          state: data?.state,
          firstName: data?.first_name,
          lastName: data?.last_name,
          // address: 'fsd',
          addressType: label,
          landmark: data?.landmark,
          telephone: data?.mobile,
          countryPhoneCode: '+91',
          countryId: 'IN',
          addressId: addressId,
          country: 'IN',
          // address2: 'fsd',
          building: data?.flat,
          locality: data?.area,
          networkType: 1,
          networkSpeed: 0,
        },
      })
      if (res) {
        const updateAddressDataToPush = {
          event: 'save_address_interaction',
          page_type: GDL_PAGE_TYPE,
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          cta_text: 'update_address',
          section_name: 'edit_address',
        }
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: updateAddressDataToPush })

        localStorage.setItem('postcode', data?.pincode)
        setShowSnackBar({ open: true, message: `${res.m}`, severity: '' })
        navigate.goBack()
      }
    } catch (error) {
      return error
    }

    return data
  }
  const updateAddressFormLocation = async (data: any) => {
    try {
      const res = await mutateAsync({
        url: APIS.ADD_ADDRESS,
        payload: {
          customerId: customerId,
          email: data?.email,
          pincode: data?.pincode,
          city: data?.city,
          state: data?.state,
          firstName: data?.first_name,
          lastName: data?.last_name,
          // address: 'fsd',
          addressType: label,
          landmark: data?.landmark,
          telephone: data?.mobile,
          countryPhoneCode: '+91',
          countryId: 'IN',
          addressId: addressId,
          country: 'IN',
          // address2: 'fsd',
          building: data?.flat,
          locality: data?.area,
          networkType: 1,
          networkSpeed: 0,
        },
      })
      if (res) {
        localStorage.setItem('postcode', data.pincode)

        setShowSnackBar({ open: true, message: `${res.m}`, severity: '' })
        navigate.goBack()
      }
    } catch (error) {
      return error
    }

    return data
  }
  const otpValidator = (value: any) => {
    const regex = /^[0-9]*$/
    return regex.test(value) && value.length <= 6
  }

  const [updatedPin, setUpdatedPin] = useState(false)
  const handleChange = (value: string) => {
    setUpdatedPin(true)
    setPinCode(value)
  }

  const getAddresses = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.ADDRESS_LIST,
        payload: {
          customerId: customerId,
          networkType: 1,
          networkSpeed: 0,
        },
      })
      if (res) {
        setAddress(res?.d?.addressList)
      }
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    if (addressId) {
      getAddresses()
    }
  }, [addressId])

  useEffect(() => {
    if (addressdata) {
      setCheckAddress(true)
    }
  }, [addressdata])

  useEffect(() => {
    if (addressId && address) {
      const filtered = address.find((addr: any) => addr.addressId === addressId)
      setFilteredAddress(filtered || null)
      setCheck(true)
    }
  }, [address, addressId])

  useEffect(() => {
    if (check) {
      setValue('mobile', filteredAddress?.telephone)
      setValue('first_name', filteredAddress?.firstName)
      setValue('last_name', filteredAddress?.lastName)
      setValue('email', filteredAddress?.email)
      !isNaN(Number(filteredAddress?.postcode)) && setValue('pincode', filteredAddress?.postcode ?? '')
      setValue('country', filteredAddress?.country)
      setValue('flat', filteredAddress?.street1)
      setValue('landmark', filteredAddress?.landmark)
      setValue('flat', filteredAddress?.building)
      setValue('city', filteredAddress?.city)
      setValue('state', filteredAddress?.region)
      setValue('area', filteredAddress?.locality)
      filteredAddress?.label && setLabel(filteredAddress?.label)
    }
  }, [check])

  useEffect(() => {
    if (hasLocation && addressData && Object.keys(addressData)?.length != 0) {
      setValue('city', addressData.city || addressData.town || '')
      setValue('state', addressData?.state || addressData?.district || '')
      if (!isNaN(Number(addressData?.pincode))) {
        setValue('pincode', addressData?.pincode || '')
      }
    }
  }, [addressData])

  useEffect(() => {
    if (checkAddress) {
      !isNaN(Number(addressdata?.pincode)) &&
        setValue('pincode', addressdata?.pincode ?? addressdata?.pincode?.postcode)
      setValue('country', addressdata?.country)
      setValue('city', addressdata?.district)
      setValue('state', addressdata?.state)
    }
  }, [checkAddress])

  useEffect(() => {
    if (checkPincodeLength && updatedPin) {
      getAddressDetails()
    }
  }, [checkPincodeLength, updatedPin])

  return (
    <MainAddressWrapper>
      <BackAddressWrapperDisplay onClick={handleBack} className="h1b">
        <BackArrow />
        {addressId ? 'EDIT ADDRESS' : 'ADD ADDRESS'}
      </BackAddressWrapperDisplay>
      <AddressFormWrapper
        onSubmit={handleSubmit(
          addressId ? updateAddressForm : addressdata ? updateAddressFormLocation : submitAddressForm,
        )}
      >
        <FormFieldsWrapper>
          <TopWrapper>
            <TopHeading>{addressId ? 'Update' : 'Save'} address as</TopHeading>
            <TabsWrapper>
              {tabsFields?.map((item: any, index: number) => {
                return (
                  <TabsName
                    key={index}
                    onClick={() => handleTabClick(index, item)}
                    isActive={label ? label.toLowerCase() == item.name.toLowerCase() : activeTab === index}
                  >
                    {item?.name}
                  </TabsName>
                )
              })}
            </TabsWrapper>
          </TopWrapper>
          <DetailWrapper>
            <TextWrappers isError={errors?.first_name?.message ? true : false}>
              <TextInput placeholder="First name *" control={control} name="first_name" />
              <TextLabel className={`h3b addressFields ${control?._formValues?.first_name ? `showLable` : ``}`}>
                First Name
              </TextLabel>
              <ErrorMessage className="h3r">{errors?.first_name?.message}</ErrorMessage>
            </TextWrappers>
            <TextWrappers isError={errors?.last_name?.message ? true : false}>
              <TextInput placeholder="Last name *" control={control} name="last_name" />
              <TextLabel className={`h3b addressFields ${control?._formValues?.last_name ? `showLable` : ``}`}>
                Last Name
              </TextLabel>
              <ErrorMessage className="h3r">{errors?.last_name?.message}</ErrorMessage>
            </TextWrappers>
          </DetailWrapper>
          <TextWrapper>
            <MobileInput
              control={control}
              name="mobile"
              className={`${errors?.mobile?.message ? `errorBlock` : ``} mobileInput`}
            />
            <TextLabel className={`h3b addressFields ${control?._formValues?.last_name ? `showLable` : ``}`}>
              Mobile
            </TextLabel>
            <ErrorMessage className="h3r">{errors?.mobile?.message}</ErrorMessage>
          </TextWrapper>
          <TextWrappers isError={errors?.email?.message ? true : false}>
            <TextInput placeholder="Email" control={control} name="email" />
            <TextLabel className={`h3b addressFields ${control?._formValues?.email ? `showLable` : ``}`}>
              Email
            </TextLabel>
            <ErrorMessage className="h3r">{errors?.email?.message}</ErrorMessage>
          </TextWrappers>
          <DetailWrapper>
            <TextWrappers isError={errors?.pincode?.message ? true : false}>
              <PincodeField
                placeholder="Pincode *"
                control={control}
                name="pincode"
                handleChange={handleChange}
                maxLength={6}
                validator={otpValidator}
                value={pinCode}
              />
              <TextLabel className={`h3b addressFields ${control?._formValues?.pincode ? `showLable` : ``}`}>
                Pincode
              </TextLabel>
              <ErrorMessage className="h3r">{errors?.pincode?.message}</ErrorMessage>
            </TextWrappers>
            <TextWrappers isError={errors?.country?.message ? true : false}>
              <TextInput placeholder="Country " control={control} name="country" />
              <TextLabel className={`h3b addressFields ${control?._formValues?.country ? `showLable` : ``}`}>
                Country
              </TextLabel>
              <ErrorMessage className="h3r">{errors?.country?.message}</ErrorMessage>
            </TextWrappers>
          </DetailWrapper>
          <TextWrappers isError={errors?.flat?.message ? true : false}>
            <TextInput placeholder="Flat / House no / Floor / Building *" control={control} name="flat" />
            <ErrorMessage className="h3r">{errors?.flat?.message}</ErrorMessage>
          </TextWrappers>
          <TextWrappers isError={errors?.area?.message ? true : false}>
            <TextInput placeholder="Area / Sector / Locality *" control={control} name="area" />
            <ErrorMessage className="h3r">{errors?.area?.message}</ErrorMessage>
          </TextWrappers>
          <TextWrappers isError={errors?.landmark?.message ? true : false}>
            <TextInput placeholder="Nearby landmark (optional)" control={control} name="landmark" />
            <ErrorMessage className="h3r">{errors?.landmark?.message}</ErrorMessage>
          </TextWrappers>
          <DetailWrapper>
            <TextWrappers isError={errors?.city?.message ? true : false}>
              <TextInput placeholder="City / District*" control={control} name="city" disabled />
              <TextLabel className={`h3b addressFields ${control?._formValues?.city ? `showLable` : ``}`}>
                City
              </TextLabel>
              <ErrorMessage className="h3r">{errors?.city?.message}</ErrorMessage>
            </TextWrappers>
            <TextWrappers isError={errors?.state?.message ? true : false}>
              <TextInput placeholder="State *" control={control} name="state" disabled />
              <TextLabel className={`h3b addressFields ${control?._formValues?.state ? `showLable` : ``}`}>
                State
              </TextLabel>
              <ErrorMessage className="h3r">{errors?.state?.message}</ErrorMessage>
            </TextWrappers>
          </DetailWrapper>
        </FormFieldsWrapper>
        <BorderedButton label={addressId ? 'Update Address' : 'Add Address'} variant="contained" type="submit" />
      </AddressFormWrapper>
    </MainAddressWrapper>
  )
}

export default SaveAddressView

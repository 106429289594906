import styled from 'styled-components'

export const TrackOrderStepperWrapper = styled.div`
  padding: 10px 10px 10px 20px;

  .ant-steps-item-active .ant-steps-item-icon .anticon {
    color: var(--primary_success_color);
    svg {
      fill: var(--primary_success_color);
    }
  }

  .ant-steps-item-finish .ant-steps-item-icon .anticon {
    color: var(--primary_success_color);
    svg {
      fill: var(--primary_success_color);
    }
  }
  .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--primary_success_color);
  }
  .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: var(--primary_success_color);
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    padding-top: 32px;
  }
  .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
    padding-bottom: 40px;
  }
  .description-color {
    color: var(--primary_text_color_dark);
  }
  .stepper-title {
    padding-top: 4px;
  }
`
export const TrackDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  svg {
    width: 16px;
    height: 16px;
  }
  .detail {
    display: flex;
    align-items: center;
    background-color: #edecec80;
    gap: 4px;
    padding: 4px 4px 4px 6px;
  }
  .track-detail {
    display: flex;
    gap: 10px;
  }
  a {
    text-decoration: none;
  }
`
export const CourierInformation = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--primary_text_color_darkgrey);
`
export const TrackingInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  color: var(--primary_text_color_darkgrey);
`
export const TrackDetailPageWrapper = styled.div`
  padding: 13px 21px 23px 17px;
  height: 40vh;
  @media (max-width: 768px) {
  }
`
export const StepperWrapper = styled.div`
  /* max-height: 25vh;
  overflow-y: auto; */
  padding-top: 22px;
`

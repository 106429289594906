import styled from 'styled-components'

export const HomeMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 65px);
  .mobile-display,
  .mobile-display-top {
    display: none;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    .mobile-display {
      padding-top: 15px;
      display: block;
    }
    .mobile-display-top {
      padding-top: 1px;
      display: block;
    }
    .desktop-display {
      display: none;
    }
  }
`
export const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--primary_stroke_color_light);
  /* box-shadow: 0 3px 15px 0 rgb(0, 0, 0, 0.11); */
  @media (max-width: 768px) {
    border: unset;
    .desktop-display {
      display: none;
    }
  }
`
export const MobileTopBar = styled.div`
  display: none;

  .searchMobile input::placeholder::after {
    content: '|';
    animation: cursor 1.1s infinite step-start;
  }

  @keyframes cursor {
    50% {
      opacity: 0;
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 18px 14px 0;
    input {
      height: 30px;
    }
    .searchMobile {
      width: 100%;
    }
  }
`

export const ProductListWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 18px;
  /* padding: 18px 10px; */

  > ul {
    width: 500px;
  }
  .ant-menu-submenu-title {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
export const ProductListContainer = styled.div`
  border-bottom: 2px solid #fff;
  padding: 18px;
  /* background-image: linear-gradient(to right, red 100%, transparent 0);
  background-position: bottom left;
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.3s ease-in-out; */

  &:hover {
    /* background-size: 100% 2px; */
    border-bottom: 2px solid var(--primary_brand_color);
  }
`
export const ProductSubListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 18px;
  padding: 18px 10px;
  position: absolute;

  @media (max-width: 768px) {
    display: none;
  }
`
export const ProductCategoryList = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const DesktopWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
export const MobileCategoryWrapper = styled.div`
  padding: 0 0px 40px;
  margin-top: 10px;
  img {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0;
    padding: 0px 14px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 185px);
    overflow-y: auto;
    margin-top: 20px;
    margin-bottom: 70px;

    img {
      width: 100%;
      height: auto;
    }
  }
`
export const ItemsCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .slider {
    width: auto;
    box-sizing: border-box;
    margin-top: 10px;
    text-align: center;
  }
  .imageWithLabel {
    display: inline-flex;
    flex-direction: column;
  }
  .ant-carousel .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: var(--primary_text_link_color);
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -10px;
  }
  .carousel-rect {
    button {
      color: var(--primary_brand_color);
      background-color: #fff;
      opacity: 3;
      border-radius: 16px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
    }
  }

  .slick-track {
    display: flex;
    gap: 10px;
    justify-content: space-around;
    margin: 0 auto;
  }

  .slick-slide {
    width: 100% !important;
  }

  label.textLabel {
    color: #000;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
  }
  .slick-prev,
  .slick-next {
    color: var(--primary_brand_color);
    background-color: hsla(0, 0%, 100%, 0.98);
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 11%);
    transition: opacity 0.1s ease-in;
    border-radius: 4px 0px 0px 4px;
  }
  .slick-prev::after,
  .slick-next:after {
    top: unset;
    inset-inline-start: unset;
    height: 15px;
    width: 15px;
  }
  .slick-prev::after {
    left: 12px;
  }
  .slick-next:after {
    right: 10px;
  }
  .hide-on-mobile {
    display: none;
  }
`
export const StaticCarouselContainer = styled.div`
  position: relative;
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    color: var(--primary_brand_color);
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev::after {
    content: '';
  }
  .slick-next:after {
  }
  .rectangle_image_carousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rectangleCarousel {
    width: 100%;
    padding: 0px 5px;
    min-width: 280px !important;
    box-sizing: border-box;
  }

  .image-container {
    width: 100%;
    /* height: 200px; */
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 786px) {
    .rectangleCarousel {
      min-width: 31vw !important;
    }
  }
`
export const EditorWrapper = styled.div`
  padding: 0px 25px;
  div {
    width: 100% !important;
    min-width: 100% !important;
  }

  li {
    list-style: unset !important;
  }
`

export const StaticCarousel = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-around;
  overflow-x: auto;
  .roundCarousel {
    border-radius: 50%;
  }
  .slider {
    min-width: 200px;
    object-fit: contain;
  }
  .roundCarousel img {
    min-height: 200px;
    height: 70px;
    width: auto;
  }
  .rectangleCarousel img {
    height: 100%;
    width: 100%;
  }
  .static-roundCarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  a {
    text-decoration: none !important;
  }
  ::-webkit-scrollbar {
    padding-top: 10px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 10px;
  }
  @media screen and (max-width: 786px) {
    .roundCarousel.slider {
      min-width: 18vw;
    }
    .rectangleCarousel.slider {
      min-width: 31vw;
      width: 98%;
    }
    .rectangleCarousel img {
      min-height: 10vh;
    }
    .roundCarousel img {
      min-height: unset;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
export const ItemCategoryHeading = styled.div`
  color: var(--primary_text_color_dark);
  /* H1-B */
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
`
// export const NavigationContainer = styled.div`
//   padding: 30px;
//   background-color: white;
//   width: 100%;
//   max-width: 1058px;
//   position: absolute;
//   left: 50%;
//   right: 50%;
//   top: 50px;
//   display: grid;
//   gap: 40px 0;
//   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   border-top: 1px solid black;
//   max-height: 30vh;
//   overflow-y: auto;
//   .grid-item {
//     grid-column: 1 / -1;
//   }
//   z-index: 999;
// `

export const NavigationContainer = styled.div`
  padding: 20px 20px 55px;
  background-color: white;
  width: 100%;
  max-width: 1058px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 58px;
  display: flex;
  display: grid;
  /* gap: 20px 0; */
  grid-template-columns: repeat(auto-fit, minmax(241px, 1fr));
  /* border-top: 1px solid black; */
  /* height: 100%; */
  max-height: 493px;
  /* max-height: 30vh; */
  box-shadow: 0 3px 15px 0 rgb(0, 0, 0, 0.11);

  overflow-y: auto;
  .grid-item {
    grid-column: 1 / -1;
  }
  z-index: 999;
`

export const NavigationOptionWrapper = styled.div`
  /* width: 100%;
  max-width: 241px;
  min-width: 241px; */
  /* border-right: 1px solid black; */
  padding: 4px 20px;
  ul,
  li {
    list-style: none;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: var(--primary_text_color_dark) !important;
  }
`
export const NavigationHeading = styled.div`
  font-weight: 500;
`
export const StoreCategoryWrapper = styled.div``
export const WidgetWrap = styled.div`
  padding: 0 10px;
  .view-all {
    width: max-content;
    height: max-content;
    padding: 4px 10px;
  }
`
export const WidgetsImageWrapper = styled.div`
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`
export const BodyContainer = styled.div``
export const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .small {
    width: 160px;
  }
`

const getCustomisedStyles = ({ css }: any) => {
  let customCss = ``
  if (css?.backgroundColour) customCss += `background-color: ${css.backgroundColour};`

  if (css?.backgroundImage) customCss += `background-image: url(${css.backgroundImage});`

  if (css?.fontSize) customCss += `font-size: ${css.fontSize};`

  if (css?.leftRightMargin) {
    customCss += `margin-left: ${css.leftRightMargin};`
    customCss += `margin-right: ${css.leftRightMargin};`
  }

  if (css?.topBottomMargin) {
    customCss += `margin-top: ${css.topBottomMargin};`
    customCss += `margin-bottom: ${css.topBottomMargin};`
  }

  if (css?.topBottomPadding) {
    customCss += `padding-top: ${css.topBottomPadding};`
    customCss += `padding-bottom: ${css.topBottomPadding};`
  }

  if (css?.leftRightPadding) {
    customCss += `padding-left: ${css.leftRightPadding};`
    customCss += `padding-right: ${css.leftRightPadding};`
  }

  if (css?.textColour) customCss += `color: ${css.textColour} !important;\n`

  customCss += 'label.labelText {'
  customCss += 'width: 100%;'
  customCss += 'display: flex;'
  customCss += 'justify-content: center;'
  customCss += 'font-weight: 700;'
  customCss += 'text-align: center;'

  if (css?.textColour) customCss += `color: ${css.textColour} !important;`
  if (css?.fontSize) customCss += `font-size: ${css.fontSize} !important;`
  customCss += '}'

  if (css?.removeColSpace == 0) {
    customCss += 'padding:0;'
  }

  return customCss
}

const getCustomisedStyleBlock = ({ dimensions }: any) => {
  let gridStyle = ''
  const { cols, rows } = dimensions
  if (cols == 1 && rows == 1) {
    gridStyle = ''
  } else {
    gridStyle = ' display: grid;'
    gridStyle += `grid-template-rows: repeat(${rows}, 1fr);`
    gridStyle += `grid-template-columns: repeat(${cols}, 1fr);`
  }

  gridStyle += '@media (max-width: 786px) {'
  gridStyle += 'grid-template-columns: auto'
  gridStyle += '}'

  return gridStyle
}

export const BlockDiv = styled.div<{ css: any; dimensions: any }>`
  ${({ css }) => getCustomisedStyles({ css })};
  ${({ dimensions }) => getCustomisedStyleBlock({ dimensions })}
  /* margin-top: 20px; */
  .imageResponsive {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 100%;
    height: auto;
  }
  .carouselImage {
    width: 100%;
  }
  @media (max-width: 786px) {
    label.labelText {
      margin-bottom: 24px;
    }
  }
`

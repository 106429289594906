import styled from 'styled-components'
export const CommonStyle = styled.div`
  background: var(--primary_box_fill_color);
  .h4b {
    font-family: 'Mulish';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .h4r {
    font-family: 'Mulish';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .h3b {
    font-family: 'Mulish';
    font-size: 12px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .h3r {
    font-family: 'Mulish';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .h2b {
    font-family: 'Mulish';
    font-size: 14px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
  }
  .h2r {
    font-family: 'Mulish';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .h1b {
    font-family: 'Mulish';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .h1r {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .h0b {
    font-family: 'Mulish';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .h0r {
    font-family: 'Mulish';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ultra-bold {
    color: var(--primary_text_color_dark);
    font-family: 'Mulish';
    font-size: 20px;
    font-weight: 700;
  }
  .svgIcon {
    /* width: 20px;
    height: 20px; */
  }
  .plotch_a_link {
    cursor: pointer;
    text-decoration: none !important;
  }
  .plotch_a_link :hover > a:hover {
    text-decoration: none !important;
    color: unset !important;
  }
  @media screen and (max-width: 768px) {
    .h4b {
      font-family: 'Mulish';
      font-size: 8px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .h4r {
      font-family: 'Mulish';
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`

export const CommonPageWrapper = styled.div`
  padding: 0px 21px 20px;
  height: 70vh;
  overflow: auto;
  @media screen and (max-width: 768px) {
    height: 85vh;
    padding-bottom: unset;
  }
`
export const LabelForm = styled.div`
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
`
export const FileUpload = styled.div`
  /* border: 1px dashed #edecec; */
  /* padding: 10px; */
`
export const Note = styled.div`
  font-size: 10px;
  margin-top: 5px;
`

export const NoDataDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
`

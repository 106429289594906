import { useContext } from 'react'
import { useQuery } from 'react-query'
import axiosInstance, { updateDynamicHeaders } from 'services/axiosInstance'
import { SnackBarContext } from 'context/SnackBarContext'
import { LoginRoute } from 'constants/routes'

const useGet = (key: string, url: string, configs?: any) => {
  const { setShowSnackBar } = useContext(SnackBarContext)
  const get = async () => {
    try {
      const { data } = await axiosInstance.get(url)
      return data
    } catch (error: any) {
      if (error?.response) {
        const apiError = error.response.data
        if (apiError.s === 0) {
          if (apiError?.m == 'Invalid Token') {
            localStorage.clear()
            document.cookie = 'x-plotch-token=;expires=-1;path=/'
            document.cookie = 'Authorization=;expires=-1;path=/'
            document.cookie = 'x-plotch-customer-id=;expires=-1;path=/'
            document.cookie = 'x-plotch-customer-mobile=;expires=-1;path=/'
            document.cookie = 'plotch_address_id=;expires=-1;path=/'
            document.cookie = 'source=;expires=-1;path=/'
            updateDynamicHeaders()
            window.location.href = LoginRoute.path
          } else {
            setShowSnackBar({ open: true, message: `${apiError?.m}`, severity: '' })
          }
        }
      }
    }
  }
  const defaultConfig = {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...configs,
  }
  return useQuery(key, get, defaultConfig)
}

export default useGet

import * as React from 'react'
import { SVGProps } from 'react'
// import { theme } from 'theme'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      // stroke={theme.primaryIconStaticColor}
      stroke="var(--primary_icon_static_color)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M17.5 17.5V9.376M2.501 9.375V17.5M14.94 1.875H5.06c-.852 0-1.62.468-1.95 1.19L1.417 6.756C.848 8 1.794 9.407 3.26 9.453h.078c1.227 0 2.221-.984 2.221-2.04 0 1.054.995 2.04 2.221 2.04 1.227 0 2.219-.914 2.219-2.04 0 1.054.994 2.04 2.22 2.04 1.227 0 2.222-.914 2.222-2.04 0 1.126.994 2.04 2.22 2.04h.077c1.467-.046 2.412-1.454 1.843-2.696L16.89 3.064c-.33-.72-1.099-1.19-1.95-1.19ZM1.25 18.125h17.5M5.313 11.25h3.125a.937.937 0 0 1 .938.938v3.438H4.375v-3.438a.938.938 0 0 1 .938-.938ZM11.251 18.126v-5.938a.938.938 0 0 1 .938-.938h2.5a.938.938 0 0 1 .938.938v5.938"
    />
  </svg>
)
export default SvgComponent

import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SnackBarContext } from 'context/SnackBarContext'
import { Radio, Skeleton } from 'antd'
import Drawer from '@mui/material/Drawer'
// import { LoaderContext } from 'context/loader'
import { CouponRoute, EmptyCartRoute } from 'constants/routes'
import APIS from 'constants/api'
import { useTheme } from 'context/themeContext'
import useGet from 'hooks/useGet'
import usePost from 'hooks/usePost'
import RemoveItem from 'views/RemoveItem'
import SavedAddresses from 'views/SavedAddresses'
import ReturnCancellationPolicy from 'views/ReturnCancellationPolicy'
import DownArrow from 'assets/svg/DownArrow'
import RightArrow from 'assets/svg/RightArrow'
import UpArrow from 'assets/svg/UpArrow'
import TrashIcon from 'assets/svg/TrashIcon'
import { CommonStyle } from 'styles/pages/Common'
import { pageData } from 'utils/datalayer/pageData'
import {
  OrderDetailsWrapper,
  DeliveryWrapper,
  DividerLine,
  OrderDetailContainer,
  CoupanDetailContainer,
  PaymentOptionsContainer,
} from 'styles/views/PaymentOrderDetails'
import OssError from 'views/OssError'
import { CouponNameWrapper, RemoveWrapper, BestCouponWrappers } from 'styles/views/CouponDetails'
import CutIcon from 'assets/svg/CutIcon'
import TagManager from 'react-gtm-module'
import { AddressInteractionData } from 'utils/datalayer/addressInteractionData'

interface IProductList {
  productId: number
  tax: number
  qty: number
  regularPrice: number
  discountPercentage: number
  imgUrl: string
  discountedPrice: number
  vendorDetails: {
    vendorId: string
    vendorName: string | null
    vendorCod: number
  }
  vendorPincode: string
  productName: string
  resellerPrice: number
  variants: Record<string, any>
  estimatedDeliveryTime: string
  addons: any[]
  isServiceable: number
  ondcErrorMessage: string
  actualQty: number
  errorCode: string
  plotchtag: string
}

const PaymentOrderDetails = ({
  selectedRadioValue,
  setSelectedRadioValue,
  prepaidError,
  setPrepaidError,
  setCheckoutDetails,
  setGoogledataLayer,
}: any) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isCouponVisible, setIsCouponVisible] = useState(true)
  // const [isShippingOpen, setIsShippingOpen] = useState(false)
  const [openRemoveItem, setOpenRemoveItem] = useState(false)
  const [currentProductId, setCurrentProductId] = useState<string | null>(null)
  const [anchorChoice, setAnchorChoice] = useState<'bottom' | 'left' | 'top' | 'right'>('bottom')
  const [openAddress, setOpenAddress] = useState(false)
  const [addressId, setAddressId] = useState<string | null>(localStorage.getItem('addressId'))
  const [openPolicy, setOpenPolicy] = useState(false)
  const [openServicable, setOpenServicable] = useState(false)
  const [quoteData, setQuoteData] = useState<any>()
  const [selectedProduct, setSelectedProduct] = useState<any>()
  const [vendorDetails, setVendorDetails] = useState<any>({})
  const [serviceableItems, setServiceableItems] = useState<any>([])
  const { mutateAsync } = usePost()
  const { setShowSnackBar } = useContext(SnackBarContext)
  const [openVendorIds, setOpenVendorIds] = useState<Set<string>>(new Set())
  // const { setLoader } = useContext(LoaderContext)
  const { storesData } = useTheme()
  const { changeAddressDataToPush } = AddressInteractionData()
  const { loggedIn, loggedInStatus, address, GDL_PAGE_TYPE } = pageData()

  const navigate = useHistory()
  const customerId = localStorage.getItem('customerId')
  let prepaidMethodFlag = false
  const {
    refetch: refetchPaymentOption,
    data,
    // isLoading: isPaymentOption,
  } = useGet('payment-option', `${APIS.PAYMENT_METHOD}?customerId=${customerId}`)
  const {
    refetch: refetchOndcQuoteDetails,
    data: ondcQuoteDetails,
    // isLoading: isOndcQuoteDetailsLoading,
  } = useGet('ondc-quote-details', `${APIS.CHECKOUT_QUOTE_DETAILS}?cartRefreshEvent=&quoteAddressId=${addressId}`)
  type Product = {
    productId: number
    productName: string
    brand: string
    discountPercentage: number
    variants: {
      [key: string]: string[]
    }
    category: string
    regularPrice: number
    qty: number
    isReturnable: string
  }
  useEffect(() => {
    if (ondcQuoteDetails) {
      const coupon = ondcQuoteDetails?.d?.couponCode || 'na'
      setCheckoutDetails(ondcQuoteDetails)
      if (typeof setGoogledataLayer === 'function') {
        setGoogledataLayer(datalayer)
      }

      const itemsArray = ondcQuoteDetails.d.productList.map((productItem: Product) => {
        const itemVariant = Object.entries(productItem.variants || {})
          .map(([key, value]) => `${key}-${(value as string[])[0] || 'default'}`)
          .join(' | ')

        let categoryText = 'na'

        if (productItem.isReturnable) {
          categoryText = `Returnable: ${productItem.isReturnable}`
        }

        return {
          item_name: productItem.productName?.toLowerCase(),
          item_id: productItem.productId,
          item_brand: productItem.brand?.toLowerCase(),
          discount: productItem.discountPercentage,
          item_variant: itemVariant,
          item_category: productItem.category,
          item_category2: 'na',
          item_category3: 'na',
          item_category4: 'na',
          item_category5: categoryText,
          price: productItem.regularPrice,
          item_list_id: 'na',
          item_list_name: 'na',
          quantity: productItem.qty,
          coupon: coupon,
          index: 1,
          Is_returnable: productItem.isReturnable,
        }
      })

      const totalValue = itemsArray.reduce((acc: number, item: { price: number }) => acc + item.price, 0)

      const elObject = {
        event: 'begin_checkout',
        User_Id: loggedIn,
        User_Status: loggedInStatus,
        selected_location: address,
        page_type: GDL_PAGE_TYPE,
        ecommerce: {
          value: totalValue,
          currency: 'INR',
          coupon: coupon,
          items: itemsArray,
        },
      }

      if (ondcQuoteDetails?.d?.productList && datalayer === '1') {
        TagManager.dataLayer({ dataLayer: elObject })
      }
    }
  }, [ondcQuoteDetails])
  const {
    refetch: refetchQuoteDetails,
    data: quoteDetails,
    // isLoading: isQuoteDetails,
  } = useGet('quote-details', `${APIS.CHECKOUT_QUOTE_DETAILS}`)

  const updateItemCount = async (productId: string, quantity: number) => {
    const beginCheckoutQty =
      ondcQuoteDetails?.d?.productList.find((item: IProductList) => item.productId === parseInt(productId))?.qty || 0
    if (quantity === 0) {
      handleRemoveProduct(productId)
    } else {
      //setLoader(true)
      try {
        const res = await mutateAsync({
          url: APIS.UPDATE_ITEMS,
          payload: {
            cartInstanceId: '1',
            catalogId: 'WXjfElbnz5',
            customerId: '',
            accountId: '',

            products: [
              {
                productId: productId,
                qty: quantity,
                reseller_price: 0,
              },
            ],
          },
        })
        if (res) {
          const matchedProduct = res?.d?.products.find((product: any) => product.productId === productId)
          //setLoader(false)
          if (storesData?.d?.ondcEnabled && storesData?.d?.ondcEnabled === '1') {
            refetchOndcQuoteDetails()
          } else {
            refetchQuoteDetails()
          }

          if (matchedProduct) {
            const eventType = quantity > beginCheckoutQty ? 'add_to_cart' : 'remove_from_cart'
            const selectType = quantity > beginCheckoutQty ? 'checkout_plus_icon' : 'checkout_minus_icon'
            const elObject = {
              event: eventType,
              User_Id: loggedIn,
              User_Status: loggedInStatus,
              selected_location: address,
              page_type: GDL_PAGE_TYPE,
              select_type: selectType,
              ecommerce: {
                value: matchedProduct.discountedPrice,
                currency: 'INR',
                items: [
                  {
                    item_name: matchedProduct?.productName?.toLowerCase() || 'na',
                    item_id: matchedProduct?.productId,
                    item_brand: matchedProduct?.vendorName?.toLowerCase() || 'na',
                    discount: matchedProduct.discountPercentage || 0,
                    item_variant: matchedProduct?.variant || 'na',
                    item_category: matchedProduct?.categoryName || 'na',
                    price: matchedProduct?.regularPrice,
                    item_list_id: 'na',
                    item_list_name: 'na',
                    item_category2: 'na',
                    item_category3: 'na',
                    item_category4: 'na',
                    coupon: 'na',
                    item_category5: 'na',
                    Is_returnable: matchedProduct?.returnable,
                    quantity: matchedProduct?.qty,
                    index: 1,
                  },
                ],
              },
            }

            if (matchedProduct?.productId && datalayer === '1') {
              TagManager.dataLayer({ dataLayer: elObject })
            }
          }
        }
      } catch (error: any) {
        //setLoader(false)
        const errorMessage = error?.response?.data || 'Something Went Wrong'
        setShowSnackBar({ open: true, message: `${errorMessage?.m}`, severity: '' })
      }
    }
  }

  const productLists = quoteData?.productList
  const paymentMethodList = data?.d?.paymentMethods

  const toggleIsOpen = () => setIsOpen(!isOpen)
  const toggleIsShippingOpen = (vendorId: string) => {
    setOpenVendorIds((prevOpenVendorIds) => {
      const newOpenVendorIds = new Set(prevOpenVendorIds)
      if (newOpenVendorIds.has(vendorId)) {
        newOpenVendorIds.delete(vendorId)
      } else {
        newOpenVendorIds.add(vendorId)
      }

      return newOpenVendorIds
    })
  }
  // const toggleIsShippingOpen = () => setIsShippingOpen(!isShippingOpen)
  const toggleRemoveItemDrawer = (newItem: boolean) => () => setOpenRemoveItem(newItem)
  const handlePolicyClick = (item: any) => {
    setSelectedProduct(item)
    setOpenPolicy(true)
  }
  const togglePolicyDrawer = (newItem: boolean) => () => setOpenPolicy(newItem)
  const toggleServicableDrawer = (newItem: boolean) => () => setOpenServicable(newItem)
  const toggleAddressDrawer = (newAddress: boolean) => () => setOpenAddress(newAddress)
  const handleRemoveProduct = (item: any) => {
    setOpenRemoveItem(true)
    setCurrentProductId(item)
  }
  const handleOptionClick = (value: any) => setSelectedRadioValue(value)
  const handleChangeAddressClick = () => {
    toggleAddressDrawer(true)()
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: changeAddressDataToPush })
  }
  const datalayer = storesData?.d?.googleDatalayer
  useEffect(() => {
    const currentAddressId = localStorage.getItem('addressId')
    setAddressId(currentAddressId)
  }, [])

  useEffect(() => {
    if (customerId) {
      refetchPaymentOption()
    }
  }, [customerId, refetchPaymentOption])

  const quoteDetailsApi = () => {
    // prepaidError.length > 0 && setPrepaidError([])
    if (storesData?.d?.ondcEnabled && storesData?.d?.ondcEnabled === '1') {
      refetchOndcQuoteDetails()
    } else {
      refetchQuoteDetails()
    }
  }

  useEffect(() => {
    if (storesData?.d) {
      quoteDetailsApi()
    }
  }, [storesData?.d?.ondcEnabled, refetchOndcQuoteDetails, refetchQuoteDetails])

  useEffect(() => {
    setQuoteData(ondcQuoteDetails?.d || quoteDetails?.d)
  }, [ondcQuoteDetails, quoteDetails])

  useEffect(() => {
    if (prepaidError) {
      setServiceableItems(prepaidError)
      toggleServicableDrawer(true)
    }
  })
  useEffect(() => {
    if (quoteData && quoteData.shipping && typeof quoteData.shipping === 'object') {
      const shipping = quoteData.shipping

      // Create a mapping of vendor details with vendorId as the key
      const vendorDetailsMap: {
        [key: string]: { vendorName: string; shipping_amount: number; total: number; other_charges: any }
      } = {}

      Object.keys(shipping).forEach((key) => {
        const shippingDetail = shipping[key]
        vendorDetailsMap[key] = {
          vendorName: shippingDetail.vendorName,
          shipping_amount: shippingDetail.shipping_amount,
          total: shippingDetail.total,
          other_charges: shippingDetail.other_charges,
        }
      })

      setVendorDetails(vendorDetailsMap)
    }
    // if (quoteData && quoteData.shipping && typeof quoteData.shipping === 'object') {
    //   const shipping = quoteData.shipping

    //   const shippingKeys = Object.keys(shipping)

    //   if (shippingKeys.length > 0) {
    //     const firstKey = shippingKeys[0]

    //     setVendorDetails({
    //       vendorName: shipping[firstKey]?.vendorName,
    //       shipping_amount: shipping[firstKey]?.shipping_amount,
    //       total: shipping[firstKey]?.total,
    //       other_charges: shipping[firstKey]?.other_charges,
    //     })
    //   }
    // }

    const isServiceableItems = quoteData?.productList
      ?.filter((item: IProductList) => item.isServiceable == 0)
      .map((item: IProductList) => ({
        productId: item?.productId,
        productName: item?.productName,
        ondcErrorMessage: item?.ondcErrorMessage,
        errorCode: item?.errorCode,
        imgUrl: item?.imgUrl,
        plotchtag: item?.plotchtag,
        isServiceable: item?.isServiceable,
      }))
    setServiceableItems(isServiceableItems)

    if (quoteData && quoteData?.productList.length === 0) {
      navigate.push(EmptyCartRoute.path)
    }
  }, [quoteData])

  useEffect(() => {
    const updateAnchor = () => {
      setAnchorChoice(window.innerWidth > 768 ? 'right' : 'bottom')
    }

    updateAnchor()
    window.addEventListener('resize', updateAnchor)
    return () => window.removeEventListener('resize', updateAnchor)
  }, [])

  const isEmpty = (obj: any) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object
  }

  useEffect(() => {
    if (isEmpty(quoteData?.shippingAddress)) {
      setOpenAddress(true)
    } else if (quoteData?.shippingAddress && serviceableItems?.length > 0) {
      setOpenServicable(true)
    } else {
      setOpenServicable(false)
    }
  }, [serviceableItems, quoteData])

  const groupedProductLists = productLists?.reduce((acc: any, item: any) => {
    const vendorId = item.vendorDetails.vendorId
    if (!acc[vendorId]) {
      acc[vendorId] = {
        vendorName: quoteData?.shipping[vendorId]?.vendorName,
        products: [],
      }
    }

    acc[vendorId].products.push(item)
    return acc
  }, {})

  const removeCoupanClick = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.REMOVE_COUPAN,
        payload: {
          customerId: localStorage.getItem('customerId'),
        },
      })
      if (res) {
        setIsCouponVisible(false)
        refetchOndcQuoteDetails()
        setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
      }
    } catch (error) {
      setShowSnackBar({ open: true, message: `Failed to remove coupon`, severity: '' })
    }
  }

  // useEffect(() => {
  //   const checkout_product_ids = quoteData?.productList?.map((product: any) => product?.productId)
  //   const checkout_product_names = quoteData?.productList?.map((product: any) => product?.productName)
  //   const dataToPush = {
  //     event: 'addToCart',
  //     eventName: 'addToCart',
  //     source: 'checkoutpage',
  //     content_type: 'product',
  //     productId: checkout_product_ids,
  //     productPrice: quoteData?.grandTotal?.displayTotal,
  //     prodName: checkout_product_names?.toLowerCase(),
  //     currency: 'INR',
  //   }

  //   // Push data to the data layer using TagManager
  //   storesData?.d?.googleDatalayer &&
  //     storesData?.d?.googleDatalayer == 1 &&
  //     TagManager.dataLayer({ dataLayer: dataToPush })
  // }, [quoteData])

  return (
    <>
      {quoteData ? (
        <OrderDetailsWrapper>
          <CommonStyle>
            {quoteData && quoteData?.shippingAddress && (
              <DeliveryWrapper>
                <div className="deliver-detail-wrapper">
                  <label className="h2r">
                    Deliver to:{' '}
                    <span className="h2b address-detail">
                      {quoteData?.shippingAddress?.firstName} {quoteData?.shippingAddress?.lastName}(
                      {quoteData?.shippingAddress?.label == null ? 'Home' : quoteData?.shippingAddress?.label})
                    </span>
                  </label>
                  <label className="h2r address-detail">
                    {' '}
                    {quoteData?.shippingAddress?.building} &nbsp;
                    {quoteData?.shippingAddress?.locality}&nbsp;
                    {quoteData?.shippingAddress?.city} ....
                  </label>
                </div>
                <div className="profile-change-btn h2b" onClick={handleChangeAddressClick}>
                  CHANGE
                </div>
              </DeliveryWrapper>
            )}
          </CommonStyle>
          <DividerLine />
          <OrderDetailContainer>
            <CommonStyle>
              <div className="order-container">
                <div className="order-wrapper" onClick={toggleIsOpen}>
                  <label className="h1r">Order Details ({quoteData?.totalQty} Item(s))</label>
                  {!isOpen ? <DownArrow /> : <UpArrow />}
                </div>

                {isOpen ? (
                  <div className="product-details-wrapper">
                    {groupedProductLists &&
                      Object.keys(groupedProductLists).map((vendorId: string) => {
                        const vendorGroup = groupedProductLists[vendorId]
                        const vendorShippingDetails = vendorDetails[vendorId]
                        const isShippingOpenForVendor = openVendorIds.has(vendorId)

                        return (
                          <div key={vendorId}>
                            <div className="vendor-name">
                              <label className="h2b">{vendorGroup?.vendorName?.toLowerCase()}</label>
                            </div>
                            {vendorGroup.products.map((item: any, index: number) => {
                              let imgUrl =
                                item?.imgUrl?.includes('http://') || item?.imgUrl?.includes('https://')
                                  ? item?.imgUrl
                                  : `https://cdnaz.plotch.io/image/upload/w_118,h_118${item?.imgUrl}`
                              if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
                                imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
                                imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
                              }

                              const count = item.qty

                              const deliveryTime = item?.estimatedDeliveryTime?.replace('Estimated ', '')
                              const deliveryDate = deliveryTime?.replace(/\s\d{4}$/, '')
                              // const discountedPercentage = Math.ceil(item?.discountPercentage)

                              return (
                                <div className="product-complete-description" key={index}>
                                  <img src={imgUrl} />
                                  <div className="product-description">
                                    <div className="description">
                                      <label className="h3r product-name">
                                        {item.productName
                                          ? `${item?.productName?.toLowerCase().slice(0, 15)} ... `
                                          : item?.productName.toLowerCase()}
                                        ({item?.qty} Qty{item?.variants?.colour ? ',' : ''}{' '}
                                        {item?.variants?.colour ? (
                                          item?.variants?.colour?.[0]?.startsWith('#') ? (
                                            <span
                                              className="colourBox"
                                              style={{ background: item?.variants?.colour }}
                                            />
                                          ) : (
                                            item?.variants?.colour
                                          )
                                        ) : (
                                          ''
                                        )}
                                        {item?.variants?.size ? ' , ' + item?.variants?.size : ''}
                                        {''})
                                      </label>
                                      <TrashIcon
                                        onClick={() => {
                                          handleRemoveProduct(item?.productId)
                                        }}
                                        className="trash"
                                      />
                                    </div>
                                    <div className="h3r addonsBlock">
                                      {item?.addons?.length > 0
                                        ? item?.addons?.map((addonProduct: any, index: number) => {
                                            const addon = addonProduct?.product_name
                                            return (
                                              <div className="addons h4r" key={index}>
                                                {addon}
                                              </div>
                                            )
                                          })
                                        : ''}
                                    </div>
                                    <div className="quantity-wrapper">
                                      <div className="delivery-time-wrapper">
                                        <label className="h3r delivery-time">{deliveryDate}</label>
                                        <div className="discount-details">
                                          {item?.regularPrice === item?.discountedPrice ? (
                                            <>
                                              <div className="after-discount h3r">
                                                &#8377;
                                                {item?.regularPrice?.toFixed(2)}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="after-discount h3r">
                                                &#8377;
                                                {item?.discountedPrice?.toFixed(2)}
                                              </div>
                                              {/* <div className="before-discount h3r">
                                                <span> &#8377; {item.regularPrice} </span>
                                              </div> */}
                                            </>
                                          )}

                                          {/* {discountedPercentage > 0 ? (
                                            <div className="discount-percentage h3b">
                                              {discountedPercentage ? `${discountedPercentage} % OFF` : null}
                                            </div>
                                          ) : (
                                            ''
                                          )} */}
                                        </div>
                                        <label className="h3r cancel" onClick={() => handlePolicyClick(item)}>
                                          Read Cancellation Policy <DownArrow />
                                        </label>
                                      </div>
                                      <div className="quantity h1b">
                                        <div onClick={() => updateItemCount(item?.productId, count - 1)}>-</div>
                                        <div>{count}</div>
                                        <div onClick={() => updateItemCount(item?.productId, count + 1)}>+</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}

                            {/* <div className="shipping-wrap">
                              {vendorDetails && (
                                <div className="shipping-wraps" onClick={toggleIsShippingOpen}>
                                  <label className="h3b shipping-heading">Shipping, Packaging & taxes</label>
                                  <label className="h3b">₹{vendorDetails && vendorDetails?.total}</label>
                                </div>
                              )}
                              {isShippingOpen && (
                                <div className="shipping-container">
                                  <div className="shipping-wraps">
                                    <label className="h3r">Shipping</label>
                                    <label className="h3r">₹{vendorDetails && vendorDetails?.shipping_amount}</label>
                                  </div>
                                  <div className="shipping-wraps">
                                    <label className="h3r">Packaging</label>
                                    <label className="h3r">₹{quoteData?.grandTotal?.totalPackingCharge}</label>
                                  </div>
                                  <div className="shipping-wraps">
                                    <label className="h3r">Taxes</label>
                                    <label className="h3r">
                                      ₹
                                      {quoteData?.grandTotal?.totalMiscCharge == 0
                                        ? quoteData?.grandTotal?.tax
                                        : quoteData?.grandTotal?.totalMiscCharge}
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div> */}
                            {vendorShippingDetails && (
                              <div className="shipping-wrap">
                                <div className="shipping-wraps" onClick={() => toggleIsShippingOpen(vendorId)}>
                                  <label className="h3b shipping-heading">Shipping, Packaging & taxes</label>
                                  <label className="h3b">
                                    ₹{vendorShippingDetails.total}
                                    {/* {vendorShippingDetails?.vendorName} */}
                                  </label>
                                </div>
                                {isShippingOpenForVendor && (
                                  <div className="shipping-container">
                                    <div className="shipping-wraps">
                                      <label className="h3r">Shipping</label>
                                      <label className="h3r">₹{vendorShippingDetails.shipping_amount}</label>
                                    </div>
                                    {vendorShippingDetails?.other_charges?.packing && (
                                      <div className="shipping-wraps">
                                        <label className="h3r">Packaging</label>
                                        <label className="h3r">₹{vendorShippingDetails?.other_charges?.packing}</label>
                                      </div>
                                    )}
                                    {vendorShippingDetails?.tax && (
                                      <div className="shipping-wraps">
                                        <label className="h3r">Taxes</label>
                                        <label className="h3r">
                                          ₹{vendorShippingDetails?.tax}
                                          {/* {quoteData?.grandTotal?.totalMiscCharge === 0
                                          ? quoteData?.grandTotal?.tax
                                          : quoteData?.grandTotal?.totalMiscCharge} */}
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )
                      })}

                    {quoteData && (
                      <div className="price-details-container">
                        <div className="price-details-wrapper">
                          <label className="h1r">Total MRP</label>
                          <label className="h1r">₹{quoteData?.totalMrp}</label>
                        </div>
                        <div className="price-details-wrapper">
                          <label className="h1r">Discount on MRP</label>
                          <label className="h1r discount">
                            {quoteData?.totalMrpDiscount ? '-' : ''}₹
                            {quoteData?.totalMrpDiscount - quoteData?.totalDiscount.toFixed(2)}
                          </label>
                        </div>
                        <div className="price-details-wrapper">
                          <label className="h1r">Delivery Charge</label>
                          <label className="h1r">₹{quoteData?.shippingAmount}</label>
                        </div>
                        <div className="price-details-wrapper">
                          <label className="h1r">Packaging Charge</label>
                          <label className="h1r">₹{quoteData?.grandTotal?.totalPackingCharge}</label>
                        </div>
                        <div className="price-details-wrapper">
                          <label className="h1r">Taxes</label>
                          <label className="h1r">
                            ₹
                            {quoteData?.grandTotal?.totalMiscCharge == 0
                              ? quoteData?.grandTotal?.tax
                              : quoteData?.grandTotal?.totalMiscCharge}
                          </label>
                        </div>
                        <div className="price-details-wrapper">
                          <label className="h1r">Convenience Fee</label>
                          <label className="h1r">₹{quoteData?.grandTotal?.totalMiscCharge}</label>
                        </div>
                        <div className="price-details-wrapper">
                          <label className="h1r">Coupon Discount</label>
                          <label className="h1r discount">
                            {quoteData?.totalDiscount > 0 ? '-' : ''}₹{quoteData?.totalDiscount}
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}

                <div className="order-wrapper total">
                  <label className="h1b">Total Payable</label>
                  <label className="h1b">₹{quoteData?.grandTotal?.displayTotal}</label>
                </div>
              </div>
            </CommonStyle>
          </OrderDetailContainer>
          <DividerLine />
          {/* {quoteData && quoteData?.promoCoupons.length > 0 && ( */}
          <CommonStyle>
            <CoupanDetailContainer onClick={() => navigate.push(CouponRoute.path, { state: quoteData })}>
              <div className="coupan-wrapper">
                <label className="h1b">Apply Coupon</label>
                <RightArrow />
              </div>
              {/* {quoteData?.couponCode?.length > 0 ? ( */}
              {quoteData?.couponCode ? (
                <>
                  {isCouponVisible && (
                    <BestCouponWrappers>
                      <RemoveWrapper>
                        <CouponNameWrapper className="coupon-name-wrapper" isActive={true}>
                          <div className="coupan-name h2b">{quoteData?.couponCode}</div>
                          <CutIcon />
                        </CouponNameWrapper>
                        <div
                          className="remove-coupan"
                          onClick={(e: any) => {
                            e.stopPropagation()
                            removeCoupanClick()
                          }}
                        >
                          Remove
                        </div>
                      </RemoveWrapper>
                    </BestCouponWrappers>
                  )}
                  {isCouponVisible && (
                    <label className="h2b applied-coupan">Yay, coupon is applied to your order</label>
                  )}
                </>
              ) : null}
            </CoupanDetailContainer>
          </CommonStyle>
          {/* )} */}
          <PaymentOptionsContainer>
            <CommonStyle>
              <div className="h1r payment-option">Payment Options</div>
              <div className="options-wrapper">
                <Radio.Group style={{ width: '100%' }} value={selectedRadioValue}>
                  {paymentMethodList &&
                    paymentMethodList?.map((option: { displayName: string; paymentType: string }, index: number) => {
                      if (
                        (quoteData?.showCod == 1 &&
                          option.paymentType == 'cod' &&
                          quoteData?.grandTotal?.displayTotal != 0) ||
                        option.paymentType == 'nuclei' ||
                        option.paymentType == 'mos_world' ||
                        option.paymentType == 'spicemoney'
                      ) {
                        return (
                          <div
                            key={option.paymentType}
                            className={`option ${index === paymentMethodList.length - 1 ? 'last-option' : ''}`}
                            onClick={() => handleOptionClick(option.paymentType)}
                          >
                            <Radio
                              value={option.paymentType}
                              className="h1b"
                              checked={selectedRadioValue === option.paymentType}
                            >
                              {option?.displayName}
                            </Radio>
                            <RightArrow />
                          </div>
                        )
                      }

                      if (
                        option.paymentType == 'upi' ||
                        option.paymentType == 'debit_card' ||
                        option.paymentType == 'credit_card' ||
                        option.paymentType == 'net_banking'
                      ) {
                        if (!prepaidMethodFlag) {
                          prepaidMethodFlag = true
                          return (
                            <div
                              key={option.paymentType}
                              className={`option ${index === paymentMethodList.length - 1 ? 'last-option' : ''}`}
                              onClick={() => handleOptionClick(option.paymentType)}
                            >
                              <Radio
                                value={option.paymentType}
                                className="h1b"
                                checked={selectedRadioValue === option.paymentType}
                              >
                                Credit Card/Debit Card/UPI
                              </Radio>
                              <RightArrow />
                            </div>
                          )
                        }
                      }
                    })}
                </Radio.Group>
              </div>
            </CommonStyle>
          </PaymentOptionsContainer>
          <Drawer
            open={openRemoveItem}
            // anchor="bottom"
            anchor={anchorChoice}
            sx={{
              '& .MuiDrawer-paper': {
                borderRadius: { xs: '20px 20px 0px 0px', sm: '0', xl: '0' },
              },
            }}
          >
            {
              <RemoveItem
                onClose={toggleRemoveItemDrawer(false)}
                productId={currentProductId}
                refetchOndcQuoteDetails={quoteDetailsApi}
                quoteData={quoteData.productList}
                datalyer={datalayer}
              />
            }
          </Drawer>
          <Drawer
            open={openPolicy}
            anchor={anchorChoice}
            sx={{
              '& .MuiDrawer-paper': {
                borderRadius: { xs: '20px 20px 0px 0px', sm: '0', xl: '0' },
              },
            }}
            onClose={togglePolicyDrawer(true)}
          >
            <ReturnCancellationPolicy onClose={togglePolicyDrawer(false)} product={selectedProduct} />
          </Drawer>
          <Drawer
            open={openServicable} //todo
            anchor={anchorChoice}
            sx={{
              '& .MuiDrawer-paper': {
                borderRadius: { xs: '20px 20px 0px 0px', sm: '0', xl: '0' },
              },
            }}
            onClose={toggleServicableDrawer(true)}
          >
            <OssError
              onClose={toggleServicableDrawer(false)}
              product={serviceableItems}
              refetchOndcQuoteDetails={quoteDetailsApi}
              quoteData={quoteData}
              handleChangeAddressClick={handleChangeAddressClick}
              setPrepaidError={setPrepaidError}
            />
          </Drawer>

          <Drawer
            open={openAddress}
            onClose={toggleAddressDrawer(true)}
            anchor={anchorChoice}
            sx={{
              '& .MuiDrawer-paper': {
                borderRadius: { xs: '20px 20px 0px 0px', sm: '0', xl: '0' },
              },
            }}
          >
            {<SavedAddresses onClose={toggleAddressDrawer(false)} refetchOndcQuoteDetails={quoteDetailsApi} />}
          </Drawer>
        </OrderDetailsWrapper>
      ) : (
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )}
    </>
  )
}

export default PaymentOrderDetails

import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CommentComponent from 'components/Comment'
import Button from 'components/Button'
import APIS from 'constants/api'
// import { LoaderContext } from 'context/loader'
import { SnackBarContext } from 'context/SnackBarContext'
import usePost from 'hooks/usePost'
import { ITicketDetails } from 'interfaces/components'

import {
  TagToRight,
  TicketAgentTag,
  TicketDeatiledSection,
  TicketDetails,
  TicketSection,
  TicketStatus,
  TicketWrapper,
} from 'styles/components/Ticket'
import { ButtonWrapper } from 'styles/components/Button'
import PopupModal from 'components/AddComment'

const TicketDetail = () => {
  const customerId = localStorage.getItem('customerId')
  const param: any = useParams()
  const [ticketId, setTicketId] = useState('')
  const [ticket_detail, set_ticket_detail] = useState<ITicketDetails | null>(null)
  // const { setLoader } = useContext(LoaderContext)
  const { setShowSnackBar } = useContext(SnackBarContext)
  const { mutateAsync } = usePost()
  const [open, setOpen] = useState(false)

  const changeModal = () => {
    setOpen(true)
  }

  const handleOk = () => {
    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const getTicketDetails = async (id: string) => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.TICKET_DETAILS,
        payload: {
          customerId: customerId,
          ticketId: id,
        },
      })
      if (res) {
        //setLoader(false)
        set_ticket_detail(res?.d)
      }
    } catch (error: any) {
      //setLoader(false)

      setShowSnackBar({ open: true, message: `${error?.res?.m}`, severity: 'error' })

      return error
    }

    return true
  }

  useEffect(() => {
    //setLoader(false)
    if (customerId) {
      if (param) {
        setTicketId(param?.id.toString())
      } else {
        //setLoader(false)
      }
    } else {
      //setLoader(false)
    }
  }, [customerId, param])

  useEffect(() => {
    if (ticketId) {
      getTicketDetails(ticketId)
    }
  }, [ticketId])

  const ticketOpenStatus = ['Active Ticket', 'On Hold', 'Agent Assigned', 'In Progress']

  return (
    <div>
      {ticket_detail ? (
        <div>
          <TicketSection>
            <div className="h1b">Ticket</div>
            <TicketWrapper>
              <TicketStatus className="h1b">
                {ticketOpenStatus.includes(ticket_detail?.status) ? (
                  <label className="open"> Open </label>
                ) : (
                  <label className="closed"> Closed </label>
                )}
                <TagToRight>
                  <TicketAgentTag className="h4b tagToRight"> Agent Assigned</TicketAgentTag>
                </TagToRight>
              </TicketStatus>
              <TicketDetails>
                <div className="h3b">Ticket Id: {ticketId}</div>
                <div className="h3r">Created On: {ticket_detail?.createdAt}</div>
                <div className="row-divider"></div>
                <TicketDeatiledSection>
                  <img src={ticket_detail.productImageUrl} alt={ticket_detail.productName} />
                  <div className="ticket-details">
                    <div className="h3r ">{ticket_detail.subject}</div>
                    <div className="ticketDescription">
                      <div className="h3r">{ticket_detail.content}</div>
                      <div className="h3r">{ticket_detail.context}</div>
                    </div>
                  </div>
                </TicketDeatiledSection>
              </TicketDetails>
            </TicketWrapper>
            {ticket_detail?.comments?.map((comment: any, key: number) => {
              return <CommentComponent data={comment} key={key} />
            })}
          </TicketSection>
          <ButtonWrapper className="buy-now">
            <Button variant="contained" type="button" label="Reply" className="large" onClick={changeModal} />
            <PopupModal
              open={open}
              onOk={handleOk}
              onCancel={handleCancel}
              title="Reply"
              BtnName="Reply"
              ticketId={ticketId}
              ticketDetails={getTicketDetails}
            />
          </ButtonWrapper>
        </div>
      ) : (
        'Loading...'
      )}
    </div>
  )
}

export default TicketDetail

import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M7.18652 4.37512L12.8115 10.0001L7.18652 15.6251"
      stroke="var(--primary_icon_static_color)"
      strokeWidth={1.00189}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent

import AddAddressPage from 'pages/addAddress'
import AddAddressPindrop from 'pages/addAddressPindrop'
import CartEmptyPage from 'pages/cartEmpty'
import CategoryPage from 'pages/category'
import HomePage from 'pages/home'
import LoginPage from 'pages/login'
import NotFoundPage from 'pages/notFound'
import OtpPage from 'pages/otp'
import PrivacyPolicyPage from 'pages/privacyPolicy'
import ServerErrorPage from 'pages/serverError'
import StorePage from 'pages/store'
import TermsAndCondionPage from 'pages/termsConditions'
import WistlistPage from 'pages/wistlist'
import MyAccountPage from 'pages/myAccount'
import PdpPage from 'pages/pdp'
import myProfile from 'pages/myProfile'
import Search from 'pages/search'
import ManageAddressPage from 'pages/manageAddress'
import CheckoutPage from 'pages/checkout'
import MyOrdersPage from 'pages/myOrders'
import CouponPage from 'pages/coupon'
import ViewOrderDetailsPage from 'pages/viewOrderDetails'
import CancelOrderPage from 'pages/cancelOrder'
import ReturnOrderPage from 'pages/returnOrder'
import OrderSuccessPage from 'pages/orderSuccess'
import OrderTrackPage from 'pages/orderTrack'
import OrderFailurePage from 'pages/orderFailure'
import OrderPendingPage from 'pages/orderPendng'
import ListingPage from 'pages/listing'
import AboutUsPage from 'pages/aboutUs'
import CancellationPolicyPage from 'pages/cancellationPolicy'
import CookiePolicyPage from 'pages/cookiePolicy'
import ReturnPolicyPage from 'pages/returnPolicy'
// import ContactUsPage from 'pages/contactUs'
import MyTickets from 'pages/myTickets'
import TicketDetails from 'pages/ticketDetails'
import SuggestionPage from 'pages/suggestion'
import SellerPage from 'pages/seller'
import VendorPage from 'pages/vendor'
import FailedOrderDetailsPage from 'pages/failedOrderDetails'
import StoreHyperlocalPage from 'pages/storeHyperlocal'
import SplitStoresHyperlocalPage from 'pages/searchSplitStoresHyperlocal'
import TrackDetailPage from 'pages/trackDetails'
import NodessoLogin from 'pages/nodessoLogin'
import UnauthorizedError from 'pages/unauthorizedError'

export const LoginRoute = {
  component: LoginPage,
  path: '/signin',
  exact: false,
  restricted: false,
}

export const NodessoLoginRoute = {
  component: NodessoLogin,
  path: '/login',
  exact: false,
  restricted: false,
}

export const OtpRoute = {
  component: OtpPage,
  path: '/otp',
  exact: false,
  restricted: false,
}
export const HomeRoute = {
  component: HomePage,
  path: '/',
  exact: true,
  restricted: false,
}

export const CategoryRoute = {
  component: CategoryPage,
  path: '/categories',
  exact: false,
  restricted: false,
}
export const WistlistRoute = {
  component: WistlistPage,
  path: '/wishlist',
  exact: false,
  restricted: true,
  redirect: '/',
}
export const StoreRoute = {
  component: StorePage,
  path: '/stores-near-me',
  exact: false,
  restricted: false,
}
export const StoreHyperlocalRoute = {
  component: StoreHyperlocalPage,
  path: '/store-hyperlocal',
  exact: false,
  restricted: false,
}
export const SplitStoresHyperlocalRoute = {
  component: SplitStoresHyperlocalPage,
  path: '/split-store-hyperlocal',
  exact: false,
  restricted: false,
}
export const AddAddressRoute = {
  component: AddAddressPage,
  path: '/add-address',
  exact: false,
  restricted: true,
}
export const EmptyCartRoute = {
  component: CartEmptyPage,
  path: '/empty-cart',
  exact: false,
  restricted: false,
}
export const NotFoundRoute = {
  component: NotFoundPage,
  path: '/404-error',
  exact: false,
  restricted: false,
}
export const UnauthorizedRoute = {
  component: UnauthorizedError,
  path: '/401',
  exact: false,
  restricted: false,
}

export const ServerErrorRoute = {
  component: ServerErrorPage,
  path: '/500-error',
  exact: false,
  restricted: false,
}
export const PrivacyPolicyRoute = {
  component: PrivacyPolicyPage,
  path: '/Privacy_Policy',
  exact: false,
  restricted: false,
}
export const ReturnPolicyRoute = {
  component: ReturnPolicyPage,
  path: '/Return-Policy',
  exact: false,
  restricted: false,
}
// export const ContactUsRoute = {
//   component: ContactUsPage,
//   path: '/Contact-us',
//   exact: false,
//   restricted: false,
// }
export const AboutUsRoute = {
  component: AboutUsPage,
  path: '/About_us',
  exact: false,
  restricted: false,
}
export const TermsAndCondionRoute = {
  component: TermsAndCondionPage,
  path: '/Terms-Services',
  exact: false,
  restricted: false,
}
export const CancellationPolicyRoute = {
  component: CancellationPolicyPage,
  path: '/Cancellation-Policy',
  exact: true,
  restricted: true,
}
export const CookiePolicyRoute = {
  component: CookiePolicyPage,
  path: '/cookie',
  exact: true,
  restricted: true,
}

export const MyAccountRoute = {
  component: MyAccountPage,
  path: '/my-account',
  exact: false,
  restricted: true,
  redirect: '/',
}

export const AddAddressPindropRoute = {
  component: AddAddressPindrop,
  path: '/address-pindrop',
  exact: false,
  restricted: false,
}
export const PdpRoute = {
  component: PdpPage,
  path: '/product/:name/:id',
  exact: false,
  restricted: false,
}

export const MyProfileRoute = {
  component: myProfile,
  exact: false,
  path: '/my-profile',
  restricted: true,
}

export const SearchRoute = {
  component: Search,
  exact: false,
  path: '/searchresults',
  restricted: false,
}
export const ManageAddressRoute = {
  component: ManageAddressPage,
  exact: false,
  path: '/manage-addresses',
  restricted: false,
}
export const CheckoutRoute = {
  component: CheckoutPage,
  exact: false,
  path: '/checkout',
  restricted: true,
  redirect: '/',
}
export const MyOrdersRoute = {
  component: MyOrdersPage,
  path: '/my-orders',
  exact: false,
  restricted: true,
  redirect: '/',
}
export const CouponRoute = {
  component: CouponPage,
  exact: false,
  path: '/coupon',
  restricted: false,
}
export const ViewOrderDetailsRoute = {
  component: ViewOrderDetailsPage,
  exact: false,
  path: '/secure/orderDetails',
  restricted: false,
}
export const FailedViewOrderDetailsRoute = {
  component: FailedOrderDetailsPage,
  exact: false,
  path: '/failedOrderDetails',
  restricted: false,
}
export const CancelOrderRoute = {
  component: CancelOrderPage,
  exact: false,
  path: '/cancel-order',
  restricted: false,
}
export const ReturnOrderRoute = {
  component: ReturnOrderPage,
  exact: false,
  path: '/return-order',
  restricted: false,
}
export const OrderSuccessRoute = {
  component: OrderSuccessPage,
  exact: false,
  path: '/order-success',
  restricted: false,
}
export const OrderTrackRoute = {
  component: OrderTrackPage,
  exact: false,
  path: '/order-track',
  restricted: false,
}
export const TrackDetailRoute = {
  component: TrackDetailPage,
  exact: false,
  path: '/track-details',
  restricted: false,
}
export const OrderFailureRoute = {
  component: OrderFailurePage,
  exact: false,
  path: '/order-failure',
  restricted: false,
}
export const OrderPendingRoute = {
  component: OrderPendingPage,
  exact: false,
  path: '/order-pending',
  restricted: false,
}
export const TicketRoute = {
  component: TicketDetails,
  path: '/ticket/:id',
  restricted: false,
  exact: false,
}
export const ProductListingRoute = {
  component: ListingPage,
  exact: false,
  path: '/:name',
  restricted: false,
}

export const MyTicketRoute = {
  component: MyTickets,
  exact: false,
  path: '/my-tickets',
  restricted: false,
}
export const SuggestionRoute = {
  component: SuggestionPage,
  exact: false,
  path: '/suggestion',
  restricted: false,
}
export const SellerRoute = {
  component: SellerPage,
  path: '/seller/:id',
  restricted: false,
  exact: false,
}
export const VendorRoute = {
  component: VendorPage,
  path: '/vendor/:id',
  restricted: false,
  exact: false,
}

const ROUTES = [
  LoginRoute,
  NodessoLoginRoute,
  OtpRoute,
  HomeRoute,
  SuggestionRoute,
  WistlistRoute,
  CategoryRoute,
  StoreRoute,
  StoreHyperlocalRoute,
  SplitStoresHyperlocalRoute,
  AddAddressRoute,
  NotFoundRoute,
  UnauthorizedRoute,
  EmptyCartRoute,
  ServerErrorRoute,
  PrivacyPolicyRoute,
  TermsAndCondionRoute,
  MyAccountRoute,
  AddAddressPindropRoute,
  PdpRoute,
  SellerRoute,
  VendorRoute,
  MyProfileRoute,
  SearchRoute,
  ManageAddressRoute,
  MyOrdersRoute,
  CheckoutRoute,
  CouponRoute,
  ViewOrderDetailsRoute,
  FailedViewOrderDetailsRoute,
  ReturnOrderRoute,
  CancelOrderRoute,
  OrderSuccessRoute,
  OrderTrackRoute,
  TrackDetailRoute,
  OrderFailureRoute,
  OrderPendingRoute,
  MyTicketRoute,
  AboutUsRoute,
  CancellationPolicyRoute,
  CookiePolicyRoute,
  ReturnPolicyRoute,
  // ContactUsRoute,
  TicketRoute,
  ProductListingRoute,
  // SellerRoute,
]
export default ROUTES

import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Base64 } from 'js-base64'
import 'react-phone-number-input/style.css'
// import { LoaderContext } from 'context/loader'
import APIS from 'constants/api'
import { HomeRoute } from 'constants/routes'
import usePost from 'hooks/usePost'
import Button from 'components/Button'
import OtpInput from 'components/OtpInput'
import { updateDynamicHeaders } from 'services/axiosInstance'
import { login } from 'utils'
import { useSession } from 'context/SessionContext'
import BackArrow from 'assets/svg/BackArrow'

import {
  OtpPageContainer,
  BackWrapper,
  OtpMainContainer,
  LoginWrapper,
  LoginHeading,
  HeadingWrapper,
  OtpHeading,
  OtpInputContainer,
  OtpContainer,
  LoginFormWrapper,
  OtpText,
  ResendText,
  Counter,
  MessageSent,
  ChangeNumber,
  OtpWrapper,
  ErrorMessage,
} from 'styles/pages/Login'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
import { useTheme } from 'context/themeContext'

const OTP_RESEND_INTERVAL = 60

const OtpDetailView = () => {
  const [timer, setTimer] = useState(OTP_RESEND_INTERVAL)
  const [otp, setOtp] = useState('')

  const [isResendDisabled, setIsResendDisabled] = useState(true)
  // const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  const { storesData } = useTheme()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const router = useHistory()
  const { state } = useLocation<any>()
  const { fetchSession } = useSession()

  const handleBack = () => {
    router.goBack()
  }
  const navigate = useHistory()

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      otpp: '',
    },
  })

  const submitData = async () => {
    const otpData = {
      mobile: state?.mobile,
      otp: Number(otp),
      temp_id: state.temp_id,
      plotch_referral: '',
      plotch_referral_key: '',
      attribution_instance_id: 0,
    }
    if (otp?.length === 4) {
      const dataToPush = {
        event: 'login_signup_success',
        page_type: GDL_PAGE_TYPE,
        User_Id: loggedIn,
        User_Status: loggedInStatus,
        cta_text: 'login',
        section_name: 'login_page',
        select_type: 'log_in',
      }

      storesData?.d?.googleDatalayer &&
        storesData?.d?.googleDatalayer == 1 &&
        TagManager.dataLayer({ dataLayer: dataToPush })
      try {
        //setLoader(true)
        const res = await mutateAsync({
          url: APIS.LOG_IN,
          payload: otpData,
        })
        if (res) {
          //setLoader(false)

          localStorage.setItem('customerId', res?.d?.customerId)
          localStorage.setItem('mobile', res?.d?.mobile)
          localStorage.setItem('Authorization', res?.d?.Authorization)
          const d = new Date()
          const time = d.getTime()
          login(res?.d?.Authorization)
          fetchSession()
          updateDynamicHeaders()
          const customerId = res?.d?.customerId

          if (res?.d?.Authorization && customerId) {
            document.cookie = 'Authorization=' + res?.d?.Authorization
            const session_expiry = res?.d?.sessionExpiry ? res?.d?.sessionExpiry : '604800'
            const expirationDate = new Date(time + session_expiry * 1000)
            const expires = 'expires=' + expirationDate.toUTCString()
            document.cookie = 'x-plotch-token=' + res?.d?.Authorization + ';' + expires
            const customer_id_base64 = encodeURIComponent(Base64.encode(customerId))
            const mobile_id = state?.mobile ? encodeURIComponent(Base64.encode(state?.mobile)) : ''
            document.cookie = 'x-plotch-customer-id=c_' + customer_id_base64 + ';' + expires
            mobile_id ? (document.cookie = 'x-plotch-customer-mobile=m_' + mobile_id + '' + expires) : ''
          }

          if (customerId) {
            if (state?.redirectUrl) {
              // router.push(decodeURIComponent(state?.redirectUrl))
              window.location.href = decodeURIComponent(state?.redirectUrl)
            } else {
              window.location.href = `${HomeRoute.path}`
            }
          }
        }
      } catch (error: any) {
        setError('otpp', {
          type: 'manual',
          message: 'Incorrect OTP',
        })

        return error
      }
    }
  }

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (isResendDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval)
            setIsResendDisabled(false)
            return OTP_RESEND_INTERVAL
          }

          return prevTimer - 1
        })
      }, 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isResendDisabled])

  const resendApi = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.LOG_IN,
        payload: { mobile: state?.mobile },
      })

      if (res) {
        //setLoader(false)
      }
    } catch (error) {
      return error
    }
  }

  const handleChangeNumber = () => {
    const dataToPushResend = {
      event: 'login_signup_interaction',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      cta_text: 'change number',
      section_name: 'login_page',
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPushResend })
    navigate.goBack()
  }
  const handleResendOtp = () => {
    const dataToPushResend = {
      event: 'login_signup_interaction',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      cta_text: 'resend otp',
      section_name: 'login_page',
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPushResend })
    resendApi()
    setIsResendDisabled(true)
    setTimer(OTP_RESEND_INTERVAL)
  }

  const getFullOtp = (otp: string) => {
    if (otp?.length === 4) {
      clearErrors('otpp')
      setOtp(otp)
    }
  }

  return (
    <OtpPageContainer>
      <BackWrapper onClick={handleBack}>
        <BackArrow />
        OTP
      </BackWrapper>
      <OtpMainContainer>
        <LoginWrapper>
          <OtpContainer>
            <HeadingWrapper>
              <OtpHeading>Verify with OTP</OtpHeading>
              <MessageSent>Sent via SMS to {state?.mobile}</MessageSent>
              <ChangeNumber onClick={handleChangeNumber}>Change number?</ChangeNumber>
            </HeadingWrapper>

            <LoginWrapper>
              <LoginHeading>OTP</LoginHeading>
              <LoginFormWrapper onSubmit={handleSubmit(submitData)}>
                <OtpInputContainer>
                  <OtpWrapper>
                    <OtpInput length={4} getFullOtp={getFullOtp} name={'otpp'} />
                    <ErrorMessage>{errors?.otpp?.message}</ErrorMessage>
                  </OtpWrapper>
                </OtpInputContainer>

                <Counter>{isResendDisabled ? <span>{timer} Seconds</span> : <span></span>}</Counter>
                <Button label="LOGIN" variant={'contained'} className="small" type="submit" />
              </LoginFormWrapper>
            </LoginWrapper>
          </OtpContainer>
          {!isResendDisabled && (
            <>
              <OtpText>Didn&apos;t receive OTP?</OtpText>
              <ResendText onClick={handleResendOtp}>Resend OTP</ResendText>
            </>
          )}
        </LoginWrapper>
      </OtpMainContainer>
    </OtpPageContainer>
  )
}

export default OtpDetailView

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={30} viewBox="0 0 40 30" fill="none" {...props}>
    <rect width={39} height={29} x={0.5} y={0.5} stroke="#EDECEC" rx={7.5} />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M15.001 16.878a1.876 1.876 0 1 0 0-3.753 1.876 1.876 0 0 0 0 3.753ZM25 11.254a1.876 1.876 0 1 0 0-3.753 1.876 1.876 0 0 0 0 3.753ZM25 22.504a1.876 1.876 0 1 0 0-3.753 1.876 1.876 0 0 0 0 3.753ZM16.633 15.92l6.733 3.788M23.366 10.295l-6.733 3.787"
    />
  </svg>
)
export default SvgComponent

import ViewOrderDetail from 'views/ViewOrderDetail'
import { CommonStyle } from 'styles/pages/Common'
import { CheckoutPageWrapper } from 'styles/pages/MyAccount'
import PublicLayout from 'components/Layouts/public'

const ViewOrderDetailsPage = () => {
  return (
    <PublicLayout>
      <CheckoutPageWrapper>
        <CommonStyle>
          <ViewOrderDetail />
        </CommonStyle>
      </CheckoutPageWrapper>
    </PublicLayout>
  )
}

export default ViewOrderDetailsPage

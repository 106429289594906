const accessToken = 'Authorization'

export const login = (data: string) => {
  localStorage.setItem(accessToken, data)
  window.location.reload()
}

export const isLogin = () => {
  if (localStorage.getItem(accessToken)) {
    return true
  }

  return false
}

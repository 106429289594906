import FormWidget from 'components/FormWidget'
import DOMPurify from 'dompurify'
import { useEffect } from 'react'
import { TermsWrappers, ContactUsContainer, DataWrapper } from 'styles/pages/NotFound'

const ContactUs = ({ termData }: any) => {
  const addFormAssets = () => {
    const assets: Array<{ type: 'link' | 'script'; href?: string; src?: string }> = [
      { type: 'link', href: 'https://cdnassets.plotch.io/assets/css/form_1630673015.css' },
      { type: 'link', href: 'https://cdnassets.plotch.io/assets/css/bootstrap.min.css' },
      { type: 'script', src: 'https://cdnassets.plotch.io/assets/js/jquery-2.2.4.min.js' },
      { type: 'script', src: 'https://cdnassets.plotch.io/assets/js/bootstrap.min.js' },
      { type: 'script', src: 'https://cdnassets.plotch.io/assets/js/jquery-ui.min.js' },
      { type: 'script', src: 'https://cdnassets.plotch.io/assets/js/jquery.validate.min.js' },
      { type: 'script', src: 'https://cdnassets.plotch.io/assets/js/plotchForm_builder_1721107074.js' },
    ]

    const loadScript = (index: number) => {
      if (index >= assets.length) return // All assets loaded
      const asset = assets[index]
      if (!asset.src && !asset.href) return // Ensure either src or href is defined
      const element = asset.type === 'link' ? document.createElement('link') : document.createElement('script')
      element.onload = () => loadScript(index + 1) // Load the next script once this one is loaded
      if (asset.src) {
        ;(element as HTMLScriptElement).src = asset.src
      } else if (asset.href) {
        ;(element as HTMLLinkElement).href = asset.href
        ;(element as HTMLLinkElement).rel = 'stylesheet' // Set rel attribute for link elements
      }

      document.head.appendChild(element)
    }

    loadScript(0)
  }

  useEffect(() => {
    if (termData) {
      addFormAssets()
    }
  }, [termData])

  return (
    <>
      <TermsWrappers>
        <ContactUsContainer>
          {termData && (
            <>
              <FormWidget className="col-sm-12 form_id" id="formData" formTemplatedid={termData?.elements[0].formId}>
                <input
                  type="hidden"
                  name="form_token"
                  value={termData?.elements[0]?.formToken}
                  className="form_token"
                />
                <DataWrapper dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(termData?.elements[0].formData) }} />
              </FormWidget>
            </>
          )}
        </ContactUsContainer>
      </TermsWrappers>
    </>
  )
}

export default ContactUs

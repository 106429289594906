import { pageData } from 'utils/datalayer/pageData'

export const DataLayerObject = () => {
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const dataSignInToPush = {
    event: 'navigation_interaction',
    page_type: GDL_PAGE_TYPE, // Use the page type captured during focus
    User_Id: loggedIn,
    User_Status: loggedInStatus,
    cta_text: 'sign_in',
    select_type: 'header',
    section_name: 'sign_in',
  }
  const dataMyAccountToPush = {
    event: 'navigation_interaction',
    page_type: GDL_PAGE_TYPE,
    User_Id: loggedIn,
    User_Status: loggedInStatus,
    cta_text: 'my_account',
    select_type: 'header',
    section_name: 'my account',
  }
  const dataNavLocationToPush = {
    event: 'navigation_interaction',
    page_type: GDL_PAGE_TYPE,
    User_Id: loggedIn,
    User_Status: loggedInStatus,
    cta_text: 'select_location',
    select_type: 'header',
    section_name: 'select location',
  }
  const dataNavCartToPush = {
    event: 'navigation_interaction',
    page_type: GDL_PAGE_TYPE, // Use the page type captured during focus
    User_Id: loggedIn,
    User_Status: loggedInStatus,
    cta_text: 'cart',
    select_type: 'header',
    section_name: 'cart',
  }
  const dataNavWishlistToPush = {
    event: 'navigation_interaction',
    page_type: GDL_PAGE_TYPE, // Use the page type captured during focus
    User_Id: loggedIn,
    User_Status: loggedInStatus,
    cta_text: 'wishlist',
    select_type: 'header',
    section_name: 'wishlist',
  }
  const dataLogoToPush = {
    event: 'logo_click',
    page_type: GDL_PAGE_TYPE,
    User_Id: loggedIn,
    User_Status: loggedInStatus,
  }
  const dataToPushStore = {
    event: 'navigation_interaction',
    page_type: GDL_PAGE_TYPE,
    User_Id: loggedIn,
    User_Status: loggedInStatus,
    cta_text: 'store',
    select_type: 'header',
    section_name: 'store',
  }

  return {
    dataSignInToPush,
    dataMyAccountToPush,
    dataNavLocationToPush,
    dataNavCartToPush,
    dataNavWishlistToPush,
    dataLogoToPush,
    dataToPushStore,
  }
}

import { Dialog, Box } from '@mui/material'
import LoaderIcon from 'assets/svg/LoaderIcon'
import { useHomeContext } from 'context/HomeContext'
import { useTheme } from 'context/themeContext'
import { useEffect } from 'react'
interface ILoader {
  loader: boolean
}

const SpinnerDialog = ({
  loader,
  setThemeContext,
  setSiteTitle,
  setRobot,
  setPageDescription,
  setMetaKeywords,
  setPropertyImage,
  setPropertyUrl,
  setDynamicTags,
}: any) => {
  const { themedata, storesData } = useTheme()
  const { homeData } = useHomeContext() // Use the context here

  useEffect(() => {
    if (themedata) {
      setThemeContext(themedata)
    }
  }, [themedata])

  useEffect(() => {
    if (homeData) {
      homeData?.pageTitle
        ? setSiteTitle(homeData?.pageTitle)
        : storesData?.d?.storeBrandName
        ? setSiteTitle(themedata?.storeBrandName)
        : ''
      if (themedata?.d?.objectDetails?.header?.siteLogo?.logoImage) {
        setPropertyImage(themedata?.d?.objectDetails?.header?.siteLogo?.logoImage)
      }

      if (storesData?.d?.metaTags) {
        const metaTags = storesData.d.metaTags
          .filter(
            (meta: any) =>
              meta?.meta_type && meta?.meta_type_value && meta.meta_content_type && meta.meta_content_value,
          )
          .map((meta: any, index: number) => (
            <meta
              key={index}
              {...{ [meta?.meta_type?.toLowerCase()]: meta.meta_type_value }}
              {...{ [meta?.meta_content_type?.toLowerCase()]: meta.meta_content_value }}
            />
          ))

        if (metaTags.length > 0) {
          setDynamicTags(metaTags)
        }
      }

      setMetaKeywords(homeData?.metaKeywords)
      setPageDescription(homeData?.pageDescription)
      setRobot(homeData?.robots)
      setPropertyUrl(window.location.href)
      homeData?.imgUrl && setPropertyImage(homeData?.imgUrl)
    } else {
      setSiteTitle(storesData?.d?.storeBrandName)
      setPropertyUrl(window.location.href)
      setRobot('index, follow')
      setMetaKeywords('')
      setPageDescription('')
      setPropertyImage('')
    }
  }, [homeData])

  return (
    <Dialog
      open={loader}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoaderIcon />
        <label className="loader-text"> Almost there! </label>
      </Box>
    </Dialog>
  )
}

export default SpinnerDialog

import { SearchWrapper } from 'styles/components/TextInput'
import { ISearchProps } from 'interfaces/components'

import { Input } from 'antd'

const SearchBar = ({
  placeholder,
  required,
  type,
  onFocus,
  onWheel,
  prefix,
  suffixIcon,
  disabled,
  className,
  maxLength,
  onKeyDown,
  onKeyUp,
  inputRef,
  autocomplete,
  value,
  onChange,
  onKeyPress,
}: ISearchProps) => {
  return (
    <SearchWrapper className={className}>
      <Input
        type={type}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onWheel={onWheel}
        prefix={prefix}
        suffix={suffixIcon}
        disabled={disabled}
        className={className}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        ref={inputRef}
        autoComplete={autocomplete}
        onKeyPress={onKeyPress}
      />
    </SearchWrapper>
  )
}

export default SearchBar

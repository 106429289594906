import BagCheck from 'assets/svg/BagCheck'
import { IOption } from 'interfaces/pages'
import LocationIcon from 'assets/svg/LocationIcon'
import HeartIcon from 'assets/svg/HeartIcon'
import TicketIcon from 'assets/svg/TicketIcon'
export const menuOptions: IOption[] = [
  {
    key: '1',
    icon: <BagCheck />,
    name: 'My Orders',
    description: 'View, track, cancel & return orders',
    link: '/my-orders',
  },
  {
    key: '2',
    icon: <LocationIcon />,
    name: 'Manage Addresses',
    description: 'See all your saved addresses',
    link: '/manage-addresses',
  },
  {
    key: '3',
    icon: <HeartIcon />,
    name: 'My Wishlist',
    description: 'Manage your wishlisted items',
    link: '/wishlist',
  },
  {
    key: '4',
    icon: <TicketIcon />,
    name: 'My Tickets',
    description: 'See all your issues & grievances',
    link: '/my-tickets',
  },
]

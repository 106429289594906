// import React, from 'react'
import { useHistory } from 'react-router-dom'
import { SellerRoute } from 'constants/routes'
import { Tabs } from 'antd'
import SpeedometerIcon from 'assets/svg/SpeedometerIcon'
import StarIcon from 'assets/svg/StarIcon'

import {
  SplitStoresHyperlocalWrapper,
  StoreMainWrapper,
  ImageWrapper,
  StoreContentContainer,
  StoreContentWrapper,
  StoreItemDetails,
} from 'styles/views/SplitStoresHyperlocalView'

import ItemTabView from './itemTabView'

const SplitStoresHyperlocalView = ({
  products,
  searchTerm,
  metaInfo,
  setSearchType,
  filtersData,
  filter,
  productCount,
  setFilter,
  setLoadMoreData,
}: any) => {
  // const [error, setError] = useState(false)

  const nonVegFilter = filtersData?.find((filter: any) => filter.esFilterName === 'is_nonveg')

  const navigate = useHistory()
  const searchParams = new URLSearchParams(window.location.search)

  const handleKeyChange = (key: string) => {
    if (key === '1') {
      setSearchType('store')
      searchParams.set('search_type', 'store')
      searchParams.set('page_id', '1')
    } else {
      setSearchType('item')
      searchParams.set('search_type', 'item')
      searchParams.set('page_id', '1')
    }
  }

  // const handleError = () => {
  //   setError(true)
  // }

  const storeTab = {
    label: 'Store',
    key: '1',
    children: (
      <>
        <StoreMainWrapper>
          <label className="ultra-bold web-heading">{searchTerm ? 'Store related to "' + searchTerm + '"' : ''}</label>
          <label className="h1b mobile-heading">{searchTerm ? 'Store related to "' + searchTerm + '"' : ''}</label>
          <StoreContentContainer>
            {products.map((product: any, index: number) => {
              const imageRatio = metaInfo?.image_render_type == 'square' ? 'w_300,h_300' : 'w_300,h_450'

              let imgUrl =
                product?.storeImage?.startsWith('http://') || product?.storeImage?.startsWith('https://')
                  ? product?.storeImage
                  : `https://cdnaz.plotch.io/image/upload/${imageRatio}${product?.storeImage}`
              if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes(imageRatio)) {
                imgUrl = imgUrl?.split('/upload/').join(`/upload/${imageRatio}/`)
                imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
              }

              const distance = product?.distance?.toFixed(2)

              // const productName = product?.productName
              //   .replace(/[^a-zA-Z ]/g, '')
              //   .split(' ')
              // .join('-')
              //   .toLowerCase()

              return (
                <StoreContentWrapper
                  key={index}
                  onClick={() => {
                    navigate.push(SellerRoute.path.replace(':id', product?.sellerId))
                    // localStorage.setItem('productId', product?.productId)
                  }}
                >
                  <ImageWrapper>
                    <img
                      src={imgUrl}
                      className="storeImge"
                      // onError={handleError}
                      // onLoad={() => setError(false)}
                      // style={error ? { minHeight: '80%', height: '275px' } : {}}
                    />
                  </ImageWrapper>
                  <StoreItemDetails>
                    <div className="store-name category-name h1b">
                      {product?.storeName.toLowerCase()}
                      {/* {product.storeName.length > 15
                        ? `${product.storeName.toLowerCase().slice(0, 15)}...`
                        : product?.storeName.toLowerCase()} */}
                    </div>
                    <div className="category-name h1r">
                      {product?.productName.toLowerCase()}
                      {/* {product?.productName.length > 12
                        ? `${product?.productName.toLowerCase().slice(0, 12)} ...`
                        : product?.productName.toLowerCase()} */}
                    </div>
                    <div className="store-distance-details">
                      <SpeedometerIcon />
                      <div className="h1r">{distance} KM</div>
                      <div className="h1r city-name">
                        {product?.locations[0]?.address?.city}
                        {/* {product.locations[0].address.city.length > 10
                          ? `${product.locations[0].address.city.toLowerCase().slice(0, 10)} ...`
                          : product.locations[0].address.city.toLowerCase()} */}
                      </div>
                    </div>
                    <div className="rating h1r">
                      <StarIcon />
                      {product.productRatings}
                    </div>
                    {/* <div className="offers">
                      <Pricetag />
                      <label className="offer-heading h1b"> {store.offer}</label>
                    </div> */}
                  </StoreItemDetails>
                </StoreContentWrapper>
              )
            })}
          </StoreContentContainer>
        </StoreMainWrapper>
        {/* ) : (
          <div className="no-order">No Data!</div>
        )} */}
      </>
    ),
  }
  const itemsTab = {
    label: 'Items',
    key: '2',
    children: (
      <>
        <ItemTabView
          nonVegFilter={nonVegFilter}
          filtersData={filtersData}
          products={products}
          searchTerm={searchTerm}
          metaInfo={metaInfo}
          productCount={productCount}
          filter={filter}
          setFilter={setFilter}
          setLoadMoreData={setLoadMoreData}
        />
      </>
    ),
  }

  const tabItems = [storeTab, itemsTab]

  return (
    <SplitStoresHyperlocalWrapper>
      <Tabs defaultActiveKey="1" items={tabItems} onChange={handleKeyChange} />
    </SplitStoresHyperlocalWrapper>
  )
}

export default SplitStoresHyperlocalView

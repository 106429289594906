import Drawer from '@mui/material/Drawer'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// import { LoaderContext } from 'context/loader'
import Button from 'components/Button'
import usePost from 'hooks/usePost'
import APIS from 'constants/api'
import { AddAddressRoute } from 'constants/routes'
import { IAddress } from 'interfaces/views'
import DeleteAddress from 'views/DeleteAddress'
import HomeIcon from 'assets/svg/HomeIcon'

import {
  AddressesListContainer,
  ManageAddressMainWrapper,
  ManageAddressLabel,
  ManageAddressesLists,
  AddressContainer,
  ManageAddressesContainer,
  AddressWrapper,
  PincodeLocation,
  BasicAddress,
  // CityDetail,
  PersonName,
  AddressesButtonWrapper,
  Divider,
  NoAddressWrapper,
} from 'styles/views/LocationNewUser'
import OfficeIcon from 'assets/svg/OfficeIcon'
import Business from 'assets/svg/Business'
import OtherIcon from 'assets/svg/OtherIcon'
import NoAddressIcon from 'assets/svg/NoAddressIcon'
import TagManager from 'react-gtm-module'
import { AddressInteractionData } from 'utils/datalayer/addressInteractionData'
import { useTheme } from 'context/themeContext'

const ManageAddressesList = () => {
  const [openDeleteAddress, setOpenDeleteAddress] = useState(false)
  const [currentAddressId, setCurrentAddressId] = useState<string | null>(null)
  const [checkAddressDeleted, setcheckAddressDeleted] = useState(false)
  const [anchorChoice, setAnchorChoice] = useState<'bottom' | 'left' | 'top' | 'right'>('bottom')
  const [address, setAddress] = useState([])
  const navigate = useHistory()
  const { storesData } = useTheme()

  const { editAddressDataToPush } = AddressInteractionData()

  // const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  const getAddresses = async (data: string) => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.ADDRESS_LIST,
        payload: {
          customerId: data,
          networkType: 1,
          networkSpeed: 0,
        },
      })
      if (res) {
        //setLoader(false)
        setAddress(res?.d?.addressList)
      }
    } catch (error) {
      //setLoader(false)

      return error
    }
  }

  const checkDeleted = (value: boolean) => {
    setcheckAddressDeleted(value)
  }

  useEffect(() => {
    const customerId = localStorage.getItem('customerId')
    if (customerId) {
      getAddresses(customerId)
    }
  }, [checkAddressDeleted])

  const toggleDeleteAddressDrawer = (newAddress: boolean) => () => {
    setOpenDeleteAddress(newAddress)
  }

  const handleEdit = (item: IAddress) => {
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: editAddressDataToPush })

    navigate.push(AddAddressRoute.path, {
      addressId: item?.addressId,
    })
  }

  const handleDelete = (item: IAddress) => {
    setCurrentAddressId(item?.addressId)
    toggleDeleteAddressDrawer(true)()
  }

  useEffect(() => {
    // Function to determine anchor based on window width
    const updateAnchor = () => {
      if (window.innerWidth > 768) {
        setAnchorChoice('right')
      } else {
        setAnchorChoice('bottom')
      }
    }

    // Set anchor on initial render
    updateAnchor()

    // Update anchor on window resize
    window.addEventListener('resize', updateAnchor)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateAnchor)
    }
  }, [])

  const getAddressIcon = (label: string | null) => {
    switch (label) {
      case 'Work':
        return <OfficeIcon />
      case 'Home':
      case null:
        return <HomeIcon />
      case 'Hotel':
        return <Business />
      case 'Other':
        return <OtherIcon />
      default:
        return <OtherIcon />
    }
  }

  return (
    <AddressesListContainer>
      {address?.length ? (
        <ManageAddressMainWrapper>
          <ManageAddressLabel>Saved Addresses</ManageAddressLabel>
          <ManageAddressesLists>
            {address.length
              ? address.map((item: IAddress, index: number) => (
                  <ManageAddressesContainer key={index}>
                    <AddressContainer>
                      {getAddressIcon(item?.label)}

                      <AddressWrapper>
                        <PincodeLocation>
                          {item?.postcode} - {item?.label}
                        </PincodeLocation>
                        <BasicAddress>
                          {item?.building}
                          {item?.building == null ? '' : ','}&nbsp;
                          {item?.locality}
                          {item?.locality == null ? '' : ','}&nbsp;
                          {item?.landmark},&nbsp;
                          {item?.city},&nbsp;{item?.region},&nbsp;
                          {item?.country}
                        </BasicAddress>
                        {/* <CityDetail>
                          {item?.city}&nbsp;
                          {item?.region}&nbsp;
                          {item?.country}
                        </CityDetail> */}
                        <PersonName>
                          {item?.firstName} {item?.lastName} &nbsp;({item?.telephone})
                        </PersonName>
                        <AddressesButtonWrapper>
                          <Button
                            label="Edit"
                            variant="text"
                            type="button"
                            className="small"
                            onClick={() => handleEdit(item)}
                          />
                          <Divider />
                          <Button
                            label="Delete"
                            variant="text"
                            type="button"
                            className="small"
                            onClick={() => handleDelete(item)}
                          />
                        </AddressesButtonWrapper>
                      </AddressWrapper>
                    </AddressContainer>
                  </ManageAddressesContainer>
                ))
              : 'No Address Found'}
          </ManageAddressesLists>
        </ManageAddressMainWrapper>
      ) : (
        <NoAddressWrapper>
          <NoAddressIcon />
          <div>No saved addresses</div>
        </NoAddressWrapper>
      )}

      <Drawer
        className="deleteDrawer"
        open={openDeleteAddress}
        anchor={anchorChoice}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: { xs: '20px 20px 0px 0px', sm: '0', xl: '0' },
          },
        }}
      >
        {
          <DeleteAddress
            onClose={toggleDeleteAddressDrawer(false)}
            addressId={currentAddressId}
            checkDeleted={checkDeleted}
          />
        }
      </Drawer>
    </AddressesListContainer>
  )
}

export default ManageAddressesList

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DOMPurify from 'dompurify'
import usePost from 'hooks/usePost'
// import { LoaderContext } from 'context/loader'
import APIS from 'constants/api'
import { NotFoundRoute } from 'constants/routes'
import BackArrow from 'assets/svg/BackArrow'
import { BackWrappers } from 'styles/pages/Login'
import { TermsWrapper, DetailMainContainer, TermsDescription } from 'styles/pages/NotFound'

const TermsAndCondition = () => {
  const router = useHistory()
  // const { setLoader } = useContext(LoaderContext)
  const [termsData, setTermsData] = useState<any>(null || '')
  const [feedName, setFeedName] = useState<any>(null || '')

  const { mutateAsync } = usePost()

  const fetchData = async (pageUrl: string) => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.DYNAMICURL,
        payload: {
          pageUrl: pageUrl,
          customerId: false,
          sortBy: {},
        },
      })
      if (res?.d?.sections[0]?.widgets[0]?.elements[0].data) {
        //setLoader(false)
        setFeedName(res?.d?.feedDetails?.feedName)
        setTermsData(res?.d?.sections[0]?.widgets[0]?.elements[0].data)
      }
    } catch (error: any) {
      //setLoader(false)
      router.push(NotFoundRoute.path)
      return error
    }
  }

  const handleBack = () => {
    router.goBack()
  }

  useEffect(() => {
    const path = location.pathname.split('/').pop() // Extract the last segment of the path
    const plotchDomain =
      process.env.NODE_ENV == 'production' && process.env.REACT_APP_ENV == ''
        ? window.location.hostname
        : process.env.REACT_APP_PLOTCH_DOMAIN
    if (path) {
      fetchData(`http://${plotchDomain}/${path}`)
    }
  }, [location.pathname])

  return (
    <>
      {termsData && (
        <TermsWrapper>
          <BackWrappers onClick={handleBack}>
            <BackArrow />
            {feedName}
          </BackWrappers>
          <DetailMainContainer>
            {termsData && <TermsDescription dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(termsData) }} />}
          </DetailMainContainer>
        </TermsWrapper>
      )}
    </>
  )
}

export default TermsAndCondition

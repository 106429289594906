// import Button from 'components/Button'
import CloseIcon from 'assets/svg/CloseIcon'
import {
  ReturnCancellationPolicyContainer,
  HeadingWrapper,
  TopHeadingWrapper,
  PolicyWrapper,
  ReturnCancellationPolicyWrap,
} from 'styles/views/SaveAddress'
// import { ReturnButtonWrapper } from 'styles/components/Button'
import { SvgWrapper } from 'styles/views/LocationNewUser'
import { CommonStyle } from 'styles/pages/Common'
import { OrderPolicyWrapper } from 'styles/views/ViewOrderDetail'
import APIS from 'constants/api'
import usePost from 'hooks/usePost'
import { useEffect, useState } from 'react'
// // import { LoaderContext } from 'context/loader'

const ReturnCancellationPolicy = ({ onClose, product }: any) => {
  const [productDetails, setProductDetails] = useState<any>({})
  const customerId = localStorage.getItem('customerId')
  // const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  let imgUrl =
    product?.imgUrl?.includes('http://') || product?.imgUrl?.includes('https://')
      ? product?.imgUrl
      : `https://cdnaz.plotch.io/image/upload/w_118,h_118${product?.imgUrl}`
  if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
    imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
    imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
  }

  const fetchReturnPolicyData = async () => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.PRODUCT_DETAIL,
        payload: {
          productId: product?.productId.toString(),
          customerId: customerId,
          // productUrl: pageUrl,
        },
      })
      if (res) {
        setProductDetails(res?.d)
        //setLoader(false)
      }
    } catch (error) {
      //setLoader(false)
      // router.push(NotFoundRoute.path)
    }
  }

  useEffect(() => {
    fetchReturnPolicyData()
  }, [product])

  return (
    <ReturnCancellationPolicyContainer>
      <SvgWrapper onClick={onClose} className="cross-icon">
        <CloseIcon />
      </SvgWrapper>
      <CommonStyle>
        <HeadingWrapper className="remove-product">
          <TopHeadingWrapper>
            <div className="h0b">Return & Cancellation Policy</div>
          </TopHeadingWrapper>
        </HeadingWrapper>
      </CommonStyle>
      <ReturnCancellationPolicyWrap>
        <CommonStyle>
          <OrderPolicyWrapper>
            <div className="confirm-order">
              <img src={imgUrl} />
              <div className="orders-details">
                <div className="order-name">
                  <label className="h3r product-name">
                    {/* {product.productName ? `${product?.productName?.slice(0, 15)} ... ` : product?.productName}( */}
                    {/* {product?.qty} Qty{product?.variants?.colour ? ',' : ''} */}
                    {product.productName
                      ? `${product?.productName?.toLowerCase().slice(0, 15)} ... `
                      : product?.productName.toLowerCase()}
                    ({product?.qty} Qty{product?.variants?.colour ? ',' : ''}{' '}
                    {product?.variants?.colour ? (
                      product?.variants?.colour?.[0]?.startsWith('#') ? (
                        <span className="colourBox" style={{ background: product?.variants?.colour }} />
                      ) : (
                        product?.variants?.colour
                      )
                    ) : (
                      ''
                    )}
                    {product?.variants?.size ? ' , ' + product?.variants?.size : ''}
                    {''})
                  </label>
                </div>
                <label className="h3r">{product?.estimatedDeliveryTime}</label>
              </div>
            </div>
          </OrderPolicyWrapper>
        </CommonStyle>
        <CommonStyle>
          <PolicyWrapper>
            {productDetails?.returnable === 'Yes' ? (
              <div className="policywrap">
                <div className="h1b">Return Policy</div>
                <div className="h2r">
                  Returns/Replacements allowed for this product. Refund allowed in select cases if the product delivered
                  is defective or damaged.
                </div>
              </div>
            ) : (
              <div className="policywrap">
                <div className="h1b">Return Policy</div>
                <div className="h2r">
                  Returns/Replacements is not allowed for this product. Refund allowed in select cases if the product
                  delivered is defective or damaged.
                </div>
              </div>
            )}
            {productDetails?.cancellable === 'Yes' ? (
              <div className="policywrap">
                <div className="h1b">Cancellation Policy</div>
                <div className="h2r">Cancellation is allowed for this product.</div>
              </div>
            ) : (
              <div className="policywrap">
                <div className="h1b">Cancellation Policy</div>
                <div className="h2r">Cancellation is not allowed for this product.</div>
              </div>
            )}
          </PolicyWrapper>
        </CommonStyle>
      </ReturnCancellationPolicyWrap>
      {/* <ReturnButtonWrapper>
        <Button label="Remove Undeliverable Products" variant="contained" type="submit" className="small" />
      </ReturnButtonWrapper> */}
    </ReturnCancellationPolicyContainer>
  )
}

export default ReturnCancellationPolicy

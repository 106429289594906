import { useEffect, useState } from 'react'
import { Typography } from 'antd'
import PopupModal from 'components/TicketModal'
import APIS from 'constants/api'
import useGet from 'hooks/useGet'
import { IProduct } from 'interfaces/pages/order'
import CheckmarkCircle from 'assets/svg/CheckmarkCircle'
import HelpCircle from 'assets/svg/HelpCircle'
import OfficeIcon from 'assets/svg/OfficeIcon'
import Business from 'assets/svg/Business'
import OtherIcon from 'assets/svg/OtherIcon'
import HomeIcon from 'assets/svg/HomeIcon'
import RightArrow from 'assets/svg/RightArrow'
import PendingIcon from 'assets/svg/PendingIcon'

import {
  ConfirmationOrderDetailWrapper,
  // OrderConfirmationWrapper,
  ConfirmStatusWrapper,
  // StatusWrapper,
  DeliveryAddressWrapper,
  AddressContainer,
  AddressWrapper,
  PincodeLocation,
  BasicAddress,
  // CityDetail,
  PersonName,
} from 'styles/views/ViewOrderDetail'
import { ConfirmationMessageWrapper, OrderMessage } from 'styles/pages/OrderSuccessPage'

import { OrderConfirmationWrapper, OrderStateWrapper } from 'styles/views/ViewOrderDetail'
import TrackOrderStepper from './trackOrderStepper'
import TagManager from 'react-gtm-module'
import { pageData } from 'utils/datalayer/pageData'
import { useTheme } from 'context/themeContext'

const TrackOrderDetail = () => {
  const [open, setOpen] = useState(false)
  const { storesData } = useTheme()
  const orderItemId = localStorage.getItem('orderItemId')
  const customerId = localStorage.getItem('customerId')
  const orderId = localStorage.getItem('orderId')
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  const { refetch: refetchTicket } = useGet('ticket-list', `${APIS.TICKET_LIST}?customerId=${customerId}`)

  const { refetch: refetchOrderDetails, data } = useGet(
    'order-details',
    `${APIS.GET_ORDER_DETAILS}?orderId=${orderId}&customerId=${customerId}`,
  )
  const { refetch, data: trackData } = useGet(
    'track-details',
    `${APIS.TRACK_SHIPMENT}?orderItemId=${orderItemId}&customerId=${customerId}`,
  )
  const orderData = data?.d?.orderDetails

  const changeModal = () => {
    setOpen(true)
  }

  const handleOk = () => {
    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }
  const handleCancel = () => {
    setOpen(false)
  }
  const getAddressIcon = (label: string | null) => {
    switch (label) {
      case 'Work':
        return <OfficeIcon />
      case 'Home':
      case null:
        return <HomeIcon />
      case 'Hotel':
        return <Business />
      case 'Other':
        return <OtherIcon />
      default:
        return <OtherIcon />
    }
  }
  const getStatusMessage = (statusCode: number) => {
    switch (statusCode) {
      case 0:
        return 'Pending'
      case 1:
        return 'Processing'
      case 2:
        return 'Tracking Request In Process'
      case 3:
        return 'Tracking Requested'
      case 4:
        return 'Ready For Pickup'
      case 5:
        return 'Picked Up'
      case 6:
        return 'Dispatched'
      case 7:
        return 'Delivered'
      case 8:
        return 'Cancelled'
      case 9:
        return 'RTO Delivered'
      case 10:
        return 'RTO In Transit'
      case 11:
        return 'Return Requested'
      case 12:
        return 'Return Initiated'
      case 13:
        return 'Ready For Reverse Pickup'
      case 14:
        return 'Return Picked Up'
      case 15:
        return 'Return Dispatched'
    }
  }
  const messageData = orderData?.shipments[0]
  const createdAt = messageData?.createdAt ? 'Created' : 'Confirmed'
  const shippedOn = messageData?.shippedOn ? 'Shipped' : createdAt
  const deliveredOn = messageData?.deliveredOn ? 'Delivered' : shippedOn

  const initTicketDataToPush = {
    event: 'raise_ticket_initiate',
    page_type: GDL_PAGE_TYPE,
    User_Id: loggedIn,
    User_Status: loggedInStatus,
    cta_text: 'get help',
    order_ticket_id: orderData?.orderId ? orderData?.orderId : 'NA',
  }

  useEffect(() => {
    refetch()
  }, [trackData])

  useEffect(() => {
    refetchOrderDetails()
  }, [orderData])

  return (
    <ConfirmationOrderDetailWrapper>
      <ConfirmationMessageWrapper>
        <CheckmarkCircle />
        <OrderMessage className="h0b">Your order is {deliveredOn}!</OrderMessage>
      </ConfirmationMessageWrapper>
      <OrderConfirmationWrapper>
        <div className="confirm-wrap">
          <div className="confirm-order">
            {orderData?.shipments[0]?.products[0].statusLabel == 'Pending' ? <PendingIcon /> : <CheckmarkCircle />}

            <div className="orders-details">
              <ConfirmStatusWrapper className="h1b" status={orderData?.status}>
                {getStatusMessage(orderData?.shipments[0]?.products[0]?.statusCode)}
              </ConfirmStatusWrapper>
              <label className="h3b">
                Order: {orderData?.orderId} ({orderData?.shipments[0]?.products?.length} Item
                {orderData?.shipments[0]?.products?.length > 1 ? '(s)' : ''})
              </label>
              <label className="h3r">Placed on {orderData?.orderDate}</label>
            </div>
          </div>
          <RightArrow />
        </div>

        {orderData?.shipments[0]?.products.map((item: IProduct, idx: number) => {
          let imgUrl =
            item?.image?.includes('http://') || item?.image?.includes('https://')
              ? item?.image
              : `https://cdnaz.plotch.io/image/upload/w_118,h_118${item?.image}`
          if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
            imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
            imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
          }

          return (
            <div className="confirmed-wrap order-state-type" key={idx}>
              <div className="confirm-order">
                <img src={imgUrl} />
                <div className="orders-details">
                  <div className="order-name">
                    <Typography.Text ellipsis={{ tooltip: item.name }} className="h3r item-name">
                      {item.name.toLowerCase()}
                    </Typography.Text>
                    {/* <label className="h3r">{item.name}</label> */}
                    {/* <HelpCircle /> */}
                    <HelpCircle
                      onClick={(e: any) => {
                        e.stopPropagation()
                        storesData?.d?.googleDatalayer &&
                          storesData?.d?.googleDatalayer == 1 &&
                          TagManager.dataLayer({ dataLayer: initTicketDataToPush })

                        changeModal()
                      }}
                      className="help-icon"
                    />
                  </div>
                  <div className="order-state-wrapper">
                    <div className="orders-details">
                      <label className="h3r">Delivery by {orderData?.shipments[0]?.expectedDelivery}</label>
                      <OrderStateWrapper className="h4b" state={item.statusLabel}>
                        {item.statusLabel}
                      </OrderStateWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </OrderConfirmationWrapper>
      <TrackOrderStepper trackData={trackData?.d} orderData={orderData} />
      <DeliveryAddressWrapper>
        <label className="h1b">Delivery Address</label>

        <AddressContainer>
          {getAddressIcon(orderData?.customerInfo?.addressType)}

          <AddressWrapper>
            <PincodeLocation className="h1b">
              {orderData?.customerInfo?.pincode} -{' '}
              {orderData?.customerInfo?.addressType === null ? 'Home' : orderData?.customerInfo?.addressType}
            </PincodeLocation>
            <BasicAddress className="h2r">
              {orderData?.customerInfo?.building} {orderData?.customerInfo?.building == null ? '' : ','}{' '}
              {orderData?.customerInfo?.city}, {orderData?.customerInfo?.state.toLowerCase()},{' '}
              {orderData?.customerInfo?.country}
            </BasicAddress>
            <PersonName className="h2r">
              {orderData?.customerInfo?.name} ({orderData?.customerInfo?.contact})
            </PersonName>
          </AddressWrapper>
        </AddressContainer>
      </DeliveryAddressWrapper>

      <PopupModal
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Raise Ticket"
        BtnName="Raise Ticket"
        getAllTickets={refetchTicket}
      />
    </ConfirmationOrderDetailWrapper>
  )
}

export default TrackOrderDetail

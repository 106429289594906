import styled from 'styled-components'

export const CustomizedMainWrapper = styled.div`
  width: 360px;
  .main-heading {
    color: var(--primary_text_color_dark);
  }
  .heading {
    color: var(--primary_text_color_darkgrey);
  }
  .sticky-bottom {
    position: fixed;
  }
  @media (max-width: 768px) {
    width: unset;
  }
`
export const MainWrapperAddOn = styled.div`
  max-height: 78vh;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    max-height: 70vh;
  }
`
export const MainHeadingWrappee = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 17px 21px;
  /* border-bottom: 1px solid var(--primary_stroke_color_light); */

  .cross-icon {
    cursor: pointer;
  }
`
export const ContentMainWrapper = styled.div`
  /* padding: 21px 32px 21px 48px; */
  padding: 5px 32px 67px 48px;

  @media (max-width: 768px) {
    min-height: 65vh;
    overflow-y: auto;
  }
`
export const SizeMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 15px 0 20px;
  .sub-heading {
    color: var(--primary_text_color_darkgrey);
  }
`
export const CheckoutButtonwrapper = styled.div``
export const ToppingType = styled.div`
  display: flex;
  gap: 4px;
`
export const SizeTypeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .size-wrap {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 30px;
  }

  .heading {
    width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .circle.checked {
    position: relative;
    margin: -2px;
  }
`
export const HeadingWrapper = styled.div`
  .main-heading {
    color: var(--primary_text_color_dark);
  }
  .heading {
    color: var(--primary_text_color_darkgrey);
  }
`
export const TopHeadingWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--primary_stroke_color_light);
  .item-name {
    color: var(--primary_text_link_color);
  }
`
export const MobileCheckoutButtonwrapper = styled.div`
  /* @media (max-width: 768px) { */
  background: #fff;
  border-top: 1px solid var(--primary_stroke_color_light);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: fixed;
  bottom: 0;
  border-radius: unset;
  width: 344px;
  padding: 8px;

  button,
  .checkout-item-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
  }
  @media (max-width: 768px) {
    position: unset;
    left: 0;
    width: calc(100vw - 18px);
  }
`

export const SizeSubTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  /* justify-content: space-between; */
  /* align-items: center; */
  .size-wrap {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 30px;
  }
`

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
    <path
      stroke="var(--primary_success_color)"
      // stroke="#629E58"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="m2.625 2.626.469 7.5c.022.434.337.75.75.75h4.312c.414 0 .724-.316.75-.75l.469-7.5"
    />
    <path
      stroke="var(--primary_success_color)"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M1.875 2.626h8.25"
    />
    <path
      stroke="var(--primary_success_color)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M4.5 2.625v-.937a.56.56 0 0 1 .563-.563h1.875a.56.56 0 0 1 .562.563v.937M6 4.125v5.25M4.313 4.125l.187 5.25M7.688 4.125 7.5 9.375"
    />
  </svg>
)
export default SvgComponent

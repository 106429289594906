import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      // stroke={theme.primaryIconStaticColor}
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M6.251 5.626h11.25M6.251 10h11.25M6.251 14.377h11.25M3.132 6.263a.63.63 0 1 0 0-1.262.63.63 0 0 0 0 1.262ZM3.132 10.637a.63.63 0 1 0 0-1.261.63.63 0 0 0 0 1.261ZM3.132 15.013a.63.63 0 1 0 0-1.262.63.63 0 0 0 0 1.262Z"
    />
  </svg>
)
export default SvgComponent

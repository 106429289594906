import { AccountMenuOption } from 'styles/components/AccountMenu'
import ChevronForward from 'assets/svg/ChevronForward'
import { IAccountMenuProps } from 'interfaces/pages'
import { useHistory } from 'react-router-dom'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
import { useTheme } from 'context/themeContext'

const AccountMenuComponent = ({ options }: IAccountMenuProps) => {
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const { storesData } = useTheme()

  const navigate = useHistory()
  const handleOptionClick = (link: any, eventData: any) => {
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: eventData })

    navigate.push(link)
  }

  return (
    <AccountMenuOption>
      {options.map((option, key) => (
        <a
          onClick={() =>
            handleOptionClick(option.link, {
              event: 'navigation_interaction',
              page_type: GDL_PAGE_TYPE,
              User_Id: loggedIn,
              User_Status: loggedInStatus,
              cta_text: option.name?.toLowerCase(),
            })
          }
          // onClick={() => navigate.push(option.link)}
          key={key}
        >
          <div className="option-list">
            <div className="option-item">
              <div className="option-item-icon svgIcon">{option.icon}</div>
              <div className="option-item-data">
                <div className="option-item-name h0b">{option.name}</div>
                <div className="option-item-description h2r">{option.description}</div>
              </div>
              <div className="actionArrow svgIcon">
                <ChevronForward />
              </div>
            </div>
            <div className="option-divider"></div>
          </div>
        </a>
      ))}
    </AccountMenuOption>
  )
}

export default AccountMenuComponent

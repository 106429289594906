// import { useLocation } from 'react-router-dom'

export const pageData = () => {
  // const location = useLocation()
  const customerId = localStorage.getItem('customerId')
  const sellerName = localStorage.getItem('sellerName') || 'na'
  const address = localStorage.getItem('location') || 'na'
  const placeName = address != 'na' && address != '' ? JSON.parse(address) : 'na'

  const loggedIn = customerId || 'na'
  const loggedInStatus = customerId ? 'logged_in' : 'non_logged_in'

  const pageTypeMap: { [key: string]: string } = {
    '/': 'homepage',
    '/product/:name/:id': 'product detail',
    '/checkout': 'cart listing page',
    '/my-account': 'my account page',
    '/categories': 'category page',
    '/my-orders': 'my orders page',
    '/About_us': 'about us page',
    '/Contact_us': 'contact us page',
    '/Terms-Services': 'terms and services page',
    '/Privacy_Policy': 'privacy policy page',
    '/Cancellation-Policy': 'cancellation policy page',
    '/cookie': 'cookies page',
    '/Return-Policy': 'return policy page',
    '/wishlist': 'wishlist listing page',
    '/manage-addresses': 'address page',
    '/add-addresses': 'address page',
    '/my-tickets': 'ticket page',
    '/my-profile': 'profile page',
    '/seller/:id': 'seller listing page',
    '/store-hyperlocal': 'hyperlocal store page',
    '/stores-near-me': 'store page',
    '/searchresults': 'search page',
    '/:name': '',
  }

  // const matchedPath = Object.keys(pageTypeMap).find((path) => {
  //   if (path === '/searchresults') {
  //     return location.pathname.includes('/searchresults')
  //   }

  //   const pathRegex = new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`)
  //   return pathRegex.test(location.pathname)
  // })

  const matchedPath = Object.keys(pageTypeMap).find((path) => {
    if (path === '/searchresults') {
      return window.location.pathname.includes('/searchresults')
    }

    if (path === '/:name') {
      // Match any path of the form "/something" and capture "something"
      return /^\/[^/]+$/.test(window.location.pathname)
    }

    const pathRegex = new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`)
    return pathRegex.test(window.location.pathname)
  })
  let GDL_PAGE_TYPE = matchedPath ? pageTypeMap[matchedPath] : 'unknown'

  if (matchedPath === '/:name') {
    const path = localStorage.getItem('pt') ? ' ' + localStorage.getItem('pt') : ''
    GDL_PAGE_TYPE = window.location.pathname.split('/')[1] + path || 'unknown'
  }

  const sellerNameToUse = GDL_PAGE_TYPE === 'seller listing page' ? sellerName : 'na'

  return {
    loggedIn,
    loggedInStatus,
    address: address != 'na' && address != '' ? placeName?.placeName?.toLowerCase() : 'na',
    GDL_PAGE_TYPE,
    sellerNameToUse,
  }
}

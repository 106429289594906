import * as React from 'react'
import { SVGProps } from 'react'
// import { theme } from 'theme'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g stroke="#121212" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.002}>
      <path d="M9.53 15.625 3.905 10 9.53 4.375M4.688 10h11.406" />
    </g>
  </svg>
)
export default SvgComponent

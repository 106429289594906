import styled from 'styled-components'
export const AccountMenuOption = styled.div`
  .option-item-description {
    margin-top: 7px;
  }
  .option-item {
    display: flex;
    gap: 7px;
    cursor: pointer;
  }
  .option-item-name {
    color: var(--primary_text_link_color);
  }
  .option-divider {
    margin: 17px 8px;
    margin-left: 30px;
    background: var(--primary_stroke_color_light);
    height: 1px;
  }
  a {
    all: unset;
    display: inline;
  }
  .option-item-icon path {
    stroke: var(--primary_icon_static_color);
  }
`

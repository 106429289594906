import APIS from 'constants/api'
// import { LoaderContext } from 'context/loader'
import usePost from 'hooks/usePost'
import CheckmarkCircle from 'assets/svg/CheckmarkCircle'
import HelpCircle from 'assets/svg/HelpCircle'
// import CloseCircle from 'assets/svg/CloseCircle'
import HomeIcon from 'assets/svg/HomeIcon'
import Business from 'assets/svg/Business'
import OfficeIcon from 'assets/svg/OfficeIcon'
import OtherIcon from 'assets/svg/OtherIcon'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
import { useTheme } from 'context/themeContext'

import {
  ConfirmationOrderDetailWrapper,
  OrderConfirmationWrapper,
  StatusWrapper,
  PriceDetailWrapper,
  PaymentMethodWrapper,
  DeliveryAddressWrapper,
  AddressContainer,
  AddressWrapper,
  PincodeLocation,
  BasicAddress,
  // CityDetail,
  PersonName,
} from 'styles/views/ViewOrderDetail'
import RightArrow from 'assets/svg/RightArrow'
import { useEffect, useState } from 'react'
import { ISuccessOrderData, ItemDetailsData } from 'interfaces/pages/orderSuccess'
import PendingIcon from 'assets/svg/PendingIcon'
import { ViewOrderDetailsRoute } from 'constants/routes'
import { useHistory } from 'react-router-dom'

const ConfirmationOrderDetail = () => {
  const [successOrderDetails, setSuccessOrderDetails] = useState<ISuccessOrderData>()
  const orderId = localStorage.getItem('orderId')
  const { loggedIn, loggedInStatus, address, GDL_PAGE_TYPE } = pageData()
  const { mutateAsync } = usePost()
  const navigator = useHistory()
  const { storesData } = useTheme()
  const googledatalayer = storesData?.d?.googleDatalayer
  const fetchSucesseOrderDetails = async () => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: `${APIS.ORDER_SUCCESS_DETAILS}`,
        payload: {
          orderId: orderId,
        },
      })
      if (res) {
        setSuccessOrderDetails(res?.d)

        const coupon = res?.d?.orderDetails?.promoCode || 'na'
        const freeShipping = res?.d?.shippingAmount === 0

        const itemsArray = res.d.itemDetails.map(
          (productItem: {
            name: string
            productId: string
            brand: string
            discountAmount: number
            variants: { [key: string]: string[] }
            category: string
            mrp: number
            regularPrice: number
            qty: number
          }) => {
            return {
              item_name: productItem.name?.toLowerCase(),
              item_id: productItem.productId,
              item_brand: 'na',
              discount: res?.d?.orderDetails?.discountAmount,
              item_variant: 'na',
              item_category: productItem.category,
              item_category2: 'na',
              item_category3: 'na',
              item_category4: 'na',
              item_category5: 'na',
              price: productItem.mrp,
              item_list_id: 'na',
              item_list_name: 'na',
              index: 1,
              quantity: productItem.qty,
              coupon: coupon,
              Is_returnable: 'na',
            }
          },
        )

        const elObject = {
          event: 'purchase',
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          selected_location: address,
          page_type: GDL_PAGE_TYPE,
          payment_method: res?.d?.orderDetails?.paymentMode,
          is_free_shipping: freeShipping,
          ecommerce: {
            tax: res?.d?.orderDetails?.tax,
            shipping: res?.d?.orderDetails?.shippingAmount,
            value: res?.d?.orderDetails?.total,
            transaction_id: res?.d?.incrementId,
            currency: 'INR',
            coupon: coupon,
            items: itemsArray,
          },
        }
        if (res?.d?.itemDetails && googledatalayer == '1') {
          TagManager.dataLayer({ dataLayer: elObject })
        }
        //setLoader(false)
      }
    } catch (error: any) {
      //setLoader(false)
      return error
    }
  }

  useEffect(() => {
    fetchSucesseOrderDetails()
  }, [orderId])

  const getAddressIcon = (label: string | null) => {
    switch (label) {
      case 'Work':
        return <OfficeIcon />
      case 'Home':
      case null:
        return <HomeIcon />
      case 'Hotel':
        return <Business />
      case 'Other':
        return <OtherIcon />
      default:
        return <OtherIcon />
    }
  }
  const getStatusMessage = (statusCode: number) => {
    switch (statusCode) {
      case 0:
        return 'Pending'
      case 1:
        return 'Processing'
      case 2:
        return 'Tracking Request In Process'
      case 3:
        return 'Tracking Requested'
      case 4:
        return 'Ready For Pickup'
      case 5:
        return 'Picked Up'
      case 6:
        return 'Dispatched'
      case 7:
        return 'Delivered'
      case 8:
        return 'Cancelled'
      case 9:
        return 'RTO Delivered'
      case 10:
        return 'RTO In Transit'
      case 11:
        return 'Return Requested'
      case 12:
        return 'Return Initiated'
      case 13:
        return 'Ready For Reverse Pickup'
      case 14:
        return 'Return Picked Up'
      case 15:
        return 'Return Dispatched'
      default:
        return 'Unknown Status'
    }
  }

  return (
    <ConfirmationOrderDetailWrapper>
      <OrderConfirmationWrapper>
        <div className="confirm-wrap">
          <div className="confirm-order">
            {/* {successOrderDetails?.status && getStatusMessage(successOrderDetails?.status)} */}
            {successOrderDetails?.orderDetails?.status && successOrderDetails?.orderDetails?.status === 0 ? (
              <PendingIcon />
            ) : (
              <CheckmarkCircle />
            )}
            <div className="orders-details">
              <StatusWrapper className="h1b" status={'Confirmed'}>
                {/* Confirmed */}
                {successOrderDetails?.status && getStatusMessage(successOrderDetails?.orderDetails?.status)}
              </StatusWrapper>
              <label className="h3b">
                Order: {successOrderDetails?.orderId}({successOrderDetails?.orderDetails?.totalQty} Item
                {successOrderDetails?.orderDetails?.totalQty && successOrderDetails?.orderDetails?.totalQty === 1
                  ? ''
                  : '(s)'}
                )
              </label>
              <label className="h3r">Placed on {successOrderDetails?.orderDetails?.createdAt}</label>
            </div>
          </div>
          <RightArrow onClick={() => navigator.replace(ViewOrderDetailsRoute.path + '?id=' + orderId)} />
        </div>
        {successOrderDetails?.itemDetails.map((item: ItemDetailsData, index: number) => {
          let imgUrl =
            item?.imgUrl?.includes('http://') || item?.imgUrl?.includes('https://')
              ? item?.imgUrl
              : `https://cdnaz.plotch.io/image/upload/w_118,h_118${item?.imgUrl}`
          if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
            imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
            imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
          }

          return (
            <div className="confirmed-wrap order-state-type" key={index}>
              <div className="confirm-order">
                <img src={imgUrl} />
                <div className="orders-details">
                  <div className="order-name">
                    <label className="h3r">
                      {item.name ? `${item?.name?.slice(0, 15)} ... ` : item?.name}({item?.qty} Qty)
                    </label>
                    <HelpCircle />
                  </div>
                  <label className="h3r">{successOrderDetails?.expectedDelivery}</label>
                </div>
              </div>
            </div>
          )
        })}
      </OrderConfirmationWrapper>
      <PriceDetailWrapper>
        <div className="price-details-container">
          <div className="price-details-wrapper">
            <label className="h1r">Total MRP</label>
            <label className="h1r">₹{successOrderDetails?.orderDetails?.subtotal}</label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Discount on MRP</label>
            <label className="h1r discount">
              {' '}
              {successOrderDetails?.orderDetails?.discountAmount ? '-' : ''}₹{' '}
              {successOrderDetails?.orderDetails?.discountAmount}
            </label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Delivery Charge</label>
            <label className="h1r">₹ {successOrderDetails?.orderDetails?.shippingAmount}</label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Packaging Charge</label>
            <label className="h1r">₹{successOrderDetails?.orderDetails?.totalPackingCharge}</label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Taxes</label>

            <label className="h1r">
              ₹
              {successOrderDetails?.orderDetails?.totalSurchargeTax == 0
                ? successOrderDetails?.orderDetails?.tax
                : successOrderDetails?.orderDetails?.totalSurchargeTax}
            </label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Convenience Fee</label>
            <label className="h1r">₹{successOrderDetails?.orderDetails?.totalMiscCharge}</label>
          </div>
          <div className="price-details-wrapper">
            <label className="h1r">Coupon Discount</label>
            <label className="h1r discount">-₹{successOrderDetails?.orderDetails?.couponDiscount}</label>
          </div>
          <div className="order-wrapper total">
            <label className="h1b">Order Value</label>
            <label className="h1b">₹{successOrderDetails?.orderDetails?.total}</label>
          </div>
        </div>
      </PriceDetailWrapper>
      <PaymentMethodWrapper>
        <div className="delivery-method">
          <CheckmarkCircle />
          <label className="h1b">
            {successOrderDetails?.orderDetails?.paymentMode == 'cashondelivery' ? 'Cash on Delivery' : 'Prepaid'}
          </label>
        </div>
      </PaymentMethodWrapper>
      <DeliveryAddressWrapper>
        <label className="h1b">Delivery Address</label>

        <AddressContainer>
          {successOrderDetails && getAddressIcon(successOrderDetails?.shippingAddress?.label)}
          <AddressWrapper>
            <PincodeLocation className="h1b">
              {successOrderDetails?.shippingAddress?.shippingPincode} - {successOrderDetails?.shippingAddress?.label}
            </PincodeLocation>
            <BasicAddress className="h2r"> {successOrderDetails?.shippingAddress?.shippingAddress}</BasicAddress>
            <PersonName className="h2r">
              {successOrderDetails?.shippingAddress?.firstname} {successOrderDetails?.shippingAddress?.lastname} (
              {successOrderDetails?.shippingAddress?.shippingPhone})
            </PersonName>
          </AddressWrapper>
        </AddressContainer>
      </DeliveryAddressWrapper>
    </ConfirmationOrderDetailWrapper>
  )
}

export default ConfirmationOrderDetail

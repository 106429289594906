import { useContext, useState, createContext } from 'react'
/* eslint-disable @typescript-eslint/no-explicit-any */
interface IFilter {
  homeData: any
  setHomeData: (val: any) => void
  cartCount: any
  setCartCount: (val: any) => void
  visitorData: any
  setVisitorData: (val: any) => void
}

const DefaultValues = {
  homeData: [],
  setHomeData: () => null,
  cartCount: [],
  setCartCount: () => null,
  visitorData: {},
  setVisitorData: () => null,
}

const HomeContext = createContext<IFilter>(DefaultValues)

const HomeProvider = ({ children }: { children: React.ReactNode }) => {
  const [homeData, setHomeData] = useState('')
  const [cartCount, setCartCount] = useState(null)
  const [visitorData, setVisitorData] = useState({})

  return (
    <HomeContext.Provider value={{ homeData, setHomeData, cartCount, setCartCount, visitorData, setVisitorData }}>
      {children}
    </HomeContext.Provider>
  )
}

export default HomeProvider

export const useHomeContext = () => {
  return useContext(HomeContext)
}

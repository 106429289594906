import { useContext, useEffect, useState } from 'react'
import { Radio, Typography } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import DragDropUploader from 'components/FileUploader/dragDropUploader'
import PopupModal from 'components/TicketModal'
import APIS from 'constants/api'
import Button from 'components/Button'
import TextInput from 'components/TextField'
import useGet from 'hooks/useGet'
import { IProduct } from 'interfaces/pages/order'
import HelpCircle from 'assets/svg/HelpCircle'
import InformationCircle from 'assets/svg/InformationCircle'
import {
  ReturnDetailsWrapper,
  CancellationReasonwrapper,
  CancelReasonHeading,
  ReasonWrapper,
  ReturnFormWrapper,
  BankDetailsWrapper,
  BankDetailsWrappers,
  BeneficiaryDetailsWrapper,
} from 'styles/views/CancelOrderDetails'
import { ErrorMessage } from 'styles/pages/Login'

import { OrderConfirmationWrapper, OrderStateWrapper } from 'styles/views/ViewOrderDetail'
import { ReturnButtonWrapper } from 'styles/pages/MyAccount'
import usePost from 'hooks/usePost'
import { RETURN_VALIDATION_SCHEMA, RETURN_VALIDATION_SCHEMA1 } from 'utils/validations/returnValidation'
import { SnackBarContext } from 'context/SnackBarContext'
import TagManager from 'react-gtm-module'
import { pageData } from 'utils/datalayer/pageData'
import { useHistory } from 'react-router-dom'
import { MyOrdersRoute } from 'constants/routes'
import { useTheme } from 'context/themeContext'

const ReturnOrderDetails = () => {
  const [selectedRefundMode, setSelectedRefundMode] = useState<string>('')
  const [base64List, setBase64List] = useState<string[]>([])
  const selectSchema: any = selectedRefundMode === '2' ? RETURN_VALIDATION_SCHEMA : RETURN_VALIDATION_SCHEMA1
  const { storesData } = useTheme()
  const {
    control,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver<any>(selectSchema),

    // defaultValues: {
    //   // orderId: 'none',
    //   refundMode: '',
    //   reason: '',
    //   description: '',
    //   filesDrop: [],
    // },
  })
  const [open, setOpen] = useState(false)
  const [defaultValue, setDefaultValue] = useState<any[]>([])

  const orderItemId = localStorage.getItem('orderItemId')
  const orderId = localStorage.getItem('orderId')
  const customerId = localStorage.getItem('customerId')
  const { setShowSnackBar } = useContext(SnackBarContext)
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  // Parse the query string
  // const urlParams = new URLSearchParams(queryString)

  // const _ReturnDl: any = urlParams.get('dl')
  const { mutateAsync } = usePost()
  const navigate = useHistory()

  const { refetch, data: retunDataDetails } = useGet('return-data', `${APIS.RETURN_REASONS}`)
  const { refetch: refetchRefund, data: refundModeData } = useGet('refund-data', `${APIS.GET_REFUND_TYPES}`)
  const { refetch: refetchTicket } = useGet('ticket-list', `${APIS.TICKET_LIST}?customerId=${customerId}`)

  const { refetch: refetchOrderDetails, data } = useGet(
    'order-details',
    `${APIS.GET_ORDER_DETAILS}?orderId=${orderId}&customerId=${customerId}`,
  )
  const returnData = retunDataDetails?.d?.returnReasons
  const refundTypes = refundModeData?.d?.refundTypes

  const orderData = data?.d?.orderDetails

  const submitReturnReason = async (data: any) => {
    const payload: any = {
      orderItemId: orderItemId,
      // orderItemId: orderData?.shipments[0]?.products[0]?.orderItemId,
      refundType: Number(data.refundMode),
      cancellationReasonId: data.reason,
      base64List: base64List || [],
      return_comment: data.description,
      // refundType: 1,
      bankDetails: {},
    }

    if (data.refundMode === '2') {
      payload.bankDetails = {
        account_holder: data.holderName,
        account_number: data.accountNumber,
        ifsc_code: data.ifscCode,
      }
    }

    try {
      const res = await mutateAsync({
        url: APIS.RETURN_ORDER_ITEM,
        payload,
      })
      if (res) {
        setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
        navigate.push(MyOrdersRoute.path)

        // return res
        // setAddress(res?.d?.addressList)
      }
    } catch (error: any) {
      setShowSnackBar({ open: true, message: `${error?.response?.data?.m}`, severity: 'error' })

      return error
    }
  }

  const handleDefaultValue = (fileData: any) => {
    setDefaultValue(fileData)
  }
  const handleBase64ListChange = (newBase64List: string[]) => {
    setBase64List(newBase64List)
  }
  const changeModal = () => {
    setOpen(true)
  }
  const handleOk = () => {
    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }
  const handleCancel = () => {
    setOpen(false)
  }

  useEffect(() => {
    refetch()
  }, [returnData])
  useEffect(() => {
    refetchRefund()
  }, [refundModeData])

  useEffect(() => {
    if (customerId) {
      refetchOrderDetails()
    }
  }, [customerId])
  const handleRefundModeChange = (value: any) => {
    setSelectedRefundMode(value)
  }

  return (
    <>
      {orderData && (
        <ReturnDetailsWrapper>
          {/* {orders.map((order, index) => ( */}
          {/* <OrderConfirmationWrapper key={index}> */}
          <OrderConfirmationWrapper>
            <div className="cancel-wrap">
              <div className="confirm-order">
                <InformationCircle />
                <label className="h1b cancel-color">1 item will be returned</label>
              </div>
            </div>
            {orderData?.shipments[0]?.products
              .filter((item: any) => item?.orderItemId == orderItemId) // Filter by matching ID
              .map((item: IProduct, idx: number) => {
                // {orderData?.shipments[0]?.products.map((item: IProduct, idx: number) => {
                let imgUrl =
                  item?.image?.includes('http://') || item?.image?.includes('https://')
                    ? item?.image
                    : `https://cdnaz.plotch.io/image/upload/w_118,h_118${item?.image}`
                if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
                  imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
                  imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
                }

                return (
                  <div className="confirmed-wrap order-state-type" key={idx}>
                    <div className="confirm-order">
                      <img src={imgUrl} />
                      <div className="orders-details">
                        <div className="order-name-Wrap">
                          <Typography.Text ellipsis={{ tooltip: item.name }} className="h3r item-name">
                            {item.name.toLowerCase()}
                          </Typography.Text>
                          {/* <label className="h3r">{item.name}</label> */}
                          {/* <HelpCircle /> */}
                          <HelpCircle
                            onClick={(e: any) => {
                              const initTicketDataToPush = {
                                event: 'raise_ticket_initiate',
                                page_type: GDL_PAGE_TYPE,
                                User_Id: loggedIn,
                                User_Status: loggedInStatus,
                                cta_text: 'get help',
                                order_ticket_id: orderId ? orderId : 'NA',
                              }
                              e.stopPropagation()
                              storesData?.d?.googleDatalayer &&
                                storesData?.d?.googleDatalayer == 1 &&
                                TagManager.dataLayer({ dataLayer: initTicketDataToPush })

                              changeModal()
                            }}
                            className="help-icon"
                          />
                        </div>
                        <div className="order-state-wrapper">
                          <div className="orders-details">
                            <label className="h3r">Delivery by {orderData?.shipments[0]?.expectedDelivery}</label>
                            <OrderStateWrapper className="h4b" state={item.statusLabel}>
                              {item.statusLabel}
                            </OrderStateWrapper>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </OrderConfirmationWrapper>
          {/* ))} */}
          <ReturnFormWrapper onSubmit={handleSubmit(submitReturnReason)}>
            <CancellationReasonwrapper>
              <CancelReasonHeading>
                <label className="h1b">Reason for Return</label>
                <label className="h2r">This will help us improve your experience</label>
              </CancelReasonHeading>
              <ReasonWrapper>
                <Controller
                  name="reason"
                  control={control}
                  render={({ field }) => (
                    <Radio.Group
                      onChange={(e) => {
                        field.onChange(e.target.value)
                      }}
                      value={field.value}
                      className="reason-wrap"
                    >
                      {returnData?.map((option: any) => (
                        <Radio key={option?.reasonId} value={option?.reasonId}>
                          {option?.reason}
                        </Radio>
                      ))}
                    </Radio.Group>
                  )}
                />

                {errors.reason && <p className="error-message">{errors.reason.message}</p>}
              </ReasonWrapper>
            </CancellationReasonwrapper>
            <CancellationReasonwrapper>
              <CancelReasonHeading>
                <label className="h1b"> Refund Mode</label>
              </CancelReasonHeading>
              <ReasonWrapper>
                <Controller
                  name="refundMode"
                  control={control}
                  render={({ field }) => (
                    <Radio.Group
                      onChange={(e) => {
                        const value = e.target.value
                        field.onChange(value)
                        handleRefundModeChange(value) // Call the handler to check if "Bank Account" is selected.
                      }}
                      value={field.value}
                    >
                      {refundTypes?.map((option: any) => (
                        <Radio key={option?.id} value={option?.id}>
                          {option?.name}
                        </Radio>
                      ))}
                      {/* <Radio value="Bank Account">Refund to Bank Account</Radio>
                      <Radio value="Other">Other</Radio> */}
                    </Radio.Group>
                  )}
                />
                {errors.refundMode && <p className="error-message">{errors.refundMode.message}</p>}
              </ReasonWrapper>
              {selectedRefundMode == '2' && (
                <BankDetailsWrapper>
                  {/* <CancelReasonHeading> */}
                  <label className="h1b">Bank Details</label>
                  {/* </CancelReasonHeading> */}
                  <BankDetailsWrappers>
                    <BeneficiaryDetailsWrapper>
                      {/* <label className="h1b"> Account Holder Name</label> */}
                      <ReasonWrapper>
                        <TextInput placeholder="Account holder name *" control={control} name="holderName" />
                        <ErrorMessage className="h3r">{errors?.holderName?.message}</ErrorMessage>
                      </ReasonWrapper>
                    </BeneficiaryDetailsWrapper>
                    <BeneficiaryDetailsWrapper>
                      {/* <label className="h1b"> Account Number</label> */}
                      <ReasonWrapper>
                        <TextInput
                          placeholder="Account number *"
                          control={control}
                          name="accountNumber"
                          type="number"
                        />
                        <ErrorMessage className="h3r">{errors?.accountNumber?.message}</ErrorMessage>
                      </ReasonWrapper>
                    </BeneficiaryDetailsWrapper>
                    <BeneficiaryDetailsWrapper>
                      {/* <label className="h1b">IFSC Code</label> */}
                      <ReasonWrapper>
                        <TextInput placeholder="IFSC code *" control={control} name="ifscCode" />
                        <ErrorMessage className="h3r">{errors?.ifscCode?.message}</ErrorMessage>
                      </ReasonWrapper>
                    </BeneficiaryDetailsWrapper>
                  </BankDetailsWrappers>
                </BankDetailsWrapper>
              )}
            </CancellationReasonwrapper>
            <CancellationReasonwrapper>
              <CancelReasonHeading>
                <label className="h1b"> Upload Image</label>
              </CancelReasonHeading>
              <ReasonWrapper>
                <DragDropUploader
                  name="filesDrop"
                  control={control}
                  onBase64ListChange={handleBase64ListChange}
                  // length={1} // Max number of files
                  defaultValue={defaultValue} // Pass default value if needed
                  handleDefault={handleDefaultValue}
                />
                {errors.description && <p className="error-message">{errors.description.message}</p>}
              </ReasonWrapper>
            </CancellationReasonwrapper>
            <CancellationReasonwrapper>
              <CancelReasonHeading>
                <label className="h1b"> Return Comment</label>
              </CancelReasonHeading>
              <ReasonWrapper>
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <textarea
                      style={{ width: '100%' }}
                      {...field}
                      rows={3}
                      // placeholder="Description...."
                      className="custom-textarea"
                    />
                  )}
                />
              </ReasonWrapper>
            </CancellationReasonwrapper>
            <ReturnButtonWrapper>
              <Button label="RETURN ORDER" variant="contained" type="submit" className="small" />
            </ReturnButtonWrapper>
          </ReturnFormWrapper>
        </ReturnDetailsWrapper>
      )}

      <PopupModal
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Raise Ticket"
        BtnName="Raise Ticket"
        getAllTickets={refetchTicket}
      />
    </>
  )
}

export default ReturnOrderDetails

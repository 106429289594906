import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Skeleton, Typography } from 'antd'
import PopupModal from 'components/TicketModal'
import { LoginRoute, MyOrdersRoute, OrderTrackRoute } from 'constants/routes'
import APIS from 'constants/api'
import { IProduct } from 'interfaces/pages/order'
import useGet from 'hooks/useGet'
import CheckmarkCircle from 'assets/svg/CheckmarkCircle'
import HelpCircle from 'assets/svg/HelpCircle'
// import CloseCircle from 'assets/svg/CloseCircle'
import HomeIcon from 'assets/svg/HomeIcon'
import OfficeIcon from 'assets/svg/OfficeIcon'
import Business from 'assets/svg/Business'
import OtherIcon from 'assets/svg/OtherIcon'
import PendingIcon from 'assets/svg/PendingIcon'
import BackArrow from 'assets/svg/BackArrow'

import {
  ViewOrderDetailWrapper,
  OrderConfirmationWrapper,
  ConfirmStatusWrapper,
  OrderStateWrapper,
  PriceDetailWrapper,
  PaymentMethodWrapper,
  DeliveryAddressWrapper,
  AddressContainer,
  AddressWrapper,
  PincodeLocation,
  BasicAddress,
  // CityDetail,
  PersonName,
} from 'styles/views/ViewOrderDetail'
import { CheckoutWrappers, BackWrapper } from 'styles/pages/MyAccount'
import TagManager from 'react-gtm-module'
import { pageData } from 'utils/datalayer/pageData'
import { useTheme } from 'context/themeContext'

const ViewOrderDetail = () => {
  const [open, setOpen] = useState(false)
  const customerId = localStorage.getItem('customerId')
  const orderId = localStorage.getItem('orderId')
  const { storesData } = useTheme()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  const { refetch: refetchTicket } = useGet('ticket-list', `${APIS.TICKET_LIST}?customerId=${customerId}`)

  const navigate = useHistory()
  const changeModal = () => {
    setOpen(true)
  }
  const handleOk = () => {
    setTimeout(() => {
      setOpen(false)
    }, 3000)
  }
  const handleCancel = () => {
    setOpen(false)
  }
  const handleBack = () => {
    navigate.push(MyOrdersRoute.path)
  }

  // const { setLoader } = useContext(LoaderContext)
  const { refetch: refetchOrderDetails, data } = useGet(
    'order-details',
    `${APIS.GET_ORDER_DETAILS}?orderId=${orderId}&customerId=${customerId}`,
  )
  const orderData = data?.d?.orderDetails

  const trackOrderDataToPush = {
    event: 'track_order',
    page_type: GDL_PAGE_TYPE,
    User_Id: loggedIn,
    User_Status: loggedInStatus,
    cta_text: 'track order',
    order_ticket_id: orderId ? orderId : 'NA',
  }

  const getAddressIcon = (label: string | null) => {
    switch (label) {
      case 'Work':
        return <OfficeIcon />
      case 'Home':
      case null:
        return <HomeIcon />
      case 'Hotel':
        return <Business />
      case 'Other':
        return <OtherIcon />
      default:
        return <OtherIcon />
    }
  }

  useEffect(() => {
    if (customerId) {
      refetchOrderDetails()
    }
  }, [customerId])

  useEffect(() => {
    if (!customerId) {
      const currentUrl = window.location.pathname + window.location.search // This includes both path and query string
      navigate.push(`${LoginRoute.path}?redirect=${encodeURIComponent(currentUrl)}`)
    }
  }, [customerId])

  const getStatusMessage = (statusCode: number) => {
    switch (statusCode) {
      case 0:
        return 'Pending'
      case 1:
        return 'Processing'
      case 2:
        return 'Tracking Request In Process'
      case 3:
        return 'Tracking Requested'
      case 4:
        return 'Ready For Pickup'
      case 5:
        return 'Picked Up'
      case 6:
        return 'Dispatched'
      case 7:
        return 'Delivered'
      case 8:
        return 'Cancelled'
      case 9:
        return 'RTO Delivered'
      case 10:
        return 'RTO In Transit'
      case 11:
        return 'Return Requested'
      case 12:
        return 'Return Initiated'
      case 13:
        return 'Ready For Reverse Pickup'
      case 14:
        return 'Return Picked Up'
      case 15:
        return 'Return Dispatched'
    }
  }

  const statusCodeArray = [1, 2, 3, 4, 5, 6, 12, 13, 14, 15, 16, 28]
  const shouldShowTrackButton = statusCodeArray.includes(orderData?.shipments[0]?.products[0]?.statusCode)

  return (
    <>
      {orderData ? (
        <CheckoutWrappers>
          <BackWrapper className="padding_full_page" onClick={handleBack}>
            <BackArrow />
            <div className="h1b">ORDER DETAILS</div>
          </BackWrapper>

          <ViewOrderDetailWrapper>
            {/* {orderData &&
        orderData?.map((order: IOrderData, index: number) => ( */}
            <OrderConfirmationWrapper>
              <div className="confirmed-wraps">
                {orderData?.shipments[0]?.products[0].statusLabel == 'Pending' ? <PendingIcon /> : <CheckmarkCircle />}
                {/* {orderData?.status == 0 || orderData?.status == 7  ? <CheckmarkCircle /> : <CloseCircle />} */}
                {/* {'Confirmed' || 'Delivered' ? <CheckmarkCircle /> : <CloseCircle />} */}
                <div className="confirm-order-details">
                  <div className="orders-status-wrap">
                    <ConfirmStatusWrapper className="h1b" confirmStatus={orderData?.status}>
                      {getStatusMessage(orderData?.status)}
                    </ConfirmStatusWrapper>
                    {shouldShowTrackButton && (
                      <label
                        className="h2b track-color"
                        onClick={() => {
                          storesData?.d?.googleDatalayer &&
                            storesData?.d?.googleDatalayer == 1 &&
                            TagManager.dataLayer({ dataLayer: trackOrderDataToPush })
                          navigate.replace(`${OrderTrackRoute.path}?id=${orderData?.orderId.toString()}`)
                          localStorage.setItem(
                            'orderItemId',
                            orderData?.shipments[0]?.products[0]?.orderItemId.toString(),
                          )
                        }}
                      >
                        TRACK
                      </label>
                    )}
                  </div>
                  <label className="h3b">
                    Order: {orderData?.orderId} ({orderData?.shipments[0]?.products?.length} Item
                    {orderData?.shipments[0]?.products?.length > 1 ? '(s)' : ''})
                  </label>
                  <label className="h3r">Placed on {orderData?.orderDate}</label>
                </div>
              </div>
              {orderData?.shipments[0]?.products?.map((item: IProduct, idx: number) => {
                let imgUrl =
                  item?.image?.includes('http://') || item?.image?.includes('https://')
                    ? item?.image
                    : `https://cdnaz.plotch.io/image/upload/w_118,h_118${item?.image}`
                if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes('w_118,h_118')) {
                  imgUrl = imgUrl?.split('/upload/').join(`/upload/w_118,h_118/`)
                  imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
                }

                return (
                  <div className="confirmed-wrap order-state-type" key={idx}>
                    <div className="confirm-order">
                      <img src={imgUrl} />
                      <div className="orders-details">
                        <div className="order-name">
                          <Typography.Text ellipsis={{ tooltip: item.name }} className="h3r item-name">
                            {item.name.toLowerCase()}
                          </Typography.Text>
                          <HelpCircle
                            onClick={(e: any) => {
                              const initTicketDataToPush = {
                                event: 'raise_ticket_initiate',
                                page_type: GDL_PAGE_TYPE,
                                User_Id: loggedIn,
                                User_Status: loggedInStatus,
                                cta_text: 'get help',
                                order_ticket_id: orderId ? orderId : 'NA',
                              }
                              e.stopPropagation()
                              storesData?.d?.googleDatalayer &&
                                storesData?.d?.googleDatalayer == 1 &&
                                TagManager.dataLayer({ dataLayer: initTicketDataToPush })

                              changeModal()
                            }}
                            className="help-icon"
                          />
                        </div>

                        <div className="order-state-wrapper">
                          <div className="orders-details">
                            <label className="h3r">
                              {item.statusCode != 8 && (
                                <label className="h3r">
                                  {![11, 12, 14, 15, 16, 22, 30]?.includes(item?.statusCode)
                                    ? [7, 6]?.includes(item?.statusCode)
                                      ? 'Delivered on ' + orderData?.shipments[0]?.deliveredOn
                                      : 'Delivered By ' + orderData?.shipments[0]?.expectedDelivery
                                    : ''}
                                </label>
                              )}
                            </label>
                            <OrderStateWrapper className="h4b" state={item.statusLabel}>
                              {item.statusLabel}
                            </OrderStateWrapper>
                          </div>
                          {/* {item.type ? <div className="order-type h2b">{item.type}</div> : ''} */}
                        </div>
                        {/* {item.error ? <div className="order-error-type h4b">{item.error}</div> : ''} */}
                      </div>
                    </div>
                  </div>
                )
              })}
            </OrderConfirmationWrapper>
            {/* ))} */}
            <PriceDetailWrapper>
              <div className="price-details-container">
                <div className="price-details-wrapper">
                  <label className="h1r">Total MRP</label>
                  <label className="h1r">₹{orderData?.orderSummaryV1?.mrpTotal}</label>
                </div>
                <div className="price-details-wrapper">
                  <label className="h1r">Discount on MRP</label>
                  <label className="h1r discount">
                    {orderData?.orderSummaryV1?.discountMrp ? '-' : ''}₹{orderData?.discountMrp}
                  </label>
                </div>
                <div className="price-details-wrapper">
                  <label className="h1r">Delivery Charge</label>
                  <label className="h1r">₹{orderData?.orderSummaryV1?.deliveryCharge}</label>
                </div>
                <div className="price-details-wrapper">
                  <label className="h1r">Packaging Charge</label>
                  <label className="h1r">₹{orderData?.orderSummaryV1?.totalPackingCharge}</label>
                </div>
                <div className="price-details-wrapper">
                  <label className="h1r">Taxes</label>
                  <label className="h1r">₹{orderData?.orderSummaryV1?.tax}</label>
                </div>
                <div className="price-details-wrapper">
                  <label className="h1r">Convenience Fee</label>
                  <label className="h1r">₹{orderData?.orderSummaryV1?.convenientFee}</label>
                </div>
                <div className="price-details-wrapper">
                  <label className="h1r">Coupon Discount</label>
                  <label className="h1r discount">
                    {orderData?.orderSummaryV1?.couponDiscount ? '-' : ''}₹{orderData?.orderSummaryV1?.couponDiscount}
                  </label>
                </div>
                <div className="order-wrapper total">
                  <label className="h1b">Order Value</label>
                  <label className="h1b">₹{orderData?.orderSummary?.totalPayable}</label>
                </div>
              </div>
            </PriceDetailWrapper>
            <PaymentMethodWrapper>
              <div className="paid-wrapper">
                <label className="h1b">Payment Method</label>
                {/* {isPaid ? <label className="h2b paid">paid</label> : null} */}
              </div>

              <div className="delivery-method">
                <CheckmarkCircle />
                <label className="h1b">{orderData?.paymentMode}</label>
              </div>
            </PaymentMethodWrapper>
            <DeliveryAddressWrapper>
              <label className="h1b">Delivery Address</label>

              <AddressContainer>
                {/* <HomeIcon /> */}
                {getAddressIcon(orderData?.customerInfo?.addressType)}

                <AddressWrapper>
                  <PincodeLocation className="h1b">
                    {orderData?.customerInfo?.pincode} -{' '}
                    {orderData?.customerInfo?.addressType === null ? 'Home' : orderData?.customerInfo?.addressType}
                  </PincodeLocation>
                  <BasicAddress className="h2r">
                    {orderData?.customerInfo?.building} {orderData?.customerInfo?.building == null ? '' : ','}{' '}
                    {orderData?.customerInfo?.city}, {orderData?.customerInfo?.state.toLowerCase()},{' '}
                    {orderData?.customerInfo?.country}
                    {/* {orderData?.customerInfo?.street} */}
                  </BasicAddress>
                  {/* <CityDetail className="h2r"></CityDetail> */}
                  <PersonName className="h2r">
                    {orderData?.customerInfo?.name} ({orderData?.customerInfo?.contact})
                  </PersonName>
                </AddressWrapper>
              </AddressContainer>
            </DeliveryAddressWrapper>
          </ViewOrderDetailWrapper>
        </CheckoutWrappers>
      ) : (
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )}
      <PopupModal
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Raise Ticket"
        BtnName="Raise Ticket"
        getAllTickets={refetchTicket}
      />
    </>
  )
}

export default ViewOrderDetail

import PublicLayout from 'components/Layouts/public'
import React from 'react'
import { CommonStyle } from 'styles/pages/Common'
import StoreHyperlocalView from 'views/StoreHyperlocalView'

const StoreHyperlocalPage = () => {
  return (
    <PublicLayout>
      <CommonStyle>
        <StoreHyperlocalView />
      </CommonStyle>
    </PublicLayout>
  )
}

export default StoreHyperlocalPage

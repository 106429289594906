import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      // stroke=" #FF3E6B"
      stroke=" var(--primary_text_color_darkgrey)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M10 1.875c-3.105 0-5.625 2.398-5.625 5.352 0 3.398 3.75 8.784 5.127 10.644a.616.616 0 0 0 .996 0c1.377-1.86 5.127-7.243 5.127-10.644 0-2.954-2.52-5.352-5.625-5.352Z"
    />
    <path
      stroke=" var(--primary_text_color_darkgrey)"
      // stroke=" #FF3E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M10.001 9.378a1.876 1.876 0 1 0 0-3.753 1.876 1.876 0 0 0 0 3.753Z"
    />
  </svg>
)
export default SvgComponent

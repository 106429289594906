import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      stroke="var(--primary_icon_static_color)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M12.603.999H8.76a.452.452 0 0 0-.318.131L.763 8.808a.902.902 0 0 0 0 1.272l3.656 3.656a.902.902 0 0 0 1.272 0l7.678-7.674a.452.452 0 0 0 .131-.32V1.9a.894.894 0 0 0-.897-.9Z"
    />
    <path fill="var(--primary_icon_static_color)" d="M11 4.501A1 1 0 1 1 11 2.5 1 1 0 0 1 11 4.5Z" />
    <path
      stroke="var(--primary_icon_static_color)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="m7.188 15 8.187-8.188A.432.432 0 0 0 15.5 6.5v-4"
    />
  </svg>
)

export default SvgComponent

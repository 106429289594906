import { Controller } from 'react-hook-form'
import { Input } from 'antd'
import { ITeaxtinputContainer } from 'interfaces/components'
import { InputWrapper } from 'styles/components/TextInput'

const PincodeField = ({
  placeholder,
  required,
  type,
  onFocus,
  onWheel,
  name,
  control,
  prefix,
  disabled,
  className,
  maxLength,
  onKeyDown,
  onKeyUp,
  inputRef,
  autocomplete,
  handleChange,
  validator,
}: ITeaxtinputContainer) => (
  <InputWrapper>
    <Controller
      render={({ field: { value, onChange } }) => (
        <Input
          type={type}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={(e) => {
            const newValue = e.target.value

            if (validator(newValue)) {
              const regex = /^[0-9]*$/
              if (regex.test(newValue) && newValue.length <= 6) {
                onChange(newValue)
                if (handleChange) handleChange(newValue)
              }
            }
          }}
          onFocus={onFocus}
          onWheel={onWheel}
          prefix={prefix}
          disabled={disabled}
          className={className}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          ref={inputRef}
          autoComplete={autocomplete}
        />
      )}
      control={control}
      name={name}
    />
  </InputWrapper>
)
export default PincodeField

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import usePost from 'hooks/usePost'
import APIS from 'constants/api'
import MobileInput from 'components/MobileInput'
import { LOGIN_VALIDATION_SCHEMA } from 'utils/validations/loginValidation'
// import { LoaderContext } from 'context/loader'
import { useHistory } from 'react-router'
import 'react-phone-number-input/style.css'
import BackArrow from 'assets/svg/BackArrow'
import Button from 'components/Button'
import { IPayloadData } from 'interfaces/pages'
import {
  LoginPageContainer,
  BackWrapper,
  LoginContainer,
  LoginWrapper,
  LoginHeading,
  PolicyContainer,
  LoginFormWrapper,
  TextWrapper,
  ErrorMessage,
} from 'styles/pages/Login'
import { OtpRoute, PrivacyPolicyRoute, TermsAndCondionRoute } from 'constants/routes'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'
import { useTheme } from 'context/themeContext'

const LoginDetailView = () => {
  const { mutateAsync } = usePost()
  const navigate = useHistory()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const { storesData } = useTheme()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<any>({
    mode: 'onSubmit',

    resolver: yupResolver(LOGIN_VALIDATION_SCHEMA),
    defaultValues: {
      mobile: '',
    },
  })

  const checkMobile = watch()
  const queryParams = new URLSearchParams(window.location.search)
  const redirectUrl = queryParams.get('redirect')
  const submitData = async (data: IPayloadData) => {
    //setLoader(true)
    const dataToPush = {
      event: 'login_signup_initiate',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      cta_text: 'continue',
      section_name: 'login_page',
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })

    try {
      const res = await mutateAsync({
        url: APIS.LOG_IN,
        payload: {
          mobile: data.mobile,
          plotch_referral: '',
          plotch_referral_key: '',
          attribution_instance_id: 0,
        },
      })
      if (res) {
        //setLoader(false)
        navigate.push(OtpRoute.path, {
          mobile: data?.mobile,
          temp_id: res?.d?.temp_id,
          redirectUrl: redirectUrl,
        })
      }
    } catch (error) {
      //setLoader(false)
      return error
    }
  }

  return (
    <LoginPageContainer>
      <BackWrapper
        onClick={() => {
          navigate.goBack()
          // navigate.push(HomeRoute.path)
        }}
      >
        <BackArrow />
        Login
      </BackWrapper>
      <LoginContainer>
        <LoginWrapper>
          <LoginHeading>Login or Signup</LoginHeading>
          <LoginFormWrapper onSubmit={handleSubmit(submitData)}>
            <TextWrapper>
              <MobileInput control={control} name="mobile" />
              <ErrorMessage>{errors?.mobile?.message}</ErrorMessage>
            </TextWrapper>
            <Button
              label="CONTINUE"
              variant={!(checkMobile?.mobile?.length === 10) ? 'disabled' : 'contained'}
              type={!(checkMobile?.mobile?.length === 10) ? 'button' : 'submit'}
              className="small"
            />
          </LoginFormWrapper>
        </LoginWrapper>
        <PolicyContainer>
          By continuing, I agree to the
          <span
            onClick={() => {
              navigate.push(TermsAndCondionRoute.path)
            }}
          >
            Terms of Use
          </span>
          &
          <span
            onClick={() => {
              navigate.push(PrivacyPolicyRoute.path)
            }}
          >
            Privacy Policy
          </span>
        </PolicyContainer>
      </LoginContainer>
    </LoginPageContainer>
  )
}

export default LoginDetailView

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#a)">
      <path d="M5.833 5a.833.833 0 0 1 .834-.833h6.666a.833.833 0 0 1 .834.833v10a.834.834 0 0 1-.834.833H6.667A.834.834 0 0 1 5.833 15V5ZM18.333 14.167H17.5a.833.833 0 0 1-.833-.834V6.667a.833.833 0 0 1 .833-.834h.833M1.667 14.167H2.5a.833.833 0 0 0 .833-.834V6.667a.833.833 0 0 0-.833-.834h-.833" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent

import { useHistory } from 'react-router-dom'
import TicketDetail from 'components/TicketDetails'
import BackArrow from 'assets/svg/BackArrow'
import { CommonStyle } from 'styles/pages/Common'
import { BackWrapper, CheckoutPageWrapper, CheckoutWrappers, ManageAddressWrapper } from 'styles/pages/MyAccount'
// import PrivateLayout from 'components/Layouts/private'
import PublicLayout from 'components/Layouts/public'

const TicketDetails = () => {
  const navigate = useHistory()
  const handleBack = () => {
    navigate.goBack()
  }

  return (
    <PublicLayout>
      <CheckoutPageWrapper>
        <CheckoutWrappers>
          <ManageAddressWrapper>
            <CommonStyle>
              <BackWrapper className="padding_full_page" onClick={handleBack}>
                <BackArrow />
                <div className="h1b">TICKET DETAILS</div>
              </BackWrapper>
            </CommonStyle>
            <CommonStyle>
              <TicketDetail />
            </CommonStyle>
          </ManageAddressWrapper>
        </CheckoutWrappers>
      </CheckoutPageWrapper>
    </PublicLayout>
  )
}

export default TicketDetails

import styled from 'styled-components'

export const CouponDetailsWrapper = styled.div`
  .coupan-wrapper {
    padding: 0px 22px 6px;
    min-height: 61px;
  }
  .ant-input-group-wrapper-outlined .ant-input-group-addon {
    border-left: unset;
    background: unset;
  }
  .ant-input-group-wrapper-outlined .ant-input-group-addon {
    border-radius: 4px 0px 0px 4px;
    border-top: 1px solid var(--primary_line_color_light);
    border-left: 0px solid var(--primary_line_color_light);
    border-right: 1px solid var(--primary_line_color_light);
    border-bottom: 1px solid var(--primary_line_color_light);
  }
  .ant-input-group .ant-input-group-addon {
    border-radius: 0 4px 4px 0;
  }
  .ant-input-group .ant-input:hover {
    border-inline-end-width: 0px;
  }
  .ant-input-outlined:hover,
  .ant-input-outlined:focus-within {
    border-color: var(--primary_line_color_light);
    box-shadow: none;
    background-color: unset;
  }
  input {
    border-radius: 4px 0px 0px 4px;
    border-top: 1px solid var(--primary_line_color_light);
    border-left: 1px solid var(--primary_line_color_light);
    border-right: 0px solid var(--primary_line_color_light);
    border-bottom: 1px solid var(--primary_line_color_light);
    background: var(--primary_box_fill_color);
    height: 40px;
    ::placeholder {
      color: var(--primary_stroke_dark);
      font-family: 'Mulish';
      font-size: 12px;
      font-weight: 700;
    }
  }
  button {
    span {
      color: var(--primary_fail_color);
      font-family: 'Mulish';
      font-size: 12px;
      font-weight: 700;
    }
  }
`
export const RemoveWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .remove-coupan {
    color: var(--primary_button_active_fill_color);
    font-family: 'Mulish';
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
`
export const CouponNameWrapper = styled.div<{ isActive?: boolean }>`
  svg {
    width: 14px;
    height: 14px;
    path {
      stroke: ${({ isActive }) => (isActive ? 'var(--primary_success_color)' : '')};
    }
  }
  .coupan-name {
    border: 1px dashed ${({ isActive }) => (isActive ? 'var(--primary_success_color)' : 'var(--primary_stroke_color)')};
  }
  color: ${({ isActive }) => (isActive ? 'var(--primary_success_color)' : 'var(--primary_stroke_color)')};
`
export const BestCouponWrapper = styled.div`
  padding: 11px 22px 6px;
  .available-coupon-wrapper {
    display: flex;
    gap: 10px;
    padding-top: 32px;
  }
  .available-coupon-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-bottom: 2px solid var(--primary_line_color_light);
    padding-bottom: 28px;
  }
  .coupon-name-wrapper {
    position: relative;
    padding-bottom: 10px;

    svg {
      position: absolute;
      top: -5px;
      left: 10px;
    }
  }
  .coupan-name {
    width: max-content;
    /* width: 125px; */
    padding: 10px;
    text-transform: capitalize;
    text-align: center;
    /* font-family: 'Mulish';
    font-size: 16px;
    font-weight: 700; */
    border-radius: 4px;
  }
  .color-style {
    color: var(--primary_stroke_color);
  }
  .no-couponsAvailable {
    height: 50vh;
  }
`
export const BestCouponWrappers = styled(BestCouponWrapper)`
  padding: 11px 4px 6px 0;
  .coupon-name-wrapper {
    padding-bottom: 0;
  }
`

export const BestCouponContainer = styled(BestCouponWrapper)`
  max-height: 50vh;
  overflow-y: auto;
`

import styled from 'styled-components'

export const SelectLocationViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 24px 0;
  width: 360px;
  background-color: var(--primary_box_fill_color);
  border: 1px solid var(--primary_stroke_color_light);

  @media (max-width: 768px) {
    padding: 0;
    padding-top: 20px;
    width: 100%;
    border: none;
    .delivery-location {
      padding-left: 10px;
    }
  }
`
export const AddressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 13px;
  border-radius: 16px 16px 0px 0px;
  background: var(--primary_box_fill_color);
  /* border: 1px solid var(--primary_stroke_color_light); */

  .change {
    width: 64px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid var(--tertiary_button_stroke_color);
    background: var(--primary_box_fill_color);
    color: var(--secondary_label_active_text_color);
    font-family: 'Mulish';
    font-size: 12px;
    font-weight: 700;
  }
  @media (max-width: 768px) {
    /* border-top: 1px solid var(--primary_stroke_color_light); */
  }
`
export const AddressDetails = styled.div`
  width: 100%;
  max-width: 210px;
`
export const MapWrapper = styled.div`
  position: relative;
  .map-component {
    height: 435px;
    width: 100%;
  }
  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    border-radius: 8px;
    background: var(--tertiary_box_fill_color);
    color: var(--primary_text_color_light);
    font-family: 'Mulish';
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    span {
      font-size: 10px;
      font-weight: 400;
    }
  }
  .leaflet-bottom {
    display: none;
  }
  .current-location {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 30px;
    z-index: 999;
  }
  @media (max-width: 768px) {
    .current-location {
      bottom: 60px;
    }
    .map-component {
      height: calc(100vh - 249px);
    }
  }
`
export const AddressContainer = styled.div`
  border: 1px solid var(--primary_stroke_color_light);
  border-radius: 16px 16px 0px 0px;
  position: relative;
  bottom: 30px;
  z-index: 999;

  /* position: absolute;
  bottom: 0px;
  width: 100%;
  margin: 0px auto;
  z-index: 999; */

  @media (max-width: 768px) {
    position: fixed;
    bottom: 60px;
    width: 100%;
    margin: 0px auto;
  }
`
export const SearchbarWrapper = styled.div`
  z-index: 9999;
  position: relative;
  .search-address {
    width: 100%;
  }
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`
export const AddressType = styled.div`
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
`
export const MainAddress = styled.div`
  color: var(--primary_text_color_dark);
  /* H2-R */
  font-family: 'Mulish';
  font-size: 12px;
  font-weight: 400;
`

import { useEffect, useState } from 'react'
import { GetProp, Image, Upload, UploadFile, UploadProps } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { Controller } from 'react-hook-form'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const FileUploader = ({
  name,
  control,
  onBase64ListChange,
  length,
  defaultValue,
  handleDefault,
  className,
}: {
  name: string
  control: any
  onBase64ListChange: any
  length: number
  defaultValue: any
  handleDefault: any
  className?: any
}) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [base64List, setBase64List] = useState<string[]>([])
  const [fileList, setFileList] = useState<UploadFile[]>(defaultValue)

  const resetData = () => {
    setFileList([])
    setBase64List([])
  }

  useEffect(() => {
    resetData()
  }, [control.reset])

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList)
    convertFilesToBase64(newFileList)
  }

  useEffect(() => {
    onBase64ListChange(base64List)
  }, [base64List, onBase64ListChange])

  const convertFilesToBase64 = async (files: UploadFile[]) => {
    const base64Array: string[] = await Promise.all(
      files.map(async (file) => {
        if (file.originFileObj) {
          const finalstring = (await getBase64(file.originFileObj as FileType)).split('base64,')
          const finalFileUrl = finalstring[1]
          return finalFileUrl
        }

        return ''
      }),
    )

    setBase64List(base64Array)
    onBase64ListChange(base64Array)
    handleDefault(base64Array)
  }

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  )

  const dummyRequest = ({ onSuccess }: UploadRequestOption) => {
    setTimeout(() => {
      onSuccess && onSuccess('ok')
    }, 0)
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          <Upload
            className={`${className} h1r`}
            {...field}
            customRequest={dummyRequest}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={(info) => {
              handleChange(info)
              field.onChange(info.fileList)
            }}
            multiple
          >
            {fileList.length >= length ? null : uploadButton}
          </Upload>
          {previewImage && (
            <Image
              wrapperStyle={{ display: 'none' }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
            />
          )}
        </>
      )}
    />
  )
}

export default FileUploader

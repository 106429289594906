import PrivateLayout from 'components/Layouts/private'
import SelectLocationView from 'views/SelectLocation'

const AddAddressPindrop = () => {
  return (
    <PrivateLayout>
      <SelectLocationView />
    </PrivateLayout>
  )
}

export default AddAddressPindrop

import { pageData } from 'utils/datalayer/pageData'

export const AddressInteractionData = () => {
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const addressInteractionDataToPush = {
    event: 'address_interaction',
    page_type: GDL_PAGE_TYPE,
    User_Status: loggedInStatus,
    User_Id: loggedIn,
    cta_text: 'add_new_address',
  }
  const editAddressDataToPush = {
    event: 'address_interaction',
    page_type: GDL_PAGE_TYPE,
    User_Status: loggedInStatus,
    User_Id: loggedIn,
    cta_text: 'edit_address',
  }
  const changeAddressDataToPush = {
    event: 'address_interaction',
    page_type: GDL_PAGE_TYPE,
    User_Status: loggedInStatus,
    User_Id: loggedIn,
    cta_text: 'change_address',
  }
  const useAddressDataToPush = {
    event: 'address_interaction',
    page_type: GDL_PAGE_TYPE,
    User_Status: loggedInStatus,
    User_Id: loggedIn,
    cta_text: 'use_this_address',
  }

  return {
    addressInteractionDataToPush,
    editAddressDataToPush,
    changeAddressDataToPush,
    useAddressDataToPush,
  }
}

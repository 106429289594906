import TagManager from 'react-gtm-module'
import { useTheme } from 'context/themeContext'
import { pageData } from 'utils/datalayer/pageData'

import {
  FooterMainWrapper,
  FooterContainer,
  HeadingWrapper,
  CategoryContainer,
  AddressHeading,
  Categorieswrapper,
  FooterWrapper,
} from 'styles/components/Footer'
// import { useHistory } from 'react-router-dom'

const FooterLayout2 = () => {
  const { themedata, storesData } = useTheme()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  // const router = useHistory()

  const footerData = themedata?.d?.objectDetails?.footer
  const companyName = themedata?.d?.objectDetails?.footer?.footerCustom?.footerCustomLabel
  const companyAddress = themedata?.d?.objectDetails?.footer?.footerCustom?.footerCustomText

  const footerListKeys = Object.keys(footerData || {}).filter((key: any) => key.startsWith('footerLinkList'))
  const extractedLists = footerListKeys.map((key) => ({
    ...footerData[key],
    objectDetails: footerData[key]?.objectDetails,
  }))

  const enabledLists = extractedLists.filter((list) => {
    const enableKey = Object.keys(list).find((key) => key.startsWith('enableLinkList')) ?? ''
    return list[enableKey] === '1'
  })

  const handleFooterDataLayerPush = (category: any) => {
    const itemName = category?.text.toLowerCase()
    const url = `${category.href.includes('https') ? category.href : '/' + category.href}`

    // router.push(`${category.href.includes('https') ? category.href : '/' + category.href}`)

    const dataToPush = {
      event: 'footer_interaction',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      cta_text: itemName ? itemName : 'NA',
      section_name: 'footer',
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })

    window.location.href = url
  }

  return (
    <FooterMainWrapper>
      {enabledLists.map((category, index) => {
        const titleKey = Object.keys(category).find((key) => key.endsWith('Title'))
        const title = titleKey ? category[titleKey] : ''
        if (title === 'OUR COMPANY') {
          return null
        }

        return (
          <FooterContainer key={index}>
            <HeadingWrapper className="footer-heading">{title}</HeadingWrapper>
            <CategoryContainer>
              {category?.objectDetails?.map((item: any, idx: number) => (
                <Categorieswrapper key={idx}>
                  <a
                    // href={`${item.href.includes('https') ? item.href : '/' + item.href}`}
                    className="plotch_a_link"
                    // onClick={(e) => handleFooterDataLayerPush(e, item?.text, item.href)}
                    onClick={() => handleFooterDataLayerPush(item)}
                  >
                    {item?.text}
                  </a>
                </Categorieswrapper>
              ))}
            </CategoryContainer>
          </FooterContainer>
        )
      })}
      <FooterContainer>
        <FooterWrapper>
          {footerData?.footerContactUs?.enableContactUs == '1' && (
            <FooterContainer>
              <HeadingWrapper className="footer-heading">
                {footerData?.footerContactUs?.footerContactUsHeader
                  ? footerData?.footerContactUs?.footerContactUsHeader
                  : `SUPPORT`}
              </HeadingWrapper>
              <CategoryContainer>
                <Categorieswrapper>{footerData?.footerContactUs?.footerContactUs}</Categorieswrapper>
              </CategoryContainer>
            </FooterContainer>
          )}
        </FooterWrapper>
      </FooterContainer>
      <FooterContainer>
        <AddressHeading className="footer-heading">{companyName}</AddressHeading>
        <Categorieswrapper>{companyAddress}</Categorieswrapper>
      </FooterContainer>
    </FooterMainWrapper>
  )
}

export default FooterLayout2

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={19} viewBox="0 0 21 19" fill="none" {...props}>
    <path
      stroke="var(--primary_text_color_darkgrey)"
      // stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="m4.786 6.898 5.793 4.986 5.794-4.986"
    />
  </svg>
)
export default SvgComponent

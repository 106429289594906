import styled from 'styled-components'
export const FooterMainWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 100px;
  padding: 40px 20px;
  box-shadow: 0 3px 15px 0 rgb(0, 0, 0, 0.11);
  background-color: var(--primary_text_color_dark);

  @media (max-width: 768px) {
    display: none;
  }
`
export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: max-content;
  a {
    text-decoration: none;
    color: var(--primary_box_fill_color);
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
  }
  .footer-heading {
    color: var(--primary_icon_rating_color);
  }
`
export const HeadingWrapper = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
`
export const AddressHeading = styled(HeadingWrapper)`
  text-transform: capitalize;
`
export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const Categorieswrapper = styled.div`
  width: 100%;
  max-width: 400px;
  color: var(--primary_box_fill_color);
`

export const AppWrapper = styled.div`
  display: flex;
  gap: 4px;
`
export const IconWrapper = styled.div`
  display: flex;
  gap: 4px;
`

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M13.8046 5.36333L8.34313 2.66775C8.23746 2.61562 8.1212 2.5885 8.00337 2.5885C7.88554 2.5885 7.76928 2.61562 7.66361 2.66775L2.20211 5.36333C1.99273 5.46592 1.81621 5.62501 1.69249 5.82264C1.56877 6.02026 1.50277 6.24855 1.50195 6.4817V12.252C1.50195 12.9425 2.06895 13.5023 2.76848 13.5023H13.2395C13.939 13.5023 14.506 12.9425 14.506 12.252V6.4817C14.5051 6.24841 14.4389 6.02004 14.315 5.8224C14.191 5.62476 14.0142 5.46575 13.8046 5.36333V5.36333Z"
      stroke="#629E58"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4199 11.5015L8.37967 8.35896C8.24799 8.25654 8.08593 8.20093 7.9191 8.20093C7.75228 8.20093 7.59022 8.25654 7.45854 8.35896L3.41797 11.5015"
      stroke="#629E58"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.66797 9.22064L13.9195 6.00073"
      stroke="#629E58"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.91797 6.00073L6.26328 9.28317"
      stroke="#629E58"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent

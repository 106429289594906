import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'
import { Badge } from 'antd'
import TagManager from 'react-gtm-module'
import { pageData } from 'utils/datalayer/pageData'
import { CheckoutRoute, EmptyCartRoute, HomeRoute, LoginRoute } from 'constants/routes'
import { useTheme } from 'context/themeContext'
import LocationNewUser from 'views/LocationNewUser'
import NavProductList from 'views/NavProductList'
import SavedAddresses from 'views/SavedAddresses'
import CartIcon from 'assets/svg/CartIcon'
import DownArrow from 'assets/svg/DownArrow'
import LocationIcon from 'assets/svg/LocationIcon'
import MenuIcon from 'assets/svg/MenuIcon'
import {
  MobileNavbarContainer,
  LeftContainer,
  MobileRightContainer,
  ImageWrapper,
  CartWrapper,
  LocationWrapper,
} from 'styles/components/Navbar'
import { CommonStyle } from 'styles/pages/Common'
import { DataLayerObject } from '../dataLayer'

const MobileNavbar = ({ cartData }: any) => {
  const [open, setOpen] = useState(false)
  const [openCategory, setOpenCategory] = useState(false)
  const [openAddress, setOpenAddress] = useState(false)
  const navigate = useHistory()
  const { themedata, storesData } = useTheme()
  const router = useHistory()
  const headerLogo = themedata?.d?.objectDetails?.header?.siteLogo?.logoImage
  const address: any = localStorage.getItem('location')
  const postcode: any = localStorage.getItem('postcode')
  const currentAddress = JSON.parse(address)
  const { dataSignInToPush, dataMyAccountToPush, dataNavLocationToPush } = DataLayerObject()
  const ishyperlocalEnable = storesData?.d?.isHyperlocalEnabled ? storesData?.d?.isHyperlocalEnabled : 0
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }
  const toggleCategoryDrawer = (newList: boolean) => () => {
    setOpenCategory(newList)
  }
  const toggleAddressDrawer = (newAddress: boolean) => () => {
    setOpenAddress(newAddress)
  }

  const handleLocationClick = () => {
    TagManager.dataLayer({ dataLayer: dataNavLocationToPush })

    if (localStorage.getItem('customerId') && localStorage.getItem('sessionId')) {
      toggleAddressDrawer(true)()
    } else {
      toggleDrawer(true)()
    }
  }

  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const handleClick = () => {
    router.push(HomeRoute.path)
    const dataToPush = {
      event: 'logo_click',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })
  }
  const handleCartLogin = () => {
    if (!localStorage.getItem('customerId')) {
      navigate.push(LoginRoute.path + '?redirect=checkout')
      TagManager.dataLayer({ dataLayer: dataSignInToPush })
    } else {
      if (!cartData) {
        navigate.push(EmptyCartRoute.path)
        TagManager.dataLayer({ dataLayer: dataMyAccountToPush })
      } else {
        navigate.push(CheckoutRoute.path)
        TagManager.dataLayer({ dataLayer: dataMyAccountToPush })
      }
    }
  }

  return (
    <MobileNavbarContainer>
      <>
        <LeftContainer>
          <MenuIcon onClick={toggleCategoryDrawer(true)} />
        </LeftContainer>
        <MobileRightContainer>
          <ImageWrapper>{headerLogo && <img src={headerLogo} onClick={handleClick} loading="lazy" />}</ImageWrapper>
          <CartWrapper>
            {themedata?.d?.objectDetails?.general?.showOndcLocation?.showOndcLocation && ishyperlocalEnable == 1 && (
              <LocationWrapper onClick={handleLocationClick}>
                <LocationIcon />
                {currentAddress?.address?.postcode || postcode ? (
                  <>{currentAddress?.address?.postcode || postcode}</>
                ) : (
                  <span className="text-nonbreak">Location</span>
                )}
                <DownArrow />
              </LocationWrapper>
            )}
            {/* <CartIcon
            onClick={() => {
              navigate.push(EmptyCartRoute.path)
            }}
          /> */}
            <Badge count={cartData} color="var(--primary_icon_highlight_color)">
              <CartIcon onClick={handleCartLogin} />
            </Badge>
            {/* <CartIcon
            onClick={handleCartLogin}
            // onClick={() => {
            //   navigate.push(EmptyCartRoute.path)
            // }}
          /> */}
          </CartWrapper>
        </MobileRightContainer>
      </>

      <Drawer
        open={open}
        anchor="right"
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%',
          },
        }}
      >
        {<LocationNewUser onClose={toggleDrawer(false)} />}
      </Drawer>
      <Drawer
        open={openAddress}
        anchor="bottom"
        sx={{
          '& .MuiDrawer-paper': {
            height: 'auto', // Set height to auto
            borderRadius: '20px 20px 0px 0px',
          },
        }}
      >
        {<SavedAddresses onClose={toggleAddressDrawer(false)} />}
      </Drawer>
      <Drawer
        open={openCategory}
        onClose={toggleCategoryDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '264px',
          },
        }}
      >
        {
          <CommonStyle>
            <NavProductList onClose={toggleCategoryDrawer(false)} />
          </CommonStyle>
        }
      </Drawer>
    </MobileNavbarContainer>
  )
}

export default MobileNavbar

import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import PrivateLayout from 'components/Layouts/private'
import { HomeRoute } from 'constants/routes'
import {
  NotFoundPageWrapper,
  MainContainer,
  LogoWrapper,
  MainWrapper,
  Heading,
  SubHeading,
} from 'styles/pages/NotFound'
import { useTheme } from 'context/themeContext'

const UnauthorizedError = () => {
  const { themedata } = useTheme()

  const themeLogo = themedata?.d?.objectDetails?.header?.siteLogo?.logoImage

  const router = useHistory()

  const handleClick = () => {
    router.push(HomeRoute.path)
  }

  return (
    <PrivateLayout>
      <NotFoundPageWrapper>
        <MainContainer>
          <LogoWrapper>
            <img src={themeLogo} alt="WebsiteLogo" />
          </LogoWrapper>
          <MainWrapper>
            <Heading>401</Heading>
            <SubHeading>Unauthorized Access</SubHeading>
          </MainWrapper>
          <Button label="Go To Home" variant="contained" type="submit" className="small" onClick={handleClick} />
        </MainContainer>
      </NotFoundPageWrapper>
    </PrivateLayout>
  )
}

export default UnauthorizedError

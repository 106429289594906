import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <g clipPath="url(#clip0_757_1706)">
      <path
        d="M14.2998 5.70283C13.9748 5.37776 13.7845 4.94199 13.767 4.48268C13.7495 4.02337 13.906 3.57438 14.2052 3.22549C14.23 3.19647 14.2428 3.15917 14.2412 3.12107C14.2397 3.08296 14.2237 3.04687 14.1966 3.02002L12.4728 1.29424C12.4443 1.26576 12.4057 1.24976 12.3654 1.24976C12.3251 1.24976 12.2865 1.26576 12.258 1.29424L9.5103 4.04189C9.40892 4.14324 9.33255 4.26682 9.28725 4.40283V4.40283C9.24212 4.53915 9.16585 4.66308 9.06448 4.76479C8.96311 4.86649 8.83944 4.94318 8.70327 4.98877V4.98877C8.56717 5.03412 8.44348 5.11048 8.34194 5.21181L1.29546 12.2567C1.26698 12.2852 1.25098 12.3239 1.25098 12.3642C1.25098 12.4044 1.26698 12.4431 1.29546 12.4716L3.01929 14.1954C3.04613 14.2225 3.08223 14.2384 3.12033 14.24C3.15844 14.2416 3.19574 14.2288 3.22475 14.204C3.57357 13.9044 4.02267 13.7477 4.48213 13.7651C4.9416 13.7825 5.37754 13.9728 5.70266 14.298C6.02779 14.6231 6.21812 15.059 6.23554 15.5185C6.25296 15.978 6.0962 16.4271 5.79663 16.7759C5.77188 16.8049 5.75901 16.8422 5.7606 16.8803C5.7622 16.9184 5.77814 16.9545 5.80522 16.9813L7.52905 18.7052C7.55755 18.7337 7.59618 18.7497 7.63647 18.7497C7.67676 18.7497 7.7154 18.7337 7.74389 18.7052L14.7908 11.6587C14.8921 11.5572 14.9685 11.4335 15.0138 11.2974V11.2974C15.0589 11.161 15.1352 11.0371 15.2366 10.9354C15.338 10.8337 15.4616 10.757 15.5978 10.7114V10.7114C15.7338 10.6661 15.8574 10.5898 15.9587 10.4884L18.7064 7.74072C18.7349 7.71223 18.7509 7.67359 18.7509 7.6333C18.7509 7.59301 18.7349 7.55437 18.7064 7.52588L16.9826 5.80205C16.9557 5.77496 16.9196 5.75903 16.8815 5.75743C16.8434 5.75584 16.8061 5.76871 16.7771 5.79346C16.4287 6.09321 15.98 6.2504 15.5207 6.2336C15.0614 6.2168 14.6253 6.02724 14.2998 5.70283V5.70283Z"
        stroke="var(--primary_static_icon_color)"
        strokeWidth="1.00189"
        strokeMiterlimit="10"
      />
      <path
        d="M9.79229 5.49298L9.14014 4.84082"
        stroke="var(--primary_static_icon_color)"
        strokeWidth="1.00189"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.514 7.21322L11.0752 6.77478"
        stroke="var(--primary_static_icon_color)"
        strokeWidth="1.00189"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.2334 8.93371L12.7949 8.49487"
        stroke="var(--primary_static_icon_color)"
        strokeWidth="1.00189"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.1668 10.8655L14.5146 10.2134"
        stroke="var(--primary_static_icon_color)"
        strokeWidth="1.00189"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_757_1706">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent

import { useContext, useEffect, useState } from 'react'
import usePost from 'hooks/usePost'
import Button from 'components/Button'
import APIS from 'constants/api'
import { SnackBarContext } from 'context/SnackBarContext'
import CloseIcon from 'assets/svg/CloseIcon'
import AlertIcon from 'assets/svg/AlertIcon'
import {
  ReturnCancellationPolicyContainer,
  HeadingWrapper,
  TopHeadingWrapper,
  OrderListWrap,
} from 'styles/views/SaveAddress'
import { OssButtonWrapper, OssSubButtonWrapper } from 'styles/components/Button'
import { SvgWrapper } from 'styles/views/LocationNewUser'
import { CommonStyle } from 'styles/pages/Common'
import { OssWrapper } from 'styles/views/ViewOrderDetail'
// import { LoaderContext } from 'context/loader'
import { useHomeContext } from 'context/HomeContext'
import { DeliveryWrapper } from 'styles/views/PaymentOrderDetails'
// import TrashIcon from 'assets/svg/TrashIcon'

interface IProduct {
  productId: number
  productName: string
  ondcErrorMessage: string
  errorCode: string
  imgUrl: string
  plotchtag: string
  plotchTag?: string
  isServiceable: string
  onInitError: string
  variants: Record<string, any>
  qty: string
  actualQty: string
}

interface IOssErrorProps {
  onClose: any
  product: IProduct[]
  refetchOndcQuoteDetails: any
  quoteData: any
  handleChangeAddressClick: any
  setPrepaidError: any
}

const OssError = ({
  onClose,
  product,
  refetchOndcQuoteDetails,
  quoteData,
  handleChangeAddressClick,
  setPrepaidError,
}: IOssErrorProps) => {
  const { mutateAsync } = usePost()
  // const { setLoader } = useContext(LoaderContext)
  const { setShowSnackBar } = useContext(SnackBarContext)
  const { setCartCount, cartCount } = useHomeContext()
  const [errorMessage, setErrorMessage] = useState('')
  const [otherError, setOtherError] = useState(false)
  const [buttonText, setButtonText] = useState('')
  const ids = product.map((item: any) => item?.productId)

  const handleRemoveProduct = async (id: any) => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        // url: '',
        url: APIS.REMOVE_ITEM,
        payload: { productIds: id },
        // payload: { productIds: '[productId]' },
      })
      if (res) {
        // if (showSnackbar) {
        setShowSnackBar({ open: true, message: `Product Removed`, severity: '' })
        // }
        setPrepaidError([])
        refetchOndcQuoteDetails()
        //setLoader(false)

        setCartCount(cartCount - 1)
        onClose()
      }
    } catch (error) {
      //setLoader(false)
      return error
    }
  }
  let errorMessageText = ''
  // let otherMessage = false
  // let priceMessage = false
  // let reviewMessage = false
  // let changeAdd = false
  // let outOfStock = false
  let otherMessageCount = 0
  let priceMessageCount = 0
  let reviewMessageCount = 0
  let outOfStockCount = 0
  let changeAddCount = 0
  const errorMessages: any = []

  useEffect(() => {
    product?.length > 0 &&
      product?.map((item: IProduct) => {
        if (item?.isServiceable == '0' || item?.onInitError == '1') {
          const trimmedErrorCode = String(item?.errorCode || '').trim()
          switch (trimmedErrorCode) {
            case '51518':
            case '51519':
              errorMessageText = 'Following products have issues due to error in processing'
              // otherMessage = true;
              otherMessageCount++
              break

            case '51515':
              errorMessageText = 'Pricing and other cart details have changed for following products'
              // priceMessage = true
              priceMessageCount++
              break

            case '230023':
              errorMessageText = 'Cart value is less than minimum order value'
              // reviewMessage = true
              reviewMessageCount++
              break

            case '210004':
              errorMessageText = 'Sellers of these products not reachable currently'
              // otherMessage = true;
              otherMessageCount++
              break
            case '210003':
            case '230009':
              errorMessageText = 'Following products not deliverable on this delivery address'
              changeAddCount++
              break

            case '230004':
              errorMessageText = 'Following products are out of stock and removed from cart'
              // outOfStock = true
              outOfStockCount++
              break

            case '240001':
            case '240000':
              errorMessageText = 'Following products have issues due to error in processing'
              // otherMessage = true;
              otherMessageCount++
              break

            default:
              errorMessageText = 'Following products have issues due to error in processing'
              // otherMessage = true;
              otherMessageCount++
              break
          }
        }
      })

    setErrorMessage(errorMessageText)

    if (
      priceMessageCount > 0 ||
      otherMessageCount > 0 ||
      outOfStockCount > 0 ||
      reviewMessageCount > 0 ||
      changeAddCount > 0
    )
      setOtherError(true)

    if (otherMessageCount > 0) errorMessages.push('Try Again')
    if (outOfStockCount > 0) errorMessages.push('Remove OOS products & Continue')
    if (reviewMessageCount > 0) errorMessages.push('Review cart')
    if (changeAddCount > 0) errorMessages.push('Change Address')
    if (priceMessageCount > 0) errorMessages.push('Continue Checkout With New Prices')

    if (errorMessages.length > 1) {
      setButtonText('Try Again')
    } else if (errorMessages.length === 1) {
      setButtonText(errorMessages[0])
    }
  }, [quoteData])
  return (
    <ReturnCancellationPolicyContainer>
      <SvgWrapper onClick={onClose} className="cross-icon">
        <CloseIcon />
      </SvgWrapper>
      <CommonStyle>
        <HeadingWrapper className="remove-item">
          <TopHeadingWrapper>
            <AlertIcon />
            <div className="h0b message-wrap">
              {otherError == true && errorMessage
                ? errorMessage
                : 'Following products are out of stock and removed from cart'}
            </div>
          </TopHeadingWrapper>
        </HeadingWrapper>
      </CommonStyle>
      <CommonStyle>
        <OrderListWrap>
          {quoteData && quoteData?.shippingAddress && (
            <DeliveryWrapper>
              <div className="deliver-detail-wrapper">
                <label className="h2r">
                  Deliver to:{' '}
                  <span className="h2b address-detail">
                    {quoteData?.shippingAddress?.firstName} {quoteData?.shippingAddress?.lastName}(
                    {quoteData?.shippingAddress?.label == null ? 'Home' : quoteData?.shippingAddress?.label})
                  </span>
                </label>
                <label className="h2r address-detail">
                  {quoteData?.shippingAddress?.building} &nbsp;
                  {quoteData?.shippingAddress?.locality}&nbsp;
                  {quoteData?.shippingAddress?.city} ....
                </label>
              </div>
              <div className="profile-change-btn h3b" onClick={handleChangeAddressClick}>
                CHANGE
              </div>
            </DeliveryWrapper>
          )}
          {product?.length > 0 &&
            product?.map((item: IProduct, index: number) => {
              let imgUrl =
                item.imgUrl.includes('http://') || item.imgUrl.includes('https://')
                  ? item.imgUrl
                  : `https://cdnaz.plotch.io/image/upload/w_118,h_118${item.imgUrl}`
              if (imgUrl.includes('cdnaz.plotch.io') && !imgUrl.includes('w_118,h_118')) {
                imgUrl = imgUrl.split('/upload/').join(`/upload/w_118,h_118/`)
                imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
              }

              return (
                <OssWrapper key={index}>
                  <div className="confirm-order">
                    <img src={imgUrl} />
                    <div className="orders-details">
                      <div className="order-name remove-wrapper">
                        <div>
                          <div>
                            <label className="h3r itemSection">
                              <span className="itemName">{item?.productName}</span>(
                              {item?.actualQty ? item.actualQty : item?.qty} Qty
                              {item?.variants?.colour ? ',' : ''}{' '}
                              {item?.variants?.colour ? (
                                item?.variants?.colour?.[0]?.startsWith('#') ? (
                                  <span className="colourBox" style={{ background: item?.variants?.colour }} />
                                ) : (
                                  item?.variants?.colour
                                )
                              ) : (
                                ''
                              )}
                              {item?.variants?.size ? ' , ' + item?.variants?.size : ''}
                              {''})
                            </label>
                          </div>
                          <label className="h3r oss-message">
                            {item?.plotchtag ? item?.plotchtag : item?.plotchTag}
                          </label>
                        </div>
                        <Button
                          label="REMOVE"
                          variant="text"
                          type="submit"
                          className="small h3b profile-change-btn"
                          onClick={() => {
                            handleRemoveProduct([item?.productId])
                          }}
                        />
                      </div>
                    </div>
                    {/* <TrashIcon
                      onClick={() => {
                        handleRemoveProduct(item?.productId)
                      }}
                      className="trash"
                    /> */}
                  </div>
                </OssWrapper>
              )
            })}
        </OrderListWrap>
      </CommonStyle>
      <OssSubButtonWrapper>
        <Button
          label="Remove products with error"
          variant="outline"
          type="submit"
          className="subAction"
          onClick={() => handleRemoveProduct(ids)}
          // onClick={handleRemoveProduct(item?.productId)}
        />
      </OssSubButtonWrapper>
      <OssButtonWrapper>
        {buttonText == 'Try Again' && (
          <Button
            label={`${buttonText}`}
            variant="contained"
            type="submit"
            className="small"
            onClick={() => {
              onClose()
              window.location.reload()
            }}
            // onClick={handleRemoveProduct(item?.productId)}
          />
        )}
        {buttonText == 'Remove OOS products & Continue' && (
          <Button
            label={`${buttonText}`}
            variant="contained"
            type="submit"
            className="small"
            onClick={() => handleRemoveProduct(ids)}
            // onClick={handleRemoveProduct(item?.productId)}
          />
        )}
        {buttonText == 'Change Address' && (
          <Button
            label={`${buttonText}`}
            variant="contained"
            type="button"
            className="small"
            onClick={handleChangeAddressClick}
            // onClick={handleRemoveProduct(item?.productId)}
          />
        )}
        {buttonText == 'Review cart' && (
          <Button
            label={`${buttonText}`}
            variant="contained"
            type="button"
            className="small"
            onClick={() => onClose()}
            // onClick={handleRemoveProduct(item?.productId)}
          />
        )}
        {buttonText == 'Continue Checkout With New Prices' && (
          <Button
            label={`${buttonText}`}
            variant="contained"
            type="button"
            className="small"
            onClick={() => onClose()}
            // onClick={handleRemoveProduct(item?.productId)}
          />
        )}
      </OssButtonWrapper>
    </ReturnCancellationPolicyContainer>
  )
}

export default OssError

import styled from 'styled-components'
export const AnnouncementWrapper = styled.div`
  p {
    text-align: center;
    margin: 0;
    padding: 4px;
  }
  .desktop-announcement {
  }
  .mobile-announcement {
    display: none;
  }
  @media (max-width: 768px) {
    .desktop-announcement {
      display: none;
    }
    .mobile-announcement {
      display: block;
    }
  }
`

import { useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import Button from 'components/Button'
import PrivateLayout from 'components/Layouts/private'
import { AddAddressRoute } from 'constants/routes'
import ManageAddressesList from 'views/ManageAddressesList/addressList'
import BackArrow from 'assets/svg/BackArrow'
import { CommonStyle } from 'styles/pages/Common'
import { BackWrapper, AccountManageWrappers, BottomButtonWrapper } from 'styles/pages/MyAccount'
import { AddressInteractionData } from 'utils/datalayer/addressInteractionData'
import { useTheme } from 'context/themeContext'

const ManageAddressPage = () => {
  const navigate = useHistory()
  const { addressInteractionDataToPush } = AddressInteractionData()
  const { storesData } = useTheme()
  const handleBack = () => {
    navigate.goBack()
  }

  return (
    <PrivateLayout>
      <AccountManageWrappers>
        <CommonStyle>
          <BackWrapper onClick={handleBack}>
            <BackArrow />
            <div className="h1b">MANAGE ADDRESSES</div>
          </BackWrapper>
        </CommonStyle>
        <ManageAddressesList />
        <BottomButtonWrapper>
          <Button
            label="Add Address"
            variant="contained"
            type="submit"
            className="small"
            onClick={() => {
              storesData?.d?.googleDatalayer &&
                storesData?.d?.googleDatalayer == 1 &&
                TagManager.dataLayer({ dataLayer: addressInteractionDataToPush })
              navigate.push(AddAddressRoute.path)
            }}
          />
        </BottomButtonWrapper>
      </AccountManageWrappers>
    </PrivateLayout>
  )
}

export default ManageAddressPage

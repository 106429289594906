import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <g stroke="var(--primary_brand_color)" strokeWidth={1.002}>
      <path
        strokeMiterlimit={10}
        d="M17.5 10c0-4.14-3.359-7.5-7.5-7.5-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.141 0 7.5-3.36 7.5-7.5Z"
      />
      <path strokeLinecap="round" strokeLinejoin="round" d="m12.5 12.5-5-5M7.5 12.5l5-5" />
    </g>
  </svg>
)
export default SvgComponent

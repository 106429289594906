import { createContext, useContext, useEffect, useState } from 'react'
import useGet from 'hooks/useGet'
import APIS from 'constants/api'
import { useSession } from './SessionContext'
import { SnackBarContext } from 'context/SnackBarContext'

interface ICountContext {
  themedata: any
  setThemedata: (data: any) => void
  storesData: any
  setStoresData: (data: any) => void
  isServiceableProduct: (vendorLat: number, vendorLon: number, circleRadius: number) => Promise<boolean>
}

const defaultValues = {
  themedata: null,
  setThemedata: () => null,
  storesData: null,
  setStoresData: () => null,
  isServiceableProduct: async () => true,
}

const ThemeContext = createContext<ICountContext>(defaultValues)

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [themedata, setThemedata] = useState({})
  const [storesData, setStoresData] = useState({})
  const { setShowSnackBar } = useContext(SnackBarContext)

  const { refetch: refetchStore, data: storeData } = useGet('store_details', `${APIS.STORE_DETAILS}`)
  const { refetch, data: themeData } = useGet('theme', `${APIS.THEME_API}`)
  const { isSession } = useSession()
  const [CDNLink, setCDNLink] = useState('')
  const [cssContent, setCssContent] = useState('')

  useEffect(() => {
    if (themeData) {
      setThemedata(themeData)
    }
  }, [themeData])

  useEffect(() => {
    if (storeData) {
      setStoresData(storeData)
    }
  }, [storeData])

  const fetchCSSFromCDN = async (url: string) => {
    try {
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`)
      }

      return await response.text()
    } catch (error) {
      setShowSnackBar({ open: true, message: error as string, severity: '' })

      // console.error('Error fetching CSS from CDN:', error)
      return null
    }
  }

  const isServiceableProduct = async (vendorLat: any, vendorLon: any, circleRadius: any) => {
    let customerAddr: any = localStorage.getItem('location')
    let customerLat
    let customerLon
    if (customerAddr) {
      customerLat = 20.5937
      customerLon = 78.9629
    }

    customerAddr = JSON.parse(customerAddr)
    customerLat = customerAddr?.latitude ? Number(customerAddr?.latitude) : 0
    customerLon = customerAddr?.longitude ? Number(customerAddr?.longitude) : 0
    if (
      storeData?.d?.isHyperlocalEnable == 1 &&
      (!customerLat || !customerLon || customerLat == undefined || customerLon == undefined)
    ) {
      customerLat = 20.5937
      customerLon = 78.9629
    }

    if (customerLat != 0 && customerLon != 0) {
      const R = 6371 // Radius of the earth in km
      const dLat = deg2rad(Number(vendorLat) - Number(customerLat))
      const dLon = deg2rad(Number(vendorLon) - Number(customerLon))
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(customerLat)) * Math.cos(deg2rad(Number(vendorLat))) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c // Distance in km
      return d > Number(circleRadius) ? false : true
    }

    return true
  }

  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180)
  }

  useEffect(() => {
    const cdnLink = storeData?.d?.themeInstanceId
      ? `https://cdnassets.plotch.io/assets/css/${storeData.d.themeInstanceId}_UI.css`
      : ''
    // const cdnLink = storeData?.d?.themeInstanceId
    //   ? `${process.env.REACT_APP_CDN_UI_CSS_PATH}/assets/css/${storeData.d.themeInstanceId}_UI.css`
    //   : ''
    if (cdnLink) {
      setCDNLink(cdnLink)
    }
  }, [storeData?.d?.themeInstanceId])

  useEffect(() => {
    if (isSession) {
      refetchStore()
      refetch()
    }
  }, [isSession, refetch, refetchStore])

  useEffect(() => {
    const loadCSS = async () => {
      if (CDNLink) {
        const css = await fetchCSSFromCDN(CDNLink)
        if (css) {
          setCssContent(css)
        }
      }
    }

    loadCSS()
  }, [CDNLink])

  useEffect(() => {
    if (cssContent) {
      const style = document.createElement('style')
      style.appendChild(document.createTextNode(cssContent))
      document.head.appendChild(style)

      return () => {
        document.head.removeChild(style)
      }
    }
  }, [cssContent])

  return (
    <ThemeContext.Provider value={{ themedata, setThemedata, storesData, setStoresData, isServiceableProduct }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
export { ThemeContext }

export const useTheme = () => {
  return useContext(ThemeContext)
}

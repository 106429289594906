import PublicLayout from 'components/Layouts/public'
import FailedViewOrderDetail from 'views/ViewOrderDetail/failedViewOrderDetail'
import { CommonStyle } from 'styles/pages/Common'
import { CheckoutPageWrapper } from 'styles/pages/MyAccount'

const FailedOrderDetailsPage = () => {
  return (
    <PublicLayout>
      <CheckoutPageWrapper>
        <CommonStyle>
          <FailedViewOrderDetail />
        </CommonStyle>
      </CheckoutPageWrapper>
    </PublicLayout>
  )
}

export default FailedOrderDetailsPage

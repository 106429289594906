import MobileHomeDetails from 'views/HomeDetails/mobileHomeDetails'
import PublicLayout from 'components/Layouts/public'
// import { pushToDataLayer } from 'utils/datalayer'
// import { useEffect } from 'react'

const HomePage = () => {
  // useEffect(() => {
  //   console.log('Home---------')
  //   pushToDataLayer({
  //     event: 'pageview',
  //     page_type: 'homepage',
  //     category: 'na',
  //     sub_category: 'na',
  //     tags: 'na',
  //     // User_Id : '{{dynamic}}',
  //     // 'User_Status' : '{{dynamic}}',
  //     brand: 'na',
  //     product_id: 'na',
  //     section_name: 'na',
  //     // 'selected_location' : '{{dynamic}}',
  //     items_added_in_cart: 'na',
  //     items_added_to_wishlist: 'na',
  //     seller_name: 'na',
  //   })
  // }, [])
  return (
    <PublicLayout>
      <MobileHomeDetails />
    </PublicLayout>
  )
}

export default HomePage

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={106}
    height={105}
    viewBox="0 0 106 105"
    fill="none"
    {...props}
    className="spin"
  >
    <path
      fill="var(--primary_brand_color)"
      d="M75.666 52.403c1.76 0 3.208 1.433 2.989 3.18a25.499 25.499 0 1 1-6.7-20.623c1.203 1.284.874 3.294-.55 4.329-1.424 1.034-3.401.695-4.658-.538a19.125 19.125 0 1 0 5.467 16.825c.292-1.736 1.692-3.173 3.452-3.173Z"
    />
    <path
      fill="var(--primary_brand_color)"
      d="M22.975 70.352c-1.466.87-3.372.391-4.12-1.14a38.249 38.249 0 1 1 22.1 19.43c-1.614-.547-2.334-2.376-1.659-3.941.675-1.566 2.488-2.272 4.111-1.751a32.076 32.076 0 1 0-19.222-16.9c.725 1.544.257 3.432-1.21 4.302Z"
    />
  </svg>
)
export default SvgComponent

import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ListProducts from 'components/ListProduct'
import PublicLayout from 'components/Layouts/public'
import APIS from 'constants/api'
import usePost from 'hooks/usePost'
import { Product } from 'interfaces/pages'
// import { LoaderContext } from 'context/loader'
import { useFilter } from 'context/FliterContext'
import { CommonStyle } from 'styles/pages/Common'
import { DataWrapper, SearchPageWrapper } from 'styles/pages/SearchPage'
import SplitStoresHyperlocalView from 'views/SplitStoresHyperlocalView'
import { useTheme } from 'context/themeContext'
import { useHomeContext } from 'context/HomeContext'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const Search = () => {
  const [searchDetails, setSearchDetails] = useState<Product[]>([])
  const [loadMoreData, setLoadMoreData] = useState(false)
  const [isSorting, setIsSorting] = useState(false)
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768)
  const [productCount, setProductCount] = useState('')
  const [productInitialCount, setProductInitialCount] = useState('')
  const [filtersData, setFiltersData] = useState<any>([])
  const [filtersSortData, setFiltersSortData] = useState<any>([])
  const { filterProduct, filterSortProduct } = useFilter()
  const [metaInfo, setMetaInfo] = useState<any>()
  const query = useQuery()
  const searchTerm = query.get('q')
  const { storesData } = useTheme()
  const { setHomeData } = useHomeContext()
  // const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  const [dataVisisble, setDataVisible] = useState(true)

  const queryString = window.location.search.split('?')[1]
  const searchParams = new URLSearchParams(queryString)
  const page = searchParams.get('page_id') || '1'
  const type = searchParams.get('search_type') || 'store'
  const [filter, setFilter] = useState({
    page: parseInt(page),
    perPage: 48,
  })
  const [searchType, setSearchType] = useState(type)

  const searchProductData = async (paramsArray: any, sortBy: any) => {
    try {
      //setLoader(true)
      const res = await mutateAsync({
        url: APIS.SEARCH_PRODUCT,
        payload: {
          criteria: '',
          search: searchTerm ? searchTerm : '',
          pageId: filter.page,
          perPage: filter.perPage,
          customerId: false,
          // sortBy: {},
          sortBy: sortBy,
          // filters: paramsArray,
          filters: metaInfo?.flow_type === 'hyperlocal-fnb' && searchType == 'store' ? [] : paramsArray,
          search_type: searchType,
        },
      })
      if (res) {
        //setLoader(false)
        setMetaInfo(res?.d?.metaInfo)
        setFiltersData(res?.d?.displayFilters)
        setFiltersSortData(res?.d?.sorts)

        if (!isLargeScreen && loadMoreData) {
          if (isSorting) {
            setSearchDetails(res?.d?.products)
            setIsSorting(false)
          } else res?.d?.products && setSearchDetails((prev: Product[]) => [...prev, ...res?.d?.products])
        } else {
          setSearchDetails(res?.d?.products)
        }

        setDataVisible(true)
        setProductInitialCount(res?.d?.productCount)
        setProductCount(res?.d?.totalProducts)
      }
    } catch (error: any) {
      //setLoader(false)
      setDataVisible(false)
      return error
    }
  }

  useEffect(() => {
    if (storesData?.d?.pageConfig) {
      const pageConfig = storesData?.d?.pageConfig
      const enableSearchCache = pageConfig?.search_page_cache
      const searchTTL = pageConfig?.search_ttl
      if (enableSearchCache == 1 && searchTTL != 0) {
        const metaTag = document.createElement('meta')
        metaTag.httpEquiv = 'Cache-Control'
        metaTag.content = `public,max-age=${searchTTL}`
        document.head.appendChild(metaTag)

        return () => {
          document.head.removeChild(metaTag)
        }
      }
    }
  }, [storesData])

  useEffect(() => {
    // Update the isLargeScreen state whenever the window size changes
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768)
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const queryString = window.location.search.split('?')[1] // Get the query string part of the URL
    const searchParams = new URLSearchParams(queryString)

    if (metaInfo?.flow_type === 'hyperlocal-fnb') {
      // Set the search_type and flow_type to the desired values
      searchParams.set('search_type', searchType)
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`
      window.history.replaceState(null, '', newUrl)
    }

    const filtersObject: { [key: string]: string[] } = {}
    const sortObject: any = {}

    searchParams.forEach((value, key) => {
      if (key === 'sort_by') {
        if (value === 'priceasc') sortObject['discountedPriceOrder'] = 'asc'
        else if (value === 'pricedesc') sortObject['discountedPriceOrder'] = 'desc'
        else if (value === 'popular') sortObject['relevance'] = 'desc'
      } else if (!['searchby', 'q', 'page_id'].includes(key)) {
        // If the key is 'store_name', join the values into a single string if there are multiple parts
        if (key === 'store_name') {
          filtersObject[key] = [value.replace(/,\s*/g, ', ')] // Replaces commas with a single comma
        } else {
          filtersObject[key] = value.split(',')
        }
      }
    })

    searchProductData(
      Object.keys(filtersObject).length ? [filtersObject] : [],
      Object.keys(sortObject).length ? sortObject : {},
    )
  }, [searchTerm, filter, filterProduct, filterSortProduct, searchType])

  useEffect(() => {
    const seoDetails = {
      robots: 'index, follow',
      pageTitle: `Buy ${
        searchTerm ? searchTerm?.charAt(0)?.toUpperCase() + searchTerm?.slice(1)?.toLowerCase() : ''
      } Online | ${storesData?.d?.storeBrandName}`,
      metaKeywords: `${
        searchTerm ? searchTerm?.charAt(0)?.toUpperCase() + searchTerm?.slice(1)?.toLowerCase() : ''
      } | ${
        searchTerm ? searchTerm?.charAt(0)?.toUpperCase() + searchTerm?.slice(1)?.toLowerCase() : ''
      } Online Shopping`,
      imgUrl: ``,
      url: `${window.location.href}`,
    }
    setHomeData(seoDetails)
  }, [storesData])
  return (
    <PublicLayout>
      {metaInfo?.flow_type === 'intercity' ? (
        <SearchPageWrapper>
          <CommonStyle>
            {searchDetails?.length > 0 ? (
              <ListProducts
                products={searchDetails}
                setFilter={setFilter}
                productCount={productCount}
                productInitialCount={productInitialCount}
                filter={filter}
                searchTerm={searchTerm}
                filtersData={filtersData}
                filtersSortData={filtersSortData}
                setIsSorting={setIsSorting}
                setLoadMoreData={setLoadMoreData}
              />
            ) : (
              <DataWrapper className="h1b"> No Data</DataWrapper>
              // <DataWrapper className="h1b">{dataVisisble ? '' : 'No Data'}</DataWrapper>
            )}
          </CommonStyle>
        </SearchPageWrapper>
      ) : (
        <CommonStyle>
          {searchDetails?.length > 0 ? (
            <SplitStoresHyperlocalView
              products={searchDetails}
              searchTerm={searchTerm}
              metaInfo={metaInfo}
              setSearchType={setSearchType}
              filtersData={filtersData}
              filter={filter}
              productCount={productCount}
              setFilter={setFilter}
              setLoadMoreData={setLoadMoreData}
              searchType={searchType}
            />
          ) : (
            <DataWrapper className="h1b">{dataVisisble ? '' : 'No Data'}</DataWrapper>
          )}
        </CommonStyle>
      )}
    </PublicLayout>
  )
}

export default Search

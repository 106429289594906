import styled from 'styled-components'
import { CommonStyle } from 'styles/pages/Common'

export const CustomisedMenuWrapper = styled.div`
  width: 360px;
  overflow-y: auto;
  height: 90vh;
  @media screen and (max-width: 768px) {
    height: 50vh;
    width: 100%;
  }
  :hover + ::-webkit-scrollbar {
    display: block;
  }
  ::-webkit-scrollbar {
    padding-top: 10px;
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 10px;
  }
`

export const CustomisedMenuList = styled.div`
  display: flex;
  flex-flow: column;
  gap: 10px;
`
export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .menuName {
    color: var(--primary_text_link_color);
    width: 290px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  svg {
    height: 20px;
    width: 20px;
  }
`
export const CommonFontWrapper = styled(CommonStyle)`
  padding: unset;
  margin-top: 27px;
  display: flex;
  flex-flow: column;
  gap: 15px;

  .css-1086bdv-MuiPaper-root-MuiAccordion-root {
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  & .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(0deg) !important;
  }
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg) !important;
  }
  & .MuiAccordion-region .MuiAccordionDetails-root {
    padding: 0px 16px !important;
  }
`
export const LineDivider = styled.div`
  background: var(--primary_stroke_color_light);
  height: 1px;
  margin: 0 3px;
`
export const OuterLineDivider = styled(LineDivider)`
  margin: 0 24px 0 18px;
`
export const MenuItem = styled.div`
  & .MuiButtonBase-root {
    min-height: 30px;
  }
  & .MuiAccordionSummary-root {
    min-height: 30px;
  }
`
export const AddonProductWrapper = styled.div`
  .addonPrice {
    margin-left: auto;
  }
  .addonData {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 0;
  }
  .addonProductName {
    width: max-content;
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  img {
    width: 40px;
    aspect-ratio: 1;
    object-fit: contain;
    border-radius: 8px;
  }
`

import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import InfiniteScroll from 'react-infinite-scroll-component'
// import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import { Radio, Select, Tooltip, Typography, Pagination } from 'antd'
import SortFilterComponent from 'components/SortFilterBottom'
import { LoginRoute, PdpRoute } from 'constants/routes'
import { useWishListContext } from 'context/WishlistDetailContext'
import { useFilter } from 'context/FliterContext'
import { useTheme } from 'context/themeContext'
import { IFilterOption } from 'interfaces/components'
import { pageData } from 'utils/datalayer/pageData'
import FilterView from 'views/FilterView'
import HeartIcon from 'assets/svg/HeartIcon'
import SelectArrow from 'assets/svg/SelectArrow'
import WishlistedIcon from 'assets/svg/WishlistedIcon'
import {
  ProductSectionContainer,
  ProductSectionWrapper,
  ProductListWrapper,
  ProductWrapper,
  ProductDetailsWrapper,
  ImageWrapper,
} from 'styles/components/ListProducts'
import { SearchSection, SeachWrapper, TypeSelectWrapper, SortByWrapper } from 'styles/pages/SearchPage'
import { CommonStyle } from 'styles/pages/Common'

const { Option } = Select

const ListProducts = ({
  products,
  setFilter,
  productCount,
  productInitialCount,
  filter,
  feedDetails,
  searchTerm,
  filtersData,
  filtersSortData,
  setIsSorting,
  setLoadMoreData,
  isServiceable = true,
  imageRenderType,
}: any) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768)
  const { wishlistIdData, handleAddWishlist, handleRemoveWishlist } = useWishListContext()
  const [selectedOption, setSelectedOption] = useState('')
  const [totalProductCount, setTotalProductCount] = useState<number>(productInitialCount)
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  const { storesData } = useTheme()
  const navigate = useHistory()

  const { setFilterSortProduct } = useFilter() // Use the context here
  const customerId = localStorage.getItem('customerId')
  const handleWishlistClick = async (productId: string, product?: any) => {
    handleAddWishlist(productId, product, storesData?.d?.googleDatalayer)
  }

  const handleRemoveWishlistClick = async (productId: string) => {
    handleRemoveWishlist(productId)
  }

  const [currentPage, setCurrentPage] = useState(filter.page)
  const fetchMoreData = () => {
    const scrollData = {
      event: 'page_scroll',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      scroll_percent: '',
    }
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: scrollData })

    setLoadMoreData(true)
    setFilter({
      ...filter,
      page: filter.page + 1,
      perPage: filter.perPage,
    })
  }
  const handlePageChange = (page: number) => {
    setCurrentPage(page)

    const urlSearchParams = new URLSearchParams(window.location.search)

    if (filter['page'] != 0) {
      urlSearchParams.set('page_id', page.toString())
    } else {
      urlSearchParams.delete('page_id')
    }

    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`
    window.history.pushState({}, '', newUrl)

    // Logic for large screens (>768px)
    if (isLargeScreen) {
      setFilter((prevFilter: any) => ({
        ...prevFilter,
        page,
      }))
    }
    // Logic for small screens (<768px)
    else {
      setLoadMoreData(true)
      setFilter({
        ...filter,
        page: filter.page + 1,
        perPage: filter.perPage,
      })
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const queryString = window.location.search.split('?')[1]
    const searchParams = new URLSearchParams(queryString)
    const sortByData = searchParams.get('sort_by') || ''
    if (sortByData) {
      if (sortByData == 'priceasc') setSelectedOption('discountedPriceOrder:asc')
      else if (sortByData == 'pricedesc') setSelectedOption('discountedPriceOrder:desc')
      else if (sortByData == 'popular') setSelectedOption('relevance:desc')
    }
  }, [])

  useEffect(() => {
    if (isLargeScreen) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setTotalProductCount(productInitialCount)
    } else {
      if (filter?.page > 0) {
        setTotalProductCount(products.length)
      }
    }
  }, [products])

  const handleOptionChange = (value: string, label: string) => {
    setSelectedOption(value)

    const createObj = value.split(':')
    const myObj = {
      [createObj[0]]: createObj[1],
    }

    setFilterSortProduct(myObj)

    // Update URL with selected sorting option
    const urlSearchParams = new URLSearchParams(window.location.search)
    if (createObj[0] == 'relevance') {
      urlSearchParams.set('sort_by', 'popular')
    } else {
      urlSearchParams.set('sort_by', 'price' + createObj[1])
    }

    const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}`
    window.history.pushState({}, '', newUrl)

    const dataToPush = {
      event: 'sort_interactions',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      category: 'na',
      cta_text: label?.toLowerCase(),
      section_name: 'sort_by',
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })
  }

  const [error, setError] = useState(false)

  const handleError = () => {
    setError(true)
  }
  const renderProductList = () => (
    <ProductListWrapper>
      {products?.map((product: any, index: number) => {
        const imageRatio = storesData?.d?.imageSize == 'Square' ? 'w_300,h_300' : 'w_300,h_450'

        let imgUrl =
          product?.imgUrl?.startsWith('http://') || product?.imgUrl?.startsWith('https://')
            ? product?.imgUrl
            : `https://cdnaz.plotch.io/image/upload/${imageRatio}${product?.imgUrl}`
        if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes(imageRatio)) {
          imgUrl = imgUrl?.split('/upload/').join(`/upload/${imageRatio}/`)
          imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
        }

        imgUrl = imgUrl + '?product_id=' + product?.productId + '&s=1'
        let imgUrlRenderType = ''
        if (imageRenderType && imageRenderType !== undefined) {
          imgUrlRenderType = '&tf=' + imageRenderType
        }

        imgUrl = imgUrl + imgUrlRenderType

        const productName = product?.productName
          .replace(/[^a-zA-Z ]/g, '')
          .split(' ')
          .join('-')
        return (
          <ProductWrapper
            key={index}
            className="product-wrapper"
            onClick={() => {
              navigate.push(
                PdpRoute.path
                  .replace(':name', productName ? productName : 'product')
                  .replace(':id', product?.productId),
              )
              localStorage.setItem('productId', product?.productId)
              localStorage.setItem('productName', product?.productName)
            }}
          >
            <ImageWrapper>
              <img
                src={error ? imgUrl : imgUrl}
                onError={handleError}
                onLoad={() => setError(false)}
                style={error ? { minHeight: '80%', height: '275px' } : {}}
              />
            </ImageWrapper>

            <ProductDetailsWrapper>
              <div className="heading-wrapper">
                <div className="heading-details">
                  <Typography.Text ellipsis={{ tooltip: product?.vendorName }} className="h1b subCategory">
                    {product?.vendorName.toLowerCase()}
                  </Typography.Text>
                  {isServiceable === true &&
                    (wishlistIdData[product?.productId] ? (
                      <WishlistedIcon
                        className="wishlist-icon"
                        onClick={(e: any) => {
                          e.stopPropagation()
                          if (customerId) {
                            handleRemoveWishlistClick(product?.productId)
                          }
                        }}
                      />
                    ) : (
                      <HeartIcon
                        className="wishlist-icon 123"
                        onClick={(e: any) => {
                          e.stopPropagation()
                          const queryString = window.location.search.split('?')[1]

                          if (customerId) {
                            handleWishlistClick(product?.productId, product)
                          } else {
                            navigate.push(
                              LoginRoute.path +
                                `?redirect=searchresults/searchresults?${encodeURIComponent(queryString)}`,
                            )
                          }
                        }}
                      />
                    ))}
                </div>

                <Tooltip title={product.productName}>
                  <label className="h3r product-name">
                    {/* {product.productName} */}
                    {product.productName > 33
                      ? `${product.productName.toLowerCase()?.slice(0, 33)} ...`
                      : product.productName.toLowerCase()}
                  </label>
                </Tooltip>
              </div>
              <div className="discount-details">
                {product?.regularPrice === product?.discountedPrice ? (
                  <>
                    <div className="after-discount h1b">
                      &#8377;
                      {product?.regularPrice}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="after-discount h1b">
                      &#8377;
                      {product?.discountedPrice}
                    </div>
                    <div className="before-discount h3r">
                      <span> &#8377; {product.regularPrice} </span>
                    </div>
                  </>
                )}

                {product?.discountPercentage > 0 ? (
                  <div className="discount-percentage h4r">
                    {product.discountPercentage ? `${product.discountPercentage}% OFF` : null}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </ProductDetailsWrapper>
          </ProductWrapper>
        )
      })}
    </ProductListWrapper>
  )
  return (
    <ProductSectionContainer>
      <FilterView filtersData={filtersData} />
      <ProductSectionWrapper>
        <SortByWrapper>
          <SeachWrapper>
            {feedDetails?.feedName ? (
              <SearchSection className="h1b searchTitle">{feedDetails.feedName}</SearchSection>
            ) : window.location.pathname?.includes('/products-near-me') ||
              window.location.pathname?.includes('/all-products') ? (
              <SearchSection className="h1b searchTitle">
                {window.location.pathname.split('-').join(' ').replace('/', '')}
              </SearchSection>
            ) : (
              window.location.pathname.includes('/seller') && (
                <SearchSection className="h1b searchTitle">Products</SearchSection>
              )
            )}
            <SearchSection className="h1b searchTitle">
              {searchTerm ? 'Your search results for "' + searchTerm + '"' : ''}
            </SearchSection>

            {storesData?.d?.ondcEnabled == 1 &&
              (productInitialCount > 0 ? (
                <SearchSection
                  className={`${window.matchMedia('(max-width: 768px)').matches ? 'h3r' : 'h2r'} search_count`}
                >
                  Showing {totalProductCount} products
                </SearchSection>
              ) : null)}
          </SeachWrapper>
          <TypeSelectWrapper>
            <Select
              defaultValue={'Sort by'}
              style={{ width: 90 }}
              suffixIcon={<SelectArrow />}
              onChange={(value, option) => handleOptionChange(value, (option as any).label)}
              // onChange={handleOptionChange}
              className="select_sort"
              optionLabelProp="label"
              popupMatchSelectWidth={false}
              value={selectedOption ? selectedOption : 'Sort by'}
            >
              {filtersSortData?.map((filterItems: IFilterOption) => {
                const itemName = filterItems?.name.split(':')
                const optionValue = `${filterItems.esFilterName}:${filterItems.orderBy}`
                return (
                  <Option
                    key={filterItems.esFilterName + filterItems.orderBy}
                    value={optionValue}
                    label={filterItems.name}
                  >
                    <Radio checked={selectedOption === optionValue} />
                    {itemName.length > 1 ? `${itemName[0]} (${itemName[1].trim()})` : itemName[0]}
                  </Option>
                )
              })}
            </Select>
          </TypeSelectWrapper>
        </SortByWrapper>

        {isLargeScreen ? (
          <>
            {products.length > 0 ? renderProductList() : 'No Data'}
            <div className="pagination">
              <Pagination
                current={currentPage}
                pageSize={filter.perPage}
                total={productCount}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
              {/* <Pagination
                total={productCount}
                defaultCurrent={currentPage}
                pageSize={filter.pageSize}
                onChange={handlePageChange}
                hideOnSinglePage

              /> */}
            </div>
          </>
        ) : (
          <InfiniteScroll
            dataLength={products.length}
            next={fetchMoreData}
            hasMore={products.length < productCount}
            loader={''}
            scrollThreshold={0.98}
            endMessage={''}
          >
            {renderProductList()}
          </InfiniteScroll>
        )}
        <CommonStyle>
          <SortFilterComponent
            filtersSortData={filtersSortData}
            handleOptionChange={handleOptionChange}
            setIsSorting={setIsSorting}
            filtersData={filtersData}
          />
        </CommonStyle>
      </ProductSectionWrapper>
    </ProductSectionContainer>
  )
}

export default ListProducts

import { useHistory } from 'react-router-dom'
import ListTickets from 'views/ListTickets'
import BackArrow from 'assets/svg/BackArrow'
import { ManageAddressWrapper, BackWrapper, CheckoutPageWrapper, CheckoutWrappers } from 'styles/pages/MyAccount'
import { CommonStyle } from 'styles/pages/Common'
// import PrivateLayout from 'components/Layouts/private'
import PublicLayout from 'components/Layouts/public'

const MyTickets = () => {
  const navigate = useHistory()

  const handleBack = () => {
    navigate.goBack()
  }

  return (
    <PublicLayout>
      <CheckoutPageWrapper>
        <CheckoutWrappers>
          <ManageAddressWrapper>
            <CommonStyle>
              <BackWrapper className="padding_full_page" onClick={handleBack}>
                <BackArrow />
                <div className="h1b">TICKETS</div>
              </BackWrapper>
            </CommonStyle>
            <CommonStyle>
              <ListTickets />
            </CommonStyle>
          </ManageAddressWrapper>
        </CheckoutWrappers>
      </CheckoutPageWrapper>
    </PublicLayout>
  )
}

export default MyTickets

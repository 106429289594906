import * as yup from 'yup'

const LOGIN_VALIDATION_SCHEMA = yup.object().shape({
  mobile: yup
    .string()
    .required('Mobile Number is required')
    .max(10, 'max 10 digits')
    .matches(/^[6789]\d{9}$/, 'A valid Mobile Number is required'),
})
const ADDRESS_VALIDATION_SCHEMA = yup.object().shape({
  first_name: yup.string().required('First name is required').matches(/^\S/, 'First character cannot be a space'),
  last_name: yup.string().required('Last name is required').matches(/^\S/, 'First character cannot be a space'),
  mobile: yup
    .string()
    .required('Mobile Number is required')
    .max(10, 'max 10 digits')
    .matches(/^[6789]\d{9}$/, 'A valid Mobile Number is required'),
  pincode: yup.string().required('Pincode is required').matches(/^\S/, 'First character cannot be a space'),
  country: yup.string().required('Country is required').matches(/^\S/, 'First character cannot be a space'),
  flat: yup
    .string()
    .required('Flat / House no / Floor / Building is required')
    .matches(/^\S/, 'First character cannot be a space'),
  area: yup
    .string()
    .required('Area / Sector / Locality is required')
    .matches(/^\S/, 'First character cannot be a space'),
  email: yup
    .string()
    .required('Email is required')
    .matches(/^\S/, 'First character cannot be a space')
    .email('Please enter a valid Email'),
  city: yup.string().required('City / District is required').matches(/^\S/, 'First character cannot be a space'),
  state: yup.string().required('State is required').matches(/^\S/, 'First character cannot be a space'),
})

const PROFILE_VALIDATION_SCHEMA = yup.object().shape({
  first_name: yup.string().required('First name is required').matches(/^\S/, 'First character cannot be a space'),
  last_name: yup.string().required('Last name is required').matches(/^\S/, 'First character cannot be a space'),
  mobile: yup
    .string()
    .required('Mobile Number is required')
    .max(10, 'max 10 digits')
    .matches(/^[6789]\d{9}$/, 'A valid Mobile Number is required'),
  email: yup
    .string()
    .required('Email is required')
    .matches(/^\S/, 'First character cannot be a space')
    .email('Please enter a valid Email'),
  alt_email: yup.string().email('Please enter a valid Email'),
})

const OTP_LOGIN_VALIDATION = yup.object({
  otp0: yup.string().required(),
  otp1: yup.string().required(),
  otp2: yup.string().required(),
  otp3: yup.string().required(),
})

export { LOGIN_VALIDATION_SCHEMA, OTP_LOGIN_VALIDATION, ADDRESS_VALIDATION_SCHEMA, PROFILE_VALIDATION_SCHEMA }

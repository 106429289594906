import styled from 'styled-components'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  input {
    font-family: 'Mulish';
    font-size: 14px;
    font-weight: 700;
    :focus-visible {
      outline: var(--primary_stroke_color);
    }
    ::placeholder {
      color: var(--primary_text_color_dark);
      font-family: 'Mulish';
      font-size: 14px;
      font-weight: 700;
    }
  }
`

export const InputContainer = styled.input`
  width: 32px;
  height: 40px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid var(--primary_stroke_color);
`

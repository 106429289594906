import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path fill="#fff" d="M0 0h20v20H0z" />
    <path
      stroke="var(--primary_icon_static_color)"
      // stroke={theme.primaryIconStaticColor}
      // stroke="#121212"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M3.125 6.25h13.75M3.125 10h13.75M3.125 13.75h13.75"
    />
  </svg>
)
export default SvgComponent

import styled from 'styled-components'
export const ConfirmationMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  svg {
    path {
      stroke-width: 0.5px;
    }
    width: 48px;
    height: 48px;
  }
`
export const TrackMessageWrapper = styled(ConfirmationMessageWrapper)`
  padding: 44px 0 10px;
`
export const OrderMessage = styled.div`
  color: var(--primary_success_color);
`
export const FailureMessage = styled.div`
  color: var(--primary_brand_color);
`
export const PendingMessage = styled.div`
  color: var(--primary_caution_color);
`

import { BottomNavbarContainer, NavItems, DataWrapper, NavWrapper, IconWrapper } from 'styles/components/Navbar'
import { NavLink } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import { navData, navDataNonOndc } from '../navData'
import { pageData } from 'utils/datalayer/pageData'
import { useTheme } from 'context/themeContext'
import { useEffect, useState } from 'react'

const NavBottom = () => {
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const { themedata, storesData } = useTheme()
  const [navigationData, setNavigationData] = useState(navData)
  const [msiteNavigationType, setMsiteNavigationType] = useState('')

  useEffect(() => {
    if (themedata?.d?.objectDetails?.footer?.msiteFooterNavbar?.msiteNavigationType !== undefined) {
      setMsiteNavigationType(themedata?.d?.objectDetails?.footer?.msiteFooterNavbar?.msiteNavigationType)
    }
  }, [themedata])

  useEffect(() => {
    if (msiteNavigationType != '') {
      if (msiteNavigationType == 'type2') setNavigationData(navData)
      else if (msiteNavigationType == 'type1') setNavigationData(navDataNonOndc)
    }
  }, [msiteNavigationType])

  const handleNavClick = (item: string) => {
    if ((item === 'Wishlist' || item === 'Account' || item == 'Cart') && !localStorage.getItem('customerId')) {
      const dataSignInToPush = {
        event: 'navigation_interaction',
        page_type: GDL_PAGE_TYPE, // Use the page type captured during focus
        User_Id: loggedIn,
        User_Status: loggedInStatus,
        cta_text: 'sign_in',
        select_type: 'bottom',
        section_name: 'sign_in',
      }
      storesData?.d?.googleDatalayer &&
        storesData?.d?.googleDatalayer == 1 &&
        TagManager.dataLayer({ dataLayer: dataSignInToPush })
    } else {
      const dataToPush = {
        event: 'navigation_interaction',
        page_type: GDL_PAGE_TYPE,
        User_Id: loggedIn,
        User_Status: loggedInStatus,
        cta_text: item,
        select_type: 'bottom',
        section_name: item,
      }

      storesData?.d?.googleDatalayer &&
        storesData?.d?.googleDatalayer == 1 &&
        TagManager.dataLayer({ dataLayer: dataToPush })
    }
  }

  return (
    <BottomNavbarContainer>
      {navigationData.map((item, index) => (
        <DataWrapper key={index}>
          <NavLink
            to={item?.link}
            className={location.pathname == item.link ? 'active' : 'inactive'}
            onClick={() => handleNavClick(item?.title)}
          >
            <NavWrapper>
              <IconWrapper>{item?.icon}</IconWrapper>
              <NavItems>{item?.title}</NavItems>
            </NavWrapper>
          </NavLink>
        </DataWrapper>
      ))}
    </BottomNavbarContainer>
  )
}

export default NavBottom

import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { BackWrapperDisplay, ErrorMessage, TextWrapper, TextLabel, TextWrappers } from 'styles/pages/Login'
import Button from 'components/Button'
import TextInput from 'components/TextField'
import AccountMenu from 'components/AccountMenu'
import MobileInput from 'components/MobileInput'
import APIS from 'constants/api'
// import { LoaderContext } from 'context/loader'
import { SnackBarContext } from 'context/SnackBarContext'
import usePost from 'hooks/usePost'
import useGet from 'hooks/useGet'
import { IOption } from 'interfaces/pages'
import { PROFILE_VALIDATION_SCHEMA } from 'utils/validations/loginValidation'
import BackArrow from 'assets/svg/BackArrow'
import LocationIcon from 'assets/svg/LocationIcon'
import { CommonStyle } from 'styles/pages/Common'
import {
  ManageProfileFormWrapper,
  DetailWrapper,
  FormFieldsWrapper,
  TabsName,
  TabsWrapper,
  TopWrapper,
} from 'styles/views/SaveAddress'
import { BottomButtonWrapper, ManageAddressWrapper } from 'styles/pages/Profile'
import { AccountWrappers } from 'styles/pages/MyAccount'
import { genderOptions } from './genderOptions'
import { ManageAddressRoute, MyAccountRoute } from 'constants/routes'
import TagManager from 'react-gtm-module'
import { pageData } from 'utils/datalayer/pageData'
import { useTheme } from 'context/themeContext'

const ProfileView: React.FC = () => {
  const customerId = localStorage.getItem('customerId')
  const mobileNumber = localStorage.getItem('mobile')
  const navigate = useHistory()
  const [label, setLabel] = useState()
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const { storesData } = useTheme()
  // const { setLoader } = useContext(LoaderContext)
  const { setShowSnackBar } = useContext(SnackBarContext)
  const { mutateAsync } = usePost()

  const handleBack = () => {
    navigate.goBack()
  }

  const [activeTab, setActiveTab] = useState(0)

  const handleTabClick = (index: number, item: any) => {
    setActiveTab(index)
    setLabel(item.name)
  }

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(PROFILE_VALIDATION_SCHEMA),
    defaultValues: {
      mobile: mobileNumber,
      first_name: '',
      last_name: '',
      email: '',
      alt_email: '',
      gender: label,
    },
  })
  const { refetch: refetchProfile, data } = useGet('profile-data', `${APIS.GET_PROFILE}?customerId=${customerId}`)

  useEffect(() => {
    if (customerId) {
      refetchProfile()
    }
  }, [])

  useEffect(() => {
    if (data) {
      data?.d?.gender == 'Female' ? setActiveTab(1) : data?.d?.gender == 'Other' ? setActiveTab(2) : setActiveTab(0)
      setValue('mobile', data?.d?.mobile)
      setValue('first_name', data?.d?.firstName)
      setValue('last_name', data?.d?.lastName)
      setValue('email', data?.d?.email)
      setValue('alt_email', data?.d?.orderEmailId)
      setValue('gender', label)
    }
  }, [data])

  const submitProfileForm = async (data: any) => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.SAVE_PROFILE,
        payload: {
          mobile: mobileNumber,
          firstName: data?.first_name,
          lastName: data?.last_name,
          email: data?.email,
          orderEmailId: data?.alt_email,
          customerId: customerId,
          gender: label,
        },
      })
      if (res) {
        //setLoader(false)
        const profileSaveData = {
          event: 'profile_interaction',
          page_type: GDL_PAGE_TYPE,
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          cta_text: 'save_details',
        }
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: profileSaveData })
        setShowSnackBar({ open: true, message: `${res?.m}`, severity: '' })
        navigate.push(MyAccountRoute.path)
      }
    } catch (error: any) {
      //setLoader(false)

      setShowSnackBar({ open: true, message: `${error?.res?.m}`, severity: 'error' })

      return error
    }

    return data
  }

  const menuOptions: IOption[] = [
    {
      key: '2',
      icon: <LocationIcon />,
      name: 'Manage Addresses',
      description: 'See all your saved addressess',
      link: ManageAddressRoute.path,
    },
  ]
  return (
    <AccountWrappers>
      <CommonStyle>
        <BackWrapperDisplay className="padding_full_pages h1b" onClick={handleBack}>
          <BackArrow />
          MANAGE PROFILE
        </BackWrapperDisplay>
        <ManageProfileFormWrapper onSubmit={handleSubmit(submitProfileForm)}>
          <FormFieldsWrapper className="form-margin">
            <TopWrapper>
              <TabsWrapper>
                {genderOptions?.map((item: any, index: number) => {
                  return (
                    <TabsName key={index} onClick={() => handleTabClick(index, item)} isActive={activeTab === index}>
                      {item?.name}
                    </TabsName>
                  )
                })}
              </TabsWrapper>
            </TopWrapper>
            <DetailWrapper className="name-container">
              <TextWrappers isError={errors?.state?.message ? true : false}>
                <TextInput placeholder="First name *" value={data?.d?.firstName} control={control} name="first_name" />
                <TextLabel className="h3b profileFields">First Name</TextLabel>
                <ErrorMessage className="h3r">{errors?.first_name?.message}</ErrorMessage>
              </TextWrappers>
              <TextWrappers isError={errors?.state?.message ? true : false}>
                <TextInput placeholder="Last name *" value={data?.d?.lastName} control={control} name="last_name" />
                <TextLabel className="h3b profileFields">Last Name</TextLabel>
                <ErrorMessage className="h3r">{errors?.last_name?.message}</ErrorMessage>
              </TextWrappers>
            </DetailWrapper>
            <TextWrapper>
              <MobileInput
                control={control}
                name="mobile"
                value={data?.d?.mobile}
                className="mobileInput"
                disabled={true}
              />
              <TextLabel className="h3b profileFields">Phone</TextLabel>
              <ErrorMessage className="h3r">{errors?.mobile?.message}</ErrorMessage>
            </TextWrapper>
            <TextWrappers isError={errors?.email?.message ? true : false}>
              <TextInput placeholder="Email *" control={control} value={data?.d?.email} name="email" />
              <TextLabel className={`h3b profileFields`}>Email</TextLabel>
              <ErrorMessage className="h3r">{errors?.email?.message}</ErrorMessage>
            </TextWrappers>
            <TextWrappers>
              <TextInput placeholder="Alt Email *" control={control} value={data?.d?.orderEmailId} name="alt_email" />
              <TextLabel className="h3b profileFields">Alt Email</TextLabel>
              <ErrorMessage className="h3r">{errors?.alt_email?.message}</ErrorMessage>
            </TextWrappers>
            <ManageAddressWrapper>
              <AccountMenu options={menuOptions} />
            </ManageAddressWrapper>
            {/* <ButtonWrapper>
              <Button label="Save" variant="contained" onClick={handleSubmit} type="submit" />
            </ButtonWrapper> */}
          </FormFieldsWrapper>
          <BottomButtonWrapper>
            <Button label="Save" variant="contained" onClick={handleSubmit} type="submit" />
          </BottomButtonWrapper>
        </ManageProfileFormWrapper>
      </CommonStyle>
    </AccountWrappers>
  )
}

export default ProfileView

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      stroke="#121212"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M8.002 2.5a5.501 5.501 0 1 0 0 11.002 5.501 5.501 0 0 0 0-11.002Z"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M6.251 6.322s.026-.547.612-1.018c.347-.28.763-.36 1.139-.366.341-.005.646.052.829.139.313.149.921.512.921 1.284 0 .813-.531 1.182-1.137 1.588-.605.407-.77.847-.77 1.303"
    />
    <path fill="#121212" d="M7.818 11.508a.628.628 0 1 0 0-1.256.628.628 0 0 0 0 1.256Z" />
  </svg>
)
export default SvgComponent

import styled from 'styled-components'

export const NotFoundPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 37px;
  padding: 100px 16px;
  background-color: var(--primary_box_fill_color);
  border: 1px solid var(--primary_stroke_color_light);
  width: 360px;
  min-height: 70%;
  padding: 20px;
  @media (max-width: 768px) {
    padding-top: 20px;
    width: 100%;
    border: none;
  }
`
export const TermsWrapper = styled.div`
  background-color: var(--primary_box_fill_color);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 408px;
  min-height: 70%;
  padding: 20px 24px 0;
  border: 1px solid var(--primary_stroke_color_light);

  @media (max-width: 768px) {
    gap: 16px;
    padding: 20px;
    width: 360px;
    border: none;
  }
  @media (max-height: 600px) {
    min-height: 85%;
  }
`
export const TermsWrappers = styled.div`
  background-color: var(--primary_box_fill_color);
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 70%;
  padding: 20px 24px 0;

  @media (max-width: 768px) {
    gap: 16px;
    padding: 0px 20px;
  }
  @media (max-height: 600px) {
    min-height: 85%;
  }
`
export const EmptyCartPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 200px;
  padding: 100px 16px;
  background-color: var(--primary_box_fill_color);
  width: 360px;
  min-height: 70%;
  padding: 20px;
  border: 1px solid var(--primary_stroke_color_light);

  @media (max-width: 768px) {
    padding-top: 20px;
    width: 100%;
    border: none;
  }
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  .small {
    width: 160px;
  }
  svg {
    width: 64px;
    height: 64px;
  }
`
export const LogoWrapper = styled.div`
  padding: 9px 20px;
  display: flex;
  img {
    width: 160px;
    height: 46.135px;
  }
`
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`
export const DetailMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 60px;
`
export const ContactUsContainer = styled.div`
  display: flex;
  padding-bottom: 60px;
  input,
  button,
  textarea {
    width: 100%;
  }
  .form-group {
    margin-bottom: 15px;
  }
  label {
    display: inline-block;
    max-width: 90%;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .form_card .min-max,
  .form_card .inputFields,
  .form_card .sideBySide,
  .form_card .inputField,
  .form_card #submitBtn,
  .form_card .rate,
  .form_card .slidecontainer,
  .form_card .input-group {
    width: 100% !important;
  }
  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075); */
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    /* -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s; */
    /* -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; */
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  @media (max-width: 768px) {
    gap: 20px;
    flex-direction: column;

    .form_card .min-max,
    .form_card .inputFields,
    .form_card .sideBySide,
    .form_card .inputField,
    .form_card #submitBtn,
    .form_card .rate,
    .form_card .slidecontainer,
    .form_card .input-group {
      width: 100% !important;
    }
  }
`
export const DataWrapper = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`
export const Description = styled.div`
  color: var(--primary_text_color_dark);
  /* H1-B */
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
  p {
    margin: 0;
  }
`
export const TermsDescription = styled.div`
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  p {
    margin: 0;
  }
`
export const ContactUsDescription = styled.div`
  color: var(--primary_text_color_dark);
  /* font-family: 'Mulish'; */
  padding: 20px;
  button {
    padding: 0 !important;
  }
`
export const Heading = styled.div`
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 64px;
  font-weight: 700;
`
export const SubHeading = styled.div`
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 32px;
  font-weight: 700;
  text-align: center;
`
export const Details = styled.div`
  width: 100%;
  max-width: 295px;
  color: var(--primary_text_color_dark);
  text-align: center;
  /* H1-B */
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
`

export const ProductNotFoundWrapper = styled.div`
  position: relative;
  transform: translateY(50%);
`

import React, { useContext, useEffect, useState } from 'react'
import VegImage from 'assets/images/veg-img.png'
import NonVegImage from 'assets/images/non-veg.png'
// import { LoaderContext } from 'context/loader'
import { SnackBarContext } from 'context/SnackBarContext'
import {
  MenuContainer,
  MobileCheckoutButtonwrapper,
  DetailsWrap,
  ImageWrap,
  ButtonWrap,
  CheckoutButtonwrapper,
} from 'styles/views/StoreHyperlocalView'
import {
  StoreMainWrapper,
  ImageWrapper,
  MainBodyWrapper,
  RecomendedContainer,
  MenuTypeWrapper,
  MainBodyFilterContainer,
} from 'styles/views/SplitStoresHyperlocalView'
import { useFilter } from 'context/FliterContext'
import Line from 'assets/svg/Line'
import Button from 'components/Button'
import { Pagination } from 'antd'
import DownArrow from 'assets/svg/DownArrow'
import StoreFilter from './storeFilter'
import { CheckoutRoute, PdpRoute } from 'constants/routes'
import { useHistory } from 'react-router-dom'
import APIS from 'constants/api'
import usePost from 'hooks/usePost'
import { useHomeContext } from 'context/HomeContext'
import { useWishListContext } from 'context/WishlistDetailContext'
import { DataWrapper } from 'styles/pages/SearchPage'

const ItemTabView = ({
  nonVegFilter,
  filtersData,
  products,
  searchTerm,
  metaInfo,
  productCount,
  filter,
  setFilter,
  setLoadMoreData,
}: any) => {
  // const [error, setError] = useState(false)
  const [currentPage, setCurrentPage] = useState(filter.page)
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768)
  const { mutateAsync } = usePost()
  const { setFilterProduct } = useFilter()
  const navigate = useHistory()
  const { setCartCount, cartCount, visitorData } = useHomeContext()
  const { cartIdData } = useWishListContext()
  const [cartData, setCartData] = useState([])

  const { setShowSnackBar } = useContext(SnackBarContext)
  // const { setLoader } = useContext(LoaderContext)
  const customerId = localStorage.getItem('customerId')

  const getCartDetails = async () => {
    // //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.GET_CART_DETAILS,
        payload: {
          customerId: customerId,
        },
      })
      if (res) {
        setCartCount(res?.d?.cartItemsCount)
        setCartData(res?.d?.products)

        // wishlistedProduct && setWishlistIdData(wishlistedProduct)
        // //setLoader(false)
      }
    } catch (error) {
      // //setLoader(false)

      return error
    }
  }

  const handleFilterClick = (filter: any) => {
    setFilterProduct(filter)
  }

  // const handleError = () => {
  //   setError(true)
  // }
  const searchParams = new URL(window.location.href).searchParams
  const storeName = searchParams.get('store_name')

  const handlePageChange = (page: number) => {
    setCurrentPage(page)

    searchParams.set('page_id', page.toString())

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    window.history.pushState({}, '', newUrl)

    if (isLargeScreen) {
      setFilter((prevFilter: any) => ({
        ...prevFilter,
        page,
      }))
    } else {
      setLoadMoreData(true)
      setFilter({
        ...filter,
        page: filter.page + 1,
        perPage: filter.perPage,
      })
    }
  }

  useEffect(() => {
    // Update the isLargeScreen state whenever the window size changes
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768)
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    getCartDetails()
  }, [])
  // const checkProduct = cartIdData.find((item: any) => {
  // return item == productId
  // })

  const addToCart = async (productId: string) => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.ADD_TO_CART,
        payload: {
          customerId: customerId ? customerId : false,
          products: [
            {
              productId: productId,
              qty: 1,
              addons: [],
            },
          ],
          flag: 1,
        },
      }).then()
      if (res) {
        //setLoader(false)
        setCartCount(cartCount + 1)
        setShowSnackBar({ open: true, message: `Added To Cart`, severity: '' })
        getCartDetails()
      }
    } catch (error: any) {
      //setLoader(false)
      const errorMessage = error?.response?.data || 'Something Went Wrong'
      setShowSnackBar({ open: true, message: `${errorMessage?.m}`, severity: '' })
    }
  }

  const updateItemCount = async (productId: string, quantity: number) => {
    if (quantity === 0) {
      // handleRemoveProduct(productId)
    } else {
      //setLoader(true)
      try {
        const res = await mutateAsync({
          url: APIS.UPDATE_ITEMS,
          payload: {
            cartInstanceId: '1',
            catalogId: 'WXjfElbnz5',
            customerId: '',
            accountId: '',

            products: [
              {
                productId: productId,
                qty: quantity,
                reseller_price: 0,
              },
            ],
          },
        })
        if (res) {
          //setLoader(false)
          getCartDetails()
          // refetchOndcQuoteDetails()
        }
      } catch (error: any) {
        //setLoader(false)
        const errorMessage = error?.response?.data || 'Something Went Wrong'
        setShowSnackBar({ open: true, message: `${errorMessage?.m}`, severity: '' })
      }
    }
  }

  // Added the helper function to get the quantity of a paticular product in the cart based on productId
  const getQuantityByProductId = (productId: number) => {
    const product: any = cartData.length > 0 && cartData.find((item: any) => item?.productId === productId)
    return product && product.qty
  }

  return (
    <StoreMainWrapper>
      {nonVegFilter && (
        <MenuTypeWrapper>
          {nonVegFilter?.filtersData.map((filter: { filterItem: number; productCount: number }, index: number) => {
            if (filter.filterItem === 0) {
              return (
                <MenuContainer key={index} onClick={() => handleFilterClick(filter)}>
                  <label className="menu h1r">Veg</label>
                  <img src={VegImage} alt="Veg" />
                </MenuContainer>
              )
            } else if (filter.filterItem === 1) {
              return (
                <MenuContainer key={index} onClick={() => handleFilterClick(filter)}>
                  <label className="menu h1r">Non Veg</label>
                  <img src={NonVegImage} alt="Non Veg" />
                </MenuContainer>
              )
            }

            return null // Return nothing if filterItem is not 0 or 1
          })}
        </MenuTypeWrapper>
      )}

      <MainBodyFilterContainer>
        <StoreFilter filtersData={filtersData} />
        {products && products?.length > 0 ? (
          <MainBodyWrapper>
            <RecomendedContainer>
              <label className="ultra-bold mobile-item">{searchTerm ? 'Items from "' + searchTerm + '"' : ''}</label>
              <label className="ultra-bold web-item">
                {storeName ? 'Items from "' + storeName + '"' : 'Items from "All Stores"'}
              </label>
            </RecomendedContainer>

            <div className="content-list">
              {products?.map((item: any, index: number) => {
                const imageRatio = metaInfo?.image_render_type == 'square' ? 'w_300,h_300' : 'w_300,h_450'

                let imgUrl =
                  item?.imgUrl?.startsWith('http://') || item?.imgUrl?.startsWith('https://')
                    ? item?.imgUrl
                    : `https://cdnaz.plotch.io/image/upload/${imageRatio}${item?.imgUrl}`
                if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes(imageRatio)) {
                  imgUrl = imgUrl?.split('/upload/').join(`/upload/${imageRatio}/`)
                  imgUrl = imgUrl.includes('http:') ? imgUrl.replace('http:', 'https:') : imgUrl
                }

                const productName = item?.productName
                  .replace(/[^a-zA-Z ]/g, '')
                  .split(' ')
                  .join('-')
                  .toLowerCase()

                const productIdNumber = Number(item.productId)
                // const qty = item.qty
                return (
                  <div
                    className="content-item"
                    key={index}
                    onClick={() => {
                      localStorage.setItem('productId', item?.productId)
                      navigate.push(
                        PdpRoute.path
                          .replace(':name', productName ? productName : 'product')
                          .replace(':id', item?.productId),
                      )
                    }}
                  >
                    <div className="quantity-wrapper">
                      <ImageWrapper className="defaultImage">
                        <img
                          src={imgUrl}
                          // src={error ? imgUrl : imgUrl}
                          // onError={handleError}
                          // onLoad={() => setError(false)}
                          // style={error ? { minHeight: '80%', height: '275px' } : {}}
                        />
                      </ImageWrapper>

                      {/* <ButtonWrap> */}

                      {cartIdData.includes(productIdNumber) || getQuantityByProductId(productIdNumber) ? (
                        <div className="customizable-wrap">
                          <div className="quantity h1b">
                            <div
                              onClick={(e: any) => {
                                e.stopPropagation()
                                updateItemCount(item?.productId, getQuantityByProductId(productIdNumber) - 1)
                              }}
                            >
                              -
                            </div>
                            <div>{getQuantityByProductId(productIdNumber)}</div>
                            <div
                              onClick={(e: any) => {
                                e.stopPropagation()
                                updateItemCount(item?.productId, getQuantityByProductId(productIdNumber) + 1)
                              }}
                            >
                              +
                            </div>
                          </div>
                          {products?.attachGroups && <div className="h3r custom-heading">Customizable</div>}
                          {/* </div> */}
                        </div>
                      ) : (
                        <ButtonWrap
                          onClick={(e: any) => {
                            e.stopPropagation()
                            addToCart(item?.productId)
                          }}
                        >
                          <Button label="Add" variant="outline" type="submit" className="small" />
                          {products?.attachGroups && <div className="h3r custom-heading">Customizable</div>}
                        </ButtonWrap>
                      )}
                    </div>
                    {/* <div className="quantity-wrapper"> */}

                    <div className="item-details">
                      <ImageWrap>
                        {item?.isNonVeg ? (
                          <img src={item?.isNonVeg === 0 || '' ? VegImage : NonVegImage} />
                        ) : (
                          <img src={VegImage} />
                        )}
                      </ImageWrap>

                      <div className="category-name h1r">
                        {item?.productName.toLowerCase()}
                        {/* {item?.productName.length > 18
 ? `${item?.productName.toLowerCase().slice(0, 18)} ...`
 : item?.productName.toLowerCase()} */}
                      </div>
                      <div className="discount-details">
                        {item?.regularPrice === item?.discountedPrice ? (
                          <div className="after-discount h1b">
                            <span> &#8377; </span>
                            {item?.regularPrice}
                          </div>
                        ) : (
                          <>
                            <div className="after-discount h1b">
                              <span> &#8377; </span>
                              {item?.discountedPrice}
                            </div>
                            <div className="before-discount h3r">
                              <span> &#8377; </span>
                              {item?.regularPrice}
                            </div>
                          </>
                        )}
                        {/* {productDetails?.discountPercentage > 0 ? ( */}
                        <div className="discount-percentage h4b">
                          {item?.discountPercentage}% OFF
                          {/* {discount} % OFF` : null} */}
                        </div>
                        {/* ) : (
 ''
 )} */}
                      </div>
                      {/* <div className="price h1r">
 <span>{price}</span> <span className="original-price">{originalPrice}</span>{' '}
 <span className="discount">{discount} OFF</span>
 </div> */}

                      <DetailsWrap className="h2b">
                        View Details <DownArrow />
                      </DetailsWrap>
                    </div>
                  </div>
                  // )
                  // })}
                  // </>
                )
              })}
            </div>

            {visitorData?.itemsTotalCount > 0 && (
              <CheckoutButtonwrapper>
                <div className="checkout-item-wrap">
                  <label className="item-quantity h1b">
                    {visitorData?.itemsTotalCount} Item{visitorData?.itemsTotalCount > 1 ? '(s)' : ''}
                  </label>
                  <div> |</div>
                  <div className="price-wrap h1b">
                    {' '}
                    <span> &#8377; </span>
                    {visitorData?.itemPriceTotal}
                  </div>
                </div>
                {/* <Button label="Go To Cart " variant="outline" type="submit" className="skip" /> */}

                <Line />
                <Button
                  label="Checkout"
                  variant="contained"
                  type="submit"
                  className="small"
                  onClick={() => {
                    navigate.push(CheckoutRoute.path)
                  }}
                />
              </CheckoutButtonwrapper>
            )}

            {/* <MobileStoreItemsHyperLocalView /> */}
            {productCount > 48 ? (
              <>
                <div className="load-more">
                  <Button
                    label="Load More..."
                    variant="contained"
                    className=" h2b loadBtn"
                    onClick={handlePageChange}
                  />
                </div>
                <div className="pagination">
                  <Pagination
                    current={currentPage}
                    pageSize={filter.perPage}
                    total={productCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </MainBodyWrapper>
        ) : (
          <DataWrapper className="h1b">No Data</DataWrapper>
        )}
      </MainBodyFilterContainer>
      {visitorData?.itemsTotalCount > 0 && (
        <MobileCheckoutButtonwrapper>
          <div className="checkout-item-wrap">
            <label className="item-quantity h1b">
              {visitorData?.itemsTotalCount} Item{visitorData?.itemsTotalCount > 1 ? '(s)' : ''}
            </label>
            <div> |</div>
            <div className="price-wrap h1b">
              {' '}
              <span> &#8377; </span>
              {visitorData?.itemPriceTotal}
            </div>
          </div>

          {/* <Button label="Go To Cart " variant="outline" type="submit" className="skip" /> */}

          <Line />
          <Button
            label="Checkout"
            variant="contained"
            type="submit"
            className="small"
            onClick={() => {
              navigate.push(CheckoutRoute.path)
            }}
          />
        </MobileCheckoutButtonwrapper>
      )}
    </StoreMainWrapper>
  )
}

export default ItemTabView

// PizzaComponents.js
import DownArrow from 'assets/svg/DownArrow'
import React, { useState } from 'react'
import { DetailsWrap, ImageWrapper, ImageWrap } from 'styles/views/StoreHyperlocalView'

const ContentItem = ({ name, price, type, originalPrice, discount, image }: any) => {
  const [count, setCount] = useState(1)

  const decrement = () => {
    setCount((prevCount) => (prevCount > 1 ? prevCount - 1 : 1))
  }
  const increment = () => {
    setCount((prevCount) => prevCount + 1)
  }

  return (
    <div className="content-item">
      <div className="quantity-wrapper">
        <ImageWrapper>
          <img src={image} alt={name} />
        </ImageWrapper>
        <div className="quantity h1b">
          <div onClick={decrement}>-</div>
          <div>{count}</div>
          <div onClick={increment}>+</div>
        </div>
        <div className="h3r">Customizable</div>
      </div>
      <div className="item-details">
        <ImageWrap>
          <img src={type} alt={name} />
        </ImageWrap>

        <div className="h1r">{name}</div>
        <div className="discount-details">
          {/* {productDetails?.regularPrice === productDetails?.discountedPrice ? ( */}
          {/* <div className="after-discount h1b">
            <span> &#8377; </span>
            {price}
          </div> */}
          {/* ) : ( */}
          <>
            <div className="after-discount h1b">
              <span> &#8377; </span>
              {price}
            </div>
            <div className="before-discount h3r">
              <span> &#8377; </span>
              {originalPrice}
            </div>
          </>
          {/* )} */}
          {/* {productDetails?.discountPercentage > 0 ? ( */}
          <div className="discount-percentage h4r">
            {discount}% OFF
            {/* {discount} % OFF` : null} */}
          </div>
          {/* ) : (
            ''
          )} */}
        </div>
        {/* <div className="price h1r">
          <span>{price}</span> <span className="original-price">{originalPrice}</span>{' '}
          <span className="discount">{discount} OFF</span>
        </div> */}

        <DetailsWrap className="h2b">
          View Details <DownArrow />
        </DetailsWrap>
      </div>
    </div>
  )
}

const ContentList = ({ items }: any) => (
  <div className="content-list">
    {items.map((item: any, index: number) => (
      <ContentItem key={index} {...item} />
    ))}
  </div>
)

export { ContentItem, ContentList }

import { useLocation } from 'react-router-dom'
import PrivateLayout from 'components/Layouts/private'
import SaveAddressView from 'views/SaveAddress'
import { CommonStyle } from 'styles/pages/Common'

const AddAddressPage = () => {
  const { state } = useLocation<any>()
  return (
    <PrivateLayout>
      <CommonStyle>
        <SaveAddressView addressId={state?.addressId} />
      </CommonStyle>
    </PrivateLayout>
  )
}

export default AddAddressPage

import styled from 'styled-components'

export const PdpDetailViewContainer = styled.div`
  svg.wishlist-icon {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  svg.wishlisted-icon {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .mobile-wishlist-icon {
    display: none;
  }
  .share-icon {
    cursor: pointer;
  }

  .desktop-buy-now {
    border: none;
    padding: unset;
  }
  .buy-now {
    display: none;
  }

  .pdpBtns button {
    width: 100% !important;
  }

  .size-chart {
    cursor: pointer;
  }

  .slider {
    min-width: 100%;
    box-sizing: border-box;
  }
  .ant-carousel .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: var(--primary_text_link_color);
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -10px;
  }
  @media (max-width: 768px) {
    max-height: 86vh;
    .buy-now {
      display: flex;
      position: fixed;
      bottom: 0;
      right: 0;
      width: calc(100vw - 24px);
    }
    .desktop-buy-now {
      display: none;
    }
    .wishlist-icon {
      /* display: none; */
    }
    .share-icon {
      display: none;
    }
    .mobile-wishlist-icon {
      display: block;
    }
  }
`
export const PdpDetailViewWrapper = styled.div`
  display: flex;
  gap: 25px;
  padding-top: 10px;
  .carousel-display {
    display: none;
  }
  .image-list {
    border: 1px solid var(--primary_stroke_color_light);
    cursor: pointer;
  }
  .selected-image {
    border: 1px solid var(--primary_text_link_color);
  }
  @media (max-width: 1200px) {
    gap: 20px;
  }
  @media (max-width: 768px) {
    display: block;
    gap: unset;
    .carousel-display {
      display: block;
      img {
        width: 100%;
      }
    }
  }
`
export const DesktopView = styled.div`
  display: flex;
  gap: 11px;
  @media (max-width: 768px) {
    display: none;
  }
`
export const ImageList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  img {
    width: 96px;
    height: 96px;
  }
`
export const CrousalWrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: relative;
  }
`
export const MobileIconsWrapper = styled.div`
  .share_twitter {
    display: none;
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    padding: 0 10px;
    width: calc(100vw - 60px);

    svg {
      fill: #fff;
    }
    .share_twitter {
      display: block;
    }
  }
`
export const SimilarIconWrapper = styled.div`
  border-radius: 8px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 118px;
`
export const ZoomImage = styled.div`
  position: relative;
  cursor: zoom-in;
  img {
    width: 600px !important;
    object-fit: contain;
  }
  .zoomImageDiv {
    height: 500px !important;
    width: 500px !important;
    background-color: #fff;
  }
  .zoomImageDiv img {
    scale: 1.5;
    transform-origin: 0 0;
  }
  @media (max-width: 1024px) {
    img {
      width: 387px;
      height: 570px;
    }
  }
`
export const ProductContainer = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 344px;
  min-width: 344px;
  @media (max-width: 768px) {
    padding: 15px 16px 80px 19px;
    width: unset;
    max-width: unset;
    min-width: unset;
    /* position: relative; */
  }
`
export const PdpMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 10px 80px;
  min-height: 80vh;
  @media (max-width: 1200px) {
    padding: 10px 30px;
  }
  @media (max-width: 768px) {
    /* overflow-y: auto;
    max-height: 86vh; */
    padding: 10px 0px 60px;
    gap: 20px;
  }
`
export const ProductBasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-top: 12px;
  border-top: 1px solid var(--primary_stroke_color_light);
`
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */

  .detail-product-name {
    width: 100%;
  }
  .sub-title {
    margin-top: 4px;
  }
  .productNameTitle,
  .sub-title {
    text-transform: capitalize;
  }
  @media screen and (max-width: 786px) {
    .detail-product-name {
      max-width: 285px;
    }
  }
`
export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .discount-details {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .discount-percentage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 16px;
    border-radius: 8px;
    background: var(--primary_label_active_fill_color);
    color: #fff;
    font-family: 'Mulish';
    font-size: 10px;
    font-weight: 700;
  }
  .quantity {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 6px;
    width: 72px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid var(--primary_button_stroke_color, #4f4e4d);
    background: var(--primary_box_fill_color);
    color: var(--secondary_label_active_text_color);
  }
  .ship-detail {
    color: var(--primary_text_color_dark);
  }
  .after-discount {
    color: #000;
  }
  .before-discount {
    font-family: 'Mulish';
    font-size: 12px;
    font-weight: 400;
    text-decoration: line-through;
  }
`
export const SizeDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  .size-heading {
    color: #000;
    margin-bottom: 6px;
    text-transform: capitalize;
  }
  .size-name-wrapper {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
  }
  .size-name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid var(--primary_button_stroke_color);
    border-radius: 100%;
    text-transform: uppercase;
    color: #000;
    cursor: pointer;
  }
  .notCircle {
    width: auto;
    max-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px 5px;
  }
  .circleVariant {
    width: 32px !important;
    text-align: center;
    display: inline;
  }
  .mainItemDiv {
    border-radius: 4px;
    width: auto;
  }
  .active {
    border: 1.5px solid var(--primary_brand_color);
    color: var(--primary_brand_color);
  }

  .selected {
    border: 1.5px solid var(--primary_brand_color);
    color: var(--primary_brand_color);
  }

  .size-chart {
    color: var(--primary_text_link_color);
  }
`
export const ReadWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--secondary_text_link_color);
  cursor: pointer;
  /* H4-B */
  font-family: 'Mulish';
  font-weight: 700;
`
export const ColorDetails = styled.div`
  .size-heading {
    color: var(---primary_text_color_dark);
    margin-bottom: 6px;
    text-transform: capitalize;
  }
  .size-name-wrapper {
    display: flex;
    gap: 6px;
  }
  .color-name {
    margin-top: 6px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }
`
export const SizeChartImageWrapper = styled.div`
  img {
    max-width: 100%;
    height: auto;
  }
`
export const ColorContainer = styled.div`
  display: flex;
  gap: 9px;

  .active {
    border: 1.5px solid var(--primary_brand_color);
    color: var(--primary_brand_color);
  }

  .selected {
    border: 1.5px solid var(--primary_brand_color);
    color: var(--primary_brand_color);
  }
`
export const Box1 = styled.div<{ textColor: any }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  border-radius: 100%;
  cursor: pointer;
  background: ${({ textColor }) => `${textColor}`};

  .active {
    border: 1.5px solid var(--primary_brand_color);
    color: var(--primary_brand_color);
  }

  .selected {
    border: 1.5px solid var(--primary_brand_color);
    color: var(--primary_brand_color);
  }

  :hover {
    opacity: 0.5;
  }
`
export const ProductDetailsWrapper = styled.div`
  padding-top: 12px;
  border-top: 1px solid var(--primary_stroke_color_light);
  .prod-desc-heading {
    color: var(--primary_text_color_dark);
  }
`
export const ProductCompleteDetails = styled.div`
  display: grid;
  margin-top: 13px;
  grid-template-columns: 50% 50%;
  /* grid-template-columns: 165px 165px; */
  gap: 8px 15px;
  font-family: 'Mulish';
  .view-store {
    justify-content: flex-end;
    cursor: pointer;
    svg {
      transform: rotate(-90deg);
    }
  }
  .prod-detail-wrap {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--primary_stroke_color_light);
  }
  .heading {
    color: var(--primary_text_color_dark);

    /* H4-R */
    font-weight: 400;
  }
  .desc-detail {
    color: var(--primary_text_color_dark);
    word-wrap: break-word;
    /* margin-top: 1px; */
    margin-top: auto;
  }
  .seller_name {
    margin-top: 4px;
  }
  .attributeValue,
  .seller_name {
    text-transform: capitalize;
  }
  .toggle-view {
    color: var(--secondary_text_link_color);
  }
`
export const SocialIconWrappers = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px;
  .anticon svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
`
export const ProductDesc = styled.div`
  font-family: 'Mulish';
  font-size: 10px;
  .prod-desc-heading {
    color: var(--primary_text_color_dark);
    /* H3-B */
    font-weight: 700;
  }
  .product-description {
    margin-top: 10px;
    width: 100%;
    max-width: 307px;
    color: var(--primary_text_color_dark);
    font-weight: 400;
    text-transform: capitalize;
  }
`

export const ZoomContainer = styled.div`
  .ant-modal-mask {
    background-color: #fff !important;
  }
  @media screen and (max-width: 767px) {
    &.ant-modal {
      border-radius: 0;
      padding: 0;
      margin: 0 !important;
      max-width: 100% !important;
    }
    .ant-modal-content {
      max-width: 100% !important;
      border-radius: 0;
    }
    /* .ant-modal-root .ant-modal-centered .ant-modal {
      height: 100%;
      margin-top: 0px;
      max-width: 100%;
    } */
  }
`
export const ZoomCarousel = styled.div`
  max-width: 500px;
  height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  img {
    aspect-ratio: 1;
    max-width: 100%;
    object-fit: contain;
    height: 450px;
    transform-origin: 0 0;
  }
  .ant-carousel .slick-dots {
    bottom: -2%;
  }
  .ant-carousel .slick-dots li.slick-active {
    width: 16px;
  }
  .ant-carousel .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: var(--primary_text_link_color);
  }
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    color: #000 !important;
    top: 100%;
    transform: translateY(10%);
  }
  @media (max-width: 768px) {
    height: 90vh;
  }

  .zoomBtns {
    position: relative;
    top: 40px;
    justify-content: center;
    gap: 20px;
    display: flex;
    font-size: 20px !important;
    color: gray;
  }
  .slider {
    overflow: auto;
  }
  .zoomBtn .anticon {
    width: 20px;
    height: 20px;
  }
  .scale1 {
    transform: scale(1);
  }
  .scale2 {
    transform: scale(1.5);
  }
  .scale3 {
    transform: scale(2);
  }

  .ant-carousel img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-zoom: none;
    /* user-drag: none; */
    user-select: none;
  }
`

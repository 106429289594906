import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PublicLayout from 'components/Layouts/public'
// import { LoaderContext } from 'context/loader'
import usePost from 'hooks/usePost'
import APIS from 'constants/api'
import { useTheme } from 'context/themeContext'
import PdpDetailView from 'views/PdpDetailView'
import SimilarProducts from 'views/SimilarProducts'
import { CommonStyle } from 'styles/pages/Common'
import { PdpMainWrapper } from 'styles/views/PdpDetailView'
import { useHomeContext } from 'context/HomeContext'
import NotFound from 'components/NotFound'
import TagManager from 'react-gtm-module'
import useGet from 'hooks/useGet'
import { updateDynamicHeaders } from 'services/axiosInstance'
import { pageData } from 'utils/datalayer/pageData'

const PdpPage = () => {
  const [productDetails, setProductDetails] = useState<any>({})
  const [similarProductDetails, setSimilarProductDetails] = useState<any>([])
  // const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  const [productNotFound, setProductNotFound] = useState(false)
  const { setHomeData } = useHomeContext()
  // const customerId = localStorage.getItem('customerId')
  const param: any = useParams()
  const productId = param?.id
  const [widgetDetails, setWidgetDetails] = useState<any>()
  const { themedata, storesData } = useTheme()
  const googleDatalayer = storesData?.d?.googleDatalayer
  const [similarProductWidgets, setSimilarWidget] = useState<any>([])
  const [vendorProducts, setVendorProducts] = useState<any>([])
  const { loggedIn, loggedInStatus, address, GDL_PAGE_TYPE } = pageData()
  // const widgetDetails = themedata?.d?.objectDetails?.productView?.widgetId
  // console.log(widgetDetails)
  // console.log('🚀 ~ PdpPage ~ widgetDetails:', widgetDetails[0]?.widgetId)

  // const widgetDetails = themedata?.d?.objectDetails?.productView?.widgetId?.[0]
  // const similarProductWidgets = widgetDetails
  //   ? widgetDetails?.filter((widget: any) => widget.widgetType === 'similar_products')
  //   : []

  // console.log('similarProductWidgets', similarProductWidgets)
  useEffect(() => {
    if (themedata?.d?.objectDetails?.productView?.widgetId) {
      setWidgetDetails(themedata?.d?.objectDetails?.productView?.widgetId)
    }
  }, [themedata])

  useEffect(() => {
    if (storesData?.d?.ondcEnabled == 1 && widgetDetails?.[0]?.widgetType === 'similar_products') {
      setVendorProducts(widgetDetails ? widgetDetails : [])
    } else {
      setSimilarWidget(widgetDetails ? widgetDetails : [])
    }
  }, [widgetDetails])

  // const fetchData = async (pageUrl: string) => {
  //   //setLoader(true)
  //   try {
  //     const res = await mutateAsync({
  //       url: APIS.PRODUCT_DETAIL,
  //       payload: {
  //         productId,
  //         customerId,
  //         productUrl: pageUrl,
  //       },
  //     })
  //     if (res) {
  //       setProductDetails(res?.d)
  //       //setLoader(false)
  //       setProductNotFound(false)
  //     }
  //   } catch (error) {
  //     //setLoader(false)
  //     // router.push(NotFoundRoute.path)
  //     setProductNotFound(true)
  //   }
  // }

  const { refetch, data } = useGet('fetchData', `${APIS.PRODUCT_DETAIL_V1}`)

  useEffect(() => {
    if (data?.s == 1) {
      setProductDetails(data?.d)
      setProductNotFound(false)
    } else if (data?.s == 0) {
      setProductNotFound(true)
    }
  }, [data])

  useEffect(() => {
    const discount =
      productDetails?.regularPrice && productDetails?.discountedPrice
        ? parseFloat((productDetails.regularPrice - productDetails.discountedPrice).toFixed(2))
        : 0
    const itemCategory = Array.isArray(productDetails?.categoryName)
      ? productDetails?.categoryName[0]?.toLowerCase()
      : productDetails?.categoryName?.toLowerCase()

    let categoryText = 'na'
    if (productDetails?.returnable) {
      categoryText = `Returnable: ${productDetails?.returnable}`
    }

    if (productDetails?.cancellable) {
      categoryText +=
        categoryText !== 'na'
          ? ` | Cancellable: ${productDetails?.cancellable}`
          : `Cancellable: ${productDetails?.cancellable}`
    }

    const GDL_CATEGORY_5_lowercase = categoryText?.toLowerCase()
    let variantStringArray
    if (productDetails?.variants_list) {
      Object.entries(productDetails?.variants_list).map(([key, values]) => {
        if (Array.isArray(values) && values.length > 0) {
          variantStringArray = `${key}-${values.join(', ')}`
        } else {
          variantStringArray = `${key}-`
        }
      })
    }

    const elObject = {
      event: 'view_item',
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      selected_location: address,
      page_type: GDL_PAGE_TYPE,
      ecommerce: {
        value: productDetails?.regularPrice,
        currency: 'INR',
        items: [
          {
            item_name: productDetails?.productName?.toLowerCase(),
            item_id: productDetails?.productId,
            item_brand: productDetails?.brand?.toLowerCase(),
            discount: discount,
            item_variant: variantStringArray,
            item_category: itemCategory,
            price: productDetails?.regularPrice,
            item_list_id: 'na',
            item_list_name: 'na',
            item_category2: 'na',
            item_category3: 'na',
            item_category4: 'na',
            item_category5: GDL_CATEGORY_5_lowercase,
            quantity: 1,
            index: 1,
          },
        ],
      },
    }

    // Push the elObject to the data layer
    if (productDetails?.productId && googleDatalayer === '1') {
      TagManager.dataLayer({ dataLayer: elObject })
    }
  }, [productDetails])

  const fetchSimilarData = async (id: any) => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.VENDOR_PRODUCT,
        payload: { sellerId: id },
      })
      if (res) {
        const data = res?.d
        data.widget_style = 'carousel'
        setSimilarProductDetails(data)
        //setLoader(false)
      }
    } catch (error) {
      //setLoader(false)
    }
  }

  useEffect(() => {
    if (storesData) {
      if (storesData?.d?.pageConfig) {
        const pageConfig = storesData?.d?.pageConfig
        const enableProductCache = pageConfig?.product_page_cache
        const productTTL = pageConfig?.product_ttl
        if (enableProductCache == 1 && productTTL != '') {
          const metaTag = document.createElement('meta')
          metaTag.httpEquiv = 'Cache-Control'
          metaTag.content = `public,max-age=${productTTL}`
          document.head.appendChild(metaTag)

          return () => {
            document.head.removeChild(metaTag)
          }
        }
      }
    }
  }, [storesData])

  const getWidgetProducts = async () => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.GET_WIDGET_PRODUCT,
        payload: { widgetId: widgetDetails[0]?.widgetId, productId },
      })
      if (res) {
        setSimilarProductDetails(res?.d)
        //setLoader(false)
      }
    } catch (error) {
      //setLoader(false)
    }
  }

  useEffect(() => {
    const productName = productDetails?.productName?.replace(/[^a-zA-Z0-9]/g, ' ').trim()
    //process.env.PUBLIC_URL+productDetails?.imgUrl
    let imageUrl =
      productDetails?.imgUrl?.includes('https://') || productDetails?.imgUrl?.includes('http://')
        ? productDetails?.imgUrl
        : `https://cdnaz.plotch.io/image/upload/w_500${productDetails?.imgUrl}`
    if (imageUrl.includes('cdnaz.plotch.io')) {
      imageUrl = imageUrl.includes('w_') ? imageUrl : imageUrl.split('upload/').join('upload/w_500/')
    }

    const seoDetails = {
      robots: productDetails?.seoDetails?.metaRobots,
      pageTitle: `Buy ${productName} Online | ${storesData?.d?.storefrontName}`,
      metaKeywords: `${productName} | ${productName} Online Shopping`,
      imgUrl: `${imageUrl}`,
      url: `${window.location.href}`,
    }
    setHomeData(seoDetails)
  }, [productDetails])

  useEffect(() => {
    const customHeader = {
      productId: productId,
    }
    updateDynamicHeaders(customHeader)
    const path = location.pathname.split('/').pop()
    // const plotchDomain =
    //   process.env.NODE_ENV == 'production' && process.env.REACT_APP_ENV == ''
    //     ? window.location.hostname
    //     : process.env.REACT_APP_PLOTCH_DOMAIN
    if (path) {
      // fetchData(`http://${plotchDomain}/${path}`)
      refetch()
    }
  }, [productId])

  useEffect(() => {
    if (storesData?.d?.ondcEnabled == 1 && vendorProducts?.[0]?.widgetType == 'similar_products') {
      if (productDetails?.sellerId) {
        fetchSimilarData(productDetails?.sellerId)
      }
    } else if (
      widgetDetails?.length > 0 &&
      themedata?.d?.objectDetails?.productView?.widgetId &&
      similarProductWidgets[0]?.widgetType == 'similar_products'
    ) {
      getWidgetProducts()
    }
  }, [productDetails, widgetDetails, similarProductWidgets.length, vendorProducts])

  useEffect(() => {
    const dataToPush = {
      event: 'ViewContent',
      eventName: 'ViewContent',
      source: 'productDetailsPage',
      content_type: 'Product',
      productId,
      productPrice: productDetails?.regularPrice,
      prodName: productDetails?.productName,
      currency: 'INR',
    }

    // Push data to the data layer using TagManager
    TagManager.dataLayer({ dataLayer: dataToPush })
  }, [productDetails])

  return (
    <PublicLayout>
      {/* <GoogleTagManager
        event="ViewContent"
        additionalData={{
          event: 'ViewContent',
          eventName: 'ViewContent',
          source: 'productDetailsPage',
          content_type: 'Product',
          productId,
          productPrice: productDetails?.regularPrice,
          prodName: productDetails?.productName,
          currency: 'INR',
        }}
      /> */}
      <CommonStyle>
        <PdpMainWrapper>
          {!productNotFound && Object.values(productDetails).length > 0 && (
            <PdpDetailView productDetails={productDetails} similarProductDetails={similarProductDetails?.products} />
          )}
          {!productNotFound && similarProductDetails?.products?.length > 0 && (
            <SimilarProducts products={similarProductDetails} />
          )}
          {productNotFound && <NotFound />}
        </PdpMainWrapper>
      </CommonStyle>
    </PublicLayout>
  )
}

export default PdpPage

import styled from 'styled-components'

export const BottomButtonWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    position: fixed;
    /* position: absolute; */
    bottom: 0px;
    display: flex;
    width: 100%;
    align-items: center;
    border-top: 1px solid var(--primary_line_color_dark);
    background-color: var(--primary_box_fill_color);
    button {
      width: 100% !important;
      border-radius: 0;
      border: 0px !important;
      width: fit-content;
      height: 48px;
      display: flex;
      justify-content: center;
      gap: 4px;
      align-items: center;
      text-transform: uppercase;
    }
  }
`

export const BottomButtonDivider = styled.div`
  width: 2.1px;
  height: 32px;
  background: var(--primary_line_color_dark);
`

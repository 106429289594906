import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none" {...props}>
    <path stroke=" #FF3E6B" strokeWidth={0.032} d="M.732.489H19.43v19.968H.732z" />
    <path
      stroke=" #FF3E6B"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M17.103 10.474c0-4.14-3.146-7.5-7.024-7.5-3.877 0-7.023 3.36-7.023 7.5 0 4.14 3.146 7.5 7.023 7.5 3.878 0 7.024-3.36 7.024-7.5Z"
    />
    <path
      fill=" #FF3E6B"
      d="M13.544 6.37 9.256 8.2a2.335 2.335 0 0 0-.787.554c-.224.24-.4.525-.518.84l-1.715 4.578a.332.332 0 0 0 .065.338c.04.043.092.072.148.085a.276.276 0 0 0 .167-.016l4.288-1.832c.295-.126.562-.314.787-.553.224-.24.4-.526.518-.84l1.715-4.579a.332.332 0 0 0-.065-.337.288.288 0 0 0-.148-.085.275.275 0 0 0-.167.016Zm-3.464 5.042a.84.84 0 0 1-.488-.158.927.927 0 0 1-.323-.42.997.997 0 0 1-.05-.542.959.959 0 0 1 .24-.48.862.862 0 0 1 .45-.257.826.826 0 0 1 .507.054c.16.07.298.19.394.345a.983.983 0 0 1-.11 1.184.85.85 0 0 1-.62.274Z"
    />
  </svg>
)
export default SvgComponent

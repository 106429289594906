import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={37} height={30} fill="none" {...props}>
    <g stroke="#1273D2" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3}>
      <path d="m24.503 21.002-6-6-6 6M18.503 15.002v13.5" />
      <path d="M31.088 24.587a7.5 7.5 0 0 0-3.585-14.085h-1.89A11.999 11.999 0 0 0 4.305 6.427a12 12 0 0 0 .698 15.025" />
      <path d="m24.503 21.002-6-6-6 6" />
    </g>
  </svg>
)
export default SvgComponent

import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'
import usePost from 'hooks/usePost'
import PublicLayout from 'components/Layouts/public'
import ListProducts from 'components/ListProduct'
import CustomizeMenu from 'components/CustomizeMenu'
import APIS from 'constants/api'
import { useTheme } from 'context/themeContext'
import { useMenuContext } from 'context/MenuContext'
import { useFilter } from 'context/FliterContext'
import { SnackBarContext } from 'context/SnackBarContext'
import { Product } from 'interfaces/pages'
import LocationIcon from 'assets/svg/LocationVectorIcon'
import BookIcon from 'assets/svg/BookIcon'
import { CommonStyle } from 'styles/pages/Common'
import { DataWrapper } from 'styles/pages/SearchPage'
import {
  SellerNearMeStoreWrapper,
  SellerProductDetailsWrapper,
  StoreImageWrapper,
  MenuContainer,
} from 'styles/components/ListProducts'

const SellerPage = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const [productDetails, setProductDetails] = useState<Product[]>([])
  const [loadMoreData, setLoadMoreData] = useState(false)
  const [filtersData, setFiltersData] = useState<any>([])
  const [filtersSortData, setFiltersSortData] = useState<any>([])
  const [productCount, setProductCount] = useState('')
  const [productInitialCount, setProductInitialCount] = useState('')
  const [feedDetails, setFeedDetails] = useState<any>()
  const [isSorting, setIsSorting] = useState(false)
  const [anchorChoice, setAnchorChoice] = useState<'bottom' | 'left' | 'top' | 'right'>('bottom')
  const [serviceableFlag, setServiceableFlag] = useState(true)
  const [isError, setIsError] = useState(false)
  const { isServiceableProduct } = useTheme()
  const [imageRenderType, setImageRenderType] = useState('')
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768)
  const { filterProduct, filterSortProduct } = useFilter()
  const { mutateAsync } = usePost()
  const { menu, setMenu } = useMenuContext()
  const param = useParams<any>()
  const sellerId = param?.id

  const { setShowSnackBar } = useContext(SnackBarContext)
  const searchParams = new URL(window.location.href).searchParams
  const page = searchParams.get('page_id') || '1'
  const [filter, setFilter] = useState({
    page: parseInt(page),
    perPage: 48,
  })

  const fetchData = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.VENDOR_PRODUCT,
        payload: {
          pageId: filter.page,
          perPage: filter.perPage,
          filters: filterProduct,
          sortBy: filterSortProduct,
          sellerId: sellerId,
        },
      })
      if (res) {
        const vendorName = res?.d?.products[0]?.vendorName
        localStorage.setItem('sellerName', vendorName)
        setFiltersData(res?.d?.displayFilters)
        setFeedDetails(res?.d?.feedDetails)
        setFiltersSortData(res?.d?.sorts)
        if (res?.d?.meta_info?.image_render_type && res?.d?.meta_info?.image_render_type !== undefined) {
          setImageRenderType(res?.d?.meta_info?.image_render_type)
        }

        if (!isLargeScreen && loadMoreData) {
          if (isSorting) {
            setProductDetails(res?.d?.products)

            setIsSorting(false)
          } else setProductDetails((prev: Product[]) => [...prev, ...res?.d?.products])
        } else {
          setProductDetails(res?.d?.products)
        }

        setProductInitialCount(res?.d?.productCount)
        setProductCount(res?.d?.totalProducts)
        setIsError(false)
        if (res?.d?.totalProducts == 0) setIsError(true)
      }
    } catch (error: any) {
      setIsError(true)
      return error
    }
  }
  const toggleMenuDrawer = (newMenu: boolean) => () => {
    setOpenMenu(newMenu)
  }

  useEffect(() => {
    fetchData()
  }, [filter.page, filterProduct, filterSortProduct])

  useEffect(() => {
    const metaTag = document.createElement('meta')
    metaTag.httpEquiv = 'Cache-Control'
    metaTag.content = `public,max-age=3600`
    document.head.appendChild(metaTag)

    return () => {
      document.head.removeChild(metaTag)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [sellerId])

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const vendorGps = productDetails?.[0]?.locations?.[0]?.gps
    if (vendorGps) {
      const [vendorLat, vendorLon] = vendorGps?.split(',')
      const circleRadius = productDetails?.[0]?.circleRadius?.value
      isServiceableProduct(Number(vendorLat), Number(vendorLon), Number(circleRadius)).then((flag) => {
        setServiceableFlag(flag)
      })
    }
  }, [productDetails])

  let distanceKmDisplay: any
  let distanceKm: any = productDetails[0]?.distance
  if (Number.isFinite(distanceKm)) {
    distanceKmDisplay = distanceKm.toFixed(2)
  } else {
    distanceKmDisplay = '-999' // This is for invalid distance
  }

  distanceKm = distanceKmDisplay

  let address: any = ''
  let startTime: any = ''
  let endTime: any = ''
  if (productDetails[0]?.locations?.[0].address) {
    address += productDetails[0]?.locations?.[0].address?.city ? productDetails[0]?.locations?.[0].address?.city : ''
    if (productDetails[0]?.locations?.[0].address?.state) {
      address += ',' + productDetails[0]?.locations?.[0].address?.state
    }
  }

  const formateTime = (time: any) => {
    const date = new Date()
    const [hours, minutes] = time.split(':')
    date.setHours(Number(hours), Number(minutes))
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata',
    }
    const newTime = new Intl.DateTimeFormat('en-In', options).format(date)

    return newTime
  }

  if (productDetails[0]?.storefrontTiming) {
    const storeTime = productDetails[0]?.storefrontTiming
    startTime = storeTime?.[0]?.start_time
    endTime = storeTime?.[storeTime.length - 1]?.end_time
    startTime = formateTime(startTime)
    endTime = formateTime(endTime)
  }

  useEffect(() => {
    const updateAnchor = () => {
      if (window.innerWidth > 768) {
        setAnchorChoice('right')
      } else {
        setAnchorChoice('bottom')
      }
    }

    updateAnchor()

    window.addEventListener('resize', updateAnchor)

    return () => {
      window.removeEventListener('resize', updateAnchor)
    }
  }, [])

  const fetchSimilarData = async (sellerId: string) => {
    try {
      const res = await mutateAsync({
        url: APIS.CUSTOM_MENU,
        payload: { sellerId },
      })
      if (res) {
        setMenu(res?.d?.subMenu || [])
      }
    } catch (error) {
      setShowSnackBar({ open: true, message: `${error}`, severity: '' })
    }
  }

  useEffect(() => {
    fetchSimilarData(sellerId)
  }, [sellerId])

  return (
    <PublicLayout>
      {productDetails?.length > 0 ? (
        <>
          {menu?.length > 0 && (
            <MenuContainer onClick={toggleMenuDrawer(true)}>
              <label className="menu h1r">Menu</label>
              <BookIcon />
            </MenuContainer>
          )}
          <CommonStyle>
            <SellerNearMeStoreWrapper className="product-wrapper">
              <StoreImageWrapper>
                <img src={productDetails[0]?.storeImage} />
              </StoreImageWrapper>
              <div className="storeTime">
                <SellerProductDetailsWrapper>
                  <div className="heading-wrapper">
                    <input type="hidden" value={`${serviceableFlag}`} />
                    <div className="heading-details">
                      <label className={`${window.matchMedia('(max-width: 768px)').matches ? 'h2b' : 'h1b'} storeName`}>
                        {productDetails[0]?.storeName.toLowerCase()}
                      </label>
                    </div>
                  </div>
                  {distanceKm && distanceKm !== '-999' && (
                    <div className="discount-details svgIcon">
                      <LocationIcon />
                      <div className={`${window.matchMedia('(max-width: 768px)').matches ? 'h3r' : 'h2r'}`}>
                        <span>{distanceKm !== '-999' ? distanceKm : ''} KM </span>
                        <span className="sellerAddr">{address ? ' . ' + address : ''}</span>
                      </div>
                    </div>
                  )}
                </SellerProductDetailsWrapper>
                <div className="storeDivider"></div>
                <SellerProductDetailsWrapper className="storeMview">
                  <div className={`${window.matchMedia('(max-width: 768px)').matches ? 'h4b' : 'h2b'} storeAvailable`}>
                    Store timing
                  </div>
                  <div
                    className={`${window.matchMedia('(max-width: 768px)').matches ? 'h4r' : 'h3r'}`}
                  >{`Open : ${startTime} Close : ${endTime}`}</div>
                </SellerProductDetailsWrapper>
              </div>
            </SellerNearMeStoreWrapper>
          </CommonStyle>

          <CommonStyle>
            {productDetails?.length > 0 && (
              <ListProducts
                products={productDetails}
                setFilter={setFilter}
                productCount={productCount}
                productInitialCount={productInitialCount}
                filter={filter}
                feedDetails={feedDetails}
                filtersData={filtersData}
                filtersSortData={filtersSortData}
                setIsSorting={setIsSorting}
                setLoadMoreData={setLoadMoreData}
                isServiceable={serviceableFlag}
                imageRenderType={imageRenderType}
              />
            )}
          </CommonStyle>
        </>
      ) : (
        <DataWrapper className="h1b">{isError ? 'No Data' : ''}</DataWrapper>
      )}

      <Drawer
        open={openMenu}
        anchor={anchorChoice}
        sx={
          window.matchMedia('(max-width:786px)').matches
            ? {
                '& .MuiDrawer-paper': {
                  height: 'auto',
                  borderRadius: '20px 20px 0px 0px',
                },
              }
            : {}
        }
      >
        {<CustomizeMenu onClose={toggleMenuDrawer(false)} />}
      </Drawer>
    </PublicLayout>
  )
}

export default SellerPage

import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { PdpRoute } from 'constants/routes'
import { useMenuContext } from 'context/MenuContext'
import CloseIcon from 'assets/svg/CloseIcon'
import ChevronForward from 'assets/svg/ChevronForward'
import {
  AddressesListContainer,
  AddressesListWrapper,
  CloseWrapper,
  DeliverySvgWrapper,
} from 'styles/views/LocationNewUser'
import { DeliveryHeading } from 'styles/views/SaveAddress'
import {
  AddonProductWrapper,
  CommonFontWrapper,
  CustomisedMenuList,
  CustomisedMenuWrapper,
  LineDivider,
  MenuItem,
  MenuWrapper,
  OuterLineDivider,
} from 'styles/components/CustomizeMenu'

interface CustomizeMenuProps {
  onClose: any
}

const CustomizeMenu: React.FC<CustomizeMenuProps> = ({ onClose }) => {
  const [expanded, setExpanded] = useState<string | false>(false)

  const { menu } = useMenuContext()

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
    event.preventDefault()
  }

  return (
    <AddressesListContainer>
      <AddressesListWrapper>
        <CloseWrapper>
          <DeliveryHeading>Menu</DeliveryHeading>
          <DeliverySvgWrapper className="crossIcon" onClick={onClose}>
            <CloseIcon />
          </DeliverySvgWrapper>
        </CloseWrapper>
        <CustomisedMenuWrapper>
          <CommonFontWrapper>
            {menu.map((item: any, key: any) => (
              <CustomisedMenuList key={key}>
                <Accordion
                  expanded={expanded === item?.groupId}
                  onChange={handleAccordionChange(item?.groupId)}
                  style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                  }}
                >
                  <AccordionSummary
                    id={`panel-header_${item?.groupId}`}
                    aria-controls={`panel-content_${key}`}
                    expandIcon={<ChevronForward />}
                  >
                    <MenuItem>
                      <MenuWrapper>
                        <span className="h1b menuName">
                          {item?.groupName} {`(${item?.addonProducts?.length})`}
                        </span>
                      </MenuWrapper>
                    </MenuItem>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MenuItem>
                      <AddonProductWrapper>
                        {item?.addonProducts?.map((addons: any, index: number) => {
                          const productName = addons?.product_name
                            ?.trim()
                            .replace(/[^a-zA-Z0-9]/g, ' ')
                            .split(' ')
                            .join('-')
                          let productImg = addons?.main_image

                          if (!addons?.main_image?.includes('https') || !addons?.main_image?.includes('http')) {
                            productImg = `https://cdnaz.plotch.io/image/upload/w_118,h_118${addons?.main_image}`
                          } else if (
                            addons?.main_image?.includes('http') &&
                            addons?.main_image?.includes('https://cdnaz.plotch.io')
                          ) {
                            productImg = productImg.split('image/upload/').join('image/upload/w_118,h_118/')
                          }

                          return (
                            <div className="addonItems" key={addons?.product_id}>
                              {index != 0 ? <LineDivider /> : ''}
                              <div
                                className="addonData"
                                onClick={() =>
                                  (window.location.href = PdpRoute.path
                                    .replace(':name', productName)
                                    .replace(':id', addons?.product_id))
                                }
                              >
                                <div className="productImg">
                                  <img src={productImg} alt={addons?.product_name} />
                                </div>
                                <div className="addonProductName h1b">{addons?.product_name}</div>
                                <div className="addonPrice h1r">
                                  <span> &#8377; </span> {addons?.price}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </AddonProductWrapper>
                    </MenuItem>
                  </AccordionDetails>
                </Accordion>
                <OuterLineDivider />
              </CustomisedMenuList>
            ))}
          </CommonFontWrapper>
        </CustomisedMenuWrapper>
        {/* <CustomisedMenuWrapper>
          <CommonFontWrapper>
            {menu?.map((item: any, key: any) => (
              <CustomisedMenuList key={key}>
                <Collapse expandIconPosition="end">
                  <Panel header={`${item?.groupName} (${item?.addonProducts?.length})`} key={item?.groupId}>
                    <MenuItem>
                      <AddonProductWrapper>
                        {item?.addonProducts?.map((addons: any) => {
                          const productName = addons?.product_name
                            ?.trim()
                            .replace(/[^a-zA-Z0-9]/g, ' ')
                            .split(' ')
                            .join('-')
                          let productImg = addons?.main_image

                          if (!addons?.main_image?.includes('https') || !addons?.main_image?.includes('http')) {
                            productImg = `https://cdnaz.plotch.io/image/upload/w_118,h_118${addons?.main_image}`
                          } else if (
                            addons?.main_image?.includes('http') &&
                            addons?.main_image?.includes('https://cdnaz.plotch.io')
                          ) {
                            productImg = productImg.split('image/upload/').join('image/upload/w_118,h_118/')
                          }

                          return (
                            <div className="addonItems" key={addons?.product_id}>
                              <div
                                className="addonData"
                                onClick={() =>
                                  (window.location.href = PdpRoute.path
                                    .replace(':name', productName.toLowerCase())
                                    .replace(':id', addons?.product_id))
                                }
                              >
                                <div className="productImg">
                                  <img src={productImg} alt={addons?.product_name} />
                                </div>
                                <div className="addonProductName h2r">{addons?.product_name}</div>
                                <div className="addonPrice h2r">
                                  <span> &#8377; </span> {addons?.price}
                                </div>
                              </div>
                              <LineDivider />
                            </div>
                          )
                        })}
                      </AddonProductWrapper>
                    </MenuItem>
                  </Panel>
                </Collapse>
                <OuterLineDivider />
              </CustomisedMenuList>
            ))}
          </CommonFontWrapper>
        </CustomisedMenuWrapper> */}
      </AddressesListWrapper>
    </AddressesListContainer>
    // </Drawer>
  )
}

export default CustomizeMenu

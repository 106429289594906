import { Tabs } from 'antd'
import BookIcon from 'assets/svg/BookIcon'
import Pricetag from 'assets/svg/Pricetag'
import VegImage from 'assets/images/veg-img.png'
import PizzaImg from 'assets/images/pizza.png'
import NonVegImage from 'assets/images/non-veg.png'
import SpeedometerIcon from 'assets/svg/SpeedometerIcon'
import {
  StoreHyperMainWrapper,
  StoreHyperHeadingContainer,
  StoreHyperHeading,
  MenuTypeWrapper,
  MenuContainer,
  MainBodyWrapper,
  CheckoutButtonwrapper,
  MobileCheckoutButtonwrapper,
  RecomendedContainer,
} from 'styles/views/StoreHyperlocalView'
import { ContentList } from './contentList'
import Button from 'components/Button'
import Line from 'assets/svg/Line'
import MobileStoreHyperLocalView from './mobileStoreHyperLocalView'

// const StoreHyperlocalView = () => {
const tabItems = [
  {
    key: '1',
    label: 'Party Combo (5)',
    content: (
      <ContentList
        items={[
          {
            name: 'Veggie Delight Pizza',
            price: '129',
            originalPrice: '219',
            discount: '41%',
            image: PizzaImg,
            type: VegImage,
          },
          {
            name: 'Veggie Delight Pizza',
            price: '129',
            originalPrice: '219',
            discount: '41%',
            image: PizzaImg,
            type: VegImage,
          },
        ]}
      />
    ),
  },
  {
    key: '2',
    label: 'Veg Pizza (9)',
    content: (
      <ContentList
        items={[
          {
            name: 'Veggie Delight Pizza',
            price: '129',
            originalPrice: '219',
            discount: '41%',
            image: PizzaImg,
            type: VegImage,
          },
          {
            name: 'Veggie Delight Pizza',
            price: '129',
            originalPrice: '219',
            discount: '41%',
            image: PizzaImg,
            type: VegImage,
          },
        ]}
      />
    ),
  },
  {
    key: '3',
    label: 'Party Combo (12)',
    content: (
      <ContentList
        items={[
          {
            name: 'Veggie Delight Pizza',
            price: '129',
            originalPrice: '219',
            discount: '41%',
            image: PizzaImg,
            type: VegImage,
          },
          {
            name: 'Veggie Delight Pizza',
            price: '129',
            originalPrice: '219',
            discount: '41%',
            image: PizzaImg,
            type: VegImage,
          },
        ]}
      />
    ),
  },
  {
    key: '4',
    label: 'New Thin n Crispy Pizzas (7)',
    content: (
      <ContentList
        items={[
          {
            name: 'Veggie Delight Pizza',
            price: '129',
            originalPrice: '219',
            discount: '41%',
            image: PizzaImg,
            type: VegImage,
          },
          {
            name: 'Veggie Delight Pizza',
            price: '129',
            originalPrice: '219',
            discount: '41%',
            image: PizzaImg,
            type: VegImage,
          },
        ]}
      />
    ),
  },
  {
    key: '5',
    label: 'Best in Pizza (10)',
    content: (
      <ContentList
        items={[
          {
            name: 'Veggie Delight Pizza',
            price: '129',
            originalPrice: '219',
            discount: '41%',
            image: PizzaImg,
            type: VegImage,
          },
          {
            name: 'Veggie Delight Pizza',
            price: '129',
            originalPrice: '219',
            discount: '41%',
            image: PizzaImg,
            type: VegImage,
          },
        ]}
      />
    ),
  },
]

const StoreHyperlocalView = () => {
  return (
    <StoreHyperMainWrapper>
      <StoreHyperHeadingContainer>
        <StoreHyperHeading>
          <label className="h0b">Burger King</label>
          <div className="distance-details">
            <SpeedometerIcon />
            <div className="h1r">4.88 KM</div>
            <div className="h1r">Kalyan Nagar</div>
          </div>
          <div className="offers">
            <Pricetag />
            <label className="offer-heading h1r">Buy1get1</label>
          </div>
        </StoreHyperHeading>
        <MenuTypeWrapper>
          <MenuContainer>
            <label className="menu h1r">Menu</label>
            <BookIcon />
          </MenuContainer>
          <MenuContainer>
            <label className="menu h1r">Veg</label>
            <img src={VegImage} />
          </MenuContainer>
          <MenuContainer>
            <label className="menu h1r">Non Veg</label>
            <img src={NonVegImage} />
          </MenuContainer>
        </MenuTypeWrapper>
      </StoreHyperHeadingContainer>
      <MainBodyWrapper>
        <Tabs defaultActiveKey="1" tabPosition="left">
          {tabItems.map((item) => (
            <Tabs.TabPane tab={item.label} key={item.key}>
              <RecomendedContainer>
                <label className="ultra-bold heading">Recommended (3)</label>
              </RecomendedContainer>
              {item.content}
              <CheckoutButtonwrapper>
                <div className="checkout-item-wrap">
                  <label className="item-quantity h0b">1 Item(s)</label>
                  <div> |</div>
                  <div className="price-wrap h0b">
                    {' '}
                    <span> &#8377; </span>129
                  </div>
                </div>
                {/* <Button label="Go To Cart " variant="outline" type="submit" className="skip" /> */}

                <Line />
                <Button label="Checkout" variant="contained" type="submit" className="small" />
              </CheckoutButtonwrapper>
            </Tabs.TabPane>
          ))}
        </Tabs>
        <MobileStoreHyperLocalView />
      </MainBodyWrapper>
      <MobileCheckoutButtonwrapper>
        <div className="checkout-item-wrap">
          <label className="item-quantity h0b">1 Item(s)</label>
          <div> |</div>
          <div className="price-wrap h0b">
            {' '}
            <span> &#8377; </span>129
          </div>
        </div>
        {/* <Button label="Go To Cart " variant="outline" type="submit" className="skip" /> */}

        <Line />
        <Button label="Checkout" variant="contained" type="submit" className="small" />
      </MobileCheckoutButtonwrapper>
    </StoreHyperMainWrapper>
  )
}

export default StoreHyperlocalView

import usePost from 'hooks/usePost'
import APIS from 'constants/api'
// import { LoaderContext } from 'context/loader'
import { HomeRoute } from 'constants/routes'
import { updateDynamicHeaders } from 'services/axiosInstance'

export const useLogoutApi = () => {
  // const { setLoader } = useContext(LoaderContext)
  const { mutateAsync } = usePost()
  const logout = async () => {
    //setLoader(true)
    try {
      const res = await mutateAsync({
        url: APIS.LOGOUT,
        payload: { customerId: localStorage.getItem('customerId') },
      })
      if (res) {
        localStorage.removeItem('Authorization')
        localStorage.removeItem('customerId')
        document.cookie = 'x-plotch-token=;expires=-1;path=/'
        document.cookie = 'Authorization=;expires=-1;path=/'
        document.cookie = 'x-plotch-customer-id=;expires=-1;path=/'
        document.cookie = 'x-plotch-customer-mobile=;expires=-1;path=/'
        document.cookie = 'plotch_address_id=;expires=-1;path=/'
        document.cookie = 'source=;expires=-1;path=/'
        updateDynamicHeaders()
        //setLoader(false)
        window.location.href = HomeRoute.path
      }
    } catch (error: any) {
      //setLoader(false)
      return error
    }
  }

  return logout
}

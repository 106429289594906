import { useHistory } from 'react-router-dom'
import OrderHistory from 'views/OrderHistory'
import BackArrow from 'assets/svg/BackArrow'
import { CommonStyle } from 'styles/pages/Common'
import {
  CheckoutWrappers,
  // ManageAddressWrapper,
  BackWrapper,
  CheckoutPageWrapper,
} from 'styles/pages/MyAccount'
import PublicLayout from 'components/Layouts/public'
import { MyAccountRoute } from 'constants/routes'

const MyOrdersPage = () => {
  const navigate = useHistory()

  const handleBack = () => {
    navigate.push(MyAccountRoute.path)
  }

  return (
    <PublicLayout>
      <CheckoutPageWrapper>
        <CheckoutWrappers>
          <CommonStyle>
            <BackWrapper className="padding_full_page" onClick={handleBack}>
              <BackArrow />
              <div className="h1b">ORDERS</div>
            </BackWrapper>
          </CommonStyle>
          <CommonStyle>
            <OrderHistory />
          </CommonStyle>
        </CheckoutWrappers>
      </CheckoutPageWrapper>
    </PublicLayout>
  )
}

export default MyOrdersPage

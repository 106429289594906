import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import Drawer from '@mui/material/Drawer'
import { CheckoutRoute, EmptyCartRoute, LoginRoute } from 'constants/routes'
import LocationNewUser from 'views/LocationNewUser'
import SavedAddresses from 'views/SavedAddresses'
import CartIcon from 'assets/svg/CartIcon'
import DownArrow from 'assets/svg/DownArrow'
import LocationIcon from 'assets/svg/LocationIcon'
import BackArrow from 'assets/svg/BackArrow'
// import SearchIcon from 'assets/svg/SearchIcon'
import { MobileNavbarContainer, MobileRightContainer, CartWrapper, LocationWrapper } from 'styles/components/Navbar'
import { BackWrapper } from 'styles/pages/Login'
import { Badge } from 'antd'
import { DataLayerObject } from '../dataLayer'
import { useTheme } from 'context/themeContext'

const PdpMobileNavbar = ({ cartData }: any) => {
  const [open, setOpen] = useState(false)
  const [openAddress, setOpenAddress] = useState(false)
  const navigate = useHistory()
  const address: any = localStorage.getItem('location')
  const postcode: any = localStorage.getItem('postcode')
  const currentAddress = JSON.parse(address)
  const { dataSignInToPush, dataMyAccountToPush, dataNavLocationToPush } = DataLayerObject()
  const { themedata, storesData } = useTheme()
  const ishyperlocalEnable = storesData?.d?.isHyperlocalEnabled ? storesData?.d?.isHyperlocalEnabled : 0
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const toggleAddressDrawer = (newAddress: boolean) => () => {
    setOpenAddress(newAddress)
  }

  const handleLocationClick = () => {
    TagManager.dataLayer({ dataLayer: dataNavLocationToPush })

    if (localStorage.getItem('customerId') && localStorage.getItem('sessionId')) {
      toggleAddressDrawer(true)()
    } else {
      toggleDrawer(true)()
    }
  }
  const handleBack = () => {
    navigate.goBack()
  }

  const handleCartLogin = () => {
    if (!localStorage.getItem('customerId')) {
      navigate.push(LoginRoute.path + '?redirect=checkout')
      TagManager.dataLayer({ dataLayer: dataSignInToPush })
    } else {
      if (!cartData) {
        navigate.push(EmptyCartRoute.path)
        TagManager.dataLayer({ dataLayer: dataMyAccountToPush })
      } else {
        navigate.push(CheckoutRoute.path)
        TagManager.dataLayer({ dataLayer: dataMyAccountToPush })
      }
    }
  }

  return (
    <MobileNavbarContainer>
      <BackWrapper onClick={handleBack}>
        <BackArrow />
      </BackWrapper>
      <MobileRightContainer>
        {themedata?.d?.objectDetails?.general?.showOndcLocation?.showOndcLocation && ishyperlocalEnable == 1 && (
          <LocationWrapper onClick={handleLocationClick}>
            <LocationIcon />
            {currentAddress?.address?.postcode || postcode ? (
              <>{currentAddress?.address?.postcode || postcode}</>
            ) : (
              <span className="text-nonbreak">Select Location</span>
            )}
            <DownArrow />
          </LocationWrapper>
        )}
        <CartWrapper>
          {/* <SearchIcon /> */}
          <Badge count={cartData} color="var(--primary_icon_highlight_color)">
            <CartIcon onClick={handleCartLogin} />
          </Badge>
        </CartWrapper>
      </MobileRightContainer>
      <Drawer
        open={open}
        anchor="right"
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%',
          },
        }}
      >
        {<LocationNewUser onClose={toggleDrawer(false)} />}
      </Drawer>
      <Drawer
        open={openAddress}
        onClose={toggleAddressDrawer(false)}
        anchor="bottom"
        sx={{
          '& .MuiDrawer-paper': {
            height: 'auto',
            borderRadius: '20px 20px 0px 0px',
          },
        }}
      >
        {<SavedAddresses onClose={toggleAddressDrawer(false)} />}
      </Drawer>
    </MobileNavbarContainer>
  )
}

export default PdpMobileNavbar

import { useHistory } from 'react-router-dom'
import ConfirmationOrderDetail from 'views/ConfirmationOrderDetail'
import BackArrow from 'assets/svg/BackArrow'
import CheckmarkCircle from 'assets/svg/CheckmarkCircle'
import { CommonStyle } from 'styles/pages/Common'
import { CheckoutWrappers, BackWrapper, CheckoutPageWrapper } from 'styles/pages/MyAccount'
import { ConfirmationMessageWrapper, OrderMessage } from 'styles/pages/OrderSuccessPage'
import PublicLayout from 'components/Layouts/public'
import { MyOrdersRoute } from 'constants/routes'

const OrderSuccessPage = () => {
  const navigate = useHistory()

  const handleBack = () => {
    navigate.push(MyOrdersRoute.path)
  }

  return (
    <PublicLayout>
      <CheckoutPageWrapper>
        <CheckoutWrappers>
          <CommonStyle>
            <BackWrapper className="padding_full_page" onClick={handleBack}>
              <BackArrow />
              <div className="h1b">ORDER SUCCESS</div>
            </BackWrapper>
          </CommonStyle>
          <CommonStyle>
            <ConfirmationMessageWrapper>
              <CheckmarkCircle />
              <OrderMessage className="h0b">Yay, your order is confirmed!</OrderMessage>
            </ConfirmationMessageWrapper>
          </CommonStyle>
          <CommonStyle>
            <ConfirmationOrderDetail />
          </CommonStyle>
        </CheckoutWrappers>
      </CheckoutPageWrapper>
    </PublicLayout>
  )
}

export default OrderSuccessPage

import styled from 'styled-components'

export const SearchPageWrapper = styled.div`
  .small {
    width: 100%;
  }
  .search_count {
    margin-top: 6px;
  }

  @media (min-width: 786px) {
    .searchTitle {
      font-size: 20px;
    }
  }
`

export const SearchSection = styled.div`
  width: max-content;
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-weight: 700;
`
export const TypeSelectWrapper = styled.div`
  width: 167px;
  /* .select_sort {
    width: 200px;
  } */
  .select_sort .ant-select-selector {
    width: 167px;
    border-radius: 13px; /* Rounded corners */
    border-color: #d9d9d9; /* Light border color */
    height: 40px; /* Height of the component */
    display: flex;
    align-items: center;
  }
  .ant-select-single {
    height: unset;
  }
  .select_sort .ant-select-selection-placeholder {
    color: #000; /* Placeholder text color */
  }

  .select_sort .ant-select-arrow {
    color: #000; /* Arrow color */
    right: -68px;
    top: 54%;
  }
  .ant-select-outlined:hover .ant-select-selector {
    border-color: var(--primary_button_stroke_color_light) !important;
    box-shadow: none !important; /* Focus shadow */
  }
  .select_sort.ant-select:not(.ant-select-customize-input):hover .ant-select-selector,
  .select_sort.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: var(--primary_button_stroke_color_light) !important;
    box-shadow: none !important; /* Focus shadow */
  }
  @media (max-width: 768px) {
    .select_sort {
      display: none;
    }
  }
`
export const SortByWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 26px;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`
export const SeachWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 6px; */
  /* padding-bottom: 20px; */
  @media (max-width: 768px) {
    padding: 0px 0px 10px;
    width: 100%;
  }
`
export const DataWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 128px);
  display: flex;
  justify-content: center;
  align-items: center;
`
export const WishListMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  /* padding: 40px 74px; */
  @media (max-width: 768px) {
    margin-left: 18px;
  }
`
export const WishlistLabel = styled.div`
  color: #000;
  font-family: 'Mulish';
  font-size: 20px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
export const SubHeadingStore = styled.div`
  color: #000;
  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 400;
`

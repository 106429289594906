import styled from 'styled-components'

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  background-color: var(--primary_box_fill_color);
  gap: 16px;
  padding: 24px;
  border: 1px solid var(--primary_stroke_color_light);
  /* height: calc(100vh - 65px); */

  @media (max-width: 768px) {
    padding: 10px 0 0;
    width: 100%;
    border: none;
  }
`
export const MainAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  background-color: var(--primary_box_fill_color);
  gap: 16px;
  padding: 24px;
  border: 1px solid var(--primary_stroke_color_light);
  /* height: calc(100vh - 65px); */

  @media (max-width: 768px) {
    padding: 16px 16px 0;
    width: unset;

    border: none;
  }
`
export const TopHeading = styled.div`
  color: var(--primary_text_color_dark);
  /* H1-R */
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 400;
`
export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const TabsWrapper = styled.div`
  display: flex;
  gap: 11px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`
export const FormFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .ant-select-arrow {
    top: 70%;
  }
  .ant-select-arrow > span {
    svg {
      fill: black !important;
    }
  }
  @media (max-width: 768px) {
    /* width: 320px; */
  }
`

export const ButtonWrapper = styled.div`
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
`
export const DetailWrapper = styled.div`
  display: flex;
  gap: 16px;
`
export const TabsName = styled.div<{ isActive?: boolean }>`
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
  border: 1px solid
    ${({ isActive }) => (isActive ? 'var(--primary_label_active_stroke_color)' : 'var(--primary_stroke_color_light)')};

  color: ${({ isActive }) => (isActive ? 'var(--primary_label_active_text_color)' : 'var(--primary_text_color_dark)')};

  background: var(--primary_box_fill_color);
  padding: 5px 15px;
  cursor: pointer;
  /* :active, */
  :hover {
    border-radius: 5px;
    border: 1px solid var(--primary_label_active_stroke_color);
    color: var(--primary_label_active_text_color);
  }
`
export const AddressFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* gap: 36px; */
`
export const ManageProfileFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
  @media (max-width: 768px) {
    margin-top: 14px;
    padding: 0 20px;
  }
`
// delete addresss--
export const DeleteAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .delete-close {
    display: block;
  }
  @media (max-width: 768px) {
    .delete-close {
      display: none;
    }
    padding-top: 28px;
  }
`
export const RemoveItemsContainer = styled(DeleteAddressContainer)`
  gap: 20px;
  padding-top: 11px;
  .remove-item {
    padding-bottom: 17px;
  }
  svg {
    cursor: pointer;
    padding-right: 11px;
  }
  @media (max-width: 768px) {
    gap: 10px;
  }
`

export const ReturnCancellationPolicyContainer = styled(RemoveItemsContainer)`
  width: 100%;
  max-width: 360px;
  .message-wrap {
    width: 100%;
    max-width: 275px;
    text-align: center;
  }
  .order-list {
  }
  @media screen and (max-width: 786px) {
    width: 100%;
    max-width: 100%;
  }
`
export const PolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 10px;
  .policywrap {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  @media screen and (max-width: 786px) {
    padding: 0;
  }
`
export const ReturnCancellationPolicyWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 8px;
  height: 100%;
  min-height: 315px;
`
export const TryAgainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  position: fixed;
  bottom: 33px;
  .try {
    color: var(--primary_caution_color);
  }
`
export const OrderListWrap = styled(ReturnCancellationPolicyWrap)`
  gap: 10px;
  min-height: 315px;
`
export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
export const HeadingWrappers = styled(HeadingWrapper)`
  padding-top: 40px;
  @media screen and (max-width: 786px) {
    padding-top: 0px;
  }
`
export const TopHeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`
export const Heading = styled.div`
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 700;
`
export const DeliveryHeading = styled.div`
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 700;
`
export const Description = styled(Heading)`
  font-weight: 400;
`
export const DeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 8px 11px;
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  margin: 0px 12px;
  button {
    width: 157px;
    border-radius: 4px;
  }
  button.large {
    width: 100%;
  }
  .skip {
    border: 1px solid var(--primary_stroke_color_light);
    background: var(--secondary_button_active_fill_color);
    color: var(--primary_text_color_dark);
  }
  /* margin-top: 30px; */
  margin-top: 48px;
  .closeTicket {
    border: 1px solid var(--primary_stroke_color_light);
    background: var(--secondary_button_active_fill_color);
    color: var(--primary_text_color_dark);
  }
  @media (max-width: 768px) {
    margin-top: 0;
    margin: 0px;
  }
`
export const BuyButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 8px 11px;
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  button {
    width: 157px;
    border-radius: 4px;
  }
  .skip {
    border: 1px solid var(--primary_stroke_color_light);
    background: var(--secondary_button_active_fill_color);
    color: var(--primary_text_color_dark);
  }
  margin-top: 48px;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`
export const DesktopDeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 8px 11px;
  border: 1px solid var(--primary_stroke_color_light);
  background: var(--primary_box_fill_color);
  button {
    width: 157px;
    border-radius: 4px;
  }
  .skip {
    border: 1px solid var(--primary_stroke_color_light);
    background: var(--secondary_button_active_fill_color);
    color: var(--primary_text_color_dark);
  }
`

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M16.875 5H3.125C2.089 5 1.25 5.84 1.25 6.877v8.75c0 1.035.84 1.875 1.875 1.875h13.75c1.035 0 1.875-.84 1.875-1.875v-8.75C18.75 5.84 17.91 5 16.875 5Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M5.625 5V3.75a1.25 1.25 0 0 1 1.25-1.25h6.25a1.25 1.25 0 0 1 1.25 1.25V5M18.75 9.375H1.25M12.5 9.375v.938a.313.313 0 0 1-.312.313H7.812a.312.312 0 0 1-.312-.313v-.938"
    />
  </svg>
)
export default SvgComponent

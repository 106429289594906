import React from 'react'

interface CustomDivProps extends React.HTMLAttributes<HTMLDivElement> {
  formTemplatedid?: string
}

const FormWidget: React.FC<CustomDivProps> = ({ formTemplatedid, ...props }: any) => {
  return <div formTemplatedid={formTemplatedid} {...props} />
}

export default FormWidget

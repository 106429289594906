import { ChangeEvent, useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'
import SearchBar from 'components/SearchBar'
import Button from 'components/Button'
import MapComponent from 'components/MapComponent'
import CurrentLocation from 'components/CurrentLocation'
import { AddAddressRoute } from 'constants/routes'
// import { LoaderContext } from 'context/loader'
import useDebounce from 'hooks/useDebounce'
import BackArrow from 'assets/svg/BackArrow'
import SearchIcon from 'assets/svg/SearchIcon'

import {
  SelectLocationViewContainer,
  AddressWrapper,
  AddressDetails,
  AddressType,
  MainAddress,
  SearchbarWrapper,
  AddressContainer,
  MapWrapper,
} from 'styles/views/SelectLocation'
import { ButtonWrapper } from 'styles/components/Button'
import { BackWrapperDisplay } from 'styles/pages/Login'
import { TypeDataContainer, TypeData } from 'styles/views/LocationNewUser'

const SelectLocationView = ({ onClose }: any) => {
  const [currentPosition, setCurrentPosition] = useState<any>([20.5937, 78.9629])
  const router = useHistory()
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 300)
  const [suggestion, setSuggestion] = useState<any>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const jsonData: any = localStorage.getItem('myAddress')
  const [address, setAddress] = useState(JSON.parse(jsonData))
  // const { setLoader } = useContext(LoaderContext)
  const handleBack = () => {
    router.goBack()
  }

  const fetchLocation = () => {
    //setLoader(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setCurrentPosition([position.coords.latitude, position.coords.longitude])
          const { latitude, longitude } = position.coords
          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`,
            )
            const data = await response.json()
            localStorage.setItem(
              'myAddress',
              JSON.stringify({
                pincode: data.address['ISO3166-2-lvl4'],
                county: data.address?.county,
                state: data.address?.state,
                district: data.address?.state_district,
                town: data.address?.town,
                country: data.address?.country,
              }),
            )
            //setLoader(false)
            onClose()
          } catch (error) {
            //setLoader(false)
            return error
          }
        },
        (error) => {
          //setLoader(false)
          return error
        },
      )
    } else {
      alert('Geolocation is not supported by this browser.')
    }
  }

  useEffect(() => {
    const searchAddress = async (value: string) => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=jsonv2&countrycodes=IN&q=${value}&addressdetails=1`,
        )
        const data = await response.json()
        setSuggestion(data)
      } catch (error) {
        return error
      }
    }

    if (debouncedSearchValue) {
      searchAddress(debouncedSearchValue)
    }
  }, [debouncedSearchValue])

  const handleSuggestionItem = (item: any) => {
    const lastOption = item.address['ISO3166-2-lvl4']
    const data = JSON.stringify({
      pincode: item.address?.postcode ?? item.address?.pincode?.postcode ?? lastOption,
      county: item.address?.county,
      state: item.address?.state,
      district: item.address?.state_district,
      town: item.address?.town,
      country: item.address?.country,
    })
    localStorage.setItem('myAddress', data)
    if (item && item.lat && item.lon) setCurrentPosition([item.lat, item.lon])
    setAddress(JSON.parse(data))
    setSuggestion(null)
  }

  const handleLocation = () => {
    router.push(AddAddressRoute.path, {
      hasLocation: true,
      addressData: address,
    })
  }

  return (
    <SelectLocationViewContainer>
      <BackWrapperDisplay onClick={handleBack} className="delivery-location">
        <BackArrow />
        Select Delivery Location
      </BackWrapperDisplay>
      <SearchbarWrapper>
        <SearchBar
          placeholder="Building name, locality, pincode etc"
          name="search"
          prefix={<SearchIcon />}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.target.value)
          }}
          inputRef={inputRef}
          value={searchValue}
          className="search-address"
        />
        {searchValue.length > 2 && (
          <>
            {suggestion ? (
              <TypeDataContainer>
                {suggestion?.map((item: any, index: number) => (
                  <div key={index}>
                    <TypeData
                      onClick={() => {
                        handleSuggestionItem(item)
                        setSearchValue('')
                      }}
                    >
                      {item.display_name}
                    </TypeData>
                  </div>
                ))}
              </TypeDataContainer>
            ) : null}
          </>
        )}
      </SearchbarWrapper>
      <MapWrapper>
        <MapComponent currentPosition={currentPosition} setAddress={setAddress} />
        <div className="current-location" onClick={() => fetchLocation()}>
          <CurrentLocation />
        </div>
      </MapWrapper>

      <AddressContainer>
        {address && (
          <AddressWrapper>
            <AddressDetails>
              <AddressType>{address?.pincode ?? address?.pincode?.postcode} - Home</AddressType>
              <MainAddress className="h2r">
                {`${address?.town ? address?.town : ''} ${address?.county ? address?.county : ''} ${
                  address?.district ? address?.district : ''
                } ${address?.state ? address?.state : ''} ${address?.country ? address?.country : ''}`}
              </MainAddress>
            </AddressDetails>
            <Button
              label="Change"
              variant="outline"
              type="button"
              className="change"
              onClick={() => inputRef.current?.focus()}
            />
          </AddressWrapper>
        )}
        <ButtonWrapper>
          <Button label="Confirm Location" variant="contained" type="button" onClick={handleLocation} />
        </ButtonWrapper>
      </AddressContainer>
    </SelectLocationViewContainer>
  )
}

export default SelectLocationView

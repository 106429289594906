import styled from 'styled-components'
interface IProps {
  status?: string | boolean
  state?: string | boolean
  confirmStatus?: number
}

export const OrderHistoryWrapper = styled.div`
  /* padding: 0px 22px; */
  img {
    width: 60px;
    aspect-ratio: 1;
    object-fit: contain;
    min-height: max-content;
  }
  .no-order {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }
  .ant-tabs-nav-wrap {
    width: 100%;
    div {
      width: 50%;

      div {
        width: 100%;
        text-align: center;
      }
    }
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary_label_active_text_color);
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: var(--primary_label_active_text_color);
  }
  .ant-tabs .ant-tabs-tab:hover {
    color: unset;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }
`
export const MainHeading = styled.div`
  color: #121212;
  font-family: 'Mulish';
  font-size: 14px;
  font-weight: 700;
`
export const OrderConfirmationWrapper = styled.div`
  margin-top: 16px;
  padding: 5px 20px 20px 18px;
  border-radius: 8px;
  background-color: var(--primary_box_fill_highlight_color);
  .order-name-detail {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }
  .help-icon {
    cursor: pointer;
  }

  .cancellable-wrap {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .item-name {
    width: 210px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    text-transform: capitalize;
  }
  .status-wrap {
  }
  .confirmed-wrap {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary_line_color_light);
    padding-bottom: 12px;
  }
  .confirm-order {
    display: flex;
    gap: 19px;
  }
  .orders-details {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  .order-state-type {
    padding: 7px 0;
  }
  .cancel {
    background-color: var(--primary_brand_color);
  }
  .canceled {
    color: var(--primary_brand_color);
  }
  button {
    color: var(--primary_success_color);
    border-color: var(--primary_success_color);
    width: 60px;
    height: 20px;
  }
  .non-returnable {
    color: var(--primary_caution_color);
  }
  @media (max-width: 768px) {
    margin: 16px 22px 0;
    .item-name {
      width: 140px;
    }
  }
`

export const StatusWrapper = styled.div<IProps>`
  color: var(--primary_success_color);
  color: ${(props) =>
    props.status === 'Confirmed'
      ? 'var(--primary_success_color)'
      : props.status === 'Cancelled'
      ? 'var(--primary_brand_color)'
      : props.status === 'Delivered'
      ? 'var(--primary_success_color)'
      : props.status === 'PROCESSING'
      ? 'yellow'
      : props.status === 'Pending'
      ? 'var(--primary_caution_color)'
      : props.status === 'ASSIGNED'
      ? 'blue'
      : 'var(--primary_success_color)'};
`
export const ConfirmStatusWrapper = styled.div<IProps>`
  color: var(--primary_success_color);
  color: ${(props) =>
    props.confirmStatus === 1
      ? 'var(--primary_success_color)'
      : props.confirmStatus === 9
      ? 'var(--primary_brand_color)'
      : props.confirmStatus === 7
      ? 'var(--primary_success_color)'
      : props.confirmStatus === 0
      ? 'var(--primary_caution_color)'
      : 'var(--primary_success_color)'};
`
export const OrderStateWrapper = styled.div<IProps>`
  color: var(--primary_text_color_light);
  background-color: ${(props) =>
    props.state === 'Processing'
      ? 'var(--primary_caution_color)'
      : props.state === 'Cancelled'
      ? 'var(--primary_brand_color)'
      : props.state === 'Delivered'
      ? 'var(--primary_success_color)'
      : props.state === 'Shipped'
      ? 'var(--primary_caution_color)'
      : props.state === 'Pending'
      ? 'var(--primary_caution_color)'
      : props.state === 'ASSIGNED'
      ? 'blue'
      : 'red'};
  border-radius: 8px;
  width: max-content;
  /* width: 69px; */
  text-align: center;
  padding: 2px 6px;
`

import styled from 'styled-components'

export const InputWrapper = styled.div`
  width: 100%;
  input {
    height: 40px;
    ::placeholder {
      color: var(--primary_text_color_dark);
      font-family: 'Mulish';
      font-size: 12px;
      font-weight: 700;
    }
  }
  .ant-input {
    &:focus {
      box-shadow: none !important;
      border-color: var(--primary_stroke_color_light);
    }
    &:hover {
      outline: none !important;
      border-color: var(--primary_stroke_color_light);
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .ant-input-number {
    width: 32px;
    height: 40px;
    border-radius: 3px;
    border: 1px solid var(--primary_stroke_color);
    background: #fff;
  }
  .ant-input-number-input {
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      outline: none !important;
    }
  }
  /* .ant-input-outlined:hover,
  .ant-input-outlined:focus {
    border-color: unset !important;
    background-color: unset !important;
  } */
`
export const Wrapper = styled.div`
  display: flex;
  width: 240px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--primary_stroke_color_light);
  background-color: var(--primary_box_fill_color);
  input {
    border: 0px solid var(--primary_stroke_color);
    background: #fff;
    ::placeholder {
      color: var(--primary_text_color_dark);
      font-family: 'Mulish';
      font-size: 12px;
      font-weight: 700;
    }
  }
`
export const CountryCodes = styled.div`
  width: 80px;
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  border-right: 1px solid var(--primary_stroke_color_light);
`
export const SearchWrapper = styled.div`
  width: 320px;
  input {
    height: 30px;
    border: 1px solid var(--primary_stroke_color_light);
    background: var(--primary_box_fill_color);
  }
  .ant-input-affix-wrapper {
    border-radius: 28px;
  }
  .ant-input-outlined:focus,
  .ant-input-outlined:hover,
  .ant-input-outlined:focus-within {
    border: 1px solid var(--primary_stroke_color_light);
    box-shadow: none;
  }
`
export const PhoneWrapper = styled.div`
  color: var(--primary_text_color_dark);
  font-family: 'Mulish';
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
`
export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 42px;
  .ant-select-selector {
    outline: none;
    width: 100%;
    height: 44px !important;
    padding-top: 5px !important;
  }
  .ant-select {
    width: 100%;
  }
  .ant-select-arrow {
    color: red;
    font-size: unset;
  }
  .ant-select-selector .ant-select-selection-search-input {
    height: 44px !important;
  }
  .ant-select-arrow > span {
    svg {
      fill: red;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item > span {
    color: red;
  }
`

import { useHistory } from 'react-router-dom'
// import Button from 'components/Button'
import { PdpRoute } from 'constants/routes'
import { useTheme } from 'context/themeContext'
import CloseIcon from 'assets/svg/CloseIcon'

import {
  MobileSimilarProductSectionWrapper,
  MobileSimilarProductWrapper,
  MobileSimilarProductHeading,
  ProductWrapper,
  ProductDetailsWrapper,
} from 'styles/components/ListProducts'
import { SimilarSvgWrapper } from 'styles/views/LocationNewUser'
import { Tooltip, Typography } from 'antd'

const MobileSimilarProducts = ({ products, onClose }: any) => {
  const navigate = useHistory()
  const { storesData } = useTheme()

  return (
    <MobileSimilarProductSectionWrapper>
      <MobileSimilarProductHeading>
        <div className="h0b mobile-similar-heading">Similar Products</div>
        <SimilarSvgWrapper onClick={onClose}>
          <CloseIcon />
        </SimilarSvgWrapper>
      </MobileSimilarProductHeading>
      <MobileSimilarProductWrapper>
        {products?.map((product: any, index: number) => {
          const imageRatio = storesData?.d?.imageSize == 'Square' ? 'w_300,h_300' : 'w_300,h_450'
          let imgUrl =
            product?.imgUrl?.startsWith('http://') || product?.imgUrl?.startsWith('https://')
              ? product?.imgUrl
              : `https://cdnaz.plotch.io/image/upload/${imageRatio}${product?.imgUrl}`
          if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes(imageRatio)) {
            imgUrl = imgUrl?.split('/upload/').join(`/upload/${imageRatio}/`)
          }

          const productName = product?.productName
            .replace(/[^a-zA-Z ]/g, '')
            .split(' ')
            .join('-')
          return (
            <ProductWrapper
              key={index}
              className="product-wrapper"
              onClick={() => {
                navigate.push(
                  PdpRoute.path
                    .replace(':name', productName ? productName : 'product')
                    .replace(':id', product?.productId),
                )
                localStorage.setItem('productId', product?.productId)
              }}
            >
              <img src={imgUrl} className="similarImg" />
              <ProductDetailsWrapper>
                <div className="heading-wrapper">
                  <div className="heading-details">
                    <Typography.Text ellipsis={{ tooltip: product?.vendorName }} className="h1b subCategory">
                      {product?.vendorName.toLowerCase()}
                    </Typography.Text>
                  </div>
                  <Tooltip title={product.productName}>
                    <label className="h3r product-name">
                      {/* {product.productName} */}
                      {product.productName > 33
                        ? `${product.productName.toLowerCase()?.slice(0, 33)} ...`
                        : product.productName.toLowerCase()}
                    </label>
                  </Tooltip>
                </div>
                <div className="discount-details">
                  {product?.regularPrice === product?.discountedPrice ? (
                    <>
                      <div className="after-discount h1b">
                        &#8377;
                        {product?.regularPrice}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="after-discount h1b">
                        &#8377;
                        {product?.discountedPrice}
                      </div>
                      <div className="before-discount h3r">
                        <span> &#8377; {product.regularPrice} </span>
                      </div>
                    </>
                  )}

                  {product?.discountPercentage > 0 ? (
                    <div className="discount-percentage h4r">
                      {product.discountPercentage ? `${product.discountPercentage}% OFF` : null}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {/* <Button label="Add To Cart" variant="contained" type="submit" className="small" /> */}
              </ProductDetailsWrapper>
            </ProductWrapper>
          )
        })}
      </MobileSimilarProductWrapper>
    </MobileSimilarProductSectionWrapper>
  )
}

export default MobileSimilarProducts

import { useEffect, useState } from 'react'
// import { LoaderContext } from 'context/loader'
import OpenTicketStatus from 'assets/svg/OpenEmailIcon'
import ForwardChevron from 'assets/svg/ChevronForward'
import {
  TicketAgentTag,
  TicketDeatiledSection,
  TicketDetails,
  TicketStatus,
  TicketStatusIcon,
  TicketWrapper,
} from 'styles/components/Ticket'
import TagManager from 'react-gtm-module'
import { pageData } from 'utils/datalayer/pageData'
import { useHistory } from 'react-router-dom'
import { TicketRoute } from 'constants/routes'
import { useTheme } from 'context/themeContext'

const Ticket = ({ refetchTicket, data }: { refetchTicket: any; data: any }) => {
  const customerId = localStorage.getItem('customerId')
  const navigate = useHistory()

  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()
  const { storesData } = useTheme()
  const [ticketList, setTicketList] = useState([])
  // const { setLoader } = useContext(LoaderContext)

  useEffect(() => {
    if (customerId) {
      //setLoader(true)
      refetchTicket()
    }
  }, [customerId, refetchTicket])

  useEffect(() => {
    if (data?.s === 1) {
      setTicketList(data?.d?.raisedTicketList)
      //setLoader(false)
    }
  }, [data])
  const ticketOpenStatus = ['Active Ticket', 'On Hold', 'Agent Assigned', 'In Progress']

  return (
    <div>
      {ticketList ? (
        ticketList.map((item: any, key: number) => {
          return (
            <TicketWrapper
              key={key}
              onClick={() => {
                const ticketDataToPush = {
                  event: 'ticket_interaction',
                  page_type: GDL_PAGE_TYPE,
                  User_Id: loggedIn,
                  User_Status: loggedInStatus,
                  cta_text: 'open ticket',
                  order_ticket_id: item.ticketId ? item.ticketId : 'NA',
                }
                storesData?.d?.googleDatalayer &&
                  storesData?.d?.googleDatalayer == 1 &&
                  TagManager.dataLayer({ dataLayer: ticketDataToPush })
                navigate.push(TicketRoute.path.replace(':id', item.ticketId))
              }}
            >
              {/* <TicketWrapper key={key} onClick={() => (window.location.href = '/ticket/' + item.ticketId)}> */}
              <TicketStatus className="h1b">
                {ticketOpenStatus.includes(item['status']) ? (
                  <label className="open"> Open </label>
                ) : (
                  <label className="closed"> Closed </label>
                )}
                <TicketStatusIcon>
                  {ticketOpenStatus.includes(item['status']) !== false ? <OpenTicketStatus /> : ''}
                </TicketStatusIcon>
                <ForwardChevron className="forwordIcon" />
              </TicketStatus>
              <TicketDetails>
                <div className="h3b">Ticket Id: {item.ticketId}</div>
                <div className="h3r">Created On: {item['createdAt']}</div>
                <div className="row-divider"></div>
                <TicketDeatiledSection>
                  <img src={item['fileUpload']} alt="saree" />
                  <div className="ticket-details">
                    <div className="h3r ">{item['subject']}</div>
                    <div className="h3r">{item['content']}</div>
                    <TicketAgentTag className="h4b"> Agent Assigned</TicketAgentTag>
                  </div>
                </TicketDeatiledSection>
              </TicketDetails>
            </TicketWrapper>
          )
        })
      ) : (
        <div>No Tickets Available</div>
      )}
    </div>
  )
}

export default Ticket

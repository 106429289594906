// import axios from 'axios'
import APIS from 'constants/api'
import axiosInstance from 'services/axiosInstance'

class CustomAPIConnector {
  async onAutocomplete(searchTerm: string) {
    const url = `${APIS.SEARCH_AUTOCOMPLETE}?query=${encodeURIComponent(searchTerm)}`
    const { data } = await axiosInstance.get(url)

    try {
      const vendors = data.d.VendorsAutocompleteResult.map((vendor: any) => ({
        id: vendor._id,
        name: vendor._source.vendor_name,
        image: vendor._source.store_image,
        sellerId: vendor._source.seller_id,
        vendorId: vendor._source.vendor_id,
        // Adjust field names as per your actual API response
      }))

      const products = data.d.ProductsAutocompleteResult.map((product: any) => ({
        id: product._id,
        name: product._source.product_name,
        image: product._source.product_image, // Adjust field names as per your actual API response
        price: product._source.product_price, // Adjust field names as per your actual API response
      }))
      const combinedResult = {
        ProductResults: products,
        VendorResults: vendors,
      }

      return combinedResult
    } catch (error) {
      return {
        autocompletedResults: [],
      }
    }
  }
}

export default CustomAPIConnector

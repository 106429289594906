import { useEffect, useState } from 'react'
import { GetProp, Image, UploadFile, UploadProps } from 'antd'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { Controller } from 'react-hook-form'
import Dragger from 'antd/es/upload/Dragger'
import UploadIcon from 'assets/svg/UploadIcon'
// import { UploadIcon } from './UploadIcon' // Assuming you have an UploadIcon component

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const DragDropUploader = ({
  name,
  control,
  onBase64ListChange,
  //   length,
  defaultValue,
  handleDefault,
}: {
  name: string
  control: any
  onBase64ListChange: any
  //   length: number
  defaultValue: any
  handleDefault: any
}) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [base64List, setBase64List] = useState<string[]>([])
  const [fileList, setFileList] = useState<UploadFile[]>(defaultValue)

  const resetData = () => {
    setFileList([])
    setBase64List([])
  }

  useEffect(() => {
    resetData()
  }, [control.reset])

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList)
    convertFilesToBase64(newFileList)
  }

  useEffect(() => {
    onBase64ListChange(base64List)
  }, [base64List, onBase64ListChange])

  const convertFilesToBase64 = async (files: UploadFile[]) => {
    const base64Array: string[] = await Promise.all(
      files.map(async (file) => {
        if (file.originFileObj) {
          const finalstring = (await getBase64(file.originFileObj as FileType)).split('base64,')
          const finalFileUrl = finalstring[1]
          return finalFileUrl
        }

        return ''
      }),
    )

    setBase64List(base64Array)
    onBase64ListChange(base64Array)
    handleDefault(base64Array)
  }

  // File format validation
  const beforeUpload = (file: FileType) => {
    const isValidFormat =
      (file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/gif') &&
      (file.name.endsWith('.png') ||
        file.name.endsWith('.jpg') ||
        file.name.endsWith('.jpeg') ||
        file.name.endsWith('.gif'))

    if (!isValidFormat) {
      alert('You can only upload PNG, JPG, JPEG, or GIF files!')
    }

    return isValidFormat // Ignore the file if invalid
    // return isValidFormat || Upload.LIST_IGNORE // Ignore the file if invalid
  }

  const dummyRequest = ({ onSuccess }: UploadRequestOption) => {
    setTimeout(() => {
      onSuccess && onSuccess('ok')
    }, 0)
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          <Dragger
            {...field}
            customRequest={dummyRequest}
            fileList={fileList}
            beforeUpload={beforeUpload} // Use beforeUpload to validate file types and extensions
            onPreview={handlePreview}
            onChange={(info) => {
              handleChange(info)
              field.onChange(info.fileList)
            }}
            accept=".jpg,.jpeg,.png,.gif"
            multiple
          >
            <UploadIcon />
            <p className="h2r">Drag & Drop files here or click to browse</p>
          </Dragger>

          {previewImage && (
            <Image
              wrapperStyle={{ display: 'none' }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
            />
          )}
        </>
      )}
    />
  )
}

export default DragDropUploader

import PublicLayout from 'components/Layouts/public'
import React from 'react'
import { CommonStyle } from 'styles/pages/Common'
import SplitStoresHyperlocalView from 'views/SplitStoresHyperlocalView'

const SplitStoresHyperlocalPage = () => {
  return (
    <PublicLayout>
      <CommonStyle>
        <SplitStoresHyperlocalView />
      </CommonStyle>
    </PublicLayout>
  )
}

export default SplitStoresHyperlocalPage

import { Button, Select } from 'antd'
import FileUploader from 'components/FileUploader'
import SelectField from 'components/SelectField'
import APIS from 'constants/api'
import usePost from 'hooks/usePost'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CommonPopupModalBodySection, Divider } from 'styles/components/PopupModal'
import { FileUpload, LabelForm } from 'styles/pages/Common'
import { ErrorMessage, TextWrapper } from 'styles/pages/Login'
import { AddressFormWrapper, FormFieldsWrapper } from 'styles/views/SaveAddress'
import Modal from 'components/Modal'
import useGet from 'hooks/useGet'
// import { SnackBarContext } from 'context/SnackBarContext'

const ReturnOrderModal = ({
  open,
  onOk,
  onCancel,
  title,
  BtnName,
}: //   getAllTickets,
{
  open: boolean
  onOk: any
  onCancel: any
  title: string
  BtnName: string
  getAllTickets: any
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      orderId: 'none',
      description: '',
      queryType: '',
      fileUpload: [],
    },
  })
  const { Option } = Select
  const { mutateAsync } = usePost()
  //   const { setShowSnackBar } = useContext(SnackBarContext)
  const customerId = localStorage.getItem('customerId')
  const [data, setData] = useState<any>([])
  //   const [orderOption, setOrderOptions] = useState<any>([])
  //   const [base64List, setBase64List] = useState<string[]>([])
  const [defaultValue, setDefaultValue] = useState<any[]>([])

  const { refetch, data: retunDataDetails } = useGet('return-data', `${APIS.RETURN_REASONS}`)
  const returnData = retunDataDetails?.d?.returnReasons

  const getOrderIdList = async () => {
    try {
      const res = await mutateAsync({
        url: APIS.ORDER_LIST,
        payload: {
          customerId: customerId,
        },
      })
      if (res) {
        setData(res.d?.orders)
      }
    } catch (err: any) {
      throw new Error(err?.message)
    }
  }
  const handleBase64ListChange = () => {
    // setBase64List(newBase64List)
  }
  // const handleChange = (value: any) => {
  //   console.log(`${value}`)
  // }

  const submitReturnReason = async (data: any) => {
    data
    // try {
    //   const orderKeys = data?.orderId?.split('/')
    //   const payload = {
    //     accountId: 0,
    //     content: data?.description,
    //     createdBy: 0,
    //     customerId: customerId,
    //     fileUpload: base64List?.[0] || '',
    //     orderId: orderKeys[0] ? orderKeys[0] : 0,
    //     orderItemId: orderKeys[2] ? orderKeys[2] : 0,
    //     shipmentId: orderKeys[1] ? orderKeys[1] : 0,
    //     status: 0,
    //     subjectId: data?.query_type,
    //     alternateOrderId: '',
    //     channelType: 'website',
    //   }
    //   const res = await mutateAsync({
    //     url: APIS.CREATE_TICKET,
    //     payload: payload,
    //   })
    //   if (res) {
    //     // setShowSnackBar({ open: true, message: `${res.m}`, severity: '' })
    //     setShowSnackBar({
    //       open: true,
    //       message: `We have received your complaint and we will update you within 24 hrs. Please find your complaint number ${res.d.ticketId}`,
    //       severity: '',
    //     })
    //     onCancel()
    //     reset()
    //     getAllTickets()
    //     setBase64List([])
    //   }
    // } catch (err: any) {
    //   throw new Error(err?.message)
    // }
  }

  useEffect(() => {
    getOrderIdList()
  }, [customerId])

  useEffect(() => {
    refetch()
  }, [returnData])

  useEffect(() => {
    const options_data: any = {
      order_id: [],
      ondc_order_id: [],
    }
    if (data) {
      data.forEach((order: any) => {
        order?.shipments?.forEach((shipments: any) => {
          shipments?.products.forEach((product: any) => {
            const option = {
              key: order.orderId + '/' + shipments.id + '/' + product.orderItemId,
              value: 'Order ' + order.orderId + ' / ' + product.orderItemId,
            }
            const optionOndc = {
              key: order.orderId + '/' + shipments.id + '/' + product.orderItemId,
              value: product.ondcOrderId,
            }
            options_data['order_id'].push(option)
            options_data['ondc_order_id'].push(optionOndc)
          })
        })
      })
      //   setOrderOptions(options_data)
    }
  }, [data])
  const handleDefaultValue = (fileData: any) => {
    setDefaultValue(fileData)
  }
  const handleCancle = () => {
    onCancel()
    reset()
    setDefaultValue([])
  }

  return (
    <Modal
      centered
      className="raiseTicketModal h1b"
      open={open}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button key="back" onClick={handleCancle}>
          Close
        </Button>,
        <Divider key="" />,
        <Button className="customButton" key="submit" type="primary" onClick={handleSubmit(submitReturnReason)}>
          <span className="h1b">{BtnName}</span>
        </Button>,
      ]}
    >
      <CommonPopupModalBodySection>
        <AddressFormWrapper onSubmit={handleSubmit(submitReturnReason)}>
          <FormFieldsWrapper className="returnData">
            <TextWrapper>
              <LabelForm className="form-label">Select Reason *</LabelForm>
              <SelectField defaultValue={''} control={control} name="query_type">
                <Option key="placeholder" value="" disabled>
                  Select Reason
                </Option>
                {returnData?.map((item: any) => {
                  return (
                    <Option key={item.reasonId} value={item.reasonId}>
                      {/* <Option key={item.reasonId} value={item.reasonId} attachment_required={item.attachment_required}> */}
                      {item.reason}
                    </Option>
                  )
                })}
              </SelectField>

              <ErrorMessage>{errors?.message}</ErrorMessage>
            </TextWrapper>
            <TextWrapper>
              <LabelForm className="form-label">Select Images*</LabelForm>
              <FileUpload>
                <FileUploader
                  name="fileUpload"
                  control={control}
                  defaultValue={defaultValue}
                  handleDefault={handleDefaultValue}
                  onBase64ListChange={handleBase64ListChange}
                  length={1}
                />
              </FileUpload>
              <ErrorMessage>{errors?.message}</ErrorMessage>
              <div className="h4r upload-type"> Only PNG, JPG, JPEG, GIF files allowed!</div>
            </TextWrapper>
            <TextWrapper>
              <LabelForm className="form-label">Return Comment</LabelForm>
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <textarea
                    style={{ width: '100%' }}
                    {...field}
                    rows={5}
                    placeholder="Description...."
                    className="custom-textarea"
                  />
                )}
              />
              <ErrorMessage>{errors?.message}</ErrorMessage>
            </TextWrapper>
          </FormFieldsWrapper>
        </AddressFormWrapper>
      </CommonPopupModalBodySection>
    </Modal>
  )
}

export default ReturnOrderModal

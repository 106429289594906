import { Carousel, Tooltip, Typography } from 'antd'
// import Button from 'components/Button'
import { PdpRoute } from 'constants/routes'
import { useTheme } from 'context/themeContext'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  SimilarProductSectionWrapper,
  SimilarProductWrapper,
  SimilarProductHeading,
  ProductWrapper,
  ProductDetailsWrapper,
  ImageWrapper,
} from 'styles/components/ListProducts'
import { BlockDiv, ItemsCategoryWrapper } from 'styles/pages/Home'

const SimilarProducts = ({ products }: any) => {
  const navigate = useHistory()
  const maxInitialProducts = 8
  const [showAllProducts, setShowAllProducts] = useState(false)

  const toggleShowAllProducts = () => {
    setShowAllProducts(!showAllProducts)
  }
  const sliderConfig = products?.slider_config

  // const speed_val = sliderConfig?.speed && sliderConfig.speed != '' ? sliderConfig?.speed : 600
  // const slides_to_show_val =
  sliderConfig?.slides_to_show && sliderConfig?.slides_to_show != '' ? sliderConfig?.slides_to_show : 6

  // const slides_to_scroll_val =
  sliderConfig?.slides_to_scroll && sliderConfig.slides_to_scroll != '' ? sliderConfig?.slides_to_scroll : 1

  // const autoplay = sliderConfig?.autoplay ? true : false

  const carouselSetting = {
    infinite: true,
    arrows: true,
    dots: false,
    speed: sliderConfig?.speed || 600,
    slidesToShow: sliderConfig?.slides_to_show || 5,
    slidesToScroll: sliderConfig?.slides_to_scroll || 1,
    autoplay: sliderConfig?.autoplay ? true : false,
    draggable: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  let displayedProducts = showAllProducts ? products?.products : products?.products.slice(0, maxInitialProducts)

  if (sliderConfig) {
    displayedProducts = products?.products
  }

  const { storesData } = useTheme()
  return (
    <SimilarProductSectionWrapper>
      <SimilarProductHeading>
        <div className="ultra-bold">Similar Products</div>
        {!showAllProducts && (products?.widget_style == '' || products?.widget_style == 'grid') && (
          <div className="view-all h1b" onClick={toggleShowAllProducts}>
            View all
          </div>
        )}
      </SimilarProductHeading>

      {products?.widget_style == '' || products?.widget_style == 'grid' ? (
        <SimilarProductWrapper>
          {displayedProducts?.map((product: any, index: number) => {
            const imageRatio = storesData?.d?.imageSize == 'Square' ? 'w_300,h_300' : 'w_301,h_450'

            let imgUrl =
              product?.imgUrl?.startsWith('http://') || product?.imgUrl?.startsWith('https://')
                ? product?.imgUrl
                : `https://cdnaz.plotch.io/image/upload/${imageRatio}${product?.imgUrl}`
            if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes(imageRatio)) {
              imgUrl = imgUrl?.split('/upload/').join(`/upload/${imageRatio}/`)
            }

            const productName = product?.productName
              .replace(/[^a-zA-Z ]/g, '')
              .split(' ')
              .join('-')
            return (
              <ProductWrapper
                key={index}
                className="product-wrapper"
                onClick={() => {
                  navigate.push(
                    PdpRoute.path
                      .replace(':name', productName ? productName : 'product')
                      .replace(':id', product?.productId),
                  )
                  localStorage.setItem('productId', product?.productId)
                }}
              >
                <img className="similarImg" src={imgUrl} />

                <ProductDetailsWrapper className="similarProducts">
                  <div className="heading-wrapper">
                    <div className="heading-details">
                      <Typography.Text ellipsis={{ tooltip: product?.vendorName }} className="h1b subCategory">
                        {product?.vendorName.toLowerCase()}
                      </Typography.Text>
                    </div>
                    <Tooltip title={product.productName}>
                      <label className="h3r product-name">
                        {/* {product.productName} */}
                        {product.productName > 33
                          ? `${product.productName.toLowerCase()?.slice(0, 33)} ...`
                          : product.productName.toLowerCase()}
                      </label>
                    </Tooltip>
                  </div>
                  <div className="discount-details">
                    {product?.regularPrice === product?.discountedPrice ? (
                      <>
                        <div className="after-discount h1b">
                          &#8377;
                          {product?.regularPrice}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="after-discount h1b">
                          &#8377;
                          {product?.discountedPrice}
                        </div>
                        <div className="before-discount h3r">
                          <span> &#8377; {product.regularPrice} </span>
                        </div>
                      </>
                    )}

                    {product?.discountPercentage > 0 ? (
                      <div className="discount-percentage h4r">
                        {product.discountPercentage ? `${product.discountPercentage}% OFF` : null}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* <Button label="Add To Cart" variant="contained" type="submit" className="small" /> */}
                </ProductDetailsWrapper>
              </ProductWrapper>
            )
          })}
        </SimilarProductWrapper>
      ) : products.widget_style == 'carousel' ? (
        <ItemsCategoryWrapper>
          <BlockDiv className="myBlock" dimensions={{ rows: 1, cols: 1 }} css="">
            <Carousel className="carousel-rect" {...carouselSetting}>
              {displayedProducts?.map((product: any, index: number) => {
                let imageRatio = storesData?.d?.imageSize == 'Square' ? 'w_300,h_300' : 'w_300,h_450'
                if (products?.meta_info?.image_render_type) {
                  imageRatio = products?.meta_info?.image_render_type == 'sq' ? 'w_300,h_300' : 'w_300,h_450'
                }

                let imgUrl =
                  product?.imgUrl?.startsWith('http://') || product?.imgUrl?.startsWith('https://')
                    ? product?.imgUrl
                    : `https://cdnaz.plotch.io/image/upload/${imageRatio}${product?.imgUrl}`
                if (imgUrl?.includes('cdnaz.plotch.io') && !imgUrl?.includes(imageRatio)) {
                  imgUrl = imgUrl?.split('/upload/').join(`/upload/${imageRatio}/`)
                }

                imgUrl = imgUrl + '?product_id=' + product?.productId + '&s=1'
                if (products?.meta_info?.image_render_type) {
                  imgUrl = imgUrl + '&tf=' + products?.meta_info?.image_render_type
                }

                const productName = product?.productName
                  .replace(/[^a-zA-Z ]/g, '')
                  .split(' ')
                  .join('-')

                return (
                  <ProductWrapper
                    key={index}
                    className="product-wrapper"
                    onClick={() => {
                      navigate.push(
                        PdpRoute.path
                          .replace(':name', productName ? productName : 'product')
                          .replace(':id', product?.productId),
                      )
                    }}
                  >
                    <ImageWrapper>
                      <img src={imgUrl} />
                    </ImageWrapper>

                    <ProductDetailsWrapper>
                      <div className="heading-wrapper">
                        <div className="heading-details">
                          <label className="h1b product-name">{product.productName}</label>
                          {/* {wishlistIdData[product.productId] ? (
                            <WishlistedIcon
                              className="wishlist-icon"
                              onClick={(e: any) => {
                                e.stopPropagation()
                                if (customerId) {
                                  handleRemoveWishlistClick(product?.productId)
                                }
                              }}
                            />
                          ) : (
                            <HeartIcon
                              className="wishlist-icon"
                              onClick={(e: any) => {
                                e.stopPropagation()
                                if (customerId) {
                                  handleWishlistClick(product?.productId)
                                } else {
                                  navigate.push(LoginRoute.path)
                                }
                              }}
                            />
                          )} */}
                        </div>
                        <label className="h3r subCategory">{product?.categoryName}</label>
                      </div>
                      <div className="discount-details">
                        {product?.regularPrice === product?.discountedPrice ? (
                          <div className="after-discount h1b">
                            &#8377;
                            {product?.regularPrice}
                          </div>
                        ) : (
                          <>
                            <div className="after-discount h1b">
                              &#8377;
                              {product?.discountedPrice}
                            </div>
                            <div className="before-discount">
                              <span> &#8377; {product.regularPrice} </span>
                            </div>
                          </>
                        )}

                        {product?.discountPercentage > 0 ? (
                          <div className="discount-percentage h4r">
                            {product.discountPercentage ? `${product.discountPercentage}% OFF` : null}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </ProductDetailsWrapper>
                  </ProductWrapper>
                )
              })}
            </Carousel>
          </BlockDiv>
        </ItemsCategoryWrapper>
      ) : (
        <></>
      )}
    </SimilarProductSectionWrapper>
  )
}

export default SimilarProducts

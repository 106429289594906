import { ReactNode, useEffect, useState } from 'react'
import { useTheme } from 'context/themeContext'
import { LoginPageWrapper, TopBar, BodyWrapper, BodyWrappers } from 'styles/pages/Login'
import { useHistory } from 'react-router-dom'
import {
  AddAddressPindropRoute,
  AddAddressRoute,
  CancellationPolicyRoute,
  CookiePolicyRoute,
  HomeRoute,
  PrivacyPolicyRoute,
  ReturnPolicyRoute,
  TermsAndCondionRoute,
} from 'constants/routes'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'

const PrivateLayout = ({ children }: { children: ReactNode }) => {
  const [scrollTriggered, setScrollTriggered] = useState({
    25: false,
    50: false,
    75: false,
    100: false,
  })
  const { themedata, storesData } = useTheme()
  const navigate = useHistory()
  const headerLogo = themedata?.d?.objectDetails?.header?.siteLogo?.logoImage
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE, address } = pageData()
  // const addressData =address && address != 'na' ? JSON.parse(address) : ""
  const handleClick = () => {
    navigate.push(HomeRoute.path)
    const dataToPush = {
      event: 'logo_click',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })
  }

  useEffect(() => {
    const pageLoadData = {
      event: '-',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      selected_location: address,
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: pageLoadData })
  }, [])
  const handleScroll = () => {
    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight
    const documentHeight = document.documentElement.scrollHeight

    const scrollPercent = ((scrollTop + windowHeight) / documentHeight) * 100

    if (scrollPercent >= 25 && !scrollTriggered[25]) {
      triggerFunction(25)
      setScrollTriggered((prevState) => ({ ...prevState, 25: true }))
    }

    if (scrollPercent >= 50 && !scrollTriggered[50]) {
      triggerFunction(50)
      setScrollTriggered((prevState) => ({ ...prevState, 50: true }))
    }

    if (scrollPercent >= 75 && !scrollTriggered[75]) {
      triggerFunction(75)
      setScrollTriggered((prevState) => ({ ...prevState, 75: true }))
    }

    if (scrollPercent >= 99.5 && !scrollTriggered[100]) {
      triggerFunction(100)
      setScrollTriggered((prevState) => ({ ...prevState, 100: true }))
    }
  }

  const triggerFunction = (percentage: any) => {
    const scrollDataLayer = {
      event: 'page_scroll',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
      scroll_percent: `${percentage}%`,
    }

    // Assuming gtm_event is your function to push events to GTM or dataLayer
    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: scrollDataLayer })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollTriggered])

  return (
    <LoginPageWrapper>
      <TopBar>{headerLogo && <img src={headerLogo} onClick={handleClick} loading="lazy" />}</TopBar>
      {!(
        location.pathname == `${TermsAndCondionRoute.path}` ||
        location.pathname == `${PrivacyPolicyRoute.path}` ||
        location.pathname == `${CookiePolicyRoute.path}` ||
        location.pathname == `${ReturnPolicyRoute.path}` ||
        location.pathname == `${CancellationPolicyRoute.path}` ||
        location.pathname == `${AddAddressPindropRoute.path}` ||
        location.pathname == `${AddAddressRoute.path}`
      ) ? (
        <BodyWrapper>{children}</BodyWrapper>
      ) : (
        <BodyWrappers>{children}</BodyWrappers>
      )}
    </LoginPageWrapper>
  )
}

export default PrivateLayout

import { SVGProps } from 'react'
// import { theme } from 'theme'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M4.68745 7.50006L6.56383 5.62368L8.44021 7.50006"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.56299 6.24994L6.56299 14.3749C6.56546 15.203 6.8955 15.9964 7.48101 16.5819C8.06653 17.1674 8.85995 17.4975 9.68799 17.4999H10.313"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3157 12.4984L13.4393 14.3748L11.5629 12.4984"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4379 13.7504V5.62543C13.4354 4.79738 13.1054 4.00396 12.5198 3.41845C11.9343 2.83293 11.1409 2.5029 10.3129 2.50043H9.68787"
      stroke="var(--primary_icon_static_color)"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent

import styled from 'styled-components'

export const OrderDetailsWrapper = styled.div``
export const DeliveryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--primary_box_fill_highlight_color);
  padding: 10px 15px 11px 19px;
  .deliver-detail-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .profile-change-btn {
    color: var(--primary_text_link_color) !important;
    cursor: pointer;
  }
  .address-detail {
    text-transform: capitalize;
    width: 258px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
export const OrderDetailContainer = styled.div`
  padding: 23px 19px;
  .trash {
    cursor: pointer;
  }
  .total {
    margin-top: 14px;
  }
  .colourBox {
    display: inline-block;
    width: 12px;
    height: 12px;
    vertical-align: middle;
  }
  .order-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary_box_fill_color);
  }
  .product-complete-description {
    padding: 13px 0;
    display: flex;
    gap: 13px;
  }
  .vendor-name {
    padding-top: 14px;
    text-transform: capitalize;
    /* padding-bottom: 5px; */
  }
  .product-name {
    text-transform: capitalize;
  }
  .shipping-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 4px 0 24px;
  }
  .shipping-heading {
    /* text-decoration: underline; */
    color: var(--primary_text_color_darkgrey);
    border-bottom: 1px solid var(--primary_text_color_darkgrey);
    /* margin: 4px 0 10px; */
    cursor: pointer;
  }
  .shipping-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .shipping-wraps {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .product-description {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }
  .description {
    display: flex;
    justify-content: space-between;
  }
  .price-details-container {
    padding-top: 10px;
    border-top: 1px solid var(--primary_line_color_light);
  }
  .price-details-wrapper {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .discount {
    color: var(--secondary_text_link_color);
  }
  .quantity {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 6px;
    cursor: pointer;
    width: 72px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid var(--primary_button_stroke_color);
    background: var(--primary_box_fill_color);
    color: var(--primary_success_color);
  }
  .quantity-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
  }
  .cancel {
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
  }
  .delivery-time-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .delivery-time {
    text-transform: capitalize;
  }
  img {
    width: 60px;
    aspect-ratio: 1;
    object-fit: contain;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  .before-discount span {
    text-decoration: line-through;
  }
  .discount-details {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
  }
  .discount-percentage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 16px;
    border-radius: 8px;
    background: var(--primary_label_active_fill_color);
    color: var(--primary_text_color_light);
    padding: 1px 0px;
  }
  .addonsBlock {
    color: #2d2d2d;
    width: 80%;
    display: flex;
    gap: 4px;
    flex-flow: wrap;
  }
  .addons {
    display: flex;
    padding: 1px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--primary_box_fill_highlight_color, #fff6f6);
  }
  @media (max-width: 768px) {
    padding: 23px 19px;
  }
`
export const DividerLine = styled.div`
  height: 8px;
  background: var(--primary_line_color_light);
`
export const CoupanDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 23px 19px 18px;
  .coupan-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary_box_fill_color);
  }
  .applied-coupan {
    color: var(--primary_success_color);
  }
`
export const PaymentOptionsContainer = styled.div`
  .payment-option {
    padding: 14px 19px 9px;
    background: var(--primary_line_color_light);
  }
  .options-wrapper {
    padding: 0px 19px 18px 18px;
  }
  .option {
    padding-bottom: 20px;
    padding-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--primary_line_color_light);
  }
  .last-option {
    border-bottom: 0px solid var(--primary_line_color_light);
  }
  @media (max-width: 768px) {
  }
`

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 21 21" fill="none" {...props}>
    <path
      stroke="#121212"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="M8.784 3.172a6.348 6.348 0 0 0-3.441 1.009A6.036 6.036 0 0 0 3.06 6.868a5.8 5.8 0 0 0-.352 3.459 5.928 5.928 0 0 0 1.695 3.065 6.264 6.264 0 0 0 3.172 1.638 6.393 6.393 0 0 0 3.579-.34 6.153 6.153 0 0 0 2.78-2.205 5.845 5.845 0 0 0 1.044-3.326c0-1.588-.653-3.11-1.815-4.233a6.305 6.305 0 0 0-4.38-1.754Z"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.002}
      d="m13.405 13.625 4.328 4.183"
    />
  </svg>
)
export default SvgComponent

import SuggesstionNav from 'components/Navbar/suggesstionNav'
// import React from 'react'

const SuggestionPage = () => {
  return (
    <div>
      <SuggesstionNav />
    </div>
  )
}

export default SuggestionPage

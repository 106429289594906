import { useEffect, useState } from 'react'
import { useTheme } from 'context/themeContext'
import APIS from 'constants/api'
import useGet from 'hooks/useGet'
// import { Link } from 'react-router-dom'
import CloseIcon from 'assets/svg/CloseIcon'
import DownArrow from 'assets/svg/DownArrow'
import UpArrow from 'assets/svg/UpArrow'
// import PersonIcon from 'assets/svg/PersonIcon'
import {
  NavProductContainer,
  Label,
  NavListSvgWrapper,
  CategoryWrapper,
  CategoriesList,
  CategoryName,
  PolicyLabel,
  SubCategoryWrapper,
  SubCategoryWrappers,
  SubCategoryName,
} from 'styles/views/NavProductList'
import TagManager from 'react-gtm-module'
// import { useHistory } from 'react-router-dom'
import { pageData } from 'utils/datalayer/pageData'

const NavProductList = ({ onClose }: any) => {
  const { themedata, storesData } = useTheme()
  const footerData = themedata?.d?.objectDetails?.footer
  // const router = useHistory()

  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  const footerListKeys = Object.keys(footerData || {}).filter((key: any) => key.startsWith('footerLinkList'))
  const extractedLists = footerListKeys.map((key) => ({
    ...footerData[key],
    objectDetails: footerData[key]?.objectDetails,
  }))

  const enabledLists = extractedLists.filter((list) => {
    const enableKey = Object.keys(list).find((key) => key.startsWith('enableLinkList')) ?? ''
    return list[enableKey] === '1'
  })

  const { refetch, data } = useGet('navigation-data', `${APIS.HEADER_NAVIGATION}`)
  const navigationDetails = data?.d?.navigationDetails

  const [openCategories, setOpenCategories] = useState(Array(navigationDetails?.length).fill(false))
  const [openSubCategories, setOpenSubCategories] = useState(Array(navigationDetails?.length).fill(false))

  const handleCategoryClick = (index: number) => {
    setOpenCategories((prevState) => {
      const newState = [...prevState]
      newState[index] = !newState[index]
      return newState
    })
  }
  const handleSubCategoryClick = (index: number) => {
    setOpenSubCategories((prevState) => {
      const newState = [...prevState]
      newState[index] = !newState[index]
      return newState
    })
  }
  const handleDataLayerPush = (category: any, item?: any, categoryHead?: string) => {
    const itemName = category?.name.toLowerCase()
    // const categoryNames = category.map((item: any) => item.name)
    const url = `${category.target.url.includes('https') ? category.target.url : '/' + category.target.url}`
    // router.push(`${category.target.url.includes('https') ? category.target.url : '/' + category.target.url}`)
    let sectionName = 'na'
    let categoryName = 'na'
    if (item) {
      sectionName = item?.name?.toLowerCase() || 'na'
    } else {
      categoryName = itemName
    }

    if (categoryHead) {
      categoryName = categoryHead?.toLowerCase()
    }

    const dataToPush = {
      event: 'top_navigation',
      page_type: GDL_PAGE_TYPE,
      User_Status: loggedInStatus,
      User_Id: loggedIn,
      cta_text: itemName,
      select_type: 'hamburger',
      section_name: sectionName,
      category: categoryName,
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })

    if (categoryHead) window.location.href = url
    else if (category?.data == undefined) window.location.href = url
  }
  const handleBelowDataLayerPush = (category: any) => {
    const itemName = category?.text.toLowerCase()
    const url = `${category.href.includes('https') ? category.href : '/' + category.href}`
    // router.push(`${category.href.includes('https') ? category.href : '/' + category.href}`)

    const dataToPush = {
      event: 'top_navigation',
      page_type: GDL_PAGE_TYPE,
      User_Status: loggedInStatus,
      User_Id: loggedIn,
      cta_text: itemName ? itemName : 'na',
      select_type: 'hamburger',
      section_name: 'policy and info',
      category: 'na',
    }

    storesData?.d?.googleDatalayer &&
      storesData?.d?.googleDatalayer == 1 &&
      TagManager.dataLayer({ dataLayer: dataToPush })

    window.location.href = url
  }

  useEffect(() => {
    refetch()
  }, [data])

  return (
    <NavProductContainer>
      <NavListSvgWrapper>
        {/* <PersonIcon /> */}

        <Label className="h1r">Popular Categories</Label>
        <CloseIcon onClick={onClose} />
      </NavListSvgWrapper>

      <CategoryWrapper>
        {navigationDetails &&
          navigationDetails?.map((item: any, index: number) => {
            return (
              <SubCategoryWrapper key={index} className="sub-category">
                {item?.data && item?.data?.length > 0 ? (
                  <CategoriesList onClick={() => handleCategoryClick(index)}>
                    <CategoryName className="h2b">
                      <a
                        // href={`${item.target.url.includes('https') ? item.target.url : '/' + item.target.url}`}
                        className="plotch_a_link"
                        onClick={() => handleDataLayerPush(item)}
                      >
                        <CategoryName className="h1b">{item?.name}</CategoryName>
                      </a>
                    </CategoryName>
                    {openCategories[index] ? <UpArrow /> : <DownArrow />}
                  </CategoriesList>
                ) : (
                  <CategoriesList onClick={() => handleCategoryClick(index)}>
                    <CategoryName className="h2b">
                      <a
                        // href={`${item.target.url.includes('https') ? item.target.url : '/' + item.target.url}`}
                        className="plotch_a_link"
                        onClick={() => handleDataLayerPush(item)}
                      >
                        <CategoryName className="h1b">{item?.name}</CategoryName>
                      </a>
                    </CategoryName>
                  </CategoriesList>
                )}
                {openCategories[index] &&
                  item?.data?.map((category: any, categoryIndex: number) => {
                    return (
                      <SubCategoryWrappers key={categoryIndex}>
                        <CategoriesList
                          onClick={() => handleSubCategoryClick(categoryIndex)}
                          className="sub-categories"
                        >
                          <SubCategoryName className="h3r">
                            <a
                              // href={`${
                              //   category.target.url.includes('https') ? category.target.url : '/' + category.target.url
                              // }`}
                              className="plotch_a_link"
                              onClick={() => handleDataLayerPush(category, item)}
                            >
                              {category?.name}
                            </a>
                          </SubCategoryName>
                          {openSubCategories[categoryIndex] ? (
                            <UpArrow className="sub-icon" />
                          ) : (
                            <DownArrow className="sub-icon" />
                          )}
                        </CategoriesList>
                        {openSubCategories[categoryIndex] &&
                          category?.data?.map((subCategory: any, subCategoryIndex: number) => (
                            <CategoriesList key={subCategoryIndex} className="sub-category">
                              <CategoryName className="h3r">
                                <a
                                  // href={`${
                                  //   subCategory.target.url.includes('https')
                                  //     ? subCategory.target.url
                                  //     : '/' + subCategory.target.url
                                  // }`}
                                  className="plotch_a_link"
                                  onClick={() => handleDataLayerPush(subCategory, item, category?.name)}
                                >
                                  {subCategory?.name}
                                </a>
                              </CategoryName>
                            </CategoriesList>
                          ))}
                      </SubCategoryWrappers>
                    )
                  })}
              </SubCategoryWrapper>
            )
          })}
      </CategoryWrapper>

      <CategoryWrapper>
        {enabledLists.map((category, index) => {
          const titleKey = Object.keys(category).find((key) => key.endsWith('Title'))
          const title = titleKey ? category[titleKey] : ''
          if (title === 'TOP CATEGORIES' || title === 'OUR COMPANY') {
            // if (title === 'TOP CATEGORIES' || 'OUR COMPANY') {
            return null
          }

          return (
            <CategoryWrapper key={index}>
              {(category?.objectDetails?.length || category?.objectDetails == 'undefined') && (
                <PolicyLabel className="h1r">{title}</PolicyLabel>
              )}

              {category?.objectDetails?.map((item: any, idx: number) => (
                <CategoriesList key={idx}>
                  <CategoryName className="h1b">
                    <a
                      // href={`${item.href.includes('https') ? item.href : '/' + item.href}`}
                      className="plotch_a_link"
                      onClick={() => handleBelowDataLayerPush(item)}
                    >
                      {item?.text}
                    </a>
                  </CategoryName>
                  {/* <DownArrow /> */}
                </CategoriesList>
              ))}
            </CategoryWrapper>
          )
        })}
      </CategoryWrapper>
    </NavProductContainer>
  )
}

export default NavProductList

import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M6 3.75c.375-1.48 1.791-2.236 4.875-2.25a.374.374 0 0 1 .375.375v6.75a.375.375 0 0 1-.375.375c-3 0-4.159.605-4.875 1.5C5.288 9.61 4.125 9 1.125 9 .893 9 .75 8.811.75 8.58V1.875a.374.374 0 0 1 .375-.375c3.084.014 4.5.77 4.875 2.25ZM6 3.75v6.75"
    />
  </svg>
)
export default SvgComponent

import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import PublicLayout from 'components/Layouts/public'
import { OrderSuccessRoute } from 'constants/routes'
import APIS from 'constants/api'
// import { LoaderContext } from 'context/loader'
import { SnackBarContext } from 'context/SnackBarContext'
import usePost from 'hooks/usePost'
import RazorpayPayment from 'views/RazorpayPayment'
import PaymentOrderDetails from 'views/PaymentOrderDetails'
import BackArrow from 'assets/svg/BackArrow'
import { CommonStyle } from 'styles/pages/Common'
import { CheckoutWrappers, BackWrapper, CheckoutButtonWrapper, CheckoutPageWrapper } from 'styles/pages/MyAccount'
import PayuPayment from 'views/PayuPayment'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'

const CheckoutPage = () => {
  const [selectedRadioValue, setSelectedRadioValue] = useState<string | null>(null)
  const [prepaidData, setPrepaidData] = useState<any>()
  const [checkoutDetails, setCheckoutDetails] = useState<any>()
  const [googleDatalayer, setGoogledataLayer] = useState<any>()
  const [prepaidError, setPrepaidError] = useState<any>()
  const navigate = useHistory()
  const customerId = localStorage.getItem('customerId')
  const { setShowSnackBar } = useContext(SnackBarContext)
  const { mutateAsync } = usePost()
  const history = useHistory()
  const { loggedIn, loggedInStatus, address, GDL_PAGE_TYPE } = pageData()

  const handleBack = () => {
    history.go(-1) // Navigates two steps back in the history stack
  }

  type Product = {
    productId: number
    productName: string
    brand: string
    discountPercentage: number
    variants: {
      [key: string]: string[]
    }
    category: string
    regularPrice: number
    qty: number
    isReturnable: string
  }
  const handlePrepaidClickPay = async () => {
    try {
      const res = await mutateAsync({
        url: `${APIS.PLACED_ORDER_PREPAID}`,
        payload: {
          attchedFile: '',
          message: '',
          coinAmount: 0,
          moneyAmount: 0,
          paymentCategory: null,
          customerId: customerId,
          loyaltyCoin: '0',
          customerOrderedCurrency: 'INR',
          plotch_referral: '',
          plotch_referral_key: '',
          attribution_instance_id: '',
        },
      })
      if (res) {
        const coupon = checkoutDetails?.d?.couponCode || 'na'
        const freeShipping = checkoutDetails?.d?.shippingAmount == 0 ? true : false
        const itemsArray = checkoutDetails.d.productList.map((productItem: Product) => {
          const itemVariant = Object.entries(productItem.variants || {})
            .map(([key, value]) => `${key}-${(value as string[])[0] || 'default'}`)
            .join(' | ')

          let categoryText = 'na'

          if (productItem.isReturnable) {
            categoryText = `Returnable: ${productItem.isReturnable}`
          }

          return {
            item_name: productItem.productName?.toLowerCase(),
            item_id: productItem.productId,
            item_brand: productItem.brand?.toLowerCase(),
            discount: productItem.discountPercentage,
            item_variant: itemVariant,
            item_category: productItem.category,
            item_category2: 'na',
            item_category3: 'na',
            item_category4: 'na',
            item_category5: categoryText,
            price: productItem.regularPrice,
            item_list_id: 'na',
            item_list_name: 'na',
            quantity: productItem.qty,
            coupon: coupon,
          }
        })

        const totalValue = itemsArray.reduce((acc: number, item: { price: number }) => acc + item.price, 0)

        const elObject = {
          event: 'add_payment_info',
          User_Id: loggedIn,
          User_Status: loggedInStatus,
          selected_location: address,
          page_type: GDL_PAGE_TYPE,
          is_free_shipping: freeShipping,
          payment_type: 'prepaid',
          ecommerce: {
            value: totalValue,
            currency: 'INR',
            coupon: coupon,
            items: itemsArray,
          },
        }

        if (checkoutDetails?.d?.productList && googleDatalayer === '1') {
          TagManager.dataLayer({ dataLayer: elObject })
        }

        localStorage.setItem('orderId', res.d.orderId)
        if (res?.d?.paid == true) {
          successCallbackUrl(res)
        } else {
          setPrepaidData(res)
        }
      }
    } catch (error: any) {
      if (error.response) {
        const res = error.response.data
        if (res.d) {
          setPrepaidError(res.d?.productList)
        }
      }
    }
  }

  const successCallbackUrl = async (res: any) => {
    try {
      const payload: any = {}
      payload.orderId = res?.d?.orderId
      payload.udf3 = res?.d?.orderId
      payload.paymentGateway = 'prepaidCoupon'
      const response = await mutateAsync({
        url: `${APIS.SUCCESS_CALLBACK}`,
        payload: payload,
      })

      if (response) {
        const d = new Date()
        const time = d.getTime()
        const expirationDate = new Date(time + 60 * 20)
        const expires = 'expires=' + expirationDate.toUTCString()
        localStorage.setItem('orderId', res?.d?.orderId)
        document.cookie = 'success_order_id=' + res?.d?.orderId + ';' + expires
        setShowSnackBar({ open: true, message: `${prepaidData?.m}`, severity: '' })
        window.location.href = OrderSuccessRoute.path
      }
    } catch (error: any) {
      if (error.response) {
        const res = error.response.data
        if (res.d) {
          setPrepaidError(res.d?.productList)
        }
      }
    }
  }

  const handleCodClickPay = async () => {
    try {
      const res = await mutateAsync({
        url: `${APIS.PLACED_ORDER_COD}`,
        payload: {
          action: 'placeCodOrder',
          loyaltyCoin: '0',
          slot: '',
          attchedFile: '',
          message: '',
          coinAmount: 0,
          moneyAmount: 0,
          prepaid: 0,
          customerOrderedCurrency: 'INR',
          plotch_referral: '',
          plotch_referral_key: '',
          attribution_instance_id: '',
          customerId: customerId,
        },
      })
      if (res) {
        localStorage.setItem('orderId', res.d.orderId)
        setShowSnackBar({ open: true, message: `${res.m}`, severity: '' })
        navigate.push(OrderSuccessRoute.path)
      }
    } catch (error: any) {
      if (error.response) {
        const res = error.response.data
        if (res.d) {
          setPrepaidError(res.d?.productList)
        }
      }
    }
  }

  const handleClickPay = () => {
    if (selectedRadioValue === 'cod') {
      handleCodClickPay()
    } else {
      handlePrepaidClickPay()
    }
  }

  return (
    <PublicLayout>
      <CheckoutPageWrapper>
        <CheckoutWrappers>
          <CommonStyle>
            <BackWrapper className="padding_full_page" onClick={handleBack}>
              <BackArrow />
              <div className="h1b">PAYMENT</div>
            </BackWrapper>
          </CommonStyle>
          <PaymentOrderDetails
            selectedRadioValue={selectedRadioValue}
            setSelectedRadioValue={setSelectedRadioValue}
            prepaidError={prepaidError}
            setPrepaidError={setPrepaidError}
            setCheckoutDetails={setCheckoutDetails}
            googleDatalayer={setGoogledataLayer}
          />
          <CheckoutButtonWrapper>
            <Button
              label="PAY NOW"
              type="submit"
              className="small"
              onClick={selectedRadioValue ? handleClickPay : undefined}
              variant={selectedRadioValue ? 'contained' : 'disabled'}
            />
          </CheckoutButtonWrapper>
        </CheckoutWrappers>
        {prepaidData?.d?.gateway && prepaidData?.d?.gateway == 'razorpay' ? (
          <RazorpayPayment prepaidData={prepaidData} />
        ) : prepaidData?.d?.gateway == 'payu' || prepaidData?.d?.gateway == 'ccavenue' ? (
          <PayuPayment prepaidData={prepaidData} />
        ) : (
          <></>
        )}
      </CheckoutPageWrapper>
    </PublicLayout>
  )
}

export default CheckoutPage

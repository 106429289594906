import * as React from 'react'
import { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M6.875 16.25v2.502M3.126 1.25h7.5a1.25 1.25 0 0 1 1.25 1.25v16.094a.156.156 0 0 1-.156.156H1.876V2.5a1.25 1.25 0 0 1 1.25-1.25v0Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.002}
      d="M12.5 7.5h4.375a1.25 1.25 0 0 1 1.25 1.25v10h-6.25V8.125A.625.625 0 0 1 12.5 7.5Z"
    />
    <path
      fill="#000"
      d="M3.838 16.881a.63.63 0 1 1-.164-1.25.63.63 0 0 1 .164 1.25ZM3.838 13.756a.63.63 0 1 1-.163-1.251.63.63 0 0 1 .163 1.25ZM3.838 10.63a.63.63 0 1 1-.164-1.25.63.63 0 0 1 .164 1.25ZM3.838 7.506a.63.63 0 1 1-.163-1.25.63.63 0 0 1 .163 1.25ZM3.838 4.38a.63.63 0 1 1-.163-1.25.63.63 0 0 1 .163 1.25ZM6.963 13.756a.631.631 0 1 1-.164-1.251.631.631 0 0 1 .164 1.25ZM6.963 10.63a.63.63 0 1 1-.164-1.25.63.63 0 0 1 .164 1.25ZM6.963 7.506a.63.63 0 1 1-.164-1.25.63.63 0 0 1 .164 1.25ZM6.963 4.38a.63.63 0 1 1-.164-1.25.63.63 0 0 1 .164 1.25ZM10.088 16.881a.63.63 0 1 1-.164-1.25.63.63 0 0 1 .164 1.25ZM10.088 13.756a.631.631 0 1 1-.164-1.252.631.631 0 0 1 .164 1.252ZM10.088 10.63a.63.63 0 1 1-.164-1.25.63.63 0 0 1 .164 1.25ZM10.455 7.321a.63.63 0 0 0-.002-.891.63.63 0 0 0-.891-.002.63.63 0 0 0 .002.89.63.63 0 0 0 .891.003ZM10.088 4.38a.63.63 0 1 1-.164-1.25.63.63 0 0 1 .164 1.25ZM15.631 15.625a.63.63 0 1 0 0 1.26.63.63 0 0 0 0-1.26ZM15.631 12.5a.63.63 0 1 0 0 1.26.63.63 0 0 0 0-1.26ZM15.631 9.374a.63.63 0 1 0 0 1.262.63.63 0 0 0 0-1.262ZM13.132 15.625a.63.63 0 1 0 0 1.261.63.63 0 0 0 0-1.261ZM13.132 12.5a.63.63 0 1 0 0 1.261.63.63 0 0 0 0-1.261ZM13.132 9.374a.63.63 0 1 0 0 1.262.63.63 0 0 0 0-1.262Z"
    />
  </svg>
)
export default SvgComponent

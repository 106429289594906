import { useContext, useState, createContext } from 'react'
/* eslint-disable @typescript-eslint/no-explicit-any */
interface IFilter {
  menu: any
  setMenu: (val: any) => void
}

const DefaultValues = {
  menu: [],
  setMenu: () => null,
}

const MenuContext = createContext<IFilter>(DefaultValues)

const MenuProvider = ({ children }: { children: React.ReactNode }) => {
  //   const [filterProduct, setFilterProduct] = useState([])
  const [menu, setMenu] = useState([])

  return <MenuContext.Provider value={{ menu, setMenu }}>{children}</MenuContext.Provider>
}

export default MenuProvider

export const useMenuContext = () => {
  return useContext(MenuContext)
}

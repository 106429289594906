import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
// import usePost from 'hooks/usePost'
import PublicLayout from 'components/Layouts/public'
import ListProducts from 'components/ListProduct'
import APIS from 'constants/api'
import { NotFoundRoute } from 'constants/routes'
import { useFilter } from 'context/FliterContext'
// import { LoaderContext } from 'context/loader'
import { Product } from 'interfaces/pages'
import ListingType from 'views/ListingType'
import { CommonStyle } from 'styles/pages/Common'
import { DataWrapper } from 'styles/pages/SearchPage'
import { useHomeContext } from 'context/HomeContext'
import { useTheme } from 'context/themeContext'
import useGet from 'hooks/useGet'

const ListingPage = () => {
  const location = useLocation()
  const [productDetails, setProductDetails] = useState<Product[]>([])
  const [loadMoreData, setLoadMoreData] = useState(false)
  const [sectionType, setSectionType] = useState<Product[]>([])
  const [pageType, setPageType] = useState()
  const [filtersData, setFiltersData] = useState<any>([])
  const [filtersSortData, setFiltersSortData] = useState<any>([])
  const [productCount, setProductCount] = useState('')
  const [productInitialCount, setProductInitialCount] = useState('')
  const [feedDetails, setFeedDetails] = useState<any>()
  const [isSorting, setIsSorting] = useState(false)
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768)
  // const { setLoader } = useContext(LoaderContext)
  const { filterProduct, filterSortProduct } = useFilter()
  const [params, setParams] = useState('')
  // const { mutateAsync } = usePost()
  const { setHomeData } = useHomeContext()
  const router = useHistory()
  const { storesData } = useTheme()
  const searchUrlParam = new URLSearchParams(window.location.search)
  const page = searchUrlParam.get('page_id') || 1
  const [filter, setFilter] = useState({
    page: page,
    perPage: 48,
  })

  // useEffect(() => {
  //   if (isLoading) //setLoader(true)
  //   else //setLoader(false)
  // }, [isLoading])

  const { refetch, data } = useGet('dynamic-url-details', `${APIS.DYNAMICURL_v1}?${params.toString()}`)

  const addParam = (prefix: string, obj: any, queryParams: URLSearchParams) => {
    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        if (typeof item === 'object') {
          // Handle array of objects by calling addParam with each object
          Object.keys(item).forEach((key) => addParam(`${prefix}[${key}]`, item[key], queryParams))
        } else {
          queryParams.append(`${prefix}`, item)
        }
      })
    } else if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach((key) => {
        addParam(`${prefix}[${key}]`, obj[key], queryParams)
      })
    } else {
      queryParams.append(prefix, String(obj))
    }
  }

  useEffect(() => {
    if (params) {
      refetch()
    }
  }, [params])

  useEffect(() => {
    try {
      if (data) {
        setPageType(data?.d?.pageType)
        if (data?.d?.pageType == 'linked') {
          localStorage.setItem('pt', 'listing page')
        } else {
          localStorage.setItem('pt', 'landing page')
        }

        data?.d?.sections && setSectionType(data?.d?.sections)
        data?.d?.displayFilters && setFiltersData(data?.d?.displayFilters)
        data?.d?.feedDetails && setFeedDetails(data?.d?.feedDetails)
        data?.d?.feedDetails && setHomeData(data?.d?.feedDetails)
        data?.d?.sorts && setFiltersSortData(data?.d?.sorts)

        if (!isLargeScreen && loadMoreData) {
          if (isSorting) {
            setProductDetails(data?.d?.products)
            setIsSorting(false)
          } else data?.d?.products && setProductDetails((prev: Product[]) => [...prev, ...data?.d?.products])
        } else {
          setProductDetails(data?.d?.products)
        }

        setProductInitialCount(data?.d?.productCount)
        setProductCount(data?.d?.totalProducts)
        //setLoader(false)
      }
    } catch (error: any) {
      //setLoader(false)
      // return false
      router.push(NotFoundRoute.path)
      return error
    }
  }, [data])

  const fetchData = async (pageUrl: string, paramsArray: any, sortBy: any) => {
    try {
      const finalFilter = paramsArray.map((filter: any) => {
        const transformedFilter: any = []

        for (const key in filter) {
          if (Object.prototype.hasOwnProperty.call(filter, key)) {
            transformedFilter[key] = filter[key].join(', ')
          }
        }

        return transformedFilter
      })
      const payload: any = {
        pageUrl: pageUrl,
        pageId: filter.page,
        perPage: filter.perPage,
        filters: finalFilter,
        sortBy: sortBy,
      }
      // console.log(payload)

      const queryParams = new URLSearchParams()
      // Add each property of payload to queryParams
      Object.keys(payload).forEach((key) => {
        addParam(key, (payload as any)[key], queryParams)
      })

      setParams(queryParams.toString())
      // const res = await mutateAsync({
      //   url: APIS.DYNAMICURL,
      //   payload: {
      //     pageUrl: pageUrl,
      //     pageId: filter.page,
      //     perPage: filter.perPage,
      //     filters: paramsArray,
      //     sortBy: sortBy,
      //   },
      // })
      //
    } catch (error: any) {
      //setLoader(false)
      return false
      router.push(NotFoundRoute.path)
      return error
    }
  }

  // useEffect(() => {
  //   const path = location.pathname.split('/').pop() // Extract the last segment of the path
  //   if (path) {
  //     fetchData(`http://${process.env.REACT_APP_PLOTCH_DOMAIN}/${path}`)
  //   }
  // }, [location.pathname, filter.page, filterProduct, filterSortProduct])

  useEffect(() => {
    const path = location.pathname.split('/').pop() // Extract the last segment of the path
    const queryString = window.location.search.split('?')[1] // Get the query string part of the URL
    const searchParams = new URLSearchParams(queryString)

    const filtersObject: { [key: string]: string[] } = {}
    const sortObject: any = {}

    searchParams.forEach((value, key) => {
      if (key == 'sort_by') {
        if (value == 'priceasc') sortObject['discountedPriceOrder'] = 'asc'
        else if (value == 'pricedesc') sortObject['discountedPriceOrder'] = 'desc'
        else if (value == 'popular') sortObject['relevance'] = 'desc'
      } else {
        filtersObject[key] = value.split(',')
      }
    })

    if (path || filterProduct.length != 0) {
      fetchData(
        `/${path}`,
        Object.keys(filtersObject).length ? [filtersObject] : [],
        Object.keys(sortObject).length ? sortObject : {},
      )
    }
  }, [location.pathname, filter.page, filterProduct, filterSortProduct])

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (storesData) {
      if (storesData?.d?.pageConfig) {
        const pageConfig = storesData?.d?.pageConfig
        const enableDynamicCache = pageConfig?.dynamic_page_cache
        const dynamicTTL = pageConfig?.dynamic_ttl
        if (enableDynamicCache == 1 && dynamicTTL != '') {
          const metaTag = document.createElement('meta')
          metaTag.httpEquiv = 'Cache-Control'
          metaTag.content = `public,max-age=${dynamicTTL}`
          document.head.appendChild(metaTag)

          return () => {
            document.head.removeChild(metaTag)
          }
        }
      }
    }
  }, [storesData])

  return (
    <PublicLayout>
      {pageType === 'linked' ? (
        <CommonStyle>
          {productDetails.length > 0 ? (
            <ListProducts
              products={productDetails}
              setFilter={setFilter}
              productCount={productCount}
              productInitialCount={productInitialCount}
              filter={filter}
              feedDetails={feedDetails}
              filtersData={filtersData}
              filtersSortData={filtersSortData}
              setIsSorting={setIsSorting}
              setLoadMoreData={setLoadMoreData}
            />
          ) : (
            <DataWrapper className="h1b"> No Data</DataWrapper>
          )}
        </CommonStyle>
      ) : (
        <CommonStyle>
          {sectionType.length ? <ListingType sectionType={sectionType} /> : <DataWrapper className="h1b" />}
        </CommonStyle>
      )}
    </PublicLayout>
  )
}

export default ListingPage

import { ChangeEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import CurrentLocation from 'components/CurrentLocation'
// import { LoaderContext } from 'context/loader'
import { LoginRoute } from 'constants/routes'
import CloseIcon from 'assets/svg/CloseIcon'
import Line from 'assets/svg/Line'
import LocationIcon from 'assets/svg/LocationIcon'
import SearchIcon from 'assets/svg/SearchIcon'
import {
  LocationContainer,
  SvgWrapper,
  ImageWrapper,
  StoreNearWrapper,
  LocationText,
  LocationButtonWrapper,
  SearchWrapper,
  TypeDataContainer,
  TypeData,
} from 'styles/views/LocationNewUser'
import useDebounce from 'hooks/useDebounce'
import SearchBar from 'components/SearchBar'
import { useTheme } from 'context/themeContext'
import { pageData } from 'utils/datalayer/pageData'
import TagManager from 'react-gtm-module'

const LocationNewUser = ({ onClose }: any) => {
  const navigate = useHistory()
  // const { setLoader } = useContext(LoaderContext)
  const [suggestion, setSuggestion] = useState<any>(null)
  const [addressName, setAddressName] = useState()
  const [searchValue, setSearchValue] = useState('')
  const { themedata, storesData } = useTheme()

  const debouncedSearchValue = useDebounce(searchValue, 300)
  const { loggedIn, loggedInStatus, GDL_PAGE_TYPE } = pageData()

  const headerLogo = themedata?.d?.objectDetails?.header?.siteLogo?.logoImage
  // const fetchLocation = async () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         const { latitude, longitude } = position.coords
  //         try {
  //           const response = await fetch(
  //             `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`,
  //           )
  //           const data = await response.json()
  //           localStorage.setItem(
  //             'location',
  //             JSON.stringify({
  //               address: data.address,
  //               placeName: data.display_name,
  //               latitude,
  //               longitude,
  //             }),
  //           )

  //           onClose()
  //         } catch (error) {
  //           return error
  //         }
  //       },
  //       (error) => {
  //         return error
  //       },
  //     )
  //   } else {
  //     alert('Geolocation is not supported by this browser.')
  //   }
  // }

  const fetchLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords
          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`,
            )
            const data = await response.json()

            localStorage.setItem(
              'location',
              JSON.stringify({
                address: data.address,
                placeName: data.display_name,
                latitude,
                longitude,
              }),
            )

            // Data to push to GTM
            const dataToPush = {
              event: 'select_location_interaction',
              cta_text: 'get_current_location_using_gps',
              select_type: 'get_current_location_using_gps', // enter_your_location etc,
              page_type: GDL_PAGE_TYPE,
              User_Id: loggedIn,
              User_Status: loggedInStatus,
            }

            // Push data to GTM and wait for completion
            const pushToDataLayer = () => {
              return new Promise<void>((resolve) => {
                storesData?.d?.googleDatalayer &&
                  storesData?.d?.googleDatalayer == 1 &&
                  TagManager.dataLayer({ dataLayer: dataToPush })
                resolve()
              })
            }

            await pushToDataLayer()

            onClose()
          } catch (error) {
            return error
          }
        },
        (error) => {
          return error
        },
      )
    } else {
      alert('Geolocation is not supported by this browser.')
    }
  }

  const handleSubmit = () => {
    navigate.push(LoginRoute.path + '?redirect=add-address')
  }

  // const handleClose = () => {
  //   // console.log('close')
  //   const location = localStorage.getItem('location')

  //   if (location == undefined || location.length == 0) {
  //     localStorage.setItem(
  //       'location',
  //       JSON.stringify({
  //         address: 'India',
  //         placeName: 'Nagpur',
  //         latitude: 20.5937,
  //         longitude: 78.9629,
  //       }),
  //     )
  //     window.location.reload()
  //   }

  //   onClose()
  // }
  const handleClose = async () => {
    const location: any = localStorage.getItem('location')
    // const locationData = JSON.parse(location)

    const dataToPush = {
      event: 'select_location_interaction',
      cta_text: 'close_icon',
      select_type: 'close',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
    }

    const pushToDataLayer = () => {
      return new Promise<void>((resolve) => {
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: dataToPush })
        resolve()
      })
    }

    await pushToDataLayer()

    if (!location || location.length === 0) {
      localStorage.setItem(
        'location',
        JSON.stringify({
          address: 'India',
          placeName: 'Nagpur',
          latitude: 20.5937,
          longitude: 78.9629,
        }),
      )
    }

    onClose()
  }

  useEffect(() => {
    const searchAddress = async (value: string) => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=jsonv2&q=${value}&addressdetails=1&countrycodes=IN`,
        )
        const data = await response.json()
        setSuggestion(data)
      } catch (error) {
        return error
      }
    }

    if (debouncedSearchValue) {
      searchAddress(debouncedSearchValue)
    }
  }, [debouncedSearchValue])

  // const handleSuggestionItem = (item: any) => {
  //   setAddressName(item?.display_name)
  //   setSuggestion(null)
  //   localStorage.setItem(
  //     'location',
  //     JSON.stringify({
  //       address: { place: item?.display_name, postcode: item?.name },
  //       latitude: item?.lat,
  //       longitude: item?.lon,
  //     }),
  //   )

  //   const dataToPush = {
  //     event: 'select_location_interaction',
  //     cta_text: addressName,
  //     select_type: addressName,
  //     page_type: GDL_PAGE_TYPE,
  //     User_Id: loggedIn,
  //     User_Status: loggedInStatus,
  //   }
  //   TagManager.dataLayer({ dataLayer: dataToPush })
  //   console.log('🚀 ~ handleSuggestionItem ~ dataToPush:', dataToPush)
  //   // window.location.reload()
  //   // onClose()
  //   setTimeout(() => {
  //     // window.location.reload()
  //     // onClose()
  //   }, 100) // 100ms delay, adjust as needed

  // }

  const handleSuggestionItem = async (item: any) => {
    setAddressName(item?.display_name)
    setSuggestion(null)
    localStorage.setItem(
      'location',
      JSON.stringify({
        address: { place: item?.display_name, postcode: item?.name },
        latitude: item?.lat,
        longitude: item?.lon,
      }),
    )

    const dataToPush = {
      event: 'select_location_interaction',
      cta_text: item?.display_name,
      select_type: 'enter_your_location',
      page_type: GDL_PAGE_TYPE,
      User_Id: loggedIn,
      User_Status: loggedInStatus,
    }

    // Wrap dataLayer push in a Promise
    const pushToDataLayer = () => {
      return new Promise<void>((resolve) => {
        storesData?.d?.googleDatalayer &&
          storesData?.d?.googleDatalayer == 1 &&
          TagManager.dataLayer({ dataLayer: dataToPush })
        resolve()
      })
    }

    // Wait for data to be pushed before closing the drawer
    await pushToDataLayer()
    onClose()
  }

  return (
    <LocationContainer>
      <SvgWrapper>
        <CloseIcon onClick={handleClose} />
      </SvgWrapper>
      <ImageWrapper>
        <img src={headerLogo} alt="WebsiteLogo" />
      </ImageWrapper>
      <StoreNearWrapper>
        <LocationIcon />
        <LocationText>To shop from stores near you, we need your location</LocationText>
      </StoreNearWrapper>
      <SearchWrapper>
        <SearchBar
          placeholder="Building name, locality, pincode etc"
          prefix={<SearchIcon />}
          name="search"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          value={addressName}
        />
        {searchValue.length > 2 && (
          <>
            {suggestion ? (
              <TypeDataContainer>
                {suggestion?.map((item: any, index: number) => (
                  <div key={index}>
                    <TypeData
                      onClick={() => {
                        handleSuggestionItem(item)
                      }}
                    >
                      {item.display_name}
                    </TypeData>
                  </div>
                ))}
              </TypeDataContainer>
            ) : null}
          </>
        )}
      </SearchWrapper>

      <div onClick={fetchLocation}>
        <CurrentLocation />
      </div>
      <LocationButtonWrapper>
        <Button label="Skip" variant="outline" type="submit" className="skip" onClick={handleClose} />
        <Line />
        <Button label="Add Address" variant="contained" type="submit" className="small" onClick={handleSubmit} />
      </LocationButtonWrapper>
    </LocationContainer>
  )
}

export default LocationNewUser
